import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { get } from "lodash";
import { Button, Col, Container, Row } from "reactstrap";

// core components
import BasicHeader from "components/Headers/BasicHeader.jsx";
import { FORM_NAME_PLURAL } from "utils/constants";
import CardForms from "components/Forms/CardForms";
import NewFormModal from "components/UserManagement/NewFormModal";
import ManagementModal from "components/UserManagement/ManagementModal";
import PublishCodeContainer from "./publish/PublishCodeContainer";

const CURRENT_USER_FORMS = gql`
  query GetWorkspaceForms($id: ID!) {
    workspace(id: $id) {
      id
      forms {
        id
        name
        description
        lastUpdate
        status
        publishedAt
        renderGuid
        floatingButtonSchema
        buttonSchema
        formHash
        workspaceHash
        userId
        lead {
          count
          items {
            value
            createdAt
          }
          totalCost
          interestedCost
        }
      }
    }
  }
`;

const FormsList = () => {
  const { currentWorkspace, currentUser } = useSelector((state) => state.user);
  const { loading, error, data, refetch } = useQuery(CURRENT_USER_FORMS, {
    variables: { id: currentWorkspace.id }
  });
  const [showModal, setShowModal] = useState(null);
  const [codeModal, setCodeModal] = useState(null);
  const [buttonTo, setButtonTo] = useState("popup");
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1268 })

  if (error && !data) {
    // show error
  }

  const forms = get(data, ["workspace", "forms"], []);

  return (
    <>
      <BasicHeader>
        <Row className="mt-2">
          <Col className="col-6">
            <h1 className="text-white d-inline-block">Your {FORM_NAME_PLURAL}</h1>
          </Col>
          {!loading && forms && forms.length > 0 && (
            <Col className="col-6 text-right">
              {!isTabletOrMobile && (
                <Button color="success" onClick={() => setShowModal({ type: "newForm" })}>
                  <i className="fas fa-plus" /> Add New Form
                </Button>
              )}
            </Col>
          )}
        </Row>
      </BasicHeader>
      <Container className="mt--7" fluid>
        <CardForms
          currentWorkspace={currentWorkspace}
          isDataReady={loading}
          refetchData={refetch}
          cardFormsData={data}
          showNoLeadsMsg={true}
          setCodeModal={setCodeModal}
        />
      </Container>
      {showModal && showModal.type === "newForm" ? <NewFormModal onClose={() => setShowModal(null)} /> : null}
      {codeModal && 
        <ManagementModal
          modalSize="lg"
          classNames={{
            modal: "modal-dialog modal-dialog-centered",
            header: "text-white",
            footer: "bg-gradient-light py-3"
          }}
          customClose={<Button style={{background: 'transparent', borderColor: 'transparent', color: 'white'}} onClick={() => setCodeModal(null)}><i className="fas fa-times fa-2x"></i></Button>}
          styles={{ modal: { maxWidth: "850px" }, header: { backgroundColor: "#172b4d" } }}
          header={
            <div>
              <h3 className="ml-3 mb-0 display-3 text-white">Add Your Form to Your Website</h3>
            </div>
          }
          buttons={
            [{
              label: "Close",
              color: "secondary",
              size: "md",
              onClick: () => setCodeModal(null),
              className: "mr-auto"
            }]  
          }
        >
          <PublishCodeContainer
            formId={codeModal.formId}
            buttonTo={buttonTo}
            setButtonTo={setButtonTo}
            workspaceId={codeModal.workspaceId}
            company={currentUser.company}
            guid={codeModal.guid}
            userId={codeModal.userId}
            actionButtonSchema={codeModal.buttonSchema}
            floatingButtonSchema={codeModal.floatingButtonSchema}
            onClose={() => setCodeModal(null)}
          />
        </ManagementModal>
      }
    </>
  );
};

export default FormsList;
