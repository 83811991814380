import { useRef, useEffect } from "react";
/*
 * Custom hook to trigger autosave, it gets triggered on every interval * seconds, if there are
 * changes present.
 * Interval in seconds: integer
 * changesArray would be updatedSections
 * callback is called once interval is hit
 */
export function useAutoSave(interval, changesArray, callback) {
  const changesArrayRef = useRef(changesArray);
  const intervalRef = useRef(null);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    changesArrayRef.current = changesArray;
    if (changesArray.length > 0 && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        callback();
      }, interval * 1000);
    } else if (changesArray.length === 0) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }
  }, [changesArray]);
}
