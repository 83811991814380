import { FieldRegistry } from "./FieldRegistry";
import TextField from "./TextField";
import TextAreaField from "./TextAreaField";
import NumberField from "./NumberField";
import RadioField from "./RadioField";
import SelectField from "./SelectField";
import CheckboxField from "./CheckboxField";
import EmailField from "./EmailField";
import PhoneField from "./PhoneField";
import AddressField from "./AddressField";
import HeadlineField from "./HeadlineField";
import DateField from "./DateField";
import DateRangeField from "./DateRangeField";
import TypeaheadField from "./TypeaheadField";
import GoogleFontTypeaheadField from "./GoogleFontTypeaheadField";
import ColorPickerField from "./ColorPickerField";
import FileField from "./FileField";
import TextBlockField from "./TextBlockField";
import DividerField from "./DividerField";
import ImageField from "./ImageField";

// Object with the sections for the Elements slide-out
export const SinapiFieldsSections = [
  {
    values: [RadioField, CheckboxField, SelectField, NumberField],
    title: "Has Dollar Values",
    icon: "fas fa-dollar-sign",
    color: "success"
  },
  {
    values: [EmailField, PhoneField, AddressField],
    title: "Lead Details",
    icon: "fas fa-user-edit",
    color: "primary"
  },
  {
    values: [HeadlineField, TextBlockField, DividerField, TextField, TextAreaField, DateField, DateRangeField, ImageField],
    title: "General Information",
    icon: "fas fa-edit",
    color: "teal"
  }
];

const SinapiFields = [
  RadioField,
  CheckboxField,
  SelectField,
  NumberField,
  EmailField,
  PhoneField,
  AddressField,
  HeadlineField,
  TextBlockField,
  TextField,
  TextAreaField,
  DateField,
  DateRangeField,
  TypeaheadField,
  GoogleFontTypeaheadField,
  ColorPickerField,
  FileField,
  DividerField,
  ImageField,
];
const SinapiFieldRegistry = new FieldRegistry(SinapiFields);

export { SinapiFieldRegistry };
