import React from "react";
import { Button, Card, Row, Col, CardBody, CardFooter, Input } from "reactstrap";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => {
  const { fontImport, ...styles } = theme;
  return {
    "@import": fontImport || [],
    themePreviewCard: {
      ...styles
    }
  };
});
export function ThemePreview({ onClick, onClone, onDelete, onEdit, allowGlobalEdit, onMakeGlobal, theme, active }) {
  const classes = useStyles({ theme });
  return (
    <div
      className={"preview-theme-card " + classes.themePreviewCard}
      style={{ minHeight: "210px", marginBottom: "0.15rem", padding: "20px 0px", position: 'relative' }}
    >
      <div className="preview-theme-card-overlay" onClick={onClick}></div>
      {active && (
        <div style={{ position: "absolute", zIndex: "1", margin: "10px" }}>
          <span className="fa-stack">
            <span
              className="fas fa-circle fa-stack-2x text-success border border-white rounded-circle"
              style={{ width: "34px", height: "34px", boxShadow: "2px 2px 2px rgb(0, 0, 0, 0.15)" }}
            ></span>
            <i className="fas fa-check fa-stack-1x text-white" style={{ marginLeft: "-0.15rem" }} />
          </span>
        </div>
      )}
      <Card
        className="mx-4 shadow-none preview-card p-0"
        onClick={onClick}
        style={{ marginBottom: "1px", height: "auto", padding: "15px 20px" }}
      >
        <CardBody>
          <div className="theme-preview-toolbar">
            {allowGlobalEdit ? (
              <div className="make-global-question field-option-btn question-option" onClick={onMakeGlobal}>
                <i className="fas fa-globe" />
              </div>
            ) : null}
            {onClone ? (
              <div className="copy-question field-option-btn question-option" onClick={onClone}>
                <i className="fas fa-copy" />
              </div>
            ) : null}
            {onEdit ? (
              <div className="edit-question field-option-btn question-option" onClick={onEdit}>
                <i className="fa fa-pencil-alt" />
              </div>
            ) : null}
            {onDelete ? (
              <div className="delete-question field-option-btn question-option" onClick={onDelete}>
                <i className="fa fa-trash-alt" />
              </div>
            ) : null}
          </div>
          <Row>
            <Col xs="6">
              <label className="form-control-label">
                <div style={{ cursor: "pointer" }}>Question Label</div>
                <small className="text-muted" style={{ cursor: "pointer" }}>
                  Description of question
                </small>
              </label>
            </Col>
            <Col xs="6">
              {/* <label className="form-control-label sinapi-field-label">
                <div className="label-headline">
                  Text Label
                </div>
              </label> */}
              <Input
                className="form-control-sm"
                type="text"
                placeholder="Input"
                disabled
              />
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Col className="text-right pr-0 pl-0" xs={12}>
            <Button
              outline
              className="btn-icon float-left"
              color="secondary"
              type="button"
              size="sm"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-chevron-left"></i>
              </span>
              <span className="btn-inner--text" style={{fontSize:12}}>Back</span>
            </Button>
            <Button
              outline
              className="btn-icon"
              color="primary"
              type="button"
              size="sm"
            >
              <span className="btn-inner--text" style={{fontSize:12}}>Continue</span>
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-chevron-right"></i>
              </span>
            </Button>
          </Col>
        </CardFooter>
      </Card>
    </div>
  );
}
