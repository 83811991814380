import React from "react";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import CompanyForm from "components/Forms/CompanyForm";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { isEmptyCompanyData } from "utils/helperFunctions";

const Company = () => {
  const { currentUser } = useSelector((state) => state.user);
  const reduxDispatch = useDispatch();

  const onCompanySave = () => {
    reduxDispatch({ type: "REFETCH_USER" });
  };

  const hasCompany = !!currentUser.company;
  const company = currentUser.company;
  const freePlan = company && isEmpty(company.stripeCustomerId) ? true : false;
  const isCompanyDataIncomplete = company && isEmptyCompanyData(currentUser);
  return (
    <>      
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "160px"
        }}
      >
        <span className="mask bg-gradient-info" />

        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="12">
              {!hasCompany && (
                <h2 className="text-white mt-2">You do not have a company created yet, please create one below.</h2>
              )}
              {isCompanyDataIncomplete && (
                <h2 className="text-white mt-2">You have to complete the company data.</h2>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="10">
            <CompanyForm
              companyData={company}
              onSave={onCompanySave}
              setToEdit={!hasCompany || isCompanyDataIncomplete}
              freePlan={freePlan}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Company;
