import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost-upload";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";

export const AuthContext = React.createContext();
export const useAuth0 = () => useContext(AuthContext);

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      firstName
      lastName
      email
      phone
      roleId
      workspaces {
        items {
          id
          name
          userRoleId
        }
      }
      company {
        id
        name
        address
        city
        state
        zipcode
        planId
        logoPath
        subdomain
        customDomain {
          id
          domain
        }
        enableCustomDomain
        stripeCustomerId
        subscriptions {
          status
          id
          cancelAt
          trialEnd
          items {
            id
            plan {
              product
            }
          }
          latestInvoice {
            id
            status
            paymentIntent {
              status
            }
          }
        }
        paymentMethod {
          type
        }
      }
    }
  }
`;

const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout
  }
`;

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const [error, setError] = useState();
  const [login, { data: loginData, loading: loginLoading, error: loginError }] = useMutation(LOGIN_MUTATION);
  const [logout, { data: logoutData, loading: logoutLoading, error: logoutnError }] = useMutation(LOGOUT_MUTATION);

  useEffect(() => {
    if (!loginLoading && loginData) {
      if (loginError) {
        setError(get(loginError, ["graphQLErrors", 0, "message"]));
      } else {
        dispatch({ type: "SET_CURRENT_USER_LOGIN", payload: loginData });
      }
    }
  }, [loginData, loginLoading, loginError, dispatch]);

  useEffect(() => {
    if (!logoutLoading && logoutData) {
      if (logoutnError) {
        setError(get(logoutnError, ["graphQLErrors", 0, "message"]));
      } else {
        dispatch({ type: "LOGOUT", payload: logoutData });
      }
    }
  }, [logoutData, logoutLoading, logoutnError, dispatch]);

  const serializedState = localStorage.getItem("state");
  if (serializedState !== null) localStorage.removeItem("state");

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: user.loaded,
        user: user,
        loginError: error,
        clearLoginError: () => setError(),
        loaded: user.loaded,
        login: (...p) => login(...p),
        logout: () => logout()
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
