import React, { useEffect, useRef, useState } from "react";
import NotificationAlert from "components/NotificationAlert";

import moment from "moment";
import { defaultTo, isEmpty } from "lodash";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col, Button } from "reactstrap";
import DatePicker from "reactstrap-date-picker";

const notificationOptions = (error) => ({
  place: "bc",
  message: (
    <div className="alert-text ml-2">
      <span data-notify="message" className="ml-2">
        {error}
      </span>
    </div>
  ),
  type: "danger",
  icon: "fas fa-exclamation-triangle",
  autoDismiss: 7
});

const AnalyticsHeader = ({
  name,
  count,
  totalCost,
  totalOpens,
  status,
  conversionRate,
  startRange,
  endRange,
  setEndRange,
  setStartRange
}) => {
  const notificationAlert = useRef(null);
  const [errors, setErrors] = useState();

  const onChangeStartRange = (value) => {
    const date = moment(value).format("YYYY-MM-DD") || "";
    if (endRange) {
      if (isEmpty(date) || moment(date).isSameOrBefore(endRange)) {
        setErrors();
        setStartRange(date);
      } else {
        setErrors("The provided date is not in a valid range");
      }
    } else {
      setStartRange(date);
    }
  };

  const onChangeEndRange = (value) => {
    const date = moment(value).format("YYYY-MM-DD") || "";
    if (startRange) {
      if (isEmpty(date) || moment(startRange).isSameOrBefore(date)) {
        setErrors();
        setEndRange(date);
      } else {
        setErrors("The provided date is not in a valid range");
      }
    } else {
      setEndRange(date);
    }
  };

  useEffect(() => {
    if (errors) notificationAlert.current.notificationAlert(notificationOptions(errors));
  }, [errors]);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="header bg-gradient-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="5" xs="12">
                <h1 className="text-white d-inline-block mb-0">{name}</h1>
              </Col>
              <Col lg="7">
                <div className="form-inline-date" style={{ justifyContent: window.innerWidth < 992 ? "unset" : "flex-end" }}>
                  <DatePicker
                    size="sm"
                    showTodayButton={true}
                    dateFormat="MM/DD/YYYY"
                    onFocus={() => setErrors()}
                    value={defaultTo(startRange, "")}
                    onChange={(v) => onChangeStartRange(v)}
                  />
                  <span className="text-white mx-2" style={{ fontWeight: "600", fontSize: "1.5rem" }}>
                    -
                  </span>
                  <DatePicker
                    size="sm"
                    showTodayButton={true}
                    dateFormat="MM/DD/YYYY"
                    onFocus={() => setErrors()}
                    value={defaultTo(endRange, "")}
                    onChange={(v) => onChangeEndRange(v)}
                  />
                  <Button
                    className={`mr-0 ml-2`}
                    size="sm"
                    style={{ height: "31px", fontSize: "0.65rem" }}
                    color="info"
                    onClick={() => {
                      setStartRange(moment().subtract(1, "months").add(1, "days").format("YYYY-MM-DD"));
                      setEndRange(moment().format("YYYY-MM-DD"));
                    }}
                  >
                    Reset <i className="fas fa-sync-alt" />
                  </Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs="6" md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          Total Value
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{totalCost}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="ni ni-money-coins" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          Total Leads
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{count}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="6" md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          Total Opens
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{totalOpens}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <i className="fas fa-door-open"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              {status !== undefined && (
                <Col xs="6" md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                            Status
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {status === "draft" ? "UNPUBLISHED" : "LIVE"}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            {status === "draft" ? (
                              <i className="fas fa-pencil-ruler" />
                            ) : (
                              <i className="fas fa-thumbs-up" />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
              {conversionRate !== undefined && (
                <Col xs="6" md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                            Interested Rate
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{conversionRate} %</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            <i className="fas fa-clone" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AnalyticsHeader;
