import React, { useState, useEffect } from "react";
// react library for routing
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import ManagementModal from "./ManagementModal";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { Input } from "reactstrap";

const GET_WORKSPACES = gql`
  query getUsers($companyId: ID!) {
    company(id: $companyId) {
      workspaces {
        count
        items {
          id
          name
        }
      }
    }
  }
`;

const ADD_USER_TO_WORKSPACE = gql`
  mutation addUserToWorkspace($userId: ID!, $workspaceId: ID!) {
    workspaceAddUser(userId: $userId, workspaceId: $workspaceId) {
      id
    }
  }
`;

function AddWorkspaceToUserModal({ userId, userName, companyId, currentUserWorkspaceIds, onClose }) {
  const { loading, data } = useQuery(GET_WORKSPACES, {
    fetchPolicy: "no-cache",
    variables: {
      companyId: companyId
    }
  });
  const [addUserMutation, { loading: addingWorkspace, data: newWorkspaceData }] = useMutation(ADD_USER_TO_WORKSPACE);
  const [availableWorkspaces, setAvailableWorkspaces] = useState([]);
  const [noAvailableWorkspaces, setNoAvailableWorkspaces] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);

  useEffect(() => {
    if (!loading && data && data.company) {
      const avWorkspaces = data.company.workspaces.items.filter((work) => !currentUserWorkspaceIds.includes(work.id));
      if (avWorkspaces.length > 0) {
        setAvailableWorkspaces(avWorkspaces);
      } else {
        setNoAvailableWorkspaces(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!addingWorkspace && newWorkspaceData) {
      onClose(true);
    }
  }, [addingWorkspace]);

  const addWorkspace = () => {
    addUserMutation({
      variables: {
        workspaceId: selectedWorkspace,
        userId: userId
      }
    });
  };

  return (
    <ManagementModal
      modalSize="md"
      onClose={() => onClose()}
      header={<div>Add workspace to "{userName}":</div>}
      buttons={[
        { label: "Cancel", outline: true, onClick: () => onClose() },
        { label: "Add To User", onClick: () => addWorkspace(), disabled: noAvailableWorkspaces || !selectedWorkspace }
      ]}
    >
      {loading || addingWorkspace ? <LoaderSpinner /> : null}
      <label className="form-control-label">
        Workspace {noAvailableWorkspaces ? " (no new workspaces available)" : null}
      </label>
      <Input
        type="select"
        className="form-control-sm"
        onChange={(e) => setSelectedWorkspace(e.target.value !== "-" ? e.target.value : null)}
      >
        <option name="-" value="-">
          -
        </option>
        {availableWorkspaces.map((workspace) => {
          return (
            <option key={workspace.id} name={workspace.id} value={workspace.id}>
              {workspace.name}
            </option>
          );
        })}
      </Input>
    </ManagementModal>
  );
}

AddWorkspaceToUserModal.defaultProps = {};

AddWorkspaceToUserModal.propTypes = {
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  currentUserWorkspaceIds: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

export default AddWorkspaceToUserModal;
