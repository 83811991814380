import React from "react";
import { useEffect, useRef } from "react";

// react library for routing
import { useLocation, useHistory, Switch, Route } from "react-router-dom";

// core components
import { routes } from "routes.js";

import "./Admin.scss";

const Public = ({ routes: customRoutes, layout, ...props }) => {
  const mainContent = useRef(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (history.pathname !== location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      if (mainContent && mainContent.current) {
        mainContent.current.scrollTop = 0;
      }
    }
  }, [location]);

  const getRoutes = (routes, breadCrumb) => {
    return routes.map((prop, key) => {
      const newBreadCrumb = [...(breadCrumb || []), prop];
      if (prop.layout === (layout || "/admin")) {
        const extraProps = prop.options || {};
        extraProps.breadCrumbs = breadCrumb;
        let viewRoutes = [];
        if ((prop.collapse || prop.views) && prop.component) {
          viewRoutes = getRoutes(prop.views, newBreadCrumb);
        }
        const newRoute = (
          <Route
            path={prop.layout + prop.path}
            strict={prop.strict || false}
            exact={prop.exact || false}
            render={(props) => {
              const RouteComp = prop.component;
              return <RouteComp {...props} {...extraProps} />;
            }}
            key={key}
          />
        );
        return [newRoute, ...viewRoutes];
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <Switch>{getRoutes(routes)}</Switch>
      </div>
    </>
  );
};

export default Public;
