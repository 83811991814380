import React from "react";
import { useState } from "react";

// nodejs library that concatenates classes
import classnames from "classnames";

// components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import { Form as FormReactstrap } from "reactstrap";

import * as yup from "yup";
import { defaultTo, get } from "lodash";
import { gql } from "apollo-boost";
import { Formik, Form, Field } from "formik";
import { useMutation, useQuery } from "@apollo/react-hooks";
import ManagementModal from "components/UserManagement/ManagementModal";
import { usePermissionsWithRole } from "hooks/usePermissionsWithRole";

const userFormValidation = yup.object().shape({
  email: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phone: yup.string(),
  roleId: yup.string()
});
function getInitialValues(orgUser) {
  return ["email", "firstName", "lastName", "phone", "roleId"].reduce(
    (init, key) => ({ ...init, [key]: orgUser ? orgUser[key] || "" : "" }),
    {}
  );
}

const specialCharRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
const PasswordRequirements = {
  passLength: { label: "At least 8 characters in length", test: (password) => (password || "").length > 7 },
  lowercase: { label: "Lower case letters (a-z)", test: (password) => /[a-z]/.test(password || "") },
  uppercase: { label: "Upper case letters (A-Z)", test: (password) => /[A-Z]/.test(password || "") },
  numbers: { label: "Numbers (i.e. 0-9)", test: (password) => /[0-9]/.test(password || "") },
  specialCharacters: {
    label: "Special characters (e.g. !@#$%^&():;*)",
    test: (password) => specialCharRegex.test(password || "")
  }
};

const GET_ROLES = gql`
  query getRoles {
    roles {
      name
      code
    }
  }
`;

const SAVE_USER_PROFILE = gql`
  mutation SaveUserProfile($userId: ID!, $userProfile: UserProfileInput!) {
    saveUserProfile(userId: $userId, userProfile: $userProfile) {
      id
    }
  }
`;

const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword($userId: ID!, $password: String!, $oldPassword: String!) {
    updatePassword(userId: $userId, password: $password, oldPassword: $oldPassword)
  }
`;

const ProfileForm = ({ userData, showRole, hasChangePass }) => {
  const [editProfile, setEditProfile] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const [state, setState] = useState({});
  const [passwordErrors, setPasswordErrors] = useState();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePasswordResp, setChangePasswordResp] = useState();
  const [validating, setValidating] = useState(false);
  const isSuperAdmin = usePermissionsWithRole("superAdmin");

  const validatePassword = (val) => {
    setNewPassword(val);
    const passwordErrors = Object.keys(PasswordRequirements).reduce((req, key) => {
      if (!PasswordRequirements[key].test(val)) {
        return {
          ...req,
          [key]: false
        };
      }
      return req;
    }, {});

    if (Object.keys(passwordErrors).length === 0) {
      setPasswordErrors();
    }
    setPasswordErrors(passwordErrors);
  };

  const validateForm = () => newPassword.length > 0 && confirmPassword.length > 0;

  async function handleSubmit(event) {
    setChangePasswordResp();
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setChangePasswordResp({
        type: "danger",
        msg: "Please ensure the new password and the confirmation are the same."
      });
    } else {
      setValidating(true);
      updatePassword({
        variables: {
          userId: userData.id,
          password: newPassword,
          oldPassword: oldPassword
        }
      }).then(({ errors }) => {
        setValidating(false);
        if (errors) {
          setChangePasswordResp({ type: "danger", msg: get(errors, [0, "message"]) });
        } else {
          setNewPassword("");
          setConfirmPassword("");
          setChangePasswordResp({ type: "success", msg: "The password was updated!" });
        }
      });
    }
  }

  const closeModal = () => {
    setChangePassword(false);
    setValidating(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setChangePasswordResp("");
  };

  const [saveUserProfile] = useMutation(SAVE_USER_PROFILE);
  const { data: rolesData } = useQuery(GET_ROLES, {
    variables: {}
  });
  const [updatePassword] = useMutation(UPDATE_PASSWORD_MUTATION);

  return (
    <>
      <Formik
        initialValues={{ ...getInitialValues(userData || {}) }}
        enableReinitialize={true}
        validationSchema={userFormValidation}
        validateOnMount={true}
        onSubmit={(values, actions) => {
          setEditProfile(false);
          saveUserProfile({
            variables: {
              userProfile: values,
              userId: userData ? userData.id : null
            }
          });
          actions.setSubmitting(false);
        }}
      >
        {(formikProps) => (
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Profile</h3>
                </Col>
                <Col xs="4" className="text-right">
                  <>
                    {hasChangePass ? (
                      <a
                        href="#pablo"
                        className="btn btn-sm btn-danger mr-0 mb-2"
                        onClick={(e) => {
                          e.preventDefault();
                          setChangePassword(true);
                        }}
                      >
                        Change Password
                      </a>
                    ) : null}
                    {!editProfile ? (
                      <a
                        href="#pablo"
                        className="btn btn-sm btn-primary ml-2 mb-2"
                        onClick={(e) => {
                          e.preventDefault();
                          setEditProfile(true);
                        }}
                      >
                        Edit profile
                      </a>
                    ) : null}
                    {editProfile ? (
                      <a
                        href="#pablo"
                        className="btn btn-sm btn-primary ml-2 mb-2"
                        onClick={(e) => {
                          e.preventDefault();
                          formikProps.submitForm();
                        }}
                      >
                        Save profile
                      </a>
                    ) : null}
                  </>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <h6 className="heading-small text-muted mb-4">User information</h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-email">
                          Email address
                        </label>
                        <Field
                          name="email"
                          id="input-email"
                          readOnly={!editProfile}
                          placeholder="Email address"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      {showRole && (
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-role">
                            Role
                          </label>
                          <Field
                            as="select"
                            name="roleId"
                            id="input-role"
                            disabled={!editProfile}
                            className="form-control"
                          >
                            <option name="-" value="">
                              -
                            </option>
                            {rolesData && rolesData.roles
                              ? rolesData.roles.map((role) => (
                                  <option key={role.code} value={role.code}>
                                    {role.name}
                                  </option>
                                ))
                              : null}
                          </Field>
                        </FormGroup>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-first-name">
                          First name
                        </label>
                        <Field
                          id="input-first-name"
                          name="firstName"
                          readOnly={!editProfile}
                          placeholder="First name"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-last-name">
                          Last name
                        </label>
                        <Field
                          id="input-last-name"
                          name="lastName"
                          readOnly={!editProfile}
                          placeholder="Last name"
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">Contact information</h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="7">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-address">
                          Address{" "}
                        </label>
                        <Input
                          id="input-address"
                          placeholder="Home Address"
                          type="text"
                          disabled
                          className="form-control"
                          value={defaultTo(get(userData, ["company", "address"]), "")}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="5">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-city">
                          City
                        </label>
                        <Input
                          id="input-city"
                          placeholder="City"
                          type="text"
                          disabled
                          value={defaultTo(get(userData, ["company", "city"]), "")}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-country">
                          Country
                        </label>
                        <Input
                          disabled
                          id="input-country"
                          placeholder="Country"
                          type="text"
                          value={defaultTo(get(userData, ["company", "state"]), "")}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-country">
                          Postal code
                        </label>
                        <Input
                          disabled
                          id="input-postal-code"
                          placeholder="Postal code"
                          type="number"
                          value={defaultTo(get(userData, ["company", "zipcode"]), "")}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-phone-number">
                          Phone number
                        </label>
                        <Field
                          name="phone"
                          id="input-phone-number"
                          placeholder="Phone number"
                          className="form-control"
                          readOnly={!editProfile}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </Card>
        )}
      </Formik>
      {changePassword && (
        <ManagementModal
          onClose={() => closeModal()}
          modalSize={"md"}
          hideFooter={true}
          header={<div>CHANGE PASSWORD</div>}
          buttons={[{ label: "Close", onClick: () => closeModal() }]}
        >
          <CardBody className="px-lg-5 pt-lg-0 pb-lg-3">
            <FormReactstrap role="form" onSubmit={handleSubmit}>
              {(!changePasswordResp || changePasswordResp.type !== "success") && (
                <>
                  {!isSuperAdmin && (
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: state.focusedOld
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-lock-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Old Password"
                          type="password"
                          onChange={(e) => setOldPassword(e.target.value)}
                          onFocus={() => setState({ focusedOld: true })}
                          onBlur={() => setState({ focusedOld: false })}
                        />
                      </InputGroup>
                    </FormGroup>
                  )}
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: state.focusedNew
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="New Password"
                        type="password"
                        onChange={(e) => validatePassword(e.target.value)}
                        onFocus={() => setState({ focusedNew: true })}
                        onBlur={() => setState({ focusedNew: false })}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={
                      classnames({
                        focused: state.focusedPassword
                      }) + " mb-1"
                    }
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirm New Password"
                        type="password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onFocus={() => setState({ focusedPassword: true })}
                        onBlur={() => setState({ focusedPassword: false })}
                      />
                    </InputGroup>
                  </FormGroup>
                </>
              )}
              {changePasswordResp ? (
                <div
                  role="alert"
                  style={{ marginTop: "16px" }}
                  className={`alert alert-${changePasswordResp.type} fade show`}
                >
                  <span className="alert-text ml-1">{changePasswordResp.msg}</span>
                </div>
              ) : null}
              <ul className="password-requirements mt-3">
                {Object.keys(PasswordRequirements).map((key) => {
                  const passwordValidity = passwordErrors || {
                    lowercase: false,
                    numbers: false,
                    passLength: false,
                    specialCharacters: false,
                    uppercase: false
                  };
                  const isValidRequirement = passwordValidity[key] !== false;
                  return (
                    !isValidRequirement && (
                      <li key={key} className={`requirement-item`}>
                        {isValidRequirement ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
                        {PasswordRequirements[key].label}
                      </li>
                    )
                  );
                })}
              </ul>
              {(!changePasswordResp || changePasswordResp.type !== "success") && (
                <div className="text-center">
                  <Button
                    className="mt-2 mb-4"
                    color="info"
                    type="submit"
                    disabled={!validateForm()}
                    style={!validateForm() ? { opacity: "0.45" } : {}}
                  >
                    {validating ? "Changing !" : "Change"}
                  </Button>
                </div>
              )}
            </FormReactstrap>
          </CardBody>
        </ManagementModal>
      )}
    </>
  );
};
export default ProfileForm;
