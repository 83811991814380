import React from "react";
import "./Billing.css";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { get, findIndex, update, words } from "lodash";
import LoaderSpinner from "components/Spinner/LoaderSpinner";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import { StripePaymentInfo } from "components/Stripe/PaymentInfo";
import CompanySubscription from "components/Subscription/CompanySubscription";
import { useDispatch, useSelector } from "react-redux";

// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";

const GET_SUBSCRIPTIONS = gql`
  {
    currentUser {
      id
      email
      company {
        id
        name
        planId
        stripeCustomerId
        subscriptions {
          status
          id
          cancelAt
          trialEnd
          items {
            id
            plan {
              id
              product
            }
          }
          latestInvoice {
            id
            status
            paymentIntent {
              status
            }
          }
        }
        paymentMethod {
          type
          card {
            last4
          }
          billingDetails {
            name
            email
          }
        }
      }
    }
    plans {
      id
      name
      description
      stripeId
      hidden
      hierarchy
      canUpgrade
      features {
        id
        name
        value
      }
      price {
        amount
        currency
        interval
        id
        nickname
      }
    }
  }
`;

const GET_FEATURES_OVERWRITE = gql`
  query GetFeaturesOverwrite($companyId: ID!) {
    companyFeatures(companyId: $companyId) {
      featureId
      value
    }
  }
`;

const Billing = ({ hideHeader }) => {
  const { currentUser } = useSelector((state) => state.user);

  const { loading, error, data, refetch } = useQuery(GET_SUBSCRIPTIONS, {
    fetchPolicy: "no-cache"
  });

  const { loading: loadFeatures, data: featuresData } = useQuery(GET_FEATURES_OVERWRITE, {
    fetchPolicy: "no-cache",
    variables: { companyId: currentUser.company.id }
  });

  const dispatch = useDispatch();

  if (loading) {
    return <LoaderSpinner />;
  }
  if (error && !data) {
    // show error
    throw error;
  }
  const compSubscriptions = data.currentUser.company && data.currentUser.company.subscriptions;
  const compSubscription = compSubscriptions && compSubscriptions.length > 0 ? compSubscriptions[0] : null;
  const compPayment = data.currentUser.company.paymentMethod;
  const compProduct = compSubscription
    ? compSubscription.status !== "canceled"
      ? data.plans.find((pr) => pr.stripeId === compSubscription.items[0].plan.product)
      : undefined
    : data.plans.find((pr) => pr.id === data.currentUser.company.planId);
  const compPrice = compSubscription
    ? compProduct && compProduct.price.find((pr) => pr.id === compSubscription.items[0].plan.id)
    : { amount: 0, interval: "unlimited" };

  if (!loadFeatures && featuresData && featuresData.companyFeatures) {
    featuresData.companyFeatures.map((feature) => {
      const product = compProduct && compProduct.features.find((pr) => pr.id === feature.featureId);
      const productNameWords = words(get(product, "name"));
      const idx = findIndex(productNameWords, (val) => product.value === val);

      if (parseInt(feature.value) > 1)
        update(product, "name", (fea) => fea.replace(productNameWords[idx + 1], productNameWords[idx + 1].concat("s")));
      update(product, "name", (fea) => fea.replace(product.value, feature.value));
      update(product, "value", () => feature.value);
      return product;
    });
  }

  return (
    <>
      {!hideHeader ? <SimpleHeader name="Subscription Details" /> : null}
      <Container className={!hideHeader ? "mt--6 billing-container" : "billing-container"} fluid>
        <Row>
          <Col className="order-xl-1" xl="10">
            <CompanySubscription
              companyData={data.currentUser.company}
              plans={data.plans}
              price={compPrice}
              plan={data.currentUser.company.planId}
            />
            {compPayment && (
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Payment Info</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <StripePaymentInfo
                    paymentInfo={compPayment}
                    currSubscription={compSubscription}
                    compProduct={compProduct}
                    user={data.currentUser}
                    customerId={data.currentUser.company.stripeCustomerId}
                    onSuccessUpdate={() => {
                      refetch();
                      if (!compPayment) {
                        dispatch({ type: "REFETCH_USER" });
                      }
                    }}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Billing;
