const initialState = {};

export default function registrationReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "REGISTER_USER":
      return { ...state, newUser: payload.user, plan: payload.plan, isTrial: payload.isTrial || false }; // payload is has user object, and optional plan
    case "PICK_PLAN":
      return { ...state, plan: payload.plan, isTrial: payload.isTrial || false }; // payload contains
    case "PAYMENT_SUCCESS":
      return { ...state, subscription: payload };
    default:
      return state;
  }
}
