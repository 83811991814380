import { compact, defaultTo, get, find, forEach, has, isEmpty, map, round, sum, sumBy, toNumber, trim } from "lodash";

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getFullNameOrEmail = (user) => {
  const fullName = defaultTo(user.firstName, "") + " " + defaultTo(user.lastName, "");
  const email = defaultTo(user.email, "");
  return trim(fullName) !== "" ? fullName : trim(email) !== "" ? email : "";
};

export const validateEmail = (email) => {
  const EMAIL_REGEXP =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return EMAIL_REGEXP.test(email) ? false : true;
};

/**
 * This validation only accepts phone numbers allowed in the NANP (North America Numbering Plan)
 * Format NXX-NXX-XXXX, where N is any digit 2-9 and X is any digit 0-9
 *
 */
export const validatePhone = (phone) => {
  const NANP_REGEXP = /^(\+?1[\.\-\s]?)?\(?[2-9]\d{2}[\)\.\-\s]?\s?[2-9]\d{2}[\.\-\s]?\d{4}$/;
  return NANP_REGEXP.test(phone) ? false : true;
};

export const getCostPerQuestion = (question, value) => {
  let cost = {};
  if (["number", "select", "radio", "checkbox"].includes(question.type) && question.dollarValue) {
    if (question.hasOwnProperty("cost")) {
      (question.costStructure || ["cost"]).map((structure) => (cost[structure] = get(question, structure)));
    } else {
      const optionCost =
        question.type === "checkbox"
          ? value.map((optionCheckbox) => find(question.options, (option) => option.id === optionCheckbox))
          : find(question.options, (option) => option.id === value);
      (question.costStructure || ["cost"]).map(
        (structure) =>
          (cost[structure] =
            !isEmpty(optionCost) && question.type === "checkbox"
              ? optionCost[0][structure].hasOwnProperty("low")
                ? {
                    low: sumBy(optionCost, (opt) =>
                      toNumber(`${get(opt, [structure, "low_type"], "")}${opt[structure].low}`)
                    ),
                    expected: sumBy(optionCost, (opt) =>
                      toNumber(`${get(opt, [structure, "expected_type"], "")}${opt[structure].expected}`)
                    ),
                    high: sumBy(optionCost, (opt) =>
                      toNumber(`${get(opt, [structure, "high_type"], "")}${opt[structure].high}`)
                    )
                  }
                : sumBy(optionCost, (opt) => toNumber(`${get(opt, [`${structure}_type`], "")}${opt[structure]}`))
              : has(optionCost, "cost.low") ||
                has(optionCost, "daily.low") ||
                has(optionCost, "monthly.low") ||
                has(optionCost, "yearly.low")
              ? {
                  low: toNumber(`${get(optionCost, [structure, "low_type"], "")}${optionCost[structure].low}`),
                  expected: toNumber(
                    `${get(optionCost, [structure, "expected_type"], "")}${optionCost[structure].expected}`
                  ),
                  high: toNumber(`${get(optionCost, [structure, "high_type"], "")}${optionCost[structure].high}`)
                }
              : toNumber(`${get(optionCost, [`${structure}_type`], "")}${optionCost[structure]}`))
      );
    }
  }
  return cost;
};

const getCostInNumber = (question, value, type) => {
  const cost =
    (question &&
      get(question, ["cost", type], false) &&
      toNumber(question.cost[type].hasOwnProperty(value) ? question.cost[type][value] : question.cost[type])) ||
    0;
  return question ? (question.type !== "number" ? cost : cost * get(question, ["answer", "value"], 0)) : 0;
};

const isFixedCost = (quote) => quote.affect === "fixed-cost";

const getQuoteCost = (quote, value, type) =>
  get(quote, ["cost", type], 0).hasOwnProperty(value)
    ? get(quote, ["cost", type, value], 0)
    : get(quote, ["cost", type], 0);

export const calculateCost = (quoteJSON) => {
  let cost = {
    low: sumBy(quoteJSON, (quote) => (isFixedCost(quote) ? getCostInNumber(quote, "low", "cost") : 0)),
    high: sumBy(quoteJSON, (quote) => (isFixedCost(quote) ? getCostInNumber(quote, "high", "cost") : 0)),
    expected: sumBy(quoteJSON, (quote) => (isFixedCost(quote) ? getCostInNumber(quote, "expected", "cost") : 0))
  };
  let daily = {
    low: sumBy(quoteJSON, (quote) => (isFixedCost(quote) ? getCostInNumber(quote, "low", "daily") : 0)),
    high: sumBy(quoteJSON, (quote) => (isFixedCost(quote) ? getCostInNumber(quote, "high", "daily") : 0)),
    expected: sumBy(quoteJSON, (quote) => (isFixedCost(quote) ? getCostInNumber(quote, "expected", "daily") : 0))
  };
  let monthly = {
    low: sumBy(quoteJSON, (quote) => (isFixedCost(quote) ? getCostInNumber(quote, "low", "monthly") : 0)),
    high: sumBy(quoteJSON, (quote) => (isFixedCost(quote) ? getCostInNumber(quote, "high", "monthly") : 0)),
    expected: sumBy(quoteJSON, (quote) => (isFixedCost(quote) ? getCostInNumber(quote, "expected", "monthly") : 0))
  };
  let yearly = {
    low: sumBy(quoteJSON, (quote) => (isFixedCost(quote) ? getCostInNumber(quote, "low", "yearly") : 0)),
    high: sumBy(quoteJSON, (quote) => (isFixedCost(quote) ? getCostInNumber(quote, "high", "yearly") : 0)),
    expected: sumBy(quoteJSON, (quote) => (isFixedCost(quote) ? getCostInNumber(quote, "expected", "yearly") : 0))
  };

  forEach(quoteJSON, (quote) => {
    if (quote.affect === "adjustment") {
      if (quote.adjust !== "") {
        const question = find(quoteJSON, (option) => option.id === quote.adjust);
        cost.low = round(cost.low + quote.cost["cost"] * (getCostInNumber(question, "low", "cost") / 100), 2);
        cost.high = round(cost.high + quote.cost["cost"] * (getCostInNumber(question, "high", "cost") / 100), 2);
        cost.expected = round(
          cost.expected + quote.cost["cost"] * (getCostInNumber(question, "expected", "cost") / 100),
          2
        );

        daily.low = round(daily.low + quote.cost["cost"] * (getCostInNumber(question, "low", "daily") / 100), 2);
        daily.high = round(daily.high + quote.cost["cost"] * (getCostInNumber(question, "high", "daily") / 100), 2);
        daily.expected = round(
          daily.expected + quote.cost["cost"] * (getCostInNumber(question, "expected", "daily") / 100),
          2
        );

        monthly.low = round(monthly.low + quote.cost["cost"] * (getCostInNumber(question, "low", "monthly") / 100), 2);
        monthly.high = round(
          monthly.high + quote.cost["cost"] * (getCostInNumber(question, "high", "monthly") / 100),
          2
        );
        monthly.expected = round(
          monthly.expected + quote.cost["cost"] * (getCostInNumber(question, "expected", "monthly") / 100),
          2
        );

        yearly.low = round(yearly.low + quote.cost["cost"] * (getCostInNumber(question, "low", "yearly") / 100), 2);
        yearly.high = round(yearly.high + quote.cost["cost"] * (getCostInNumber(question, "high", "yearly") / 100), 2);
        yearly.expected = round(
          yearly.expected + quote.cost["cost"] * (getCostInNumber(question, "expected", "yearly") / 100),
          2
        );
      } else {
        cost.low = round(cost.low + cost.low * (getCostInNumber(quote, "low", "cost") / 100), 2);
        cost.high = round(cost.high + cost.high * (getCostInNumber(quote, "high", "cost") / 100), 2);
        cost.expected = round(cost.expected + cost.expected * (getCostInNumber(quote, "expected", "cost") / 100), 2);

        daily.low = round(daily.low + daily.low * (getCostInNumber(quote, "low", "cost") / 100), 2);
        daily.high = round(daily.high + daily.high * (getCostInNumber(quote, "high", "cost") / 100), 2);
        daily.expected = round(daily.expected + daily.expected * (getCostInNumber(quote, "expected", "cost") / 100), 2);

        monthly.low = round(monthly.low + monthly.low * (getCostInNumber(quote, "low", "cost") / 100), 2);
        monthly.high = round(monthly.high + monthly.high * (getCostInNumber(quote, "high", "cost") / 100), 2);
        monthly.expected = round(
          monthly.expected + monthly.expected * (getCostInNumber(quote, "expected", "cost") / 100),
          2
        );

        yearly.low = round(yearly.low + yearly.low * (getCostInNumber(quote, "low", "cost") / 100), 2);
        yearly.high = round(yearly.high + yearly.high * (getCostInNumber(quote, "high", "cost") / 100), 2);
        yearly.expected = round(
          yearly.expected + yearly.expected * (getCostInNumber(quote, "expected", "cost") / 100),
          2
        );
      }
    } else if (quote.affect === "multiplier") {
      const question = find(quoteJSON, (option) => option.id === quote.adjust);

      cost.low = round(cost.low + getQuoteCost(quote, "low", "cost") * get(question, ["answer", "value"], 0), 2);
      cost.high = round(cost.high + getQuoteCost(quote, "high", "cost") * get(question, ["answer", "value"], 0), 2);
      cost.expected = round(
        cost.expected + getQuoteCost(quote, "expected", "cost") * get(question, ["answer", "value"], 0),
        2
      );

      daily.low = round(daily.low + getQuoteCost(quote, "low", "daily") * get(question, ["answer", "value"], 0), 2);
      daily.high = round(daily.high + getQuoteCost(quote, "high", "daily") * get(question, ["answer", "value"], 0), 2);
      daily.expected = round(
        daily.expected + getQuoteCost(quote, "expected", "daily") * get(question, ["answer", "value"], 0),
        2
      );

      monthly.low = round(
        monthly.low + getQuoteCost(quote, "low", "monthly") * get(question, ["answer", "value"], 0),
        2
      );
      monthly.high = round(
        monthly.high + getQuoteCost(quote, "high", "monthly") * get(question, ["answer", "value"], 0),
        2
      );
      monthly.expected = round(
        monthly.expected + getQuoteCost(quote, "expected", "monthly") * get(question, ["answer", "value"], 0),
        2
      );

      yearly.low = round(yearly.low + getQuoteCost(quote, "low", "yearly") * get(question, ["answer", "value"], 0), 2);
      yearly.high = round(
        yearly.high + getQuoteCost(quote, "high", "yearly") * get(question, ["answer", "value"], 0),
        2
      );
      yearly.expected = round(
        yearly.expected + getQuoteCost(quote, "expected", "yearly") * get(question, ["answer", "value"], 0),
        2
      );
    }
  });
  return { cost, daily, monthly, yearly };
};

export function getSubscriptionStatusName(subscription) {
  if (!subscription) {
    return null;
  }
  if (subscription.status === "canceled") {
    return "Canceled";
  }
  if (subscription.status.startsWith("trial")) {
    return "Trial";
  }
  return capitalize(subscription.status.replace(/_/g, " "));
}

export const getLimitSubscriptionPerFeatureType = (subscription, featureType) =>
  get(
    find(get(subscription, ["compProduct", "features"], []), (feature) => feature.id.includes(featureType)),
    ["value"]
  );

export const stringToBoolean = (string) => {
  if (string)
    switch (string.toLowerCase()) {
      case "false":
      case "no":
      case "0":
      case "":
        return false;
      default:
        return true;
    }
  else return false;
};

export const formHasCost = (cost) =>
  sum([
    get(cost, ["cost", "expected"], 0),
    get(cost, ["daily", "expected"], 0),
    get(cost, ["monthly", "expected"], 0),
    get(cost, ["yearly", "expected"], 0)
  ]);

export const currencyToNumber = (currency) => Number(currency.replace(/[$,]/g, ""));

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export const numberToCurrency = (number) => formatter.format(number);

export const isEmptyCompanyData = (user) =>
  isEmpty(get(user, ["company", "name"])) ||
  isEmpty(get(user, ["company", "address"])) ||
  isEmpty(get(user, ["company", "city"])) ||
  isEmpty(get(user, ["company", "state"])) ||
  isEmpty(get(user, ["company", "zipcode"]));

export const getPosition = (string, subString, index) => string.split(subString, index).join(subString).length;

/**
 * Checks whether the given value is a Integer value or not and returns the corresponding boolean value
 * @param {string} number
 * @returns boolean
 */
export const isInt = (n) => Number(n) == n && n % 1 === 0;

/**
 * Checks whether the given value is a Float value or not and returns the corresponding boolean value
 * @param {string} number
 * @returns boolean
 */
export const isFloat = (n) => Number(n) == n && n % 1 !== 0;

/**
 * Checks if the domain is from an Agency and returns true, if it's from Sinapi return false
 * @returns boolean
 */
export const isAgency = !(
  window.location.hostname.includes("localhost") || window.location.hostname.includes("sinapi.io")
);

// Validation functions that keep the user entering only valid numbers
export const showOnlyNumbers = (e) => {
  if (e.which < 48 || e.which > 57) {
    e.preventDefault();
  }
};
export const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

const latestThemeStructure = {
  "& .card": {
    border: "0px",
    boxShadow: "none",
    backgroundColor: "transparent"
  },
  fontFamily: "Montserrat, sans-serif",
  fontImport: "url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400&display=swap)",
  "& .card-body": {
    boxShadow: "0 0 11px 1px rgba(136, 152, 170, 0.35)",
    paddingTop: "25px",
    paddingLeft: "25px",
    paddingRight: "25px",
    paddingBottom: "25px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px"
  },
  "& .card-footer": {
    paddingTop: "20px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "20px",
    backgroundColor: "rgba(51, 51, 51, 1)"
  },
  "& .form-control": {
    fontSize: "14px",
    borderRadius: "4px"
  },
  "& .image-option": {
    borderRadius: "10px",
    "& .image-option-label": {
      fontSize: "14px"
    }
  },
  backgroundColor: "rgba(222, 232, 238, 1)",
  "& .label-headline": {
    color: "rgba(82, 95, 127, 1)",
    fontSize: "14px"
  },
  "& .label-sub-headline": {
    color: "rgba(136,152,170, 1)",
    fontSize: "13px"
  },
  "& .btn-outline-primary": {
    color: "rgba(255, 255, 255, 1)",
    fontSize: "14px",
    borderColor: "rgba(255, 255, 255, 1)",
    borderStyle: "solid",
    borderWidth: "0px",
    backgroundColor: "rgba(94, 114, 228, 1)"
  },
  "& .sinapi-form-headline": {
    color: "rgba(82, 95, 127, 1)",
    fontSize: "28px"
  },
  "& .btn-outline-secondary": {
    color: "rgba(0, 0, 0, 1)",
    fontSize: "14px",
    borderColor: "rgba(255, 255, 255, 1)",
    borderStyle: "solid",
    borderWidth: "0px",
    backgroundColor: "rgba(247, 250, 252, 1)"
  },
  "& .image-option-selected": {
    borderSize: "1px",
    borderColor: "blue",
    borderStyle: "solid"
  },
  "& .sinapi-form-sub-headline": {
    color: "rgba(136, 152, 170, 1)",
    fontSize: "20px",
    fontWeight: "300"
  },
  "& .label-sub-headline a:link": {
    color: "rgba(74, 144, 226, 1)"
  }
};

export const reformatTheme = (t) => {
  const oldCard = get(t, "& .card", false);
  const newCard = get(latestThemeStructure, "& .card", {});
  const cardFooterProps = ["backgroundColor", "paddingBottom", "paddingLeft", "paddingRight", "paddingTop"];
  const cardFooterRadius = ["borderBottomRightRadius", "borderBottomLeftRadius"];
  const cardBodyRadius = ["BorderTopRightRadius", "borderTopLeftRadius"];
  const cardBodyProps = ["backgroundColor", "borderColor", "borderStyle", "borderWidth", "boxShadow"];
  let newCardFooter = get(latestThemeStructure, "& .card-footer", {});
  let newCardBody = get(latestThemeStructure, "& .card-body", {});
  let updatedTheme = { ...latestThemeStructure, ...t };
  const isNewTheme = t.hasOwnProperty("& .card-footer") && t.hasOwnProperty("& .card-body");
  if (!isNewTheme) {
    const borderRadius = get(oldCard, "borderRadius", "6px");
    const cardFooter = get(oldCard, "& .card-footer", {});
    cardFooterProps.forEach((prop) => {
      newCardFooter = { ...newCardFooter, [prop]: cardFooter[prop] };
    });
    cardFooterRadius.forEach((prop) => {
      newCardFooter = { ...newCardFooter, [prop]: borderRadius };
    });
    cardBodyProps.forEach((prop) => {
      newCardBody = { ...newCardBody, [prop]: oldCard[prop] };
    });
    cardBodyRadius.forEach((prop) => {
      newCardBody = { ...newCardBody, [prop]: borderRadius };
    });
    updatedTheme = {
      ...updatedTheme,
      "& .card-footer": newCardFooter,
      "& .card-body": newCardBody,
      "& .card": newCard
    };
  }

  return { ...t, ...updatedTheme };
};
