import { get } from "lodash";
import env from "Environment";

export const AWS_S3_ASSETS_BUCKET = env.ASSETS_URL + get(env, "DEV_ASSETS_PREFIX", "");

export const FORM_NAME = "Form";

export const FORM_NAME_PLURAL = "Forms";

export const LOGICAL_OPERATORS = ["AND", "OR"];

export const EQUALS = "Equals To";
export const NOT_EQUAL = "Does Not Equal";
export const GREATER_THEN = "Greater Then";
export const GREATER_OR_EQUAL = "Greater Or Equal To";
export const LESS_THEN = "Less Then";
export const LESS_OR_EQUAL = "Less Or Equal To";
// export const BETWEEN ="Between To";
export const IS_EMPTY = "Is Empty";
export const NOT_EMPTY = "Is Not Empty";
export const CONTAINS = "Includes";

export const OPERATORS_SYMBOL = {
  [EQUALS]: "==",
  [NOT_EQUAL]: "!=",
  [GREATER_THEN]: ">",
  [GREATER_OR_EQUAL]: ">=",
  [LESS_THEN]: "<",
  [LESS_OR_EQUAL]: "<=",
  [IS_EMPTY]: "is empty",
  [NOT_EMPTY]: "not empty",
  [CONTAINS]: "in"
};

export const OPERATORS = {
  text: [IS_EMPTY, NOT_EMPTY],
  textarea: [IS_EMPTY, NOT_EMPTY],
  address: [IS_EMPTY, NOT_EMPTY],
  phone: [IS_EMPTY, NOT_EMPTY],
  email: [IS_EMPTY, NOT_EMPTY],
  number: [EQUALS, NOT_EQUAL, GREATER_THEN, GREATER_OR_EQUAL, LESS_THEN, LESS_OR_EQUAL, IS_EMPTY, NOT_EMPTY],
  date: [EQUALS, IS_EMPTY, NOT_EMPTY],
  select: [EQUALS, NOT_EQUAL, IS_EMPTY, NOT_EMPTY],
  radio: [EQUALS, NOT_EQUAL, IS_EMPTY, NOT_EMPTY],
  checkbox: [EQUALS, NOT_EQUAL, CONTAINS, IS_EMPTY, NOT_EMPTY]
};

export const FEATURE_NUMBER_OF_FORMS = "numberOfForms";
export const FEATURE_NUMBER_OF_USERS = "numberOfUsers";
export const FEATURE_NUMBER_OF_WORKSPACES = "numberOfWorkspaces";
export const FEATURE_NUMBER_OF_LEADS = "numberOfLeads";
export const FEATURE_MAX_LEAD_VALUE = "maxLeadValue";
export const FEATURE_CUSTOMIZE_THEMES = "customizeThemes";
export const FEATURE_CUSTOMIZE_EMAILS = "customizeEmails";
export const FEATURE_ADVANCED_EDITING = "advancedEditing";
export const FEATURE_DEVELOPER_CODE = "developerCode";
export const FEATURE_POPUP_CODE = "getPopupCode";
export const FEATURE_CUSTOMIZE_LANDING_PAGE = "customLandingPage";
export const FEATURE_WHITE_LABEL = "whiteLabel";
export const FEATURE_CONNECTORS = "connectors";

export const WHITE_LABELS = (whiteLabel, company) => ({
  sinapiName: whiteLabel && get(company, "enableCustomDomain") ? get(company, "name") : "Sinapi",
  sinapiURL:
    whiteLabel && get(company, "enableCustomDomain")
      ? `https://${get(company, "subdomain")}.${get(company, ["customDomain", "domain"])}`
      : env.APP_URL,
  sinapiLoginLogo:
    whiteLabel && get(company, "enableCustomDomain")
      ? `${AWS_S3_ASSETS_BUCKET}/${get(company, "logoPath")}`
      : require("assets/img/brand/sinapi-logo-white.svg"),
  sinapiLogo:
    whiteLabel && get(company, "enableCustomDomain")
      ? get(company, "logoPath")
        ? `${AWS_S3_ASSETS_BUCKET}/${get(company, "logoPath")}`
        : ""
      : require("assets/img/brand/sinapi-logo-white-without-tagline.svg")
});
