import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import * as yup from "yup";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Container,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardFooter,
  Button
} from "reactstrap";
import { get, defaultTo, map, omitBy } from "lodash";
import classnames from "classnames";
import { Formik, Form, Field } from "formik";
import LoaderSpinner from "components/Spinner/LoaderSpinner";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import ManagementModal from "components/UserManagement/ManagementModal";
import DatePicker from "reactstrap-date-picker/lib/DatePicker";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    width: "390px",
    marginLeft: "-10px"
  }),
  control: (provided) => ({
    ...provided,
    border: "unset",
    minHeight: "30px",
    width: "max-content",
    borderColor: "unset",
    boxShadow: "unset",
    "&:hover": {
      borderColor: "unset"
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "30px",
    marginTop: "-5px",
    minWidth: "340px"
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px"
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "30px",
    marginTop: "-5px"
  })
};

const GET_SALES_REP = gql`
  query getSalesRepById($id: ID) {
    salesRepById(id: $id) {
      id
      firstName
      lastName
      email
      phone
      company
      jobTitle
      commission
      guid
      archive
      type
      stripeId
      customerLinks {
        id
        company {
          id
          name
          stripeCustomerId
        }
        commission
        commissionEndDate
      }
    }
  }
`;

const SAVE_SALES_REP = gql`
  mutation SaveSalesRep($salesRepId: ID, $salesRep: SalesRepInput) {
    saveSalesRep(salesRepId: $salesRepId, salesRep: $salesRep) {
      id
    }
  }
`;

const SAVE_CUSTOMER_LINK = gql`
  mutation SaveCustomerLink(
    $id: ID
    $salesRepId: ID
    $customerId: ID
    $commission: Float
    $commissionEndDate: Timestamp
  ) {
    saveSalesRepsCustomerLink(
      id: $id
      salesRepId: $salesRepId
      customerId: $customerId
      commission: $commission
      commissionEndDate: $commissionEndDate
    )
  }
`;

const ADD_TRANSFER_DATA = gql`
  mutation AddTransferData($customerId: ID, $commission: Int, $connectStripeId: String) {
    addTransferData(customerId: $customerId, commission: $commission, connectStripeId: $connectStripeId) {
      id
    }
  }
`;

const REMOVE_CUSTOMER_LINK = gql`
  mutation RemoveCustomerLink($id: ID) {
    removeSalesRepsCustomerLink(id: $id)
  }
`;

const GET_COMPANIES_BY_NAME = gql`
  query CompaniesByName($keywords: String) {
    companiesByName(keywords: $keywords) {
      id
      name
      stripeCustomerId
    }
  }
`;

const planFormValidation = yup.object().shape({
  email: yup.string().required(),
  phone: yup.string().required(),
  firstName: yup.string().required(),
  commission: yup.string().required()
});

const getInitialValues = (salesRep) =>
  ["firstName", "lastName", "email", "phone", "commission", "stripeId", "company", "jobTitle", "type"].reduce(
    (init, key) => ({
      ...init,
      [key]: salesRep[key] || ""
    }),
    {}
  );

const isNew = (id) => (id === "new" ? true : false);

const SalesRepForm = () => {
  const history = useHistory();
  const { salesRepId } = useParams();
  const [showModal, setShowModal] = useState();
  const [typeAgent, setTypeAgent] = useState("salesRep");
  const [salesRepData, setSalesRepData] = useState();
  const [customerLinkData, setCustomerLinkData] = useState();
  const [editSalesRep, setEditSalesRep] = useState(isNew(salesRepId));
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [showModalErrorMsg, setShowModalErrorMsg] = useState();
  const [keywords, setKeywords] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);

  const getCustomerById = (customerId) => filteredCompanies.find((company) => company.value === customerId);

  const {
    data: companiesData,
    loading: companiesLoading,
    refetch: companiesRefresh
  } = useQuery(GET_COMPANIES_BY_NAME, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      keywords: keywords
    }
  });

  useEffect(() => {
    if (!companiesLoading && companiesData) {
      setFilteredCompanies(
        map(companiesData.companiesByName, (element) => ({
          value: element.id,
          label: element.name,
          customer: element.stripeCustomerId
        }))
      );
    }
  }, [companiesData, companiesLoading]);

  const loadOptions = (_inputValue, callback) => {
    setTimeout(() => {
      companiesRefresh();
      callback(filteredCompanies);
    }, 1000);
  };

  const { data, loading, refetch } = useQuery(GET_SALES_REP, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      id: salesRepId
    },
    skip: isNew(salesRepId)
  });

  useEffect(() => {
    if (!loading && data) {
      setSalesRepData(data.salesRepById);
      setTypeAgent(data.salesRepById.type);
    }
  }, [data, loading]);

  const [saveSalesRep, { data: savedData, loading: savedLoading, error: savedError }] = useMutation(SAVE_SALES_REP);

  useEffect(() => {
    if (savedError) {
      setShowErrorMsg("Something went wrong while saving.");
    }
  }, [savedError]);

  useEffect(() => {
    if (savedData && !savedLoading) {
      setEditSalesRep(false);
      history.replace(`/admin/commissions/${savedData.saveSalesRep.id}`);
    }
  }, [savedData, savedLoading]);

  const [saveCustomer, { data: savedCustomerData, loading: savedCustomerLoading, error: savedCustomerError }] =
    useMutation(SAVE_CUSTOMER_LINK);

  useEffect(() => {
    if (savedCustomerError) {
      setShowErrorMsg("Something went wrong while saving.");
    }
  }, [savedCustomerError]);

  useEffect(() => {
    if (savedCustomerData && !savedCustomerLoading) {
      setShowModal();
      setCustomerLinkData();
      refetch();
    }
  }, [refetch, savedCustomerData, savedCustomerLoading]);

  const [addTransferData, { error: addTransferDataError }] = useMutation(ADD_TRANSFER_DATA);

  useEffect(() => {
    if (addTransferDataError) {
      const error = get(addTransferDataError, ["graphQLErrors", "0", "message"], addTransferDataError);
      setShowModalErrorMsg(error.replace("Unexpected error value: ", ""));
    }
  }, [addTransferDataError]);

  const [removeCustomerLink, { data: removeCustomerLinkData, loading: removingCustomerLink }] =
    useMutation(REMOVE_CUSTOMER_LINK);

  useEffect(() => {
    if (!removingCustomerLink && removeCustomerLinkData && removeCustomerLinkData.removeSalesRepsCustomerLink) {
      refetch();
    }
  }, [refetch, removeCustomerLinkData, removingCustomerLink]);

  const linkCustomer = (data) =>
    addTransferData({
      variables: {
        customerId: customerLinkData.customer,
        commission: parseFloat(customerLinkData.commission),
        connectStripeId: salesRepData.stripeId
      }
    }).then(({ errors }) => {
      if (!errors) {
        saveCustomer({
          variables: {
            id: data ? data.id : null,
            salesRepId: salesRepId,
            customerId: customerLinkData.customerId,
            commission: parseFloat(customerLinkData.commission),
            commissionEndDate: customerLinkData.commissionEndDate
          }
        });
      }
    });

  const unLinkCustomer = (customerLinkId) =>
    addTransferData({
      variables: {
        customerId: customerLinkData.customer,
        commission: 0,
        connectStripeId: salesRepData.stripeId
      }
    }).then(({ errors }) => {
      if (!errors) {
        removeCustomerLink({
          variables: {
            id: customerLinkId
          }
        });
        setShowModal(null);
      }
    });

  useEffect(() => {
    if (showModal?.data) {
      setCustomerLinkData({
        customer: getCustomerById(showModal.data?.company.id)?.customer,
        customerId: getCustomerById(showModal.data?.company.id)?.value,
        commission: showModal.data?.commission,
        commissionEndDate: showModal.data?.commissionEndDate
          ? moment(showModal.data?.commissionEndDate).format("YYYY-MM-DD")
          : null
      });
    }
  }, [showModal]);

  return (
    <>
      <SimpleHeader
        name={isNew(salesRepId) ? "Create Sales Rep" : "Manage Sales Rep"}
        button={{ label: "Go Back", action: () => history.replace("/admin/commissions") }}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xs="6">
            <Formik
              initialValues={{ ...getInitialValues(salesRepData ?? {}) }}
              enableReinitialize={true}
              validationSchema={planFormValidation}
              validateOnMount={true}
              onSubmit={(values) => {
                saveSalesRep({
                  variables: {
                    salesRep: { ...omitBy(values, (val) => val === ""), type: typeAgent },
                    salesRepId: isNew(salesRepId) ? null : salesRepId
                  }
                });
              }}
            >
              {(formikProps) => (
                <>
                  {showErrorMsg && (
                    <Row className="align-items-center">
                      <Col className="col-12">
                        <div className="alert-header-bar alert alert-warning fade show alert-dismissible">
                          <span>
                            {showErrorMsg}
                            <br />
                            {savedError &&
                              savedError.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}
                          </span>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                            onClick={() => setShowErrorMsg()}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="6">
                          <h3 className="mb-0">Agent Details</h3>
                        </Col>
                        <Col xs="6">
                          <Input
                            type="select"
                            className="float-right form-control-sm col-6"
                            value={typeAgent}
                            onChange={(e) => setTypeAgent(e.target.value)}
                            disabled={!editSalesRep}
                          >
                            <option value="salesRep">Sales Rep</option>
                            <option value="referral">Referral</option>
                          </Input>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-firstName">
                                  First Name
                                </label>
                                <Field
                                  name="firstName"
                                  id="input-firstName"
                                  readOnly={!editSalesRep}
                                  className="form-control"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-lastName">
                                  Last Name
                                </label>
                                <Field
                                  name="lastName"
                                  id="input-lastName"
                                  readOnly={!editSalesRep}
                                  className="form-control"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-email">
                                  Email
                                </label>
                                <Field
                                  name="email"
                                  id="input-email"
                                  readOnly={!editSalesRep}
                                  className="form-control"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-phone">
                                  Phone
                                </label>
                                <Field
                                  name="phone"
                                  id="input-phone"
                                  readOnly={!editSalesRep}
                                  className="form-control"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          {typeAgent === "salesRep" && (
                            <>
                              <hr className="my-3" style={{ backgroundColor: "lightgray" }} />
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label className="form-control-label" htmlFor="input-company">
                                      Company
                                    </label>
                                    <Field
                                      name="company"
                                      readOnly={!editSalesRep}
                                      id="input-company"
                                      className="form-control"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <label className="form-control-label" htmlFor="input-jobTitle">
                                      Job Title
                                    </label>
                                    <Field
                                      name="jobTitle"
                                      id="input-jobTitle"
                                      readOnly={!editSalesRep}
                                      className="form-control"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </>
                          )}
                          <hr className="my-3" style={{ backgroundColor: "lightgray" }} />
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-stripeId">
                                  Stripe Id
                                </label>
                                <Field
                                  name="stripeId"
                                  readOnly={!editSalesRep}
                                  id="input-stripeId"
                                  className="form-control"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="input-commission">
                                  Commission
                                </label>
                                <InputGroup className={classnames("input-group-merge")} style={{ width: "70%" }}>
                                  <Field
                                    type="number"
                                    name="commission"
                                    id="input-commission"
                                    readOnly={!editSalesRep}
                                    className="form-control"
                                  />
                                  <InputGroupAddon addonType="append">
                                    <InputGroupText className="bg-gradient-lighter">
                                      <i className="fas fa-percentage" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                    <CardFooter className="bg-gradient-lighter">
                      <Row className="align-items-left">
                        <Col className="text-left" xs="12">
                          {editSalesRep && !isNew(salesRepId) && (
                            <Button
                              type="button"
                              color="secondary"
                              onClick={() => setEditSalesRep(false)}
                              className="float-left"
                            >
                              Cancel
                            </Button>
                          )}
                          {editSalesRep ? (
                            <Button
                              type="button"
                              color="primary"
                              onClick={() => formikProps.submitForm()}
                              disabled={!formikProps.isValid}
                              className="float-left"
                            >
                              {isNew(salesRepId) ? "Create Rep" : "Update Details"}
                            </Button>
                          ) : (
                            <Button
                              type="button"
                              color="primary"
                              onClick={() => setEditSalesRep(true)}
                              className="float-left"
                            >
                              Edit Details
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </>
              )}
            </Formik>
          </Col>
          <Col xs="6">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Linked Customers</h3>
                  </Col>
                  <Col xs="4" className="text-right">
                    <Button
                      size="sm"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        setCustomerLinkData();
                        setShowModal({ type: "commission" });
                      }}
                    >
                      Add Commission
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              {loading ? <LoaderSpinner /> : null}
              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" id="name-column">
                      Name
                    </th>
                    <th scope="col" id="name-column">
                      Commission
                    </th>
                    <th scope="col" id="name-column">
                      End Date
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list">
                  {salesRepData?.customerLinks.length < 1 ? (
                    <tr>
                      <td>
                        <div className="ml-4 my-1">{`No Linked Customers`}</div>
                      </td>
                    </tr>
                  ) : (
                    salesRepData?.customerLinks.map((customerLink) => (
                      <tr key={customerLink.id}>
                        <td>{customerLink.company.name}</td>
                        <td>
                          <span className="font-weight-bold">{customerLink.commission} %</span>
                        </td>
                        <td>
                          {customerLink.commissionEndDate
                            ? moment(customerLink.commissionEndDate).format("MM/DD/YYYY")
                            : "Forever"}
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right positionFixed={true}>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowModal({ type: "commission", data: customerLink });
                                }}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowModal({ type: "unLinkCommission", data: customerLink });
                                }}
                              >
                                Un-Link
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
      {showModal && showModal.type === "commission" ? (
        <ManagementModal
          modalSize="md"
          onClose={() => setShowModal(null)}
          header={<div>{`${showModal.data ? "Update" : "Create"} Commission`}</div>}
          classNames={{ body: "border-bottom", footer: "py-3" }}
          styles={{ modal: { maxWidth: "650px" } }}
          buttons={[
            { label: "No, Cancel", outline: true, className: "mr-auto", onClick: () => setShowModal(null) },
            {
              label: `${showModal.data ? "Update" : "Create"} Commission`,
              disabled: defaultTo(customerLinkData?.customerId, "") === "",
              onClick: () => linkCustomer(showModal.data)
            }
          ]}
        >
          {showModalErrorMsg && (
            <Row className="align-items-center">
              <Col className="col-12">
                <div className="alert-header-bar alert alert-warning fade show alert-dismissible">
                  <span>{showModalErrorMsg}</span>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setShowModalErrorMsg()}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col lg="6">
              <label className="form-control-label">Sales Rep</label>
              <br />
              <label className="form-control-label text-muted" style={{ fontSize: "0.9rem" }}>
                {salesRepData.firstName} {salesRepData.lastName ?? ""}
              </label>
            </Col>
            <Col lg="6">
              <label className="form-control-label">Email</label>
              <br />
              <label className="form-control-label text-muted" style={{ fontSize: "0.9rem" }}>
                {salesRepData.email}
              </label>
            </Col>
          </Row>
          <hr className="my-3" style={{ backgroundColor: "lightgray" }} />
          <Row>
            <Col lg="8">
              <label className="form-control-label">Client</label>
              <AsyncSelect
                cacheOptions
                styles={customStyles}
                className="form-control"
                loadOptions={loadOptions}
                defaultValue={filteredCompanies.find((company) => company.value === customerLinkData?.customerId)}
                defaultOptions={filteredCompanies}
                onChange={({ value, customer }) =>
                  setCustomerLinkData((prev) => ({ ...prev, customerId: value, customer: customer }))
                }
                onInputChange={(value) => setKeywords(value)}
              />
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-commission">
                  Commission
                </label>
                <InputGroup className={classnames("input-group-merge")} style={{ width: "70%" }}>
                  <Input
                    type="number"
                    name="customer-commission"
                    id="input-customer-commission"
                    className="form-control"
                    value={customerLinkData?.commission ?? ""}
                    onChange={(e) => {
                      e.persist();
                      setCustomerLinkData((prev) => ({ ...prev, commission: e.target.value }));
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="bg-gradient-lighter">
                      <i className="fas fa-percentage" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <label className="form-control-label">End Date</label>
              <DatePicker
                size="sm"
                showTodayButton={true}
                dateFormat="MM/DD/YYYY"
                value={customerLinkData?.commissionEndDate}
                onChange={(v) => setCustomerLinkData((prev) => ({ ...prev, commissionEndDate: v }))}
              />
            </Col>
          </Row>
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "unLinkCommission" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>Un-Link Commission</div>}
          classNames={{ body: "border-bottom", footer: "py-3" }}
          buttons={[
            { label: "No, Cancel", outline: true, className: "mr-auto", onClick: () => setShowModal(null) },
            {
              label: "Yes, Un-Link",
              onClick: () => unLinkCustomer(showModal.data.id)
            }
          ]}
        >
          <p>
            Are you sure you wish to unlink the following commissions from{" "}
            <span className="font-weight-bold text-info">
              {salesRepData.firstName} {salesRepData.lastName ?? ""}
            </span>
          </p>
          <span className="font-weight-bold">
            {showModal.data.company.name}: {showModal.data.commission}%
          </span>
        </ManagementModal>
      ) : null}
    </>
  );
};
export default SalesRepForm;
