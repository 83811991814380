import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { Formik, Form, Field } from "formik";
import { AWS_S3_ASSETS_BUCKET, FEATURE_WHITE_LABEL } from "../../utils/constants";
import * as yup from "yup";
import { get, defaultTo, isEmpty, find, replace } from "lodash";
import defaultImage from "../../assets/img/default-image.png";
import Ping from "ping.js";
import Switch from "react-switch";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { useCreateSubscription } from "components/Stripe/useCreateSubscription";
import { currencyToNumber, getLimitSubscriptionPerFeatureType, stringToBoolean } from "utils/helperFunctions";
import { useCompanySubscription } from "hooks/useCompanySubscription";

const GET_COMPANY = gql`
  query getCompany($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      address
      city
      state
      zipcode
      phone
      website
      logoPath
      planId
      paymentInterval
      intervalPrice
      stripeCustomerId
      subdomain
      customDomain {
        id
        domain
      }
      enableCustomDomain
      customApplicationName
    }
  }
`;

const SAVE_COMPANY = gql`
  mutation SaveCompany(
    $companyId: ID
    $company: CompanyInput!
    $file: Upload
    $prevLogo: String
    $freePlan: Boolean
    $superAdmin: Boolean
  ) {
    saveCompany(
      companyId: $companyId
      company: $company
      file: $file
      prevLogo: $prevLogo
      freePlan: $freePlan
      superAdmin: $superAdmin
    ) {
      id
    }
  }
`;

const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($subscriptionId: ID!, $customerId: String) {
    cancelSubscription(subscriptionId: $subscriptionId, customerId: $customerId) {
      status
    }
  }
`;

const GET_PRODUCTS = gql`
  {
    plans {
      id
      name
      price {
        amount
        interval
        id
      }
    }
  }
`;

const GET_CUSTOM_DOMAINS = gql`
  {
    customDomains {
      id
      domain
    }
  }
`;

const specialCharRegex = /[`·!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

const companyFormValidation = yup.object().shape({
  name: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zipcode: yup.string().required(),
  subdomain: yup.string(),
  customApplicationName: yup
    .string()
    .matches("^[a-zA-Z0-9\\s]*$", "Please enter only alphanumeric characters and space.")
});
function getInitialValues(orgCompany) {
  return [
    "name",
    "address",
    "city",
    "state",
    "zipcode",
    "phone",
    "website",
    "subdomain",
    "customApplicationName"
  ].reduce((init, key) => ({ ...init, [key]: orgCompany ? orgCompany[key] || "" : "" }), {});
}

const CompanyForm = ({
  onSave,
  companyData,
  companyId,
  setToEdit,
  isNew = false,
  freePlan,
  showPlan = false,
  superAdmin = false
}) => {
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);
  const userRole = currentWorkspace
    ? currentWorkspace.userRoleId || currentUser.roleId
    : currentUser
    ? currentUser.roleId
    : null;
  const [editCompany, setEditCompany] = useState(setToEdit || false);
  const [newCompanyID, setNewCompanyID] = useState();

  const { loading, data, refetch } = useQuery(GET_COMPANY, {
    fetchPolicy: "network-only",
    variables: {
      companyId: companyId || get(companyData, "id") || newCompanyID || get(currentUser, ["company", "id"])
    },
    skip: !(companyId || get(companyData, "id") || newCompanyID || get(currentUser, ["company", "id"]))
  });
  const [saveCompany, { data: newCompany, loading: savingComp, error: savingError }] = useMutation(SAVE_COMPANY);
  const [showErrorMsg, setShowErrorMsg] = useState();

  const [cancelSubscriptionMutation] = useMutation(CANCEL_SUBSCRIPTION);

  const cancelSubscription = (subId) => {
    cancelSubscriptionMutation({
      variables: {
        subscriptionId: subId,
        customerId: company.stripeCustomerId
      }
    });
  };

  useEffect(() => {
    if (savingError) {
      setShowErrorMsg("Something went wrong while saving.");
    }
  }, [savingError]);

  useEffect(() => {
    if (newCompany && !savingComp) {
      if (onSave) {
        onSave(newCompany);
      }
      setNewCompanyID(newCompany.saveCompany.id);
      refetch();
      setLogoPath({ src: defaultImage });
      setEditCompany(false);
    }
  }, [newCompany]);

  const { data: plansData } = useQuery(GET_PRODUCTS, {
    variables: {}
  });

  const { data: domainsData } = useQuery(GET_CUSTOM_DOMAINS, {
    variables: {}
  });

  let company = companyData;
  if ((!isNew || !isEmpty(newCompanyID)) && !company && !loading && data) {
    company = data.company;
  }

  const companySubscription = useCompanySubscription(get(company, "id"), get(company, "planId"));
  const compSubscriptionId = get(companySubscription, ["compSubscription", "id"]);
  const hasWhiteLabel = stringToBoolean(getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_WHITE_LABEL));

  const [logoPath, setLogoPath] = useState({
    src: get(company, "logoPath") ? `${AWS_S3_ASSETS_BUCKET}/${defaultTo(get(company, "logoPath"), "")}` : defaultImage
  });

  useEffect(() => {
    setLogoPath({
      src: get(company, "logoPath")
        ? `${AWS_S3_ASSETS_BUCKET}/${defaultTo(get(company, "logoPath"), "")}`
        : defaultImage
    });
    setToggleCustomDomain(defaultTo(get(company, ["enableCustomDomain"]), false));
  }, [company]);

  const [planId, setPlanId] = useState();
  const [planPrice, setPlanPrice] = useState();
  const [planPriceId, setPlanPriceId] = useState();
  const [planInterval, setPlanInterval] = useState();
  const [createSubscription] = useCreateSubscription();

  useEffect(() => {
    if (!planId) {
      setPlanId(get(company, "planId"));
      setPlanInterval(get(company, "paymentInterval") === "year" ? "yr" : "mo");
      setPlanPrice(get(company, "intervalPrice"));
    }
  }, [planId, company]);

  useEffect(() => {
    if (planId === "-") {
      setPlanInterval("-");
      setPlanPrice("");
      setPlanPriceId();
    } else if (plansData && plansData.plans) {
      const plan = plansData.plans.find((plan) => plan.id === planId);
      if (plan && plan.price) {
        const price = plan.price.find((pr) => pr.interval === planInterval);
        if (price) {
          setPlanPrice(price.amount);
          setPlanPriceId(price.id);
        } else {
          setPlanPrice("");
          setPlanPriceId();
        }
      } else {
        setPlanInterval("-");
        setPlanPrice("");
        setPlanPriceId();
      }
    }
  }, [planId, planInterval, plansData]);

  const [customDomain, setCustomDomain] = useState();
  const [domainURL, setDomainURL] = useState();
  const [toggleCustomDomain, setToggleCustomDomain] = useState();
  const [customAppName, setCustomAppName] = useState("");

  useEffect(() => {
    if (!customDomain) {
      setCustomDomain(get(company, ["customDomain", "id"]));
    }
    if (company?.customApplicationName) {
      setCustomAppName(company?.customApplicationName);
    }
  }, [customDomain, company]);

  const setCustomDomainURL = (subdomain, customDomain) => {
    const domain = find(domainsData.customDomains, (cm) => cm.id === customDomain);
    setDomainURL(`https://${subdomain}.${get(domain, "domain", "")}`);
  };

  useEffect(() => {
    if (domainsData && domainsData.customDomains && company?.subdomain) {
      setCustomDomainURL(get(company, ["subdomain"]), customDomain);
    }
  }, [domainsData]);

  const [domainErrorMsg, setDomainErrorMsg] = useState();
  const isActiveDomain = () =>
    new Ping()
      .ping(domainURL)
      .then(() => {
        setDomainErrorMsg("The domain is not available!");
      })
      .catch(() => setDomainErrorMsg());

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileSize = Math.round(file.size / 1024);
    if (fileSize > 1024) {
      setShowErrorMsg("Your image must be smaller than 1 mb to upload");
      return;
    }
    setLogoPath({ file, src: URL.createObjectURL(e.target.files[0]) });
  };

  const getInterval = (interval) => (interval === "yr" ? "year" : interval === "mo" ? "month" : "");

  return (
    <>
      {loading ? <LoaderSpinner /> : null}
      <Formik
        initialValues={{ ...getInitialValues(company || {}) }}
        enableReinitialize={true}
        validationSchema={companyFormValidation}
        validateOnMount={true}
        onSubmit={(values, actions) => {
          const companyData = showPlan
            ? {
                ...values,
                customDomain,
                planId: planId,
                paymentInterval: getInterval(planInterval),
                intervalPrice: currencyToNumber(planPrice),
                enableCustomDomain: toggleCustomDomain,
                customApplicationName: customAppName
              }
            : { ...values, customDomain, enableCustomDomain: toggleCustomDomain, customApplicationName: customAppName };
          saveCompany({
            variables: {
              company: companyData,
              companyId: company ? company.id : null,
              file: logoPath.file,
              prevLogo: company ? company.logoPath : null,
              freePlan: freePlan,
              superAdmin: superAdmin
            }
          });
          if (
            showPlan &&
            (get(company, ["planId"]) !== planId || get(company, ["paymentInterval"]) !== getInterval(planInterval)) &&
            !isEmpty(company.stripeCustomerId)
          ) {
            if (planPriceId) {
              createSubscription(null, {
                customerId: company.stripeCustomerId,
                priceId: planPriceId,
                planId: planId
              });
            } else {
              cancelSubscription(compSubscriptionId);
            }
          }
        }}
      >
        {(formikProps) => (
          <>
            {showErrorMsg && (
              <Row className="align-items-center">
                <Col className="col-12">
                  <div className="alert-header-bar alert alert-warning fade show alert-dismissible">
                    <span>
                      {showErrorMsg}
                      <br />
                      {savingError && savingError.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}
                    </span>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setShowErrorMsg()}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Col>
              </Row>
            )}
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Company</h3>
                  </Col>
                  {["admin", "superAdmin"].includes(userRole) && (
                    <Col xs="4" className="text-right">
                      {!editCompany ? (
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            setEditCompany(true);
                          }}
                        >
                          Edit company profile
                        </button>
                      ) : null}
                      {editCompany ? (
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            formikProps.submitForm();
                          }}
                          disabled={!formikProps.isValid || domainErrorMsg}
                        >
                          Save company profile
                        </button>
                      ) : null}
                    </Col>
                  )}
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col lg="6">
                      <Row>
                        <Col>
                          <h6 className="heading-small text-muted mb-4">Company Name:</h6>
                          <div className="pl-lg-4">
                            <FormGroup>
                              <Field
                                name="name"
                                readOnly={!editCompany}
                                className="form-control"
                                onChange={(event) =>
                                  formikProps.setFieldValue(
                                    event.target.name,
                                    replace(event.target.value, specialCharRegex, "")
                                  )
                                }
                              />
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>
                            <div className="heading-small h6 text-muted form-inline pb-0 mb-0">Upload Logo:</div>
                            <small className="text-muted ">Required for branding on Emails and Forms</small>
                          </label>
                          <div className="pl-lg-4">
                            <FormGroup>
                              <Input
                                className="form-control-file"
                                type="File"
                                onChange={handleFileChange}
                                disabled={!editCompany}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="6">
                      <div className="text-center" style={{ height: "100%" }}>
                        <>
                          <span style={{ display: "inline-block", height: "100%", verticalAlign: "middle" }} />
                          <img
                            alt=""
                            className="img-thumbnail"
                            style={{ maxHeight: "300px", marginTop: "10px" }}
                            src={logoPath.src}
                          />
                        </>
                      </div>
                    </Col>
                  </Row>
                  {hasWhiteLabel && (
                    <>
                      <Row>
                        <Col lg="6">
                          <hr className="my-4" />
                          <Row style={{ alignSelf: "center", lineHeight: "2.5" }}>
                            <h6 className="heading-small text-muted" style={{ paddingLeft: 15 }}>
                              Custom App Domain
                            </h6>
                            <FormGroup className="mb-0 ml-2">
                              <Switch
                                onColor="#11cdef"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                className="react-switch"
                                checked={toggleCustomDomain}
                                onChange={() => setToggleCustomDomain(!toggleCustomDomain)}
                                disabled={!editCompany}
                              />
                            </FormGroup>
                          </Row>
                          <p
                            className="text-muted"
                            style={{ textAlign: "justify", fontSize: "80%", fontWeight: "400" }}
                          >
                            This URL will point to your own white-labeled Sinapi application. Once you’ve set up your
                            custom domain, someone from our team will reach out to you when it’s been finalized and set
                            up for production use for you and your clients.
                          </p>
                        </Col>
                      </Row>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="3">
                            <FormGroup className="mb-0">
                              <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText style={{ border: "0", backgroundColor: "#e9ecef", color: "#5f5f7f" }}>
                                    https://
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Field
                                  name="subdomain"
                                  readOnly={!editCompany || !toggleCustomDomain}
                                  id="input-subdomain"
                                  className="form-control pl-2"
                                  onChange={(event) => {
                                    formikProps.setFieldValue(
                                      event.target.name,
                                      replace(event.target.value.trim(), specialCharRegex, "")
                                    );
                                    setCustomDomainURL(
                                      replace(event.target.value.trim(), specialCharRegex, ""),
                                      customDomain
                                    );
                                  }}
                                  onBlur={isActiveDomain}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col lg="1" className="p-0 m-0" style={{ maxWidth: "8px" }}>
                            <FormGroup className="mb-0">
                              <span className="display-4" style={{ lineHeight: "2.5" }}>
                                .
                              </span>
                            </FormGroup>
                          </Col>
                          <Col lg="2">
                            <FormGroup className="mb-0">
                              <Field
                                as="select"
                                name="customDomain"
                                id="input-custom-domain"
                                disabled={!editCompany || !toggleCustomDomain}
                                className="form-control"
                                onChange={({ target }) => {
                                  setCustomDomain(target.value);
                                  setCustomDomainURL(get(formikProps, ["values", "subdomain"]), target.value);
                                }}
                                value={customDomain}
                                onBlur={isActiveDomain}
                              >
                                <option name="-" value="-">
                                  -
                                </option>
                                {domainsData && domainsData.customDomains
                                  ? domainsData.customDomains.map((domain) => (
                                      <option key={domain.id} value={domain.id}>
                                        {domain.domain}
                                      </option>
                                    ))
                                  : null}
                              </Field>
                            </FormGroup>
                          </Col>
                        </Row>
                        {domainErrorMsg && <div className="small text-danger mb-2 mt--2">{domainErrorMsg}</div>}
                        <h6 className="heading-small text-muted mt-0 mb-0">
                          URL:
                          <span className="ml-2 font-weight-normal text-lowercase">{domainURL}</span>
                        </h6>
                      </div>
                      <div className="pl-lg-4 mt-3">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-custom-application-name">
                                Custom Application Name
                              </label>
                              <Field
                                name="customApplicationName"
                                disabled={!editCompany || !toggleCustomDomain}
                                id="input-custom-application-name"
                                className="form-control"
                                onChange={({ target }) => {
                                  formikProps.setFieldValue("customApplicationName", target.value);
                                  setCustomAppName(target.value);
                                }}
                              />
                              {formikProps?.errors?.customApplicationName && (
                                <small className="text-danger">{formikProps?.errors?.customApplicationName}</small>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                  <hr className="my-4" />
                  {showPlan && (
                    <>
                      <h6 className="heading-small text-muted mb-4">Plan information</h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-plan">
                                Plan
                              </label>
                              <Field
                                as="select"
                                name="planId"
                                id="input-plan"
                                disabled={!editCompany}
                                className="form-control"
                                onChange={({ target }) => setPlanId(target.value)}
                                value={planId}
                              >
                                <option name="-" value="-">
                                  -
                                </option>
                                {plansData && plansData.plans
                                  ? plansData.plans.map((plan) => (
                                      <option key={plan.id} value={plan.id}>
                                        {plan.name}
                                      </option>
                                    ))
                                  : null}
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-payment">
                                Payment Interval
                              </label>
                              <Field
                                as="select"
                                name="paymentInterval"
                                id="input-payment"
                                disabled={!editCompany}
                                className="form-control"
                                onChange={({ target }) => setPlanInterval(target.value)}
                                value={planInterval}
                              >
                                <option name="-" value="-">
                                  -
                                </option>
                                <option value="mo">Month</option>
                                <option value="yr">Year</option>
                              </Field>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-price">
                                Interval Price
                              </label>
                              <Field
                                name="intervalPrice"
                                id="input-price"
                                className="form-control"
                                readOnly={true}
                                value={!planPrice ? "" : planPrice}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                    </>
                  )}
                  <h6 className="heading-small text-muted mb-4">Information</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-phone">
                            Phone
                          </label>
                          <Field name="phone" readOnly={!editCompany} id="input-phone" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-website">
                            Website
                          </label>
                          <Field name="website" readOnly={!editCompany} id="input-website" className="form-control" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-address">
                            Address
                          </label>
                          <Field name="address" readOnly={!editCompany} id="input-address" className="form-control" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-city">
                            City
                          </label>
                          <Field name="city" readOnly={!editCompany} id="input-city" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-state">
                            State
                          </label>
                          <Field name="state" readOnly={!editCompany} id="input-state" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-postal-code">
                            Postal code
                          </label>
                          <Field
                            name="zipcode"
                            readOnly={!editCompany}
                            id="input-postal-code"
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </>
        )}
      </Formik>
    </>
  );
};
export default CompanyForm;
