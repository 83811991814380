import React, { useReducer, useEffect } from "react";
import "./VerticalSidenav.scss";
import { VerticalSidenavContext } from "./VerticalSidenavContext";
import { NavContent } from "./NavContent";
import { NavIcon } from "./NavIcon";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

function verticalSidenavReducer(state, { type, payload }) {
  switch (type) {
    case "OPEN":
      return { open: true, type: payload };
    case "SWITCH":
      return { ...state, open: true, type: null, switchTo: payload };
    case "TRIGGER_CLOSE":
      return { ...state, open: true, type: null };
    case "CLOSE":
      return { open: false, type: null };
    default:
      return state;
  }
}

/**
 * Basic vertical sidenav components, this relies on the NavIcon and NavContent components as
 * nested children. Icons are rendered as buttons, and the content is rendered on selection.
 * @param {object} props
 */
function VerticalSidenav({ children, activeNav, onOpen, onClose, location, onMenuItemClick }) {
  const [state, dispatch] = useReducer(verticalSidenavReducer, { open: false, type: null });

  useEffect(() => {
    if (!state.type && activeNav) {
      dispatch({ type: "OPEN", payload: activeNav });
    } else if (state.type && state.type !== activeNav) {
      dispatch({ type: "SWITCH", payload: activeNav });
    }
  }, [activeNav]);

  const openNav = (name, hasContent) => {
    if (onMenuItemClick) {
      onMenuItemClick(name);
    }
    if (hasContent !== false) {
      if (onOpen) {
        onOpen(name);
      }
      dispatch({ type: "OPEN", payload: name });
    }
  };

  const closeNav = () => {
    if (onOpen) {
      onClose();
    }
    dispatch({ type: "TRIGGER_CLOSE" });
  };

  /**
   * Render all the nav icons that are part of the children list.
   */
  const renderNavIconComponents = () => {
    const navIconComponents = React.Children.toArray(children).filter((child) => child.type === NavIcon);
    return navIconComponents.map((navIcon) =>
      React.cloneElement(navIcon, {
        key: `icon-${navIcon.props.name}`,
        location: location,
        onClick: () => openNav(navIcon.props.name, navIcon.props.hasContent)
      })
    );
  };

  /**
   * Renders all the nav content components wrapped in a CSSTransition as it slides out from the side.
   */
  const renderNavContentComponents = () => {
    const navContentComponents = React.Children.toArray(children).filter((child) => child.type === NavContent);
    if (navContentComponents.length === 0) {
      return null;
    }
    return navContentComponents.map((navContent) => (
      <CSSTransition
        key={`panel-${navContent.props.name}`}
        in={state.type === navContent.props.name}
        timeout={1000}
        unmountOnExit
        className="option-panel"
        onExited={() =>
          state.switchTo ? dispatch({ type: "OPEN", payload: state.switchTo }) : dispatch({ type: "CLOSE" })
        }
      >
        {React.cloneElement(navContent, {
          onClose: () => closeNav()
        })}
      </CSSTransition>
    ));
  };
  
  return (
    <VerticalSidenavContext.Provider
      value={{ active: state.type, setNavigation: (navId, hasContent) => openNav(navId, hasContent) }}
    >
      <div
        className={classnames("vertical-sidenav-container", {
          left: location === "left",
          right: location === "right",
          open: state.open
        })}
      >
        <div
          className={classnames("vertical-sidenav-panel-wrapper", {
            "show-shadow": !!state.type
          })}
          style={activeNav === "elements" ? { width: "350px" } : activeNav === "editQuestion" || activeNav === "editSuccessSection" ? { width: "550px" } : {}}
        >
          <div
            className={classnames("vertical-sidenav-panel", {
              open: !!state.type
            })}
            style={
              activeNav === "elements" ? { width: "350px" } : activeNav === "editQuestion" || activeNav === "editSuccessSection" ? { width: "550px" } : {}
            }
          >
            {renderNavContentComponents()}
          </div>
        </div>
        <div className="vertical-sidenav-buttons">{renderNavIconComponents()}</div>
      </div>
    </VerticalSidenavContext.Provider>
  );
}

VerticalSidenav.propTypes = {
  children: PropTypes.node,
  activeNav: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onMenuItemClick: PropTypes.func,
  location: PropTypes.oneOf(["left", "right"])
};

VerticalSidenav.defaultProps = {
  location: "left"
};

VerticalSidenav.NavIcon = NavIcon;
VerticalSidenav.NavContent = NavContent;

export { VerticalSidenav };
