import "./Register.css";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Redirect } from "react-router-dom";

// nodejs library that concatenates classes
import { useQuery, useMutation } from "@apollo/react-hooks";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { gql } from "apollo-boost";
import { UserRegistrationCard, PlanSelectionCard, CheckoutCard } from "components/Registration";
import { registrationReducer } from "reducers";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";
import { useCreateSubscription } from "../../components/Stripe/useCreateSubscription";
import { useUrlQuery } from "hooks/useUrlQuery";
import { compact, get, head, map } from "lodash";
import { isAgency } from "utils/helperFunctions";
import { useIsAuthenticated } from "hooks/useIsAuthenticated";

const GET_PRODUCTS = gql`
  {
    plans {
      id
      name
      stripeId
      description
      trialPeriodDays
      hidden
      customWorkspace
      features {
        id
        name
        value
      }
      price {
        amount
        currency
        interval
        id
      }
    }
  }
`;

const REGISTER_USER = gql`
  mutation RegisterUser($user: UserInput, $freePlan: Boolean, $pickedPlan: String, $stripeId: String) {
    registerUser(user: $user, freePlan: $freePlan, pickedPlan: $pickedPlan, stripeId: $stripeId) {
      id
      firstName
      lastName
      email
      company {
        stripeCustomerId
      }
    }
  }
`;

const LOGIN_USER = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
    }
  }
`;

const GET_SALES_REP = gql`
  query SalesRepByGuid($guid: String) {
    salesRepByGuid(guid: $guid) {
      commission
      stripeId
    }
  }
`;

/*
 * If register?plan=premium the plan by id 'premium' will be automatically selected.
 */
const Register = () => {
  const authenticated = useIsAuthenticated();
  const { data, loading } = useQuery(GET_PRODUCTS, {
    fetchPolicy: "no-cache"
  });
  const loginInfo = useRef();
  const query = useUrlQuery();
  const pickedPlanId = query.get("plan");
  const trialPlan = query.get("trial") === "true" ? true : false;
  const freePlan = query.get("key") === "sinapiapproved" ? true : false;
  const [pickedPlan, setPickedPlan] = useState();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [registerUser, { data: newUser, error: registerError, loading: signupLoading }] = useMutation(REGISTER_USER);
  const [loginUser] = useMutation(LOGIN_USER, { onCompleted: () => setIsAuthenticated(true) });
  const [createSubscription, { data: newSub, loading: creatingSub }] = useCreateSubscription();
  let registerErrorMsg;
  if (registerError && registerError.graphQLErrors && registerError.graphQLErrors.length > 0) {
    registerErrorMsg = registerError.graphQLErrors[0].message;
  }
  const [state, dispatch] = useReducer(registrationReducer, {});

  useEffect(() => {
    if (data && !loading) {
      const pickedPlan = pickedPlanId ? data.plans.find((pl) => pl.id === pickedPlanId) : null;
      setPickedPlan(pickedPlan);
    }
  }, [data, loading, pickedPlanId]);

  const [salesRep, setSalesRep] = useState();
  const { data: salesRepData, loading: salesRepLoading } = useQuery(GET_SALES_REP, {
    fetchPolicy: "no-cache",
    variables: {
      guid: query.get("rep")
    },
    skip: !query.get("rep")
  });
  useEffect(() => {
    if (salesRepData && !salesRepLoading) {
      setSalesRep(salesRepData.salesRepByGuid);
    }
  }, [salesRepData, salesRepLoading]);

  useEffect(() => {
    if (newUser && !signupLoading) {
      dispatch({
        type: "REGISTER_USER",
        payload: {
          user: newUser.registerUser,
          plan: pickedPlan,
          isTrial: trialPlan
        }
      });
    }
  }, [newUser]);

  useEffect(() => {
    if (newSub && !creatingSub) {
      onPaymentSuccess(newSub);
    }
  }, [newSub]);

  useEffect(() => {
    if (state.subscription && loginInfo.current) {
      const timer = setTimeout(() => {
        autoLogin();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [state.subscription]);

  if (authenticated && authenticated.dataAuth && !authenticated.errorAuth) {
    return <Redirect to="/admin/dashboard" />;
  }

  if (isAgency) {
    return <Redirect to="/auth/login" />;
  }

  if (isAuthenticated) {
    return <Redirect to="/admin/dashboard" />;
  }

  function onSignup({ firstName, lastName, email, password, companyName, customWorkspace }) {
    loginInfo.current = { email: email, password: password };
    registerUser({
      variables: {
        user: {
          lastName: lastName,
          firstName: firstName,
          email: email,
          password: password,
          companyName: companyName,
          customWorkspace: customWorkspace
        },
        freePlan: freePlan,
        pickedPlan: pickedPlanId,
        stripeId: pickedPlan && pickedPlan.stripeId
      }
    });
  }

  function autoLogin() {
    loginUser({
      variables: {
        email: loginInfo.current.email,
        password: loginInfo.current.password
      }
    });
  }

  function selectPlan(plan, isTrial, isFreePlan) {
    dispatch({ type: "PICK_PLAN", payload: { plan: plan, isTrial: isTrial } });
    const priceNoInterval = head(compact(map(plan.price, (pr) => (pr.interval === "" ? pr : undefined))));
    const priceMonthly = head(compact(map(plan.price, (pr) => (pr.interval === "mo" ? pr : undefined))));
    if (isTrial) {
      createSubscription(null, {
        customerId: state.newUser.company.stripeCustomerId,
        priceId: priceNoInterval ? [priceMonthly.id, priceNoInterval.id] : [priceMonthly.id],
        isTrial: isTrial,
        trialPeriodDays: get(plan, ["trialPeriodDays"]),
        userId: state.newUser.id,
        planId: plan.id,
        commission: parseFloat(salesRep?.commission ?? 0),
        connectStripeId: salesRep?.stripeId
      });
    }
    if (isFreePlan) {
      onPaymentSuccess({});
    }
  }

  function onPaymentSuccess(data) {
    dispatch({ type: "PAYMENT_SUCCESS", payload: data });
  }

  return (
    <>
      <AuthHeader />
      <Container className="pb-5" style={{ marginTop: "-11.2em" }}>
        <Row className="justify-content-center">
          <Col xs={(trialPlan || freePlan) && state.newUser ? "6" : "12"}>
            {!state.newUser ? (
              <UserRegistrationCard onSignup={onSignup} signupErrorMsg={registerErrorMsg} pickedPlan={pickedPlan} />
            ) : null}
            {data && !loading && state.newUser && (trialPlan || freePlan || !state.plan || state.isTrial) ? (
              <PlanSelectionCard
                plans={data.plans}
                onPickedPlan={selectPlan}
                plan={state.plan}
                trialPlan={trialPlan}
                freePlan={freePlan}
              />
            ) : null}
            {creatingSub && state.plan && state.isTrial ? <LoaderSpinner /> : null}
            {state.plan && state.newUser && !trialPlan && !freePlan && (!state.isTrial || pickedPlanId) ? (
              <CheckoutCard
                plan={state.plan}
                onSuccess={onPaymentSuccess}
                user={state.newUser}
                isTrial={state.isTrial}
                salesRep={salesRep}
              />
            ) : null}
            <div className="text-muted text-center mt-3">
              <small>Already have an account? </small>
              <a className="text-muted" href="/auth/login">
                <small>Sign In</small>
              </a>
            </div>
            {/* <div className="text-muted text-center">
              <small>
                <a className="text-muted" target="_blank" rel="noopener noreferrer" href="https://sinapi.io/privacy/">
                  Privacy
                </a>
              </small>
              <span> | </span>
              <small>
                <a className="text-muted" target="_blank" rel="noopener noreferrer" href="https://sinapi.io/terms/">
                  Terms
                </a>
              </small>
            </div> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Register;
