import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// components
import { get, find, findIndex, update, words } from "lodash";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { format, formatDistanceToNowStrict } from "date-fns";
import { Card, CardBody, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge } from "reactstrap";

import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { getSubscriptionStatusName, stringToBoolean } from "utils/helperFunctions";
import { usePermissionsWithRole } from "hooks/usePermissionsWithRole";
import { FEATURE_WHITE_LABEL } from "utils/constants";

import "./CompanySubscription.scss";

const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($subscriptionId: ID!, $customerId: String!) {
    cancelSubscription(subscriptionId: $subscriptionId, customerId: $customerId) {
      status
    }
  }
`;

const GET_SUBSCRIPTION_AND_PLANS = gql`
  query getSubscription($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      planId
      stripeCustomerId
      subscriptions {
        status
        id
        cancelAt
        trialEnd
        items {
          id
          plan {
            id
            product
          }
        }
        latestInvoice {
          id
          status
          paymentIntent {
            status
          }
        }
      }
      paymentMethod {
        billingDetails {
          name
        }
      }
    }
    plans {
      id
      name
      description
      stripeId
      hidden
      hierarchy
      canUpgrade
      features {
        id
        name
        value
      }
      price {
        amount
        currency
        interval
        id
        nickname
      }
    }
  }
`;

const GET_FEATURES_OVERWRITE = gql`
  query GetFeaturesOverwrite($companyId: ID!) {
    companyFeatures(companyId: $companyId) {
      featureId
      value
    }
  }
`;

const CompanySubscription = ({ companyData, plans, price, companyId, showDetails = true, plan }) => {
  const isAdmin = usePermissionsWithRole("admin");

  const { loading, error, data, refetch } = useQuery(GET_SUBSCRIPTION_AND_PLANS, {
    fetchPolicy: "network-only",
    variables: { companyId: companyId },
    skip: !companyId
  });
  const { loading: loadFeatures, data: featuresData } = useQuery(GET_FEATURES_OVERWRITE, {
    fetchPolicy: "no-cache",
    variables: { companyId: companyId },
    skip: !companyId
  });
  const [showCancelSubscription, setCancelSubscription] = useState(false);
  const [cancelSubscriptionMutation] = useMutation(CANCEL_SUBSCRIPTION);
  const history = useHistory();

  const cancelSubscription = (subId) => {
    setCancelSubscription(false);
    cancelSubscriptionMutation({
      variables: {
        subscriptionId: subId,
        customerId: customerId
      }
    }).then(() => {
      if (!companyData && !plans) refetch();
    });
  };

  if (loading) {
    return <LoaderSpinner />;
  }
  if (error && !data) {
    // show error
    throw error;
  }
  const compSubscriptions = companyId && data ? data.company.subscriptions : get(companyData, "subscriptions");
  const compSubscription = compSubscriptions && compSubscriptions.length > 0 ? compSubscriptions[0] : null;
  const compStatus = getSubscriptionStatusName(compSubscription);
  const endDate = compSubscription && compSubscription.cancelAt ? new Date(compSubscription.cancelAt * 1000) : null;
  const compPayment = companyId && data ? data.company.paymentMethod : get(companyData, "paymentMethod");
  const dataPlans = plans || get(data, "plans");
  let compProduct = compSubscription
    ? compSubscription.status !== "canceled"
      ? dataPlans
        ? dataPlans.find((pr) => pr.stripeId === compSubscription.items[0].plan.product)
        : undefined
      : undefined
    : dataPlans
    ? dataPlans.find((pr) => pr.id === plan)
    : undefined;
  let compPrice = price
    ? price
    : compProduct && compProduct.price.find((pr) => pr.id === compSubscription.items[0].plan.id);
  if (!compProduct && dataPlans) {
    compProduct = find(dataPlans, (pr) => {
      const planId = data ? data.company.planId : get(companyData, "planId");
      return pr.id === planId;
    });
    compPrice = { amount: 0, interval: "unlimited" };
  }
  const isPastDue = compSubscription && compSubscription.status === "past_due";
  const customerId = data ? data.company.stripeCustomerId : get(companyData, "stripeCustomerId");

  const hasWhiteLabel = stringToBoolean(
    get(
      find(get(compProduct, "features", []), (feature) => feature.id.includes(FEATURE_WHITE_LABEL)),
      ["value"]
    )
  );

  const canUpgrade = plan
    ? get(
        (plans || data.plans).find((pr) => pr.id === plan),
        "canUpgrade"
      )
    : false;

  if (!loadFeatures && featuresData && featuresData.companyFeatures) {
    featuresData.companyFeatures.map((feature) => {
      const product = compProduct && compProduct.features.find((pr) => pr.id === feature.featureId);
      const productNameWords = words(product.name);
      const idx = findIndex(productNameWords, (val) => product.value === val);

      if (parseInt(feature.value) > 1)
        update(product, "name", (fea) => fea.replace(productNameWords[idx + 1], productNameWords[idx + 1].concat("s")));
      update(product, "name", (fea) => fea.replace(product.value, feature.value));
      update(product, "value", () => feature.value);
      return product;
    });
  }

  const getPriceAmount = (price, interval) =>
    get(
      find(price, (pr) => pr.interval === interval),
      "amount",
      0
    );

  const onClickMailTo = () => {
    window.location.href = `mailto:hello@sinapi.io`;
  };

  return (
    <>
      <Card
        className="company-subscription-container"
        style={{
          boxShadow: showDetails ? "0 0 11px 1px rgb(136 152 170 / 35%)" : "0 0 1px 1px rgb(136 152 170 / 35%)",
          marginBottom: showDetails ? "30px" : "0px"
        }}
      >
        <CardBody>
          {showDetails && (
            <Row className="justify-content-center align-items-center">
              <Col sm="3" lg="3" className="mb-3">
                <div className="sub-col-header">Your Subscription</div>
                <div className="sub-col-value">{compProduct ? compProduct.name : "-"}</div>
              </Col>
              <Col sm="3" lg="2" className="mb-3">
                <div className="sub-col-header">Plan cost</div>
                <div className="sub-col-value">{compProduct ? `${compPrice.amount}/${compPrice.interval}` : "-"}</div>
              </Col>
              <Col sm="3" lg="2" className="mb-3">
                <div className="sub-col-header">Status</div>
                <div className="sub-col-value">
                  {endDate ? `Cancels ${format(endDate, "MMM d, yyyy")}` : compStatus || "-"}
                </div>
              </Col>
              {compStatus === "Trial" ? (
                <Col sm="3" lg="2" className="mb-3">
                  <div className="sub-col-header">Trial Ends</div>
                  <div className="sub-col-value">
                    {formatDistanceToNowStrict(new Date(compSubscription.trialEnd * 1000))}
                  </div>
                </Col>
              ) : null}
              <Col sm="3" lg="2" className="mb-3">
                <div className="sub-col-header">Billed To</div>
                <div className="sub-col-value">
                  {compPayment && compPayment.billingDetails ? compPayment.billingDetails.name : "-"}
                </div>
              </Col>
              <Col sm="4" lg="2" className="mb-3" style={{ display: "contents" }}>
                {compStatus && compStatus === "Active" && !endDate ? (
                  <Button
                    outline
                    color="primary"
                    type="button"
                    onClick={() => {
                      setCancelSubscription(true);
                    }}
                  >
                    <span className="btn-inner--text">Disable Account</span>
                  </Button>
                ) : null}
              </Col>
            </Row>
          )}
          {canUpgrade ? (
            <>
              <Row className="mt-0">
                <Col xs="12">
                  <Card className="bg-success mb-0">
                    <CardBody className="text-center">
                      {isAdmin ? (
                        <span className="text-white" style={{ fontSize: "0.90rem" }}>
                          If you need something more than what is offered below,{" "}
                          {hasWhiteLabel ? (
                            "contact your account representative and they can create a custom plan for your needs."
                          ) : (
                            <>
                              contact us at{" "}
                              <a href="mailto:hello@sinapi.io" style={{ color: "white", fontWeight: "600" }}>
                                hello@sinapi.io
                              </a>{" "}
                              and we can create a custom plan for your needs.
                            </>
                          )}
                        </span>
                      ) : (
                        <span className="text-white" style={{ fontSize: "0.90rem" }}>
                          Please reach out to your account administrator to upgrade or change plans
                        </span>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4">
                {(plans || data.plans || []).map(
                  (pr) =>
                    pr.hidden === false && (
                      <Col xs="12" sm="6" md="4" key={pr.id}>
                        {compProduct && pr.id === compProduct.id ? (
                          <Card className="bg-gradient-success mb-3">
                            <CardBody>
                              <Row className="justify-content-center align-items-center">
                                <span className="h4 text-uppercase ls-1 text-white">{pr.name}</span>
                                <Col style={{ position: "absolute", textAlign: "right" }}>
                                  <Badge className="badge-lg" color="default">
                                    Active
                                  </Badge>
                                </Col>
                              </Row>
                              <Row className="justify-content-center align-items-center">
                                <div className="text-white display-2">{get(compPrice, ["amount"])}</div>
                                <span className="text-white pt-3">&nbsp;/{get(compPrice, ["interval"])}</span>
                              </Row>
                              <Row className="justify-content-center align-items-center">
                                <ul
                                  className="list-unstyled mt-3 mb-4 pr-features"
                                  style={{ width: "65%", textAlign: "center" }}
                                >
                                  {pr.features.map(
                                    (feat) =>
                                      stringToBoolean(feat.value) && (
                                        <li key={feat.id}>
                                          <div className="align-items-center mb-1">
                                            <div>
                                              <span className="text-white">{feat.name}</span>
                                            </div>
                                          </div>
                                        </li>
                                      )
                                  )}
                                </ul>
                              </Row>
                            </CardBody>
                          </Card>
                        ) : (
                          <div className="sinapi-pr mb-3">
                            <h4 className="text-uppercase ls-1 text-primary py-2 mb-0">{pr.name}</h4>
                            <div className="justify-content-center align-items-center row">
                              <div className="display-2">{getPriceAmount(pr.price, "mo")}</div>
                              <span className="text-muted pt-3">&nbsp;/{"mo"}</span>
                            </div>
                            <div className="justify-content-center align-items-center row">
                              <span className="text-muted small">or&nbsp;&nbsp;</span>
                              <div className="small font-weight-600">{getPriceAmount(pr.price, "yr")}</div>
                              <span className="text-muted small">&nbsp;/{"yr"}</span>
                            </div>
                            <div
                              className="justify-content-center align-items-center"
                              style={{ width: "65%", margin: "auto" }}
                            >
                              <ul className="list-unstyled my-4 pr-features">
                                {pr.features.map(
                                  (feat) =>
                                    stringToBoolean(feat.value) && (
                                      <li key={feat.id}>
                                        <div className="align-items-center mb-1">
                                          <div>
                                            <span>{feat.name}</span>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                )}
                              </ul>
                            </div>
                            {isAdmin ? (
                              compProduct && pr.hierarchy < compProduct.hierarchy ? (
                                <div className="pr-footer">
                                  <Button className="mb-3" color="primary" type="button" onClick={onClickMailTo}>
                                    Contact Us
                                  </Button>
                                </div>
                              ) : (
                                !isPastDue && (
                                  <div className="pr-footer">
                                    <Button
                                      className="mb-3"
                                      color="primary"
                                      type="button"
                                      onClick={() => history.push("/admin/upgrade?plan=" + pr.id)}
                                    >
                                      Change Plan
                                    </Button>
                                  </div>
                                )
                              )
                            ) : null}
                          </div>
                        )}
                      </Col>
                    )
                )}
              </Row>
            </>
          ) : (
            <>
              <Row className="mt-0">
                <Col xs="12">
                  <Card className="bg-success mb-0">
                    <CardBody className="text-center">
                      {isAdmin ? (
                        <span className="text-white" style={{ fontSize: "0.90rem" }}>
                          Want to modify your account?{" "}
                          {hasWhiteLabel ? (
                            "contact your account representative"
                          ) : (
                            <>
                              Contact{" "}
                              <a href="mailto:hello@sinapi.io" style={{ color: "white", fontWeight: "600" }}>
                                hello@sinapi.io
                              </a>
                            </>
                          )}{" "}
                          and we'll get you squared away.
                        </span>
                      ) : (
                        <span className="text-white" style={{ fontSize: "0.90rem" }}>
                          Please reach out to your account administrator to upgrade or change plans
                        </span>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4" style={{ justifyContent: "center" }}>
                <Col xs="12" sm="6" md="4">
                  {compProduct && (
                    <Card className="bg-gradient-success mb-3">
                      <CardBody>
                        <Row className="justify-content-center align-items-center">
                          <span className="h2 text-uppercase ls-1 text-white">{compProduct.name}</span>
                          <Col style={{ position: "absolute", textAlign: "right" }}>
                            <Badge className="badge-lg" color="default">
                              Active
                            </Badge>
                          </Col>
                        </Row>
                        <Row className="justify-content-center align-items-center">
                          <ul
                            className="list-unstyled mt-3 mb-4 pr-features"
                            style={{ width: "65%", textAlign: "center" }}
                          >
                            {compProduct.features.map(
                              (feat) =>
                                stringToBoolean(feat.value) && (
                                  <li key={feat.id}>
                                    <div className="align-items-center mb-1">
                                      <div>
                                        <span className="text-white">{feat.name}</span>
                                      </div>
                                    </div>
                                  </li>
                                )
                            )}
                          </ul>
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
      {showCancelSubscription ? (
        <Modal isOpen={true}>
          <ModalHeader>Do you wish to disable your account?</ModalHeader>
          <ModalBody>
            When you disable your account, you will be able to access your data for your remaining billing cycle. After
            that you will no longer have access to your account and any active forms you have running will no longer
            show up. In addition you will not receive any future charges.
            <br />
            <br />
            We hate to see you go. Please contact us at <a href="mailto:hello@sinapi.io">hello@sinapi.io</a> before you
            go so we can help make it right!
          </ModalBody>
          <ModalFooter>
            <Button className="btn-outline-primary" onClick={() => setCancelSubscription(false)}>
              No, I'll Stick Around
            </Button>
            <Button color="primary" onClick={() => cancelSubscription(compSubscription.id)}>
              Cancel Subscription
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
};
export default CompanySubscription;
