import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

// reactstrap components
import { Card, CardHeader, CardBody, CardImg, Container, Row, Col } from "reactstrap";
// core components
import ProfileHeader from "components/Headers/ProfileHeader.jsx";
import ProfileForm from "components/Forms/ProfileForm.jsx";

const GET_CURRENT_USER = gql`
  {
    currentUser {
      id
      firstName
      lastName
      email
      phone
      company {
        id
        name
        address
        city
        state
        zipcode
      }
    }
  }
`;
const GET_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      phone
      roleId
      company {
        id
        name
        address
        city
        state
        zipcode
      }
    }
  }
`;

const Profile = ({ hideProfileCard, hideHeader, showRole = false, userId, hasChangePass }) => {
  const { loading, error, data } = useQuery(GET_CURRENT_USER, {
    skip: !!userId,
    fetchPolicy: "cache-and-network"
  });
  const getUserQuery = useQuery(GET_USER, {
    variables: {
      id: userId
    },
    fetchPolicy: "cache-and-network",
    skip: !userId
  });

  if (loading || getUserQuery.loading) {
    return <div>Loading</div>;
  }
  if (error && !data) {
    // show error
  }
  let userData;
  if (userId && getUserQuery.data) {
    userData = getUserQuery.data.user;
  } else if (data && data.currentUser) {
    userData = data.currentUser;
  }
  return (
    <>
      {!hideHeader ? <ProfileHeader firstName={userData.firstName} /> : null}
      <Container className={!hideHeader ? "mt--7" : ""} fluid>
        <Row>
          {!hideProfileCard ? (
            <Col className="order-xl-2" xl="4">
              <Card className="card-profile">
                <CardImg alt="..." src={require("assets/img/theme/img-1-1000x600.jpg")} top />
                <CardHeader className="text-center border-0 py-3">
                  <div className="d-flex justify-content-between"></div>
                </CardHeader>
                <CardBody className="pt-0">
                  <div className="text-center">
                    <h5 className="h2">
                      {userData.firstName}&nbsp;{userData.lastName}
                    </h5>
                    <div className="h4 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {userData.email}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ) : null}
          <Col className="order-xl-1" xl={hideProfileCard ? "12" : "8"}>
            <ProfileForm userData={userData} showRole={showRole} hasChangePass={!userId || hasChangePass} />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Profile;
