import React from "react";
import { Row, Col, Button } from "reactstrap";
import { RenderFormRow } from "./RenderFormRow";

function RenderFormSection({ section, style, formError, setFormError, activeIndex, typeForm, previous, next, sections, saveLeadAction }) {
  return (
    <Row>
      <Col lg="12">
        <div className="tab-content form-section-content" id="newForm-wrapper">
          <div className="card" /* style={style} */>
            <div className="card-body mobile-set-padding-15" /* style={{ padding: window.innerWidth < 768 ? "15px" : "2.5rem" }} */>
              <div className="form-rows">
                {section.rows.map((row, idxr) => (
                  <div key={`section_${section.id}_${idxr}`}>
                    <RenderFormRow row={row} formError={formError} setFormError={setFormError} />
                  </div>
                ))}
              </div>
            </div>
            <div
              className="card-footer"
              style={{ textAlign: "center",/*  minHeight: "90px", borderTopRightRadius: 0, borderTopLeftRadius: 0 */ }}
            >
              <Col className="text-right pr-0 pl-0" xs={12}>
                {activeIndex !== 0 && (
                  <Button
                    outline
                    className="btn-icon float-left"
                    color="secondary"
                    type="button"
                    onClick={previous}
                    disabled={formError}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-chevron-left"></i>
                    </span>
                    <span className="btn-inner--text">Back</span>
                  </Button>
                )}
                {activeIndex !== sections.length - 1 && (
                  <Button
                    outline
                    className="btn-icon"
                    color="primary"
                    type="button"
                    onClick={next}
                    disabled={formError}
                  >
                    <span className="btn-inner--text">Continue</span>
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Button>
                )}
                {typeForm !== "single-page" && activeIndex === sections.length - 1 && (
                  <Button outline type="button" color="primary" onClick={() => saveLeadAction()} disabled={formError}>
                    Complete
                  </Button>
                )}
              </Col>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export { RenderFormSection };
