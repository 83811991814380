import { toLower } from "lodash";

import { FORM_NAME } from "utils/constants";

export const generalStyles = [
  {
    title: "Background Color",
    description: `Background color of ${toLower(FORM_NAME)}`,
    type: "color-picker",
    cssPath: ["backgroundColor"]
  },
  {
    title: "Background Image",
    description: `<p>Background image of ${toLower(FORM_NAME)}</p>`,
    type: "file",
    cssPath: ["backgroundImage"],
    parseValueIn: (val) =>
      val ? val.replace("'", "").replace("url(", "").replace("'", "").replace(")", "") || null : null
  }
];
