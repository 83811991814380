import React, { useEffect, useState } from "react";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";

import { get, toInteger } from "lodash";
import { gql } from "apollo-boost";
import { format } from "date-fns";
import { useMutation } from "@apollo/react-hooks";
import { FEATURE_NUMBER_OF_FORMS } from "utils/constants";
import { useCompanySubscription } from "hooks/useCompanySubscription";
import { getLimitSubscriptionPerFeatureType } from "utils/helperFunctions";
import { useMediaQuery } from "react-responsive";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Button,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Alert,
  UncontrolledTooltip
} from "reactstrap";

import ManagementModal from "components/UserManagement/ManagementModal";
import NewFormModal from "components/UserManagement/NewFormModal";
import { numberToCurrency } from "utils/helperFunctions";

const REMOVE_FORM = gql`
  mutation RemoveForm($workspaceId: ID!, $formId: ID!) {
    removeForm(workspaceId: $workspaceId, formId: $formId)
  }
`;
const COPY_FORM = gql`
  mutation CopyForm($formId: ID!, $workspaceId: ID!) {
    copyForm(formId: $formId, workspaceId: $workspaceId ) {
      name
      schema
      lastUpdate
    }
  }
`;

const CardForms = ({ currentWorkspace, isDataReady, refetchData, cardFormsData, showNoLeadsMsg = false, setCodeModal }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [editForm, setEditForm] = useState(null);
  const [analyticForm, setAnalyticForm] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [removeFormMutation, { data: removeFormData, loading: removingForm }] = useMutation(REMOVE_FORM);
  const [copyFormMutation] = useMutation(COPY_FORM, {
    onCompleted: () => {
      refetchData();
    }
  });
  const companySubscription = useCompanySubscription(currentUser.company.id, currentUser.company.planId);
  const limitForms = toInteger(getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_NUMBER_OF_FORMS));
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1268 })

  useEffect(() => {
    if (!removingForm && removeFormData && removeFormData.removeForm) {
      refetchData();
    }
  }, [refetchData, removeFormData, removingForm]);

  const removeForm = (form) => {
    removeFormMutation({
      variables: {
        formId: form.id,
        workspaceId: currentWorkspace.id
      }
    });
    setShowModal(null);
  };

  if (editForm) {
    return <Redirect to={`/admin/form-edit/${editForm.id}`} />;
  }

  if (analyticForm) {
    return <Redirect to={`/admin/form-analytics/${analyticForm.id}`} />;
  }

  const openModal = form => {
    setCodeModal(
      {
        formId: form.formHash,
        guid: form.renderGuid,
        buttonSchema: form.buttonSchema,
        floatingButtonSchema: form.floatingButtonSchema,
        workspaceId: form.workspaceHash,
        userId: form.userId
      }
    )
  }

  const forms = get(cardFormsData, ["workspace", "forms"], []);

  const copyForm = (form) => {
    copyFormMutation({
      variables: {
        formId: form.id,
        workspaceId: currentWorkspace.id
      }
    });
  }

  return (
    <>
      <Container className="mt-2" fluid>
        {isDataReady ? <LoaderSpinner /> : null}
        {!isDataReady && forms && forms.length === 0 && showNoLeadsMsg && (
          <Row className="mt--3 justify-content-center">
            <Col lg="4" xs="4">
              <Card className="align-content-center" style={{ height: "270px" }}>
                <CardHeader style={{ backgroundColor: "#172b4d" }}>
                  <h2 className="mb-0 text-white">Create Your First Form</h2>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                      <span className="h3">Getting started is easy</span>
                      <br />
                      <p>Just click the button... really that's about it.</p>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col className="col-12">
                      <Button id="editButton" color="success" onClick={() => setShowModal({ type: "newForm" })}>
                        <i className="fas fa-plus" /> Start Building
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {!isDataReady && forms && forms.length > 0 && (
          <>
            {!showNoLeadsMsg && (
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h1 className="d-inline-block mb-0">Latest Forms</h1>
                </Col>
              </Row>
            )}
            <Row>
              {forms.map((form, index) => (
                <Col key={index} style={{ minWidth: "265px", maxWidth: "365px" }}>
                  <Card style={{ height: "440px" }}>
                    <CardHeader style={{ backgroundColor: "#172b4d" }}>
                      <h2 className="mb-0 text-white">{form.name}</h2>
                    </CardHeader>
                    <CardBody>
                      {get(form, ["lead", "count"]) > 0 ? (
                        <>
                          <Row className="align-items-center mt-2">
                            <Col className="col-12">
                              <span className="h3" style={{ color: "#444", fontSize: "16px" }}>Interested Value:</span>
                              <br />
                              <h1
                                className="font-weight-bold"
                                style={{ fontSize: "2rem", color: "darkgreen", marginBottom: "0rem" }}
                              >
                                {numberToCurrency(get(form, ["lead", "interestedCost"], 0))}
                              </h1>
                              <span className="h5 text-gray font-weight-bold">
                                Total Quoted: {numberToCurrency(get(form, ["lead", "totalCost"], 0))}
                              </span>
                            </Col>
                          </Row>
                          <Row className="align-items-center mt-3">
                            <Col className="col-12">
                              <span className="h3" style={{ color: "#444", fontSize: "16px" }}>
                                Total Leads:{" "}
                              </span>
                              {get(form, ["lead", "count"])}
                            </Col>
                          </Row>
                          <Row className="align-items-center mt-3">
                            <Col className="col-12">
                              <span className="h3" style={{ color: "#444", fontSize: "16px" }}>
                                Last Lead
                              </span>
                              <br />
                              {format(new Date(get(form, ["lead", "items", 0, "createdAt"])), "MMM d, yyyy KK:mm aaaa")}
                            </Col>
                          </Row>
                          <Row className="align-items-center mt-3">
                            <Col className="col-12">
                              <span className="h3" style={{ color: "#444", fontSize: "16px" }}>
                                Published On
                              </span>
                              <br />
                              {format(new Date(get(form, ["publishedAt"])), "MMM d, yyyy KK:mm aaaa")}
                            </Col>
                          </Row>
                        </>
                      ) : get(form, "status") === "draft" ? (
                        <>
                          <Row className="align-items-center">
                            <Col className="col-12">
                              <Alert
                                color="danger"
                                className="bg-gradient-danger"
                                style={{ padding: "1rem", paddingTop: "2rem", paddingBottom: "2rem" }}
                              >
                                <span className="alert-inner--icon">
                                  <i className="fab fa-telegram-plane fa-3x" />
                                </span>
                                <span className="alert-inner--text ml-3 mr-4" style={{ position: "absolute" }}>
                                  This form has not been yet published
                                </span>
                              </Alert>
                            </Col>
                          </Row>
                          <Row className="align-items-center mt-2">
                            <Col className="col-12">
                              <span className="h3" style={{ color: "#444", fontSize: "16px" }}>
                                Last Saved
                              </span>
                              <br />
                              {format(new Date(get(form, ["lastUpdate"])), "MMM d, yyyy KK:mm aaaa")}
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row className="align-items-center">
                            <Col className="col-12">
                              <Alert color="success" className="bg-gradient-success" style={{ padding: "1rem 1rem" }}>
                                <span className="alert-inner--icon">
                                  <i className="fas fa-thumbs-up fa-3x" />
                                </span>
                                <span className="alert-inner--text ml-3 mr-4" style={{ position: "absolute" }}>
                                  Eagerly waiting for your first lead!
                                </span>
                              </Alert>
                            </Col>
                          </Row>
                          <Row className="align-items-center mt-2">
                            <Col className="col-12">
                              <span className="h3" style={{ color: "#444", fontSize: "16px" }}>
                                Published On
                              </span>
                              <br />
                              {format(new Date(get(form, ["publishedAt"])), "MMM d, yyyy KK:mm aaaa")}
                            </Col>
                          </Row>
                        </>
                      )}
                    </CardBody>
                    <CardFooter className="bg-gradient-light">
                      <Row className="align-items-center">
                        <Col className="text-right" xs="12">
                          {get(form, ["lead", "count"]) > 0 ? (
                            <>
                              {!isTabletOrMobile && (
                                <>
                                  <Button id="codeButton" size="sm" color="primary" onClick={() => openModal(form)}>
                                    <i className="fas fa-code" />
                                  </Button>
                                  <UncontrolledTooltip target="codeButton">Get Code</UncontrolledTooltip>
                                </>
                              )}
                              
                              <Button
                                id="analyticsButton"
                                size="sm"
                                color="primary"
                                onClick={() => setAnalyticForm(form)}
                              >
                                <i className="fas fa-chart-bar" />
                              </Button>
                              <UncontrolledTooltip target="analyticsButton">Analytics</UncontrolledTooltip>
                            </>
                          ) : null}
                          
                          {(limitForms === -1 || forms.length < limitForms) && !isTabletOrMobile && (
                            <>
                              <Button id="copyButton" size="sm" color="primary" onClick={() => copyForm(form)}>
                                <i className="fas fa-copy" />
                              </Button>
                              <UncontrolledTooltip target="copyButton">Copy</UncontrolledTooltip>
                            </>
                          )}
                          {!isTabletOrMobile && (
                            <>
                              <Button id="editButton" size="sm" color="primary" onClick={() => setEditForm(form)}>
                                <i className="fas fa-edit" />
                              </Button>
                              <UncontrolledTooltip target="editButton">Edit</UncontrolledTooltip>
                            </>
                          )}
                          <Button
                            id="removeButton"
                            size="sm"
                            color="secondary"
                            className="float-left"
                            onClick={() => setShowModal({ type: "removeForm", data: form })}
                          >
                            <i className="fa fa-trash-alt" />
                          </Button>
                          <UncontrolledTooltip target="removeButton">Remove</UncontrolledTooltip>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        )}
      </Container>
      {showModal && showModal.type === "newForm" ? <NewFormModal onClose={() => setShowModal(null)} /> : null}
      {showModal && showModal.type === "removeForm" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>Remove "{showModal.data.name}" form?</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            { label: "Remove", onClick: () => removeForm(showModal.data) }
          ]}
        >
          Are you sure you want to remove "{showModal.data.name}" form?
        </ManagementModal>
      ) : null}
    </>
  );
};

export default CardForms;
