import React, { useState, useEffect } from "react";
import { get } from "lodash";
import {
  Card,
  Col,
  Button,
  CardHeader,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle
} from "reactstrap";
import PropTypes from "prop-types";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import ManagementModal from "components/UserManagement/ManagementModal";
import AddToWorkspaceModal from "components/UserManagement/AddToWorkspaceModal";
import SinapiTable from "components/Shared/SinapiTable";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { gql } from "apollo-boost";

const GET_USERS = gql`
  query getWorkspaceUsers($workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
      name
      users {
        count
        items {
          id
          firstName
          lastName
          email
          pendingInvite {
            uuid
          }
        }
      }
      company {
        id
        name
      }
    }
  }
`;

const REMOVE_USER_FROM_WORKSPACE = gql`
  mutation removeUserFromWorkspace($userId: ID!, $workspaceId: ID!) {
    workspaceRemoveUser(userId: $userId, workspaceId: $workspaceId) {
      id
    }
  }
`;

function WorkspaceUsers({ breadCrumbs }) {
  const { workspaceId, ...params } = useParams();
  const { loading, error, data, refetch } = useQuery(GET_USERS, {
    variables: { workspaceId: workspaceId },
    fetchPolicy: "network-only"
  });
  const [removeUserMutation, { loading: removingUser, data: removeUserData }] = useMutation(REMOVE_USER_FROM_WORKSPACE);
  const [showModal, setShowModal] = useState();

  useEffect(() => {
    if (removeUserData && !removingUser) {
      refetch();
    }
  }, [removeUserData]);

  const removeUser = (user) => {
    removeUserMutation({
      variables: {
        workspaceId: workspaceId,
        userId: user.id
      }
    });
    setShowModal(null);
  };

  return (
    <>
      <SimpleHeader
        name={data && data.workspace ? data.workspace.name : ""}
        breadCrumbs={breadCrumbs}
        breadCrumbParams={{ ...params, companyName: data && data.workspace ? data.workspace.company.name : "Company" }}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h5 className="mb-0">Workspace:</h5>
                <h1 className="mb-0 mt-0">{data && data.workspace ? data.workspace.name : null}</h1>
              </CardHeader>
              {loading || removingUser ? <LoaderSpinner /> : null}
              <Row>
                <Col sm="10">
                  <h3 className="mb-2 ml-4">Users</h3>
                </Col>
                <Col sm="2" className="text-right">
                  <Button
                    outline
                    color="primary"
                    size="sm"
                    className="mr-4"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowModal({ type: "addNewUser" });
                    }}
                  >
                    + ADD NEW
                  </Button>
                </Col>
              </Row>
              <SinapiTable
                data={get(data, ["workspace", "users"])}
                isLoaded={!loading && data ? true : false}
                options={{
                  emptyLabel: `No Users in ${data && data.workspace ? data.workspace.name : ""}.`,
                  columns: [
                    {
                      key: "name",
                      label: "Name",
                      render: (row, column) => {
                        return (
                          <>
                            {(row.firstName || "") + " " + (row.lastName || "")}{" "}
                            {row.pendingInvite ? <i>(Pending Invite)</i> : null}
                          </>
                        );
                      }
                    },
                    { key: "email", label: "Email" },
                    { key: "accessData", label: "Access Date" },
                    {
                      key: "options",
                      label: "",
                      className: "text-right",
                      render: (row, column) => (
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right positionFixed={true}>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowModal({ type: "removeUser", data: row });
                              }}
                            >
                              Remove From Workspace
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )
                    }
                  ]
                }}
              />
            </Card>
          </div>
        </Row>
      </Container>
      {showModal && showModal.type === "removeUser" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={
            <div>
              Remove {showModal.data.firstName} {showModal.data.lastName} from the workspace {data.workspace.name}
            </div>
          }
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            { label: "Confirm", onClick: () => removeUser(showModal.data) }
          ]}
        >
          The user will no longer be able to access content inside this workspace.
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "addNewUser" ? (
        <AddToWorkspaceModal
          companyId={data.workspace.company.id}
          workspaceId={workspaceId}
          currentWorkspaceUserIds={data.workspace.users ? data.workspace.users.items.map((u) => u.id) : []}
          workspaceName={data.workspace.name}
          onClose={(success) => {
            setShowModal(null);
            if (success) {
              refetch();
            }
          }}
        />
      ) : null}
    </>
  );
}

WorkspaceUsers.defaultProps = {};

WorkspaceUsers.propTypes = {
  breadCrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  )
};

export default WorkspaceUsers;
