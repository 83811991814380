import "./Management.scss";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import accountRoutes from "./accountRoutes";

// react library for routing

function ManagementPage() {
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);

  const userRole = currentWorkspace ? currentWorkspace.userRoleId || currentUser.roleId : null;
  for (const route of accountRoutes) {
    if (!route.hidden && (!route.roles || route.roles.includes(userRole))) {
      return <Redirect to={route.layout + route.path} />;
    }
  }
  return <Redirect to="/admin/dashboard" />;
}

ManagementPage.defaultProps = {};

ManagementPage.propTypes = {};

export default ManagementPage;
