import React, { useState, useEffect } from "react";
// react library for routing
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import ManagementModal from "./ManagementModal";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { Input } from "reactstrap";

const GET_USERS = gql`
  query getUsers($companyId: ID!) {
    company(id: $companyId) {
      users {
        count
        items {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const ADD_USER_TO_WORKSPACE = gql`
  mutation addUserToWorkspace($userId: ID!, $workspaceId: ID!) {
    workspaceAddUser(userId: $userId, workspaceId: $workspaceId) {
      id
    }
  }
`;

function AddToWorkspaceModal({ workspaceId, workspaceName, companyId, currentWorkspaceUserIds, onClose }) {
  const { loading, data } = useQuery(GET_USERS, {
    variables: {
      companyId: companyId
    }
  });
  const [addUserMutation, { loading: addingUser, data: newUserData }] = useMutation(ADD_USER_TO_WORKSPACE);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [noAvailableUsers, setNoAvailableUsers] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (!loading && data && data.company) {
      const avUsers = data.company.users.items.filter((user) => !currentWorkspaceUserIds.includes(user.id));
      if (avUsers.length > 0) {
        setAvailableUsers(avUsers);
      } else {
        setNoAvailableUsers(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!addingUser && newUserData) {
      onClose(true);
    }
  }, [addingUser]);

  const addUser = () => {
    addUserMutation({
      variables: {
        workspaceId: workspaceId,
        userId: selectedUser
      }
    });
  };

  return (
    <ManagementModal
      modalSize="md"
      onClose={() => onClose()}
      header={<div>Add user to "{workspaceName}" workspace:</div>}
      buttons={[
        { label: "Cancel", outline: true, onClick: () => onClose() },
        { label: "Add To Workspace", onClick: () => addUser(), disabled: noAvailableUsers || !selectedUser }
      ]}
    >
      {loading || addingUser ? <LoaderSpinner /> : null}
      <label className="form-control-label">User {noAvailableUsers ? " (no new users available)" : null}</label>
      <Input
        type="select"
        className="form-control-sm"
        onChange={(e) => setSelectedUser(e.target.value !== "-" ? e.target.value : null)}
      >
        <option name="-" value="-">
          -
        </option>
        {availableUsers.map((user) => {
          return (
            <option key={user.id} name={user.id} value={user.id}>
              {user.firstName} {user.lastName}
            </option>
          );
        })}
      </Input>
    </ManagementModal>
  );
}

AddToWorkspaceModal.defaultProps = {};

AddToWorkspaceModal.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  workspaceName: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  currentWorkspaceUserIds: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

export default AddToWorkspaceModal;
