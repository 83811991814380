import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// TODO: This should make use of permissions instead
const ROLES = ["superAdmin", "admin", "manager", "consultant"];
export function usePermissionsWithRole(acceptedRole) {
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);
  const [hasPermission, setHasPermission] = useState(false);

  const getPermission = () => {
    const userRole = currentWorkspace ? currentWorkspace.userRoleId || currentUser.roleId : null;
    if (userRole) {
      const accIndex = ROLES.findIndex((r) => r === acceptedRole);
      const userRoleIndex = ROLES.findIndex((r) => r === userRole);
      return userRoleIndex <= accIndex;
    }
    return false;
  };

  useEffect(() => {
    setHasPermission(getPermission());
  }, []);

  useEffect(() => {
    setHasPermission(getPermission());
  }, [currentUser, currentWorkspace]);

  return hasPermission;
}
