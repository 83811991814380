import React, { useState } from "react";

import { forEach } from "lodash";
import { Card, CardHeader, CardBody, Button, Row, Col } from "reactstrap";

import { DraggableField } from "./DraggableField";
import { SinapiFieldsSections } from "./fields";
import ManagementModal from "components/UserManagement/ManagementModal";
import CompanySubscription from "components/Subscription/CompanySubscription";
import { getLimitSubscriptionPerFeatureType, stringToBoolean } from "utils/helperFunctions";
import { FEATURE_ADVANCED_EDITING, FEATURE_WHITE_LABEL, WHITE_LABELS } from "utils/constants";

import "./FieldListCard.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const getElementOptions = (hasAdvancedEditing, setShowModal) => {
  const rows = [];
  forEach(SinapiFieldsSections, (sectionField, idxSection) => {
    forEach(sectionField.values, (FieldComp, idxField) => {
      rows.push(
        <div key={`${FieldComp.key}_${idxField}_${idxSection}_div`}>
          {idxField === 0 && (
            <>
              <h2 className="mb-0" style={idxSection !== 0 ? { marginTop: "2rem" } : {}}>
                <i className={`${sectionField.icon} text-${sectionField.color}`} style={{ fontSize: "1.5rem" }} />{" "}
                {sectionField.title}
              </h2>
              <hr className="mb-2 mt-1" />
            </>
          )}
          {hasAdvancedEditing || !FieldComp.key.startsWith("date") ? (
            <DraggableField
              key={`${FieldComp.key}_${idxField}_${idxSection}`}
              fieldData={FieldComp}
              className="draggable-field"
            />
          ) : (
            <div
              key={`${FieldComp.key}_${idxField}_${idxSection}`}
              className="draggable-field"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
            >
              <FieldComp.previewComponent />
            </div>
          )}
        </div>
      );
    });
  });
  return rows;
};

/**
 * Simple component displaying a list of all the elements within the SinapiFieldRegistry.
 * @param {object} props
 */
function FieldListCard({ onClose, companySubscription, company }) {
  const [showModal, setShowModal] = useState(false);
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);
  const userRole = currentWorkspace ? currentWorkspace.userRoleId || currentUser.roleId : null;
  const hasWhiteLabel = stringToBoolean(getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_WHITE_LABEL));
  const hasAdvancedEditing =
    getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_ADVANCED_EDITING) === "Advanced";
  return (
    <>
      <div className="vertical-sidenav-full-container">
        <Card className="mb-0">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="12" className="vertical-sidenav-header">
                <Button close onClick={() => onClose()} />
                <h1 className="mb-0">
                  <i className="fas fa-list" /> Elements
                </h1>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>{getElementOptions(hasAdvancedEditing, setShowModal)}</CardBody>
        </Card>
      </div>
      {showModal && (
        <ManagementModal
          onClose={() => setShowModal(null)}
          header={<div>ADVANCED EDITING</div>}
          buttons={[{ label: "Close", onClick: () => setShowModal(false) }]}
        >
          {!["consultant"].includes(userRole) ? (
            <>
              <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
                <h2 className="font-weight-400 text-justify">
                  Please{" "}
                  <Link className="font-weight-bold btn btn-primary" to="/admin/upgrade">
                    Upgrade your account
                  </Link>
                  {` to use this Element. This element are not allowed by your current plan.`}
                </h2>
              </CardHeader>
              <CompanySubscription
                companyData={company}
                plans={companySubscription.plans}
                price={companySubscription.compPrice}
                showDetails={false}
              />
            </>
          ) : (
            <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
              <h2 className="font-weight-400 text-justify">
                {`Your plan does not support this feature. If you would like to upgrade to get the most out of ${
                  WHITE_LABELS(hasWhiteLabel, company).sinapiName
                }, contact your account manager for assistance.`}
              </h2>
            </CardHeader>
          )}
        </ManagementModal>
      )}
    </>
  );
}

export { FieldListCard };
