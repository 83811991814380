import React from "react";

export const optionWithIcon = (icon, text, number) => {
  return (
    <div
      style={{
        border: "1px solid #e2e2e2",
        backgroundColor: "#f9f9f9",
        borderRadius: "5px",
        paddingTop: "2px",
        paddingBottom: "2px"
      }}
    >
      <span className="fa-stack" style={{ borderRight: "1px solid #e2e2e2" }}>
        <span className="far fa-stack-2x"></span>
        <strong className={`fa-stack-1x ${icon}`}>{number}</strong>
      </span>
      <span style={{ marginLeft: "5px" }}>{text}</span>
    </div>
  );
};
