import { useState, useEffect, useRef } from "react";

import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { get } from "lodash";

const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription(
    $customerId: ID
    $paymentMethodId: ID
    $priceId: [ID!]
    $isTrial: Boolean
    $trialPeriodDays: Int
    $userId: ID
    $planId: ID
    $interval: String
    $commission: Float
    $connectStripeId: String
  ) {
    createSubscription(
      customerId: $customerId
      paymentMethodId: $paymentMethodId
      priceId: $priceId
      isTrial: $isTrial
      trialPeriodDays: $trialPeriodDays
      userId: $userId
      planId: $planId
      interval: $interval
      commission: $commission
      connectStripeId: $connectStripeId
    ) {
      id
      collectionMethod
      status
      latestInvoice {
        id
        paymentIntent {
          status
          clientSecret
        }
      }
    }
  }
`;

async function handleCustomerActionRequired(stripe, subscription, paymentMethodId, isRetry) {
  if (subscription && (subscription.status === "active" || subscription.status === "trialing")) {
    // Subscription is active, no customer actions required.
    return { subscription };
  }

  // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
  // If it's a retry, the payment intent will be on the invoice itself.
  let invoice = get(subscription, "latest_invoice");
  let paymentIntent = get(subscription, ["latest_invoice", "payment_intent"]);

  if (
    stripe &&
    paymentIntent &&
    (paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method"))
  ) {
    return stripe
      .confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentMethodId
      })
      .then((result) => {
        if (result.error) {
          // Start code flow to handle updating the payment details.
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc).
          throw result;
        } else {
          if (result.paymentIntent.status === "succeeded") {
            // Show a success message to your customer.
            // There's a risk of the customer closing the window before the callback.
            // We recommend setting up webhook endpoints later in this guide.
            return {
              subscription: subscription,
              invoice: invoice
            };
          }
        }
      });
  } else {
    // No customer action needed.
    return { subscription };
  }
}
export function useCreateSubscription() {
  const [createSubscriptionMut, { data: newSub, error: subError, loading }] = useMutation(CREATE_SUBSCRIPTION);
  const [subData, setSubData] = useState({ loading: false });
  const paymentMethodRef = useRef();
  const stripeRef = useRef();

  useEffect(() => {
    if (newSub && !loading) {
      try {
        handleCustomerActionRequired(
          stripeRef.current ? stripeRef.current : null,
          newSub.createSubscription,
          paymentMethodRef.current ? paymentMethodRef.current.id : null
        ).then((data) => {
          setSubData({ loading: false, data: data });
        });
      } catch (error) {
        setSubData({ loading: false, error: error });
      }
    } else if (!loading && subError && subError.graphQLErrors.length > 0) {
      setSubData({ loading: false, processingError: subError.graphQLErrors[0] });
    }
  }, [newSub, subError]);

  const createSubscription = (paymentMethod, variables, stripe) => {
    paymentMethodRef.current = paymentMethod;
    stripeRef.current = stripe;
    setSubData({ loading: true });
    createSubscriptionMut({ variables: variables });
  };
  return [createSubscription, subData];
}
