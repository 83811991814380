import Alternative from "views/pages/stock/dashboards/Alternative.jsx";
import Buttons from "views/pages/stock/components/Buttons.jsx";
import Calendar from "views/pages/stock/Calendar.jsx";
import Cards from "views/pages/stock/components/Cards.jsx";
import Charts from "views/pages/stock/Charts.jsx";
import Components from "views/pages/stock/forms/Components.jsx";
import Dashboard from "views/pages/dashboard/Dashboard.jsx";
import Elements from "views/pages/stock/forms/Elements.jsx";
import Google from "views/pages/stock/maps/Google.jsx";
import Grid from "views/pages/stock/components/Grid.jsx";
import Icons from "views/pages/stock/components/Icons.jsx";
import Lock from "views/pages/stock/examples/Lock.jsx";
import Login from "views/pages/Login.jsx";
import Notifications from "views/pages/stock/components/Notifications.jsx";
import Pricing from "views/pages/stock/examples/Pricing.jsx";
import Profile from "views/pages/Profile.jsx";
import Company from "views/pages/Company.jsx";
import SubscribeToPlan from "views/pages/SubscribeToPlan.jsx";
import Billing from "views/pages/billing/Billing.jsx";
import ReactBSTables from "views/pages/stock/tables/ReactBSTables.jsx";
import Register from "views/pages/Register.jsx";
import RegisterForInvite from "views/pages/RegisterForInvite.jsx";
import Sortable from "views/pages/stock/tables/Sortable.jsx";
import Tables from "views/pages/stock/tables/Tables.jsx";
import Timeline from "views/pages/stock/examples/Timeline.jsx";
import Typography from "views/pages/stock/components/Typography.jsx";
import Validation from "views/pages/stock/forms/Validation.jsx";
import Vector from "views/pages/stock/maps/Vector.jsx";
import Widgets from "views/pages/stock/Widgets.jsx";
import accountRoutes from "views/pages/account/accountRoutes";
import manageRoutes from "views/pages/account/manageRoutes";

/* QuoteBuilder Custom Pages */
import LeadsList from "views/pages/leads/display.jsx";
import FormEdit from "views/pages/forms/edit.jsx";
import FormRender from "views/pages/forms/render.jsx";
import FormList from "views/pages/forms/list.jsx";
import FormAnalytic from "views/pages/forms/analytic.jsx";
import NewFormModal from "components/UserManagement/NewFormModal";

import { FORM_NAME, FORM_NAME_PLURAL } from "utils/constants";
import SupportPage from "views/pages/forms/support";
import Reset from "views/pages/Reset";
import ManageConnectors from "views/pages/manage/ManageConnectors";
import ManageCommissions from "views/pages/commission/ManageCommissions";
import SalesRepForm from "components/Forms/SalesRepForm";

const routes = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: "ni ni-shop text-white",
    component: Dashboard,
    layout: "/admin"
  },
  {
    collapse: true,
    strict: true,
    path: "/forms",
    component: FormList,
    layout: "/admin",
    name: FORM_NAME_PLURAL,
    icon: "ni ni-align-left-2 text-white",
    state: "formsCollapse",
    views: [
      {
        path: "/forms",
        exact: true,
        name: `Your ${FORM_NAME_PLURAL}`,
        component: FormList,
        layout: "/admin"
      },
      {
        path: "/form-edit/:formId",
        name: `Edit ${FORM_NAME}`,
        hidden: true,
        component: FormEdit,
        layout: "/admin"
      },
      {
        path: "/form-analytics/:formId",
        name: `${FORM_NAME} Analytics`,
        hidden: true,
        component: FormAnalytic,
        layout: "/admin"
      },
      {
        path: "/form-new",
        name: `New ${FORM_NAME}`,
        exact: true,
        component: NewFormModal,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/render",
    name: "Render Form",
    exact: true,
    hidden: true,
    component: FormRender,
    layout: "/public"
  },
  {
    path: "/leads",
    name: "Leads",
    icon: "ni ni-archive-2 text-white",
    component: LeadsList,
    layout: "/admin"
  },
  {
    path: "/connectors",
    name: "Connectors",
    icon: "fas fa-plug text-white",
    component: ManageConnectors,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    hidden: true,
    component: Login,
    layout: "/auth"
  },
  {
    path: "/reset/:token",
    name: "Reset",
    hidden: true,
    component: Reset,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    hidden: true,
    component: Register,
    layout: "/auth"
  },
  {
    path: "/registration/:inviteCode",
    exact: true,
    name: "Registration",
    hidden: true,
    component: RegisterForInvite,
    layout: "/auth"
  },
  {
    path: "/pricing",
    name: "Pricing",
    hidden: true,
    component: Pricing,
    layout: "/auth"
  },
  {
    path: "/support",
    name: "Support",
    hidden: true,
    component: SupportPage,
    layout: "/admin"
  }
];

const adminRoutes = [
  {
    path: "/profile",
    name: "Profile",
    hidden: true,
    icon: "ni ni-single-02 text-white",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/",
    strict: true,
    icon: "ni ni-single-02 text-white",
    name: "Account",
    layout: "/account",
    views: accountRoutes
  },
  {
    path: "/company-profile",
    name: "Company",
    icon: "ni ni-shop text-white",
    component: Company,
    layout: "/admin"
  },
  {
    path: "/registration",
    name: "Registration",
    hidden: true,
    component: SubscribeToPlan,
    layout: "/admin"
  },
  {
    path: "/upgrade",
    name: "Upgrade",
    hidden: true,
    options: { isUpgrade: true },
    component: SubscribeToPlan,
    layout: "/admin"
  },
  {
    path: "/billing",
    name: "Billing",
    hidden: true,
    component: Billing,
    layout: "/admin"
  }
];

const superAdminRoutes = [
  {
    path: "/",
    strict: true,
    icon: "fas fa-users-cog text-white",
    name: "Manage",
    layout: "/manage",
    collapse: true,
    state: "manageCollapse",
    views: manageRoutes
  },
  {
    name: "Sales Reps",
    exact: true,
    path: "/commissions",
    layout: "/admin",
    icon: "fas fa-user-tie text-white",
    component: ManageCommissions,
    views: [
      {
        path: "/commissions/:salesRepId",
        exact: true,
        icon: "ni ni-shop text-white",
        layout: "/admin",
        component: SalesRepForm
      }
    ]
  }
];

const documentationRoutes = [
  {
    collapse: true,
    name: "Examples",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/lock",
        name: "Lock",
        component: Lock,
        layout: "/auth"
      },
      {
        path: "/timeline",
        name: "Timeline",
        component: Timeline,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Alternate Dashboards",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/alternative-dashboard",
        name: "Alternative",
        component: Alternative,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Components",
    icon: "ni ni-ui-04 text-info",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        component: Buttons,
        layout: "/admin"
      },
      {
        path: "/cards",
        name: "Cards",
        component: Cards,
        layout: "/admin"
      },
      {
        path: "/grid",
        name: "Grid",
        component: Grid,
        layout: "/admin"
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: Notifications,
        layout: "/admin"
      },
      {
        path: "/icons",
        name: "Icons",
        component: Icons,
        layout: "/admin"
      },
      {
        path: "/typography",
        name: "Typography",
        component: Typography,
        layout: "/admin"
      },
      {
        collapse: true,
        name: "Multi Level",
        state: "multiCollapse",
        views: [
          {
            path: "#pablo",
            name: "Third level menu",
            component: () => {},
            layout: "/"
          },
          {
            path: "#pablo",
            name: "Just another link",
            component: () => {},
            layout: "/"
          },
          {
            path: "#pablo",
            name: "One last link",
            component: () => {},
            layout: "/"
          }
        ]
      }
    ]
  },
  {
    collapse: true,
    name: FORM_NAME_PLURAL,
    icon: "ni ni-single-copy-04 text-pink",
    state: "formsCollapse",
    views: [
      {
        path: "/elements",
        name: "Elements",
        component: Elements,
        layout: "/admin"
      },
      {
        path: "/components",
        name: "Components",
        component: Components,
        layout: "/admin"
      },
      {
        path: "/validation",
        name: "Validation",
        component: Validation,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Tables",
    icon: "ni ni-align-left-2 text-default",
    state: "tablesCollapse",
    views: [
      {
        path: "/tables",
        name: "Tables",
        component: Tables,
        layout: "/admin"
      },
      {
        path: "/sortable",
        name: "Sortable",
        component: Sortable,
        layout: "/admin"
      },
      {
        path: "/react-bs-table",
        name: "React BS Tables",
        component: ReactBSTables,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Maps",
    icon: "ni ni-map-big text-primary",
    state: "mapsCollapse",
    views: [
      {
        path: "/google",
        name: "Google",
        component: Google,
        layout: "/admin"
      },
      {
        path: "/vector",
        name: "Vector",
        component: Vector,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/widgets",
    name: "Widgets",
    icon: "ni ni-archive-2 text-green",
    component: Widgets,
    layout: "/admin"
  },
  {
    path: "/charts",
    name: "Charts",
    icon: "ni ni-chart-pie-35 text-info",
    component: Charts,
    layout: "/admin"
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "ni ni-calendar-grid-58 text-red",
    component: Calendar,
    layout: "/admin"
  }
];
export { routes, adminRoutes, superAdminRoutes, documentationRoutes };
export default routes;
