import React, { useState } from "react";
import "./CheckoutForm.css";

// reactstrap components
import { Button, FormGroup } from "reactstrap";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { Formik, Field } from "formik";
import * as yup from "yup";

const ELEMENT_OPTIONS = {
  showIcon: true,
  iconStyle: "solid",
  style: {
    base: {
      fontSize: "16px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#9e2146"
    }
  }
};

const formValidation = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required()
});

const DetailedCheckoutForm = ({
  onSuccess,
  onCancel,
  user,
  btnName,
  processing,
  setProcessing,
  error,
  totalCharge,
  isAnUpgrade
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethodError, setPaymentMethodError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  btnName = btnName || "Pay";

  const handleSubmit = async (billingDetails) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (cardComplete && setProcessing) {
      setProcessing(true);
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    // Use your card Element with other Stripe.js APIs
    const { error: pError, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: billingDetails
    });

    if (pError) {
      setPaymentMethodError(pError);
      setProcessing(false);
    } else {
      onSuccess(paymentMethod, stripe);
    }
  };

  const dispError = error || paymentMethodError;

  return (
    <Formik
      initialValues={{
        name: user && user.firstName ? user.firstName + " " + (user.lastName || "") : "",
        email: user ? user.email : ""
      }}
      validationSchema={formValidation}
      validateOnMount={true}
      onSubmit={({ ...values }, actions) => {
        handleSubmit({
          ...values
        });
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <FormGroup>
            {totalCharge && (
              <div className="mb-3">
                <h4 className="mb-0">Today's charge:</h4>
                <span className="font-weight-600 text-info" style={{ fontSize: "1.8rem" }}>{`${totalCharge}.00`}</span>
                {isAnUpgrade && (
                  <h5 className="text-muted">
                    <small>
                      You will be charged simply for the difference in price from you active subscription to the new one
                      you've selected. The amount will be pro-rated based on the time left in the billing cycle.
                    </small>
                  </h5>
                )}
              </div>
            )}
            <div>
              <label className="h4" htmlFor="email">
                Email
              </label>
              <Field name="email" id="email" className="form-control" />
            </div>
          </FormGroup>
          <label className="h4" htmlFor="cardNumber">
            Card Information
          </label>
          <FormGroup className="card-fields">
            <CardNumberElement
              id="cardNumber"
              options={ELEMENT_OPTIONS}
              onChange={(e) => {
                setPaymentMethodError(e.error);
                setCardComplete(e.complete);
              }}
            />
            <CardExpiryElement id="expiry" options={ELEMENT_OPTIONS} />
            <CardCvcElement id="cvc" options={ELEMENT_OPTIONS} />
            {dispError && <div className="processing-error-msg">{dispError.message}</div>}
          </FormGroup>
          <FormGroup>
            <div>
              <label className="h4" htmlFor="name">
                Name on card
              </label>
              <Field name="name" id="name" className="form-control" />
            </div>
          </FormGroup>
          {onCancel ? (
            <Button type="secondary" disabled={!stripe || processing} className="mt-3" onClick={() => onCancel()}>
              Cancel
            </Button>
          ) : null}
          <Button type="submit" disabled={!stripe || processing} className="mt-3 btn-default">
            {processing ? "Processing..." : btnName}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export { DetailedCheckoutForm };
