const initialState = { alerts: [] };

export default function alertReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "ADD_ALERT":
      return {
        alerts: [...state.alerts, payload]
      };
    case "REMOVE_ALERT":
      return { alerts: state.alerts.filter((item) => item !== payload) };
    default:
      return state;
  }
}
