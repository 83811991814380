import React, { useCallback, useEffect, useState } from "react";

import classnames from "classnames";
import _ from "lodash";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

// reactstrap components
import {
  Button,
  ButtonGroup,
  ButtonDropdown,
  Card,
  Col,
  Collapse,
  CustomInput,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  FormText,
  UncontrolledTooltip,
} from "reactstrap";
import { RichTextEditorSummer } from "components/Shared/RichTextEditor/RichTextEditorSummer";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { SinapiFieldRegistry } from "./fields";
import dragImage from "../../assets/img/drag-image.png";

import * as actions from "reducers/form-actions";
import { AWS_S3_ASSETS_BUCKET, IS_EMPTY, LOGICAL_OPERATORS, NOT_EMPTY, OPERATORS } from "utils/constants";
import { SinapiDragDropList, SinapiDraggableItem } from "components/DragAndDrop";
import ManagementModal from "components/UserManagement/ManagementModal";
import { preventPasteNegative, showOnlyNumbers } from "utils/helperFunctions";
import classNames from "classnames";
import { useRef } from "react";
import { all } from "fontawesome-5-icons-list";
import Dropzone from "react-dropzone";

const UPLOAD_IMAGE = gql`
  mutation UploadFileImage($file: Upload!) {
    uploadFileImage(file: $file)
  }
`;

const changeOptionInput = ({ e, dispatch, path = [] }, sectionRules = false) => {
  return sectionRules
    ? dispatch({
        type: actions.UPDATE_SECTION_BEING_EDITED,
        payload: {
          key: e.target.name,
          value: e.target.value,
          path: path
        }
      })
    : dispatch({
        type: actions.UPDATE_QUESTION_BEING_EDITED,
        payload: {
          key: e.target.name,
          value: e.target.value,
          path: path
        }
      });
};

const toggleOptionIsRequired = (e, dispatch) => {
  return dispatch({
    type: actions.TOGGLE_QUESTION_IS_REQUIRED,
    payload: {
      required: e.target.checked ? true : false
    }
  });
};

const toggleOptionToContactRecord = (e, dispatch) => {
  return dispatch({
    type: actions.TOGGLE_QUESTION_TO_CONTACT_RECORD,
    payload: {
      toContactRecord: e.target.checked ? true : false
    }
  });
};

const toggleCostStructure = (e, option, dispatch, isNumber, sizeOptions) => {
  return dispatch({
    type: actions.TOGGLE_QUESTION_COST_STRUCTURE,
    payload: {
      option,
      add: e.target.checked ? true : false,
      isNumber,
      size: sizeOptions,
      cost: 0
    }
  });
};

const toggleOptionImage = (e, dispatch, path = []) => {
  dispatch({
    type: actions.UPDATE_QUESTION_BEING_EDITED,
    payload: {
      key: e.target.name,
      value: e.target.checked,
      path: path
    }
  });
};

const toggleHasCostRange = (e, sizeOptions, json, dispatch, setShowAlertModal) => {
  if (e.target.checked) {
    return dispatch({
      type: actions.TOGGLE_QUESTION_COST_RANGE,
      payload: {
        size: sizeOptions,
        json:
          sizeOptions > 0
            ? _.map(_.range(sizeOptions), (option) =>
                _.map(_.get(json, ["costStructure"], []), (structure) => ({
                  structure,
                  cost: {
                    low: _.get(json, ["options", option, structure], 0),
                    expected: _.get(json, ["options", option, structure], 0),
                    high: _.get(json, ["options", option, structure], 0),
                    low_type: "+",
                    expected_type: "+",
                    high_type: "+"
                  }
                }))
              )
            : _.map(_.get(json, ["costStructure"], []), (structure) => ({
                structure,
                cost: {
                  low: _.get(json, structure, 0),
                  expected: _.get(json, structure, 0),
                  high: _.get(json, structure, 0),
                  low_type: "+",
                  expected_type: "+",
                  high_type: "+"
                }
              })),
        costRange: true
      }
    });
  } else {
    setShowAlertModal(true);
  }
};

const toggleHasCostAffect = ({ e, dispatch, groupedQuestions }) => {
  if (e.target.id === "multiplier") {
    const firstID = _.head(
      _.compact(
        _.flatten(
          Object.keys(groupedQuestions).map((key) =>
            groupedQuestions[key].map((question) => (question.type === "number" ? question.id : null))
          )
        )
      )
    );
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: "questionAdjust",
        value: firstID ?? "",
        path: []
      }
    });
  }
  return dispatch({
    type: actions.TOGGLE_QUESTION_COST_AFFECT,
    payload: {
      key: e.target.name,
      value: e.target.id,
      field: "priceAdjust",
      set: e.target.id === "adjustment" || e.target.id === "multiplier" ? true : false,
      setValue: e.target.id === "adjustment" ? "all" : "question"
    }
  });
};

const togglePriceAdjusted = ({ e, dispatch }) => {
  return dispatch({
    type: actions.UPDATE_QUESTION_PRICE_ADJUSTED,
    payload: {
      key: e.target.name,
      value: e.target.id,
      unset: e.target.id === "all" ? "questionAdjust" : undefined
    }
  });
};

const toggleHasDynamicRules = (e, dispatch, sectionRules = false) => {
  return sectionRules
    ? dispatch({
        type: actions.TOGGLE_SECTION_DYNAMIC_RULES,
        payload: {
          dynamicRules: e.target.checked ? true : false
        }
      })
    : dispatch({
        type: actions.TOGGLE_QUESTION_DYNAMIC_RULES,
        payload: {
          dynamicRules: e.target.checked ? true : false
        }
      });
};

const addDynamicGroupRule = (dispatch, sectionRules) => {
  return sectionRules
    ? dispatch({
        type: actions.TOGGLE_SECTION_ADD_DYNAMIC_GROUP_RULE,
        payload: {
          ruleGroup: [{ questionId: "", operator: "", answer: "" }]
        }
      })
    : dispatch({
        type: actions.TOGGLE_QUESTION_ADD_DYNAMIC_GROUP_RULE,
        payload: {
          ruleGroup: [{ questionId: "", operator: "", answer: "" }]
        }
      });
};

const deleteDynamicGroupRule = (index, ruleGroup, dispatch, sectionRules) => {
  return sectionRules
    ? dispatch({
        type: actions.DELETE_DYNAMIC_GROUP_RULE_FROM_SECTION,
        payload: { index, ruleGroup }
      })
    : dispatch({
        type: actions.DELETE_DYNAMIC_GROUP_RULE_FROM_QUESTION,
        payload: { index, ruleGroup }
      });
};

const addDynamicRule = (index, dispatch, sectionRules) => {
  return sectionRules
    ? dispatch({
        type: actions.TOGGLE_SECTION_ADD_DYNAMIC_RULE,
        payload: {
          index,
          rule: { questionId: "", operator: "", answer: "" }
        }
      })
    : dispatch({
        type: actions.TOGGLE_QUESTION_ADD_DYNAMIC_RULE,
        payload: {
          index,
          rule: { questionId: "", operator: "", answer: "" }
        }
      });
};

const deleteDynamicRule = (idxGroup, idxRule, dispatch, sectionRules) => {
  return sectionRules
    ? dispatch({
        type: actions.DELETE_DYNAMIC_RULE_FROM_SECTION,
        payload: { idxGroup, idxRule }
      })
    : dispatch({
        type: actions.DELETE_DYNAMIC_RULE_FROM_QUESTION,
        payload: { idxGroup, idxRule }
      });
};

const deleteOption = (index, dispatch) => {
  return dispatch({
    type: actions.DELETE_OPTION_FROM_QUESTION,
    payload: index
  });
};

const addOption = (dispatch) => {
  return dispatch({
    type: actions.ADD_OPTION_FROM_QUESTION
  });
};

const moveOption = (item, index, dispatch) => {
  return dispatch({
    type: actions.MOVE_OPTION_FROM_QUESTION,
    payload: { item, index }
  });
};

const handleJustify = ({ direction, dispatch, path = [] }) => {
  return dispatch({
    type: actions.UPDATE_QUESTION_BEING_EDITED,
    payload: {
      key: "floatClass",
      value: direction,
      path: path
    }
  });
};

// ************************************ //
// *** START: FORM QUESTION DETAILS *** //
// ************************************ //

export const GlobalEditOptions = (json, dispatch, formQuestionsWithCost, hasAdvancedEditing, setShowModal) => {
  const isHubSpotEnabled = localStorage.getItem("hubspotEnabled");
  return (
    <>
      <input
        readOnly
        type="hidden"
        className="formAttribute"
        name="id"
        value={json.hasOwnProperty("id") ? json.id : ""}
      />
      <input
        readOnly
        type="hidden"
        className="formAttribute"
        name="type"
        value={json.hasOwnProperty("type") ? json.type : ""}
      />
      {json.type !== "headline" && json.type !== "divider" && json.type !== "image-field" && (
        <>
          {json.type !== "textblock" && (
            <>
              {json.type !== "email" && (
                <FormGroup>
                  <div className="custom-control custom-checkbox mb-3">
                    <Input
                      className="custom-control-input"
                      id="isRequired"
                      name="isRequired"
                      type="checkbox"
                      onChange={(e) => toggleOptionIsRequired(e, dispatch)}
                      checked={json.hasOwnProperty("required") ? json.required : false}
                    />
                    <Label className="custom-control-label font-weight-bold" htmlFor={`isRequired`}>
                      Question is required
                    </Label>
                  </div>
                </FormGroup>
              )}
              <FormGroup>
                <label className="form-control-label">
                  <div>Title</div>
                </label>
                <Input
                  placeholder="Default input"
                  className="form-control-sm"
                  type="text"
                  value={json.hasOwnProperty("title") ? json.title : ""}
                  name="title"
                  onChange={(e) => changeOptionInput({ e, dispatch })}
                />
              </FormGroup>
            </>
          )}
          <FormGroup>
            <label className="form-control-label">
              <div>Description</div>
            </label>
            <RichTextEditorSummer
              height="350px"
              defaultValue={json.hasOwnProperty("description") ? json.description : ""}
              onChange={(value) =>
                changeOptionInput({ e: { target: { name: "description", value: value } }, dispatch })
              }
              id='summer-note'
            />
          </FormGroup>
        </>
      )}
      {json.type === "headline" && (
        <>
          <FormGroup>
            <label className="form-control-label">
              <div>Headline</div>
            </label>
            <Input
              placeholder="Default input"
              className="form-control-sm"
              type="text"
              value={json.hasOwnProperty("title") ? json.title : ""}
              name="title"
              onChange={(e) => changeOptionInput({ e, dispatch })}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label">
              <div>Sub-Headline</div>
            </label>
            <Input
              placeholder="Default input"
              className="form-control-sm"
              type="text"
              value={json.hasOwnProperty("subTitle") ? json.subTitle : ""}
              name="subTitle"
              onChange={(e) => changeOptionInput({ e, dispatch })}
            />
          </FormGroup>
        </>
      )}
      {json.type === "text" && TextEditOptions(json, dispatch)}
      {json.type === "number" &&
        NumberEditOptions(json, dispatch, formQuestionsWithCost, hasAdvancedEditing, setShowModal)}
      {json.type === "textarea" && TextareaEditOptions(json, dispatch)}
      {json.type === "email" && EmailEditOptions(json, dispatch)}
      {json.type === "phone" && PhoneEditOptions(json, dispatch)}
      {json.type === "address" && AddressEditOptions(json, dispatch)}
      {json.type === "daterange" && DateRangeEditOptions(json, dispatch)}
      {json.type === "divider" && <DividerEditOptions json={json} dispatch={dispatch} />}
      {json.type === "image-field" && <ImageEditOptions json={json} dispatch={dispatch} />}
      {['checkbox', 'radio', 'select', 'number'].includes(json.type) && isHubSpotEnabled === "true" && (
        <>
        <FormGroup>
            <div className="custom-control custom-checkbox mb-3">
              <Input
                className="custom-control-input"
                id="toContactRecord"
                name="toContactRecord"
                type="checkbox"
                onChange={(e) => toggleOptionToContactRecord(e, dispatch)}
                checked={json.hasOwnProperty("toContactRecord") ? json.toContactRecord : false}
              />
              <Label className="custom-control-label font-weight-bold" htmlFor={`toContactRecord`}>
                Add this question to the contact record in your CRM integration.
              </Label>
            </div>
          </FormGroup>
        </>
      )}
    </>
  );
};

export const TextEditOptions = (json, dispatch) => {
  return (
    <FormGroup>
      <label className="form-control-label">
        <div>Placeholder</div>
      </label>
      <Input
        placeholder=""
        className="form-control-sm"
        type="text"
        name="placeholder"
        value={json.hasOwnProperty("placeholder") ? json.placeholder : ""}
        onChange={(e) => changeOptionInput({ e, dispatch })}
      />
    </FormGroup>
  );
};

export const TextareaEditOptions = (json, dispatch) => {
  return (
    <>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder</div>
        </label>
        <Input
          placeholder=""
          className="form-control-sm"
          type="text"
          name="placeholder"
          value={json.hasOwnProperty("placeholder") ? json.placeholder : ""}
          onChange={(e) => changeOptionInput({ e, dispatch })}
        />
      </FormGroup>
    </>
  );
};

export const NumberEditOptions = (json, dispatch, formQuestionsWithCost, hasAdvancedEditing, setShowModal) => {
  const hasCostRange = _.get(json, "costRange");
  const fixedCost = _.get(json, "affectCost", "fixed-cost") === "fixed-cost" ? true : false;
  const adjCost = _.get(json, "affectCost") === "adjustment" ? true : false;
  const costStructure = _.get(json, "costStructure", ["cost"]);
  const hasCost = costStructure.length > 0;
  return (
    <>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <label className="form-control-label">
              <div>Placeholder</div>
            </label>
            <Input
              placeholder=""
              className="form-control-sm"
              type="text"
              name="placeholder"
              value={json.hasOwnProperty("placeholder") ? json.placeholder : ""}
              onChange={(e) => changeOptionInput({ e, dispatch })}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <label className="form-control-label">
              <div>Default Value</div>
            </label>
            <Input
              placeholder=""
              className="form-control-sm"
              type="number"
              name="defaultValue"
              min="0"
              value={json.hasOwnProperty("defaultValue") ? json.defaultValue : ""}
              onChange={(e) => changeOptionInput({ e, dispatch })}
              onPaste={preventPasteNegative}
              onKeyPress={showOnlyNumbers}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        {hasCost && (
          <ShowCostRanges
            json={json}
            sizeOptions={0}
            dispatch={dispatch}
            formQuestionsWithCost={formQuestionsWithCost}
            hasAdvancedEditing={hasAdvancedEditing}
            setShowModal={setShowModal}
            questionType="number"
          />
        )}
      </FormGroup>
      {!adjCost && (
        <>
          <Label className="form-control-label">Cost Structure</Label>
          <FormGroup className="form-inline mt--3" style={{ marginBottom: "0rem" }}>
            <div className="custom-control custom-checkbox mb-3">
              <Input
                className="custom-control-input"
                id={`hasOneTime`}
                name="hasOneTime"
                type="checkbox"
                onChange={(e) => toggleCostStructure(e, "cost", dispatch, true, 0)}
                checked={costStructure.includes("cost")}
              />
              <Label className="custom-control-label pt-1" htmlFor={`hasOneTime`}>
                One Time
              </Label>
            </div>
            <div className="custom-control custom-checkbox mb-3 ml-4">
              <Input
                className="custom-control-input"
                id={`hasDaily`}
                name="hasDaily"
                type="checkbox"
                onChange={(e) => toggleCostStructure(e, "daily", dispatch, true, 0)}
                checked={costStructure.includes("daily")}
              />
              <Label className="custom-control-label pt-1" htmlFor={`hasDaily`}>
                Daily
              </Label>
            </div>
            <div className="custom-control custom-checkbox mb-3 ml-4">
              <Input
                className="custom-control-input"
                id={`hasMonthly`}
                name="hasMonthly"
                type="checkbox"
                onChange={(e) => toggleCostStructure(e, "monthly", dispatch, true, 0)}
                checked={costStructure.includes("monthly")}
              />
              <Label className="custom-control-label pt-1" htmlFor={`hasMonthly`}>
                Monthly
              </Label>
            </div>
            <div className="custom-control custom-checkbox mb-3 ml-4">
              <Input
                className="custom-control-input"
                id={`hasYearly`}
                name="hasYearly"
                type="checkbox"
                onChange={(e) => toggleCostStructure(e, "yearly", dispatch, true, 0)}
                checked={costStructure.includes("yearly")}
              />
              <Label className="custom-control-label pt-1" htmlFor={`hasYearly`}>
                Yearly
              </Label>
            </div>
          </FormGroup>
        </>
      )}
      <RenderCostHTML
        json={json}
        dispatch={dispatch}
        fixedCost={fixedCost}
        adjCost={adjCost}
        hasCostRange={hasCostRange}
        costStructure={costStructure}
        questionType="number"
      />
    </>
  );
};

export const EmailEditOptions = (json, dispatch) => {
  return (
    <>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder</div>
        </label>
        <Input
          placeholder=""
          className="form-control-sm"
          type="email"
          name="placeholder"
          value={json.hasOwnProperty("placeholder") ? json.placeholder : ""}
          onChange={(e) => changeOptionInput({ e, dispatch })}
        />
      </FormGroup>
    </>
  );
};

export const PhoneEditOptions = (json, dispatch) => {
  return (
    <>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder</div>
        </label>
        <Input
          placeholder=""
          className="form-control-sm"
          type="text"
          name="placeholder"
          value={json.hasOwnProperty("placeholder") ? json.placeholder : ""}
          onChange={(e) => changeOptionInput({ e, dispatch })}
        />
      </FormGroup>
    </>
  );
};

export const AddressEditOptions = (json, dispatch) => {
  return (
    <>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder Address</div>
        </label>
        <Input
          className="form-control-sm"
          type="text"
          name="addressPlaceholder"
          value={json.hasOwnProperty("addressPlaceholder") ? json.addressPlaceholder : ""}
          onChange={(e) => changeOptionInput({ e, dispatch })}
        />
      </FormGroup>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder City</div>
        </label>
        <Input
          className="form-control-sm"
          type="text"
          name="cityPlaceholder"
          value={json.hasOwnProperty("cityPlaceholder") ? json.cityPlaceholder : ""}
          onChange={(e) => changeOptionInput({ e, dispatch })}
        />
      </FormGroup>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder State/Province</div>
        </label>
        <Input
          className="form-control-sm"
          type="text"
          name="statePlaceholder"
          value={json.hasOwnProperty("statePlaceholder") ? json.statePlaceholder : ""}
          onChange={(e) => changeOptionInput({ e, dispatch })}
        />
      </FormGroup>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder Zip/Postal Code</div>
        </label>
        <Input
          className="form-control-sm"
          type="text"
          name="zipPlaceholder"
          value={json.hasOwnProperty("zipPlaceholder") ? json.zipPlaceholder : ""}
          onChange={(e) => changeOptionInput({ e, dispatch })}
        />
      </FormGroup>
    </>
  );
};

export const DateRangeEditOptions = (json, dispatch) => {
  return (
    <>
      <FormGroup>
        <label className="form-control-label">
          <div>Separator</div>
        </label>
        <Input
          placeholder=""
          className="form-control-sm"
          type="text"
          name="separator"
          value={json.hasOwnProperty("separator") ? json.separator : ""}
          onChange={(e) => changeOptionInput({ e, dispatch })}
        />
      </FormGroup>
    </>
  );
};

const DividerEditOptions = ({ json, dispatch }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [widthUnit, setWidthUnit] = useState(_.get(json, "widthUnit", "px"));
  const [alignment, setAlignment] = useState(_.get(json, "alignment", "center"));

  const FieldComponent = SinapiFieldRegistry.getFieldComponent("color-picker");

  const handleDividerOption = (key, value) => {
    if (key === "widthUnit") {
      setWidthUnit(value);
    }
    if (key === "alignment") {
      setAlignment(value);
    }

    return dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key,
        value,
        path: []
      }
    });
  };

  const toggleWidthDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleChangeColor = (e) => {
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: "dividerColor",
        value: e.target.value,
        path: []
      }
    });
  };

  return (
    <>
      <Label className="form-control-label mb-0">Alignment</Label>
      <FormGroup className="form-inline mt-2">
        <ButtonGroup>
          <Button
            id="align-left-btn"
            className={classnames("btn-group-button", {
              "btn-group-button-active": alignment === "start"
            })}
            onClick={() => handleDividerOption("alignment", "start")}
          >
            <i className="fas fa-align-left"></i>
          </Button>
          <UncontrolledTooltip placement="bottom" target="align-left-btn" delay={{ hide: 0 }}>
            Left
          </UncontrolledTooltip>
          <Button
            id="align-center-btn"
            className={classnames("btn-group-button", {
              "btn-group-button-active": alignment === "center"
            })}
            onClick={() => handleDividerOption("alignment", "center")}
          >
            <i className="fas fa-align-center"></i>
          </Button>
          <UncontrolledTooltip placement="bottom" target="align-center-btn" delay={{ hide: 0 }}>
            Center
          </UncontrolledTooltip>
          <Button
            id="align-right-btn"
            className={classnames("btn-group-button", {
              "btn-group-button-active": alignment === "end"
            })}
            onClick={() => handleDividerOption("alignment", "end")}
          >
            <i className="fas fa-align-right"></i>
          </Button>
          <UncontrolledTooltip placement="bottom" target="align-right-btn" delay={{ hide: 0 }}>
            Right
          </UncontrolledTooltip>
        </ButtonGroup>
      </FormGroup>
      <Row className="divider-options mb-4">
        <Col>
          <Label className="form-control-label mb-0">Divider Width</Label>
          <InputGroup className="mt-2 custom-divider-width" size="sm">
            <Input
              type="number"
              min="0"
              name="widthValue"
              max={widthUnit === "%" ? "100" : ""}
              value={json.widthValue || "100"}
              onChange={(e) => changeOptionInput({ e, dispatch })}
            />
            <ButtonDropdown toggle={toggleWidthDropdown} size="sm" isOpen={openDropdown}>
              <DropdownToggle caret className="btn-group-background">
                {_.get(json, "widthUnit", "px")}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleDividerOption("widthUnit", "px")}>px</DropdownItem>
                <DropdownItem onClick={() => handleDividerOption("widthUnit", "%")}>%</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </InputGroup>
        </Col>
        <Col>
          <Label className="form-control-label mb-0">Divider Thickness</Label>
          <InputGroup className="mt-2 custom-divider-width" size="sm">
            <Input
              type="number"
              min="0"
              name="widthThickness"
              value={json.widthThickness || "1"}
              onChange={(e) => changeOptionInput({ e, dispatch })}
            />
            <div className="input-group-append divider-thickness">
              <span className="input-group-text">px</span>
            </div>
          </InputGroup>
        </Col>
      </Row>
      <Label className="form-control-label mb-0">Divider Color</Label>
      <InputGroup className="mt-2 mb-4" size="sm">
        <InputGroupAddon addonType="prepend">
          <InputGroupText className="px-1 py-0 custom-input-group-text">
            <span>Color</span>
          </InputGroupText>
        </InputGroupAddon>
        <FieldComponent question={{ value: json.dividerColor || "rgba(51,51,51,1)" }} onChange={handleChangeColor} />
      </InputGroup>
      <Row className="divider-options mb-4">
        <Col>
          <Label className="form-control-label mb-0">Spacing Top</Label>
          <InputGroup className="mt-2 custom-divider-width" size="sm">
            <Input
              type="number"
              min="0"
              name="spacingTop"
              value={json.spacingTop || "1"}
              onChange={(e) => changeOptionInput({ e, dispatch })}
            />
            <div className="input-group-append divider-thickness">
              <span className="input-group-text">px</span>
            </div>
          </InputGroup>
        </Col>
        <Col>
          <Label className="form-control-label mb-0">Spacing Bottom</Label>
          <InputGroup className="mt-2 custom-divider-width" size="sm">
            <Input
              type="number"
              min="0"
              name="spacingBottom"
              value={json.spacingBottom || "1"}
              onChange={(e) => changeOptionInput({ e, dispatch })}
            />
            <div className="input-group-append divider-thickness">
              <span className="input-group-text">px</span>
            </div>
          </InputGroup>
        </Col>
      </Row>
    </>
  );
};

const ImageEditOptions= ({json, dispatch}) => {

  const [activeDimension, setActiveDimension] = useState("width");
  const [openDropdown, setOpenDrowndown] = useState(false);
  const [activeSize, setActiveSize] = useState("px");
  const [imgPreview, setImgPreview] = useState(
    json.imageSrc && typeof json.imageSrc === "string"
      ? `${AWS_S3_ASSETS_BUCKET}/${json.imageSrc}`
      : typeof imagePath === "object"
      ? URL.createObjectURL(json.imageSrc.file)
      : "default"
  );
  const [dragActive, setDragActive] = useState(false);
  const [imageSize, setImageSize] = useState("100");
  const [errorMessage, setErrorMessage] = useState(false);

  const [uploadImage, { loading: uploadingImage }] = useMutation(UPLOAD_IMAGE, {
    onCompleted: (response) => {
      dispatch({
        type: actions.UPDATE_QUESTION_BEING_EDITED,
        payload: {
          key: "imageSrc",
          value: response.uploadFileImage,
          path: []
        }
      });
    }
  });

  const inputRef = useRef();

  const toggleDimension = (e) => {
    setActiveDimension(e.target.value);
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: "activeSize",
        value: e.target.value,
        path: []
      }
    });
  };

  const toggleSizeDropdown = () => {
    setOpenDrowndown(!openDropdown);
  };

  const changeImageSize = (e) => {
    setImageSize(e.target.value)
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: "imageSizeValue",
        value: e.target.value,
        path: []
      }
    });
  };

  const changeActiveSize = (size) => {
    setActiveSize(size);
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: "imageSizeUnit",
        value: size,
        path: []
      }
    });
  };

  const onDragFiles = (files) => {
    const file = files[0];
    if (!file) return;

    const fileSize = Math.round(file.size / 1024);

    if (fileSize < 1024) {
      setErrorMessage(false);
      setImgPreview(URL.createObjectURL(file));
      uploadImage({ variables: { file } });
    } else {
      setErrorMessage(true);
    }
  };

  const onSelectImage = (e, dispatch) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileSize = Math.round(file.size / 1024);

    if (fileSize < 1024) {
      setErrorMessage(false);
      setImgPreview(URL.createObjectURL(file));
      uploadImage({ variables: { file } });
    } else {
      setErrorMessage(true);
    }
  };
  
  const handleImageAlignment = ({ direction, dispatch, path = [] }) => {
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: "alignment",
        value: direction,
        path: []
      }
    });
  }

  return (
    <>
      <div>
        <Label className="form-control-label mb-0">Alignment</Label>
        <FormGroup className="form-inline mt-2">
          <ButtonGroup>
            <Button
              id="align-left-btn"
              className={classNames("btn-group-button", {
                "btn-group-button-active": _.get(json, "alignment", "justify-left") === "justify-left"
              })}
              onClick={() => handleImageAlignment({ direction: "justify-left", dispatch, path: [] })}
            >
              <i className="fas fa-align-left"></i>
            </Button>
            <UncontrolledTooltip placement="bottom" target="align-left-btn" delay={{ hide: 0 }}>
              Left
            </UncontrolledTooltip>
            <Button
              id="align-center-btn"
              className={classNames("btn-group-button", {
                "btn-group-button-active": _.get(json, "alignment", "justify-center") === "justify-center"
              })}
              onClick={() => handleImageAlignment({ direction: "justify-center", dispatch, path: [] })}
            >
              <i className="fas fa-align-center"></i>
            </Button>
            <UncontrolledTooltip placement="bottom" target="align-center-btn" delay={{ hide: 0 }}>
              Center
            </UncontrolledTooltip>
            <Button
              id="align-right-btn"
              className={classNames("btn-group-button", {
                "btn-group-button-active": _.get(json, "alignment", "justify-right") === "justify-right"
              })}
              onClick={() => handleImageAlignment({ direction: "justify-right", dispatch, path: [] })}
            >
              <i className="fas fa-align-right"></i>
            </Button>
            <UncontrolledTooltip placement="bottom" target="align-right-btn" delay={{ hide: 0 }}>
              Right
            </UncontrolledTooltip>
            <Button
              id="align-space-btn"
              className={classNames("btn-group-button", {
                "btn-group-button-active": _.get(json, "alignment", "justify-space") === "justify-space"
              })}
              onClick={() => handleImageAlignment({ direction: "justify-space", dispatch, path: [] })}
            >
              <i className="fas fa-grip-lines-vertical"></i>
            </Button>
            <UncontrolledTooltip placement="bottom" target="align-space-btn" delay={{ hide: 0 }}>
              Space Between
            </UncontrolledTooltip>
          </ButtonGroup>
        </FormGroup>
      </div>
      <div className="optionBody p-3 d-flex flex-column">
        {uploadingImage ? <LoaderSpinner /> : null}
        {errorMessage && (
          <small className="image-validation-err">The uploaded image exceeds the maximum file size allowed. Please ensure that your image is less than 1mb.</small>
        )}
        <Dropzone
          maxFiles={1}
          noClick
          noKeyboard
          onDrop={onDragFiles}
          accept={{
            "image/*": [".png", ".gif", ".jpeg", ".jpg"]
          }}
        >
          {({ getRootProps, getInputProps, open }) => (
            <div
              {...getRootProps()}
              className={classNames("bg-white", "mt-3", "p-3", "mb-3", {
                "border-0": !dragActive,
                "border-1": dragActive
              })}
              style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            >
              {imgPreview !== "default" && (
                <Button
                  style={{
                    background: "transparent",
                    borderColor: "transparent",
                    boxShadow: "none",
                    margin: 0,
                    alignSelf: "flex-end"
                  }}
                  onClick={() => {
                    setImgPreview("default");
                  }}
                >
                  <i className="fas fa-times fa-1x"></i>
                </Button>
              )}
              <input
                type="file"
                style={{ display: "none" }}
                ref={inputRef}
                onChange={(e) => onSelectImage(e, dispatch)}
                value={""}
                {...getInputProps()}
              />
              <img
                alt=""
                style={{ maxHeight: "100px", marginTop: "10px", maxWidth: "100px", marginBottom: "10px" }}
                src={imgPreview === "default" ? dragImage : imgPreview}
                accept="image/*"
              />
              <p className="mb-0">Drag & Drop Your Image Here</p>
              <p>- or -</p>
              <Button color="info" onClick={open}>
                Choose file
              </Button>
            </div>
          )}
        </Dropzone>
        <Row style={{ alignItems: "center" }}>
          <Col xs={3}>
            <span style={{ fontSize: "0.8rem" }}>Image Size:</span>
          </Col>
          <Col xs={5}>
            <div className="form-inline">
              <div className="custom-radio custom-control mr-2">
                <input
                  type="radio"
                  id={`image-width`}
                  className="custom-control-input"
                  value="width"
                  onChange={toggleDimension}
                  checked={activeDimension === "width"}
                />
                <label className="custom-control-label" htmlFor={`image-width`}>
                  Width
                </label>
              </div>
              <div className="custom-radio custom-control">
                <input
                  type="radio"
                  id={`image-height`}
                  className="custom-control-input"
                  value="height"
                  onChange={toggleDimension}
                  checked={activeDimension === "height"}
                />
                <label className="custom-control-label" htmlFor={`image-height`}>
                  Height
                </label>
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <InputGroup size="sm">
              <Input
                type="number"
                min="0"
                max={activeSize === "%" ? "100" : ""}
                value={imageSize || "100"}
                onChange={(e) => changeImageSize(e)}
              />
              <ButtonDropdown toggle={toggleSizeDropdown} size="sm" isOpen={openDropdown}>
                <DropdownToggle caret className="btn-group-background">
                  { activeSize || "px"}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => changeActiveSize("px")}>px</DropdownItem>
                  <DropdownItem onClick={() => changeActiveSize("%")}>%</DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </InputGroup>
          </Col>
        </Row>
      </div>
    </>
  );
}

// ********************************** //
// *** END: FORM QUESTION DETAILS *** //
// ********************************** //

// ************************************ //
// *** START: FORM QUESTION OPTIONS *** //
// ************************************ //

function RenderCostHTML({
  json,
  dispatch,
  path = [],
  hasCostRange,
  fixedCost,
  adjCost,
  multi,
  costStructure,
  questionType
}) {
  const costRanges = [
    { label: "Low", value: "low" },
    { label: "Expected", value: "expected" },
    { label: "High", value: "high" }
  ];

  const pathStructure = path.map((x) => x);

  const showCostOptions = (json) => (
    <>
      {adjCost && (
        <FormGroup style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          <Label className="form-control-label">Adjust the specified cost:</Label>
          <Input
            className="form-control-sm bg-transparent border-0 pl-0 pt-0"
            style={{ fontSize: "0.65rem", width: "75px" }}
            type="select"
            name="cost_type"
            value={_.get(json, ["cost_type"], 0)}
            onChange={(e) => changeOptionInput({ e, dispatch, path })}
          >
            <option value="+">Add</option>
            <option value="-">Subtract</option>
          </Input>
          <InputGroup className={`input-group-merge ${!fixedCost ? "col-4 pl-0" : ""}`}>
            <Input
              onChange={(e) => changeOptionInput({ e, dispatch, path })}
              name="cost"
              type="number"
              value={_.get(json, "cost", 0)}
            />
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-percentage" />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      )}
      {(fixedCost || multi) &&
        costStructure &&
        costStructure.map((structure, index) => (
          <FormGroup
            className={`${questionType === "number" && "grey-bg"}`}
            style={{
              padding: "15px",
              marginBottom: costStructure.length === index + 1 ? "1.5rem" : "0rem"
            }}
            key={`cost-structure-${index}`}
          >
            {structure === "cost" && <Label className="form-control-label">One-Time cost:</Label>}
            {structure === "daily" && <Label className="form-control-label">Daily cost:</Label>}
            {structure === "monthly" && <Label className="form-control-label">Monthly cost:</Label>}
            {structure === "yearly" && <Label className="form-control-label">Yearly cost:</Label>}
            {questionType === "number" && (
              <FormText>This will be the price per number entered into the question</FormText>
            )}
            {hasCostRange ? (
              <>
                <Row>
                  {costRanges.map((costRange) => (
                    <Col key={costRange.value}>
                      <Label className="form-control-label mb-0">{costRange.label}</Label>
                      <InputGroup className={classnames("input-group-merge")}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-dollar-sign" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={(e) => changeOptionInput({ e, dispatch, path: path.concat(structure) })}
                          name={costRange.value}
                          type="number"
                          min="0"
                          value={_.get(json, [structure, costRange.value], 0)}
                        />
                      </InputGroup>
                    </Col>
                  ))}
                </Row>
              </>
            ) : (
              <InputGroup className="input-group-merge">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-dollar-sign" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  onChange={(e) => changeOptionInput({ e, dispatch, path: pathStructure })}
                  name={structure}
                  type="number"
                  value={_.get(json, structure, 0)}
                />
                {questionType === "number" && (
                  <InputGroupAddon addonType="append">
                    <InputGroupText>/item</InputGroupText>
                  </InputGroupAddon>
                )}
              </InputGroup>
            )}
          </FormGroup>
        ))}
    </>
  );

  return showCostOptions(json);
}

function RenderImageOptions({ option, dispatch, optionIdx }) {
  const imagePath = _.get(option, ["optionImage", "imagePath"], "");
  const [dragActive, setDragActive] = useState(false);
  const [activeDimension, setActiveDimension] = useState("width");
  const [activeSize, setActiveSize] = useState("px");
  const [openDropdown, setOpenDrowndown] = useState(false);
  const [imgPreview, setImgPreview] = useState(
    imagePath && typeof imagePath === "string"
      ? `${AWS_S3_ASSETS_BUCKET}/${imagePath}`
      : typeof imagePath === "object"
      ? URL.createObjectURL(imagePath.file)
      : "default"
  );
  const [errorMessage, setErrorMessage] = useState(false);

  const [uploadImage, { loading: uploadingImage }] = useMutation(UPLOAD_IMAGE, {
    onCompleted: (response) => {
      dispatch({
        type: actions.UPDATE_QUESTION_BEING_EDITED,
        payload: {
          key: "imagePath",
          value: response.uploadFileImage,
          path: ["options", optionIdx, "optionImage"]
        }
      });
    }
  });

  const inputRef = useRef();
  const onSelectImage = (e, dispatch) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileSize = Math.round(file.size / 1024);

    if (fileSize < 1024) {
      setErrorMessage(false);
      setImgPreview(URL.createObjectURL(file));
      dispatch({
        type: actions.UPDATE_QUESTION_BEING_EDITED,
        payload: {
          key: "imagePath",
          value: {
            file: file
          },
          path: ["options", optionIdx, "optionImage"]
        }
      });
    } else {
      setErrorMessage(true);
    }
  };

  const onDragFiles = (files) => {
    const file = files[0];
    if (!file) return;

    const fileSize = Math.round(file.size / 1024);

    if (fileSize < 1024) {
      setErrorMessage(false);
      uploadImage({ variables: { file } });
      setImgPreview(URL.createObjectURL(file));
    } else {
      setErrorMessage(true);
    }
  };

  const toggleDimension = (e) => {
    setActiveDimension(e.target.value);
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: "activeSize",
        value: e.target.value,
        path: ["options", optionIdx, "optionImage"]
      }
    });
  };

  const toggleSizeDropdown = () => {
    setOpenDrowndown(!openDropdown);
  };

  const changeImageSize = (e) => {
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: activeDimension,
        value: e.target.value,
        path: ["options", optionIdx, "optionImage"]
      }
    });
  };

  const changeActiveSize = (size) => {
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: "size",
        value: size,
        path: ["options", optionIdx, "optionImage"]
      }
    });
  };

  return (
    <>
      {uploadingImage ? <LoaderSpinner /> : null}
      {errorMessage && (
        <small className="image-validation-err">The uploaded image exceeds the maximum file size allowed. Please ensure that your image is less than 1mb.</small>
      )}
      <Dropzone
        maxFiles={1}
        noClick
        noKeyboard
        onDrop={onDragFiles}
        accept={{
          "image/*": [".png", ".gif", ".jpeg", ".jpg"]
        }}
      >
        {({ getRootProps, getInputProps, open }) => (
          <div
            {...getRootProps()}
            className={classNames("bg-white", "mt-3", "p-3", "mb-3", {
              "border-0": !dragActive,
              "border-1": dragActive
            })}
            style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            {imgPreview !== "default" && (
              <Button
                style={{
                  background: "transparent",
                  borderColor: "transparent",
                  boxShadow: "none",
                  margin: 0,
                  alignSelf: "flex-end"
                }}
                onClick={() => {
                  setImgPreview("default");
                  dispatch({
                    type: actions.UPDATE_QUESTION_BEING_EDITED,
                    payload: {
                      key: "imagePath",
                      value: "",
                      path: ["options", optionIdx, "optionImage"]
                    }
                  });
                }}
              >
                <i className="fas fa-times fa-1x"></i>
              </Button>
            )}
            <input
              type="file"
              style={{ display: "none" }}
              ref={inputRef}
              onChange={(e) => onSelectImage(e, dispatch)}
              value={option.imagePath || ""}
              {...getInputProps()}
            />
            <img
              alt=""
              style={{ maxHeight: "100px", marginTop: "10px", maxWidth: "100px", marginBottom: "10px" }}
              src={imgPreview === "default" ? dragImage : imgPreview}
              accept="image/*"
            />
            <p className="mb-0">Drag & Drop Your Image Here</p>
            <p>- or -</p>
            <Button color="info" onClick={open}>
              Choose file
            </Button>
          </div>
        )}
      </Dropzone>
      {/*  */}
      <Row style={{ alignItems: "center" }}>
        <Col xs={3}>
          <span style={{ fontSize: "0.8rem" }}>Image Size:</span>
        </Col>
        <Col xs={5}>
          <div className="form-inline">
            <div className="custom-radio custom-control mr-2">
              <input
                type="radio"
                id={`${optionIdx}width`}
                className="custom-control-input"
                value="width"
                onChange={toggleDimension}
                checked={activeDimension === "width"}
              />
              <label className="custom-control-label" htmlFor={`${optionIdx}width`}>
                Width
              </label>
            </div>
            <div className="custom-radio custom-control">
              <input
                type="radio"
                id={`${optionIdx}height`}
                className="custom-control-input"
                value="height"
                onChange={toggleDimension}
                checked={activeDimension === "height"}
              />
              <label className="custom-control-label" htmlFor={`${optionIdx}height`}>
                Height
              </label>
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <InputGroup size="sm">
            <Input
              type="number"
              min="0"
              max={activeSize === "%" ? "100" : ""}
              value={option.optionImage?.[activeDimension] || "100"}
              onChange={(e) => changeImageSize(e)}
            />
            <ButtonDropdown toggle={toggleSizeDropdown} size="sm" isOpen={openDropdown}>
              <DropdownToggle caret className="btn-group-background">
                {option.optionImage?.size || "%"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => changeActiveSize("px")}>px</DropdownItem>
                <DropdownItem onClick={() => changeActiveSize("%")}>%</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </InputGroup>
        </Col>
      </Row>
    </>
  );
}

function RenderIconOptions({ option, dispatch, optionIdx }) {
  const [searchString, setSearch] = useState("");
  const [icons, setIcons] = useState(all);

  useEffect(() => {
    if (searchString !== "") {
      let iconList = [];
      all.forEach((icon) => {
        const iconKeywords = icon.search.join("");
        if (iconKeywords.includes(searchString) && !iconList.find((iL) => iL.label === icon.label)) {
          iconList.push(icon);
        }
      });
      setIcons(iconList);
    } else {
      setIcons(all);
    }
  }, [searchString]);

  const iconOnClick = useCallback(
    (classes) => {
      dispatch({
        type: actions.UPDATE_QUESTION_BEING_EDITED,
        payload: {
          key: "iconClass",
          value: classes,
          path: ["options", optionIdx]
        }
      });
    },
    [optionIdx, dispatch]
  );

  const colorOnchange = (e) => {
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: "iconColor",
        value: e.target.value,
        path: ["options", optionIdx]
      }
    });
  };

  const sizeOnchange = (e) => {
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: "iconSize",
        value: e.target.value,
        path: ["options", optionIdx]
      }
    });
  };

  const FieldComponent = SinapiFieldRegistry.getFieldComponent("color-picker");

  return (
    <>
      <div className="bg-white mt-3 p-1 =">
        <Input
          type="text"
          placeholder="search"
          style={{ border: "none", borderBottom: "1px solid", boxShadow: "none" }}
          value={searchString}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="icon-picker mt-1" style={{ display: "flex", flexWrap: "wrap" }}>
          {icons.map((icon) => (
            <div className="icons" key={icon.classes}>
              <i
                className={classNames(icon.classes, {
                  selected: option.iconClass && option.iconClass[0] === icon.classes[0]
                })}
                onClick={() => iconOnClick(icon.classes)}
              ></i>
            </div>
          ))}
        </div>
      </div>
      <Row className="mt-3">
        <Col xs={7}>
          <InputGroup size="sm">
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="px-1 py-0 custom-input-group-text">
                <span>Color</span>
              </InputGroupText>
            </InputGroupAddon>
            <FieldComponent question={{ value: option.iconColor || "rgba(51,51,51,1)" }} onChange={colorOnchange} />
          </InputGroup>
        </Col>
        <Col xs={5}>
          <InputGroup size="sm">
            <Input type="number" onChange={sizeOnchange} defaultValue="60" min="0" />
            <InputGroupAddon addonType="append">
              <InputGroupText className="px-1 py-0 custom-input-group-text">
                <span>px</span>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    </>
  );
}

function RenderOptionWidth({option, dispatch, index}) {
  const [openDropdownOptionWidth, setOpenDrowndownOptionWidth] = useState(false);
  const [activeSize, setActiveSize] = useState("px");

  const toggleSizeDropdownOptionWidth = () => {
    setOpenDrowndownOptionWidth(!openDropdownOptionWidth);
  };

  const changeOptionWidthUnit = (size, optionIdx) => {
    setActiveSize(size);
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: "optionWidthUnit",
        value: size,
        path: ["options", optionIdx, "optionImage"]
      }
    });
  };

  const changeOptionWidth = (e, optionIdx) => {
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: "optionWidth",
        value: e.target.value,
        path: ["options", optionIdx, "optionImage"]
      }
    });
  };

  return (
    <InputGroup style={{ width: "30%" }} size="sm">
      <Input
        type="number"
        min="0"
        max={activeSize === "%" ? "100" : ""}
        value={option.optionImage?.optionWidth || "100"}
        onChange={(e) => changeOptionWidth(e, index)}
      />
      <ButtonDropdown toggle={toggleSizeDropdownOptionWidth} size="sm" isOpen={openDropdownOptionWidth}>
        <DropdownToggle caret className="btn-group-background">
          {option.optionImage?.optionWidthUnit || "px"}
        </DropdownToggle>
        <DropdownMenu container="body">
          <DropdownItem onClick={() => changeOptionWidthUnit("px", index)}>px</DropdownItem>
          <DropdownItem onClick={() => changeOptionWidthUnit("%", index)}>%</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </InputGroup>
  );
}

function RenderOptions({ options, dispatch, hasCostRange, fixedCost, adjCost, multi, costStructure, json }) {
  const [label, setLabel] = useState("");
  const [isLabelModified, setIsLabelModified] = useState(false);
  const [collapse, setCollapse] = useState();
  const toggleOption = (index) => {
    setCollapse(collapse === Number(index) ? -1 : Number(index));
  };

  const hasImage = _.get(json, "hasOptionImages", false);

  const onChangeDefault = (e, optionId) => {
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: "defaultValue",
        value: e.target.checked ? optionId : "",
        path: []
      }
    });
  };

  const toggleOptionImage = (e, optionIdx) => {
    dispatch({
      type: actions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: "hasIcon",
        value: e.target.value === "image" ? false : true,
        path: ["options", optionIdx]
      }
    });
  };

  const onChangeInputLabel = (value) => {
    setIsLabelModified(true);
    setLabel(value);
  }

  return (
    <>
      <SinapiDragDropList
        accept={["option"]}
        items={options || []}
        onDrop={(item, index) => moveOption(item, index, dispatch)}
        id={"droparea-options"}
        customDropArea={
          <div className="row-drop-area" style={{ height: "50px" }}>
            Drop Option Here
          </div>
        }
      >
        {(option, index) =>
          option.id !== "0" && (
            <SinapiDraggableItem
              name={option.id}
              type="option"
              parentDropAreaId={"droparea-options"}
              useCustomDragHandle={true}
            >
              {(drag) => (
                <Card className="optionItem mb-0" key={index}>
                  <div className="optionHeader">
                    <div className="custom-control custom-checkbox">
                      <Input
                        type="checkbox"
                        id={`defaultCB${option.id}`}
                        onChange={(e) => onChangeDefault(e, option.id)}
                        checked={json.defaultValue === option.id}
                      />
                    </div>
                    <UncontrolledTooltip placement="bottom" target={`defaultCB${option.id}`} delay={{ hide: 0 }}>
                      Set as default
                    </UncontrolledTooltip>
                    <div
                      className="name"
                      data-event={index}
                      onClick={() => toggleOption(index)}
                      style={{ cursor: "move" }}
                      ref={drag}
                    >
                      {_.get(option, ["name"], "")}
                    </div>
                    <div className="buttons">
                      <Button size="sm" color="link" onClick={() => deleteOption(index, dispatch)}>
                        <i className="fas fa-trash"></i>
                      </Button>
                      <Button
                        size="sm"
                        color="link"
                        data-event={index}
                        onClick={() => {
                          toggleOption(index);
                          setLabel("");
                          setIsLabelModified(false);
                        }}
                      >
                        <i className="fas fa-chevron-down"></i>
                      </Button>
                    </div>
                  </div>
                  <Collapse isOpen={collapse === index} className="optionBody">
                    <FormGroup style={{ padding: "15px", marginBottom: "0" }}>
                      <div style={{ display: "flex" }}>
                        <Label className="form-control-label">Label</Label>
                      </div>
                      <InputGroup className={classnames("input-group-merge")}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-edit" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={(e) => onChangeInputLabel(e.target.value)}
                          onBlur={(e) => changeOptionInput({ e, dispatch, path: ["options", `${index}`] })}
                          name="name"
                          type="text"
                          value={isLabelModified ? label : _.get(option, ["name"], "")}
                        />
                      </InputGroup>
                      {json.hasOptionImages && (
                        <div className="mt-3 mb-2 d-flex align-items-center justify-content-center">
                          <span className="mr-2" style={{ fontSize: "0.8rem" }}>Option Width:</span>
                          <RenderOptionWidth index={index} option={option} dispatch={dispatch} />
                          <div className="form-inline ml-auto">
                            <div className="custom-radio custom-control mr-2">
                              <input
                                type="radio"
                                id={`${index}hasImg`}
                                className="custom-control-input"
                                value="image"
                                onChange={(e) => toggleOptionImage(e, index)}
                                checked={hasImage && !option.hasIcon}
                              />
                              <label className="custom-control-label" htmlFor={`${index}hasImg`}>
                                Image
                              </label>
                            </div>
                            <div className="custom-radio custom-control">
                              <input
                                type="radio"
                                id={`${index}hasIcon`}
                                className="custom-control-input"
                                value="icon"
                                onChange={(e) => toggleOptionImage(e, index)}
                                checked={hasImage && option.hasIcon}
                              />
                              <label className="custom-control-label" htmlFor={`${index}hasIcon`}>
                                Icon
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {hasImage && !option.hasIcon && (
                        <RenderImageOptions optionIdx={index} option={option} dispatch={dispatch} />
                      )}
                      {hasImage && option.hasIcon && (
                        <RenderIconOptions optionIdx={index} option={option} dispatch={dispatch} />
                      )}
                    </FormGroup>
                    <RenderCostHTML
                      id={index}
                      json={option}
                      dispatch={dispatch}
                      fixedCost={fixedCost}
                      adjCost={adjCost}
                      multi={multi}
                      hasCostRange={hasCostRange}
                      path={["options", `${index}`]}
                      costStructure={costStructure}
                    />
                  </Collapse>
                </Card>
              )}
            </SinapiDraggableItem>
          )
        }
      </SinapiDragDropList>
      <div className="mt-3">
        <Button size="sm" color="info" onClick={() => addOption(dispatch)}>
          <i className="fas fa-plus" /> Add Option
        </Button>
      </div>
    </>
  );
}

function ShowCostRanges({
  json,
  sizeOptions,
  dispatch,
  formQuestionsWithCost,
  hasAdvancedEditing,
  setShowModal,
  questionType
}) {
  const groupedQuestions = _.groupBy(formQuestionsWithCost, (q) => q.section);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const fixedCost = _.get(json, "affectCost") === "fixed-cost" ? true : false;
  const adjCost = _.get(json, "affectCost") === "adjustment" ? true : false;
  const multi = _.get(json, "affectCost") === "multiplier" ? true : false;

  const hasCostRange = _.get(json, "costRange", false);
  const priceAdjustAll = _.get(json, "priceAdjust", "all") === "all" ? true : false;
  return (
    <>
      <FormGroup style={{ borderLeftColor: "#f1f1f1", borderLeftStyle: "solid", borderLeftWidth: "10px" }}>
        <Row className="ml-3">
          <Label className="form-control-label">How will this affect the cost?</Label>
        </Row>
        <Row className="custom-radio ml-3 mb-3">
          <CustomInput
            type="radio"
            id="fixed-cost"
            name="affectCost"
            label="Fixed Cost"
            checked={fixedCost}
            onChange={(e) => toggleHasCostAffect({ e, dispatch })}
          />
          {hasAdvancedEditing ? (
            <CustomInput
              type="radio"
              id="adjustment"
              name="affectCost"
              className={`ml-3 ${questionType === "number" ? "invisible" : ""}`}
              label="% Adjustment"
              checked={adjCost}
              onChange={(e) => toggleHasCostAffect({ e, dispatch })}
            />
          ) : (
            <CustomInput
              type="radio"
              id="adjustment"
              name="affectCost"
              className={`ml-3 ${questionType === "number" ? "invisible" : ""}`}
              label="% Adjustment"
              checked={false}
              onChange={() => setShowModal(true)}
            />
          )}
          <CustomInput
            type="radio"
            id="multiplier"
            name="affectCost"
            className={`ml-3 ${questionType === "number" ? "invisible" : ""}`}
            label="Multiplier"
            checked={multi}
            onChange={(e) => toggleHasCostAffect({ e, dispatch, groupedQuestions })}
          />
        </Row>
        {adjCost && (
          <>
            <Row className="ml-3">
              <Label className="form-control-label">Which price will be adjusted?</Label>
            </Row>
            <Row className="custom-radio mb-1 ml-3">
              <CustomInput
                type="radio"
                id="all"
                name="priceAdjust"
                label="Entire Project"
                checked={priceAdjustAll}
                onChange={(e) => togglePriceAdjusted({ e, dispatch })}
              />
              <CustomInput
                type="radio"
                id="question"
                name="priceAdjust"
                className="ml-3"
                label="Specific Question"
                checked={!priceAdjustAll}
                onChange={(e) => togglePriceAdjusted({ e, dispatch })}
              />
            </Row>
            {priceAdjustAll && (
              <Label className="ml-3 text-muted" style={{ fontSize: "0.8rem" }}>
                The answer will adjust the $ value of the entire form
              </Label>
            )}
            {!priceAdjustAll && (
              <Label className="ml-3 mb-3 text-muted" style={{ fontSize: "0.8rem" }}>
                The answer will adjust the $ value of the specified question below
              </Label>
            )}
          </>
        )}
        {(fixedCost || multi) && (
          <Row className="custom-checkbox pb-2" style={{ marginLeft: "2.7rem" }}>
            <Input
              className="custom-control-input"
              id={`hasCostRanges`}
              name="hasCostRanges"
              type="checkbox"
              onChange={(e) => toggleHasCostRange(e, sizeOptions, json, dispatch, setShowAlertModal)}
              checked={hasCostRange}
            />
            <Label className="custom-control-label" htmlFor={`hasCostRanges`}>
              Include a cost range
            </Label>
          </Row>
        )}
        {multi && (
          <Row className="ml-3 mt-2">
            <Label className="form-control-label">
              <small className="text-muted">
                The $ value entered for this question will be multiplied by the number entered in the associated
                question. Keep in mind you can only choose "Number" question types.
                <br />
                <br />
                *Note: The $ value for the associated question does not affect this cost. Only the number entered by the
                lead.
              </small>
            </Label>
          </Row>
        )}
        {((adjCost && !priceAdjustAll) || multi) && (
          <>
            <Row className="ml-3">
              <Label className="form-control-label">Which question will be adjusted?</Label>
            </Row>
            <Row className="custom-radio mb-3 ml-3">
              <Input
                type="select"
                id="questionAdjust"
                name="questionAdjust"
                onChange={(e) => changeOptionInput({ e, dispatch, path: [] })}
                value={_.get(json, ["questionAdjust"], "")}
                style={{ width: "95%" }}
              >
                {/* <option key={"default_question_id"} id={`default_option_id`} name={`default_option_id`}>
                  - Select Question -
                </option> */}
                {/* {formQuestionsWithCost.map(
                  (question) =>
                    (multi ? question.type === "number" : true) && (
                      <option key={question.id} value={question.id}>
                        {question.title}
                      </option>
                    )
                )} */}
                {Object.keys(groupedQuestions).map((key) => (
                  <optgroup label="---">
                    {groupedQuestions[key].map(
                      (question) =>
                        (multi ? question.type === "number" : true) && (
                          <option key={question.id} value={question.id}>
                            {question.title}
                          </option>
                        )
                    )}
                  </optgroup>
                ))}
              </Input>
            </Row>
          </>
        )}
      </FormGroup>
      {showAlertModal ? (
        <ManagementModal
          modalSize="md"
          classNames={{
            modal: "modal-dialog modal-info modal-dialog-centered",
            body: "bg-gradient-info",
            footer: "bg-gradient-info"
          }}
          buttons={[
            {
              label: "No, Thanks",
              icon: "fas fa-times mr-2",
              color: "link",
              size: "md",
              onClick: () => setShowAlertModal(false),
              className: "mr-auto text-white"
            },
            {
              label: "Yes, Continue",
              icon: "fas fa-check mr-2",
              color: "secondary",
              size: "md",
              onClick: () => {
                dispatch({
                  type: actions.TOGGLE_QUESTION_COST_RANGE,
                  payload: {
                    size: sizeOptions,
                    json:
                      sizeOptions > 0
                        ? _.map(_.range(sizeOptions), (option) =>
                            _.map(_.get(json, ["costStructure"], []), (structure) => ({
                              structure,
                              cost: _.get(json, ["options", option, structure, "expected"], 0)
                            }))
                          )
                        : _.map(_.get(json, ["costStructure"], []), (structure) => ({
                            structure,
                            cost: _.get(json, [structure, "expected"], 0)
                          })),
                    costRange: false
                  }
                });
                setShowAlertModal(false);
              }
            }
          ]}
        >
          <Row>
            <Col className="text-center">
              <i className="far fa-bell fa-3x" />
              <h2 className="mt-2 text-white font-weight-light">
                By changing to a single price you will lose all low/high price points you've set.
              </h2>
              <hr className="my-2" style={{ backgroundColor: "white" }} />
              <h3 className="mx-4 text-white">Do you wish to continue?</h3>
            </Col>
          </Row>
        </ManagementModal>
      ) : null}
    </>
  );
}

export const createOptionsHTML = (json, dispatch, formQuestionsWithCost, hasAdvancedEditing, setShowModal) => {
  if (json.hasOwnProperty("options")) {
    const sizeOptions = _.get(json, ["options"]).length;
    const hasCostRange = _.get(json, "costRange");
    const fixedCost = _.get(json, "affectCost", "fixed-cost") === "fixed-cost" ? true : false;
    const adjCost = _.get(json, "affectCost") === "adjustment" ? true : false;
    const multi = _.get(json, "affectCost") === "multiplier" ? true : false;
    const costStructure = _.get(json, "costStructure", ["cost"]);
    const hasCost = costStructure.length > 0;
    const isNotSelect = ["radio", "checkbox"].includes(_.get(json, "type", ""));

    return (
      <>
        <FormGroup>
          {hasCost && (
            <ShowCostRanges
              json={json}
              sizeOptions={sizeOptions}
              dispatch={dispatch}
              formQuestionsWithCost={formQuestionsWithCost}
              hasAdvancedEditing={hasAdvancedEditing}
              setShowModal={setShowModal}
            />
          )}
        </FormGroup>
        {!adjCost && (
          <>
            <Label className="form-control-label">Cost Structure</Label>
            <FormGroup className="form-inline mt--3" style={{ marginBottom: "0rem" }}>
              <div className="custom-control custom-checkbox mb-3">
                <Input
                  className="custom-control-input"
                  id={`hasOneTime`}
                  name="hasOneTime"
                  type="checkbox"
                  onChange={(e) => toggleCostStructure(e, "cost", dispatch, false, sizeOptions)}
                  checked={costStructure.includes("cost")}
                />
                <Label className="custom-control-label pt-1" htmlFor={`hasOneTime`}>
                  One Time
                </Label>
              </div>
              <div className="custom-control custom-checkbox mb-3 ml-4">
                <Input
                  className="custom-control-input"
                  id={`hasDaily`}
                  name="hasDaily"
                  type="checkbox"
                  onChange={(e) => toggleCostStructure(e, "daily", dispatch, false, sizeOptions)}
                  checked={costStructure.includes("daily")}
                />
                <Label className="custom-control-label pt-1" htmlFor={`hasDaily`}>
                  Daily
                </Label>
              </div>
              <div className="custom-control custom-checkbox mb-3 ml-4">
                <Input
                  className="custom-control-input"
                  id={`hasMonthly`}
                  name="hasMonthly"
                  type="checkbox"
                  onChange={(e) => toggleCostStructure(e, "monthly", dispatch, false, sizeOptions)}
                  checked={costStructure.includes("monthly")}
                />
                <Label className="custom-control-label pt-1" htmlFor={`hasMonthly`}>
                  Monthly
                </Label>
              </div>
              <div className="custom-control custom-checkbox mb-3 ml-4">
                <Input
                  className="custom-control-input"
                  id={`hasYearly`}
                  name="hasYearly"
                  type="checkbox"
                  onChange={(e) => toggleCostStructure(e, "yearly", dispatch, false, sizeOptions)}
                  checked={costStructure.includes("yearly")}
                />
                <Label className="custom-control-label pt-1" htmlFor={`hasYearly`}>
                  Yearly
                </Label>
              </div>
            </FormGroup>
          </>
        )}
        {isNotSelect && (
          <>
            <Label className="form-control-label mb-0">Style Options</Label>
            <FormGroup className="form-inline mt--3" style={{ marginBottom: "0rem" }}>
              <div className="custom-control custom-checkbox mb-3">
                <Input
                  className="custom-control-input"
                  id={`hasOptionImages`}
                  name="hasOptionImages"
                  type="checkbox"
                  onChange={(e) => toggleOptionImage(e, dispatch, [])}
                  checked={_.get(json, "hasOptionImages", false)}
                />
                <Label className="custom-control-label pt-1" htmlFor={`hasOptionImages`}>
                  Add Image to Options
                </Label>
              </div>
            </FormGroup>
            <Label className="form-control-label mb-0">Alignment</Label>
            <FormGroup className="form-inline mt-2">
              <ButtonGroup>
                <Button
                  id="align-left-btn"
                  className={classNames("btn-group-button", {
                    "btn-group-button-active": _.get(json, "floatClass", "justify-left") === "justify-left"
                  })}
                  onClick={() => handleJustify({ direction: "justify-left", dispatch, path: [] })}
                >
                  <i className="fas fa-align-left"></i>
                </Button>
                <UncontrolledTooltip placement="bottom" target="align-left-btn" delay={{ hide: 0 }}>
                  Left
                </UncontrolledTooltip>
                <Button
                  id="align-center-btn"
                  className={classNames("btn-group-button", {
                    "btn-group-button-active": _.get(json, "floatClass", "justify-left") === "justify-center"
                  })}
                  onClick={() => handleJustify({ direction: "justify-center", dispatch, path: [] })}
                >
                  <i className="fas fa-align-center"></i>
                </Button>
                <UncontrolledTooltip placement="bottom" target="align-center-btn" delay={{ hide: 0 }}>
                  Center
                </UncontrolledTooltip>
                <Button
                  id="align-right-btn"
                  className={classNames("btn-group-button", {
                    "btn-group-button-active": _.get(json, "floatClass", "justify-left") === "justify-right"
                  })}
                  onClick={() => handleJustify({ direction: "justify-right", dispatch, path: [] })}
                >
                  <i className="fas fa-align-right"></i>
                </Button>
                <UncontrolledTooltip placement="bottom" target="align-right-btn" delay={{ hide: 0 }}>
                  Right
                </UncontrolledTooltip>
                <Button
                  id="align-space-btn"
                  className={classNames("btn-group-button", {
                    "btn-group-button-active": _.get(json, "floatClass", "justify-left") === "justify-space"
                  })}
                  onClick={() => handleJustify({ direction: "justify-space", dispatch, path: [] })}
                >
                  <i className="fas fa-grip-lines-vertical"></i>
                </Button>
                <UncontrolledTooltip placement="bottom" target="align-space-btn" delay={{ hide: 0 }}>
                  Space Between
                </UncontrolledTooltip>
              </ButtonGroup>
            </FormGroup>
          </>
        )}
        <RenderOptions
          options={json.options}
          json={json}
          dispatch={dispatch}
          fixedCost={fixedCost}
          adjCost={adjCost}
          multi={multi}
          hasCostRange={hasCostRange}
          costStructure={costStructure}
        />
      </>
    );
  } else {
    return <RenderCostHTML json={json} dispatch={dispatch} />;
  }
};

// ************************************ //
// ***  END: FORM QUESTION OPTIONS  *** //
// ************************************ //

// ********************************** //
// *** START: FORM QUESTION RULES *** //
// ********************************** //

const getQuestionTypeById = (questions, id) =>
  _.get(
    _.find(questions, (q) => q.id === id),
    "type",
    ""
  );

const getQuestionOptionsById = (questions, id) =>
  _.get(
    _.find(questions, (q) => q.id === id),
    "options",
    []
  );

export const createRulesHTML = (
  json,
  dispatch,
  formQuestions,
  sectionRules = false,
  hasAdvancedEditing,
  setShowModal
) => {
  const hasDynamicRules = _.get(json, ["dynamicRules", "active"], false);
  const arrayDynamicRules = _.get(json, ["dynamicRules", "rules"], []);
  return (
    <>
      <FormGroup>
        <div className="custom-control custom-checkbox mb-3">
          {hasAdvancedEditing ? (
            <Input
              className="custom-control-input"
              id={`hasDynamicRules`}
              name="hasDynamicRules"
              type="checkbox"
              onChange={(e) => toggleHasDynamicRules(e, dispatch, sectionRules)}
              checked={hasDynamicRules}
            />
          ) : (
            <Input
              className="custom-control-input"
              id={`hasNotDynamicRules`}
              type="checkbox"
              onChange={() => setShowModal(true)}
              checked={false}
            />
          )}
          <Label
            className="custom-control-label"
            htmlFor={hasAdvancedEditing ? `hasDynamicRules` : `hasNotDynamicRules`}
          >
            <span style={{ fontWeight: 700 }}>Enable Dynamic Display</span>
            <br />
            This option will either give you the option to create dynamic rules to show and hide inputs based on form
            submissions
          </Label>
        </div>
      </FormGroup>
      <hr className="mb-3 mt-3" />
      {hasDynamicRules && (
        <>
          <FormGroup className="form-inline mb-3">
            <Input
              type="select"
              className="form-control-sm col-6"
              name="action"
              value={_.get(json, ["dynamicRules", "action"], "show")}
              onChange={(e) => changeOptionInput({ e, dispatch, path: ["dynamicRules"] }, sectionRules)}
              style={{
                maxWidth: "100px",
                textAlign: "center",
                textAlignLast: "center"
              }}
            >
              <option value="show">Show</option>
              <option value="hidden">Hide</option>
            </Input>
            <Label className="form-control-label col-6 justify-content-start">
              <div>This question when:</div>
            </Label>
          </FormGroup>
          {arrayDynamicRules.map((dynamicRule, idx) => (
            <FormGroup key={`dynamicRule_${idx}`} className="mb-0">
              {_.get(dynamicRule, ["ruleGroup", "condition"]) && (
                <Row className="justify-content-end mt--2 mb-2">
                  <Col xs="auto" className="form-inline" style={{ marginRight: "20px" }}>
                    <Label style={{ color: "#5e72e4", fontSize: "0.75rem", fontWeight: "600" }}>Rules Condition</Label>
                    <Input
                      type="select"
                      name="condition"
                      onChange={(e) =>
                        changeOptionInput(
                          {
                            e,
                            dispatch,
                            path: ["dynamicRules", "rules", idx, "ruleGroup"]
                          },
                          sectionRules
                        )
                      }
                      className="form-control-sm"
                      style={{
                        maxWidth: "108px",
                        marginLeft: "10px",
                        textAlign: "center",
                        textAlignLast: "center"
                      }}
                      value={_.get(dynamicRule, ["ruleGroup", "condition"], "")}
                    >
                      {_.map(LOGICAL_OPERATORS, (value, id) => (
                        <option key={`logicalOperator_${id}`} value={value}>
                          {value}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              )}
              {_.get(dynamicRule, ["ruleGroup", "rules"], []).map((rule, idxRule) => (
                <div key={`dynamicRuleRow_${idx}_${idxRule}`} className="ml-2">
                  <Row>
                    <Col xs="1" className="pl-2 pr-0">
                      <Button
                        size="sm"
                        color="link"
                        className="minus-button"
                        onClick={() => deleteDynamicRule(idx, idxRule, dispatch, sectionRules)}
                      >
                        <i className="fas fa-minus" />
                      </Button>
                    </Col>
                    <Col xs="4" className="pl-0 pr-0">
                      <Input
                        type="select"
                        name="questionId"
                        onChange={(e) =>
                          changeOptionInput(
                            {
                              e,
                              dispatch,
                              path: ["dynamicRules", "rules", idx, "ruleGroup", "rules", idxRule]
                            },
                            sectionRules
                          )
                        }
                        value={_.get(rule, ["questionId"], "")}
                        className="form-control-sm"
                      >
                        <option key={"default_question_id"} id={`default_option_id`} name={`default_option_id`}>
                          - Select Question -
                        </option>
                        {formQuestions.map((question) => (
                          !_.includes(["headline", "divider", "image-field", "textblock"], question.type) ? 
                          <option key={`question_${question.id}`} value={question.id}>
                            {question.title}
                          </option>
                        : ""
                        ))}
                      </Input>
                    </Col>
                    <Col xs="3" className="pl-0 pr-0 ml-1">
                      <Input
                        type="select"
                        name="operator"
                        onChange={(e) =>
                          changeOptionInput(
                            {
                              e,
                              dispatch,
                              path: ["dynamicRules", "rules", idx, "ruleGroup", "rules", idxRule]
                            },
                            sectionRules
                          )
                        }
                        className="form-control-sm"
                        value={_.get(rule, ["operator"], "")}
                      >
                        <option key={"default_operator_id"} id={`default_operator_id`} name={`default_option_id`}>
                          - Operator -
                        </option>
                        {_.map(
                          OPERATORS[getQuestionTypeById(formQuestions, _.get(rule, ["questionId"], ""))],
                          (value, id) => (
                            <option key={`operator_${id}`} value={value}>
                              {value}
                            </option>
                          )
                        )}
                      </Input>
                    </Col>
                    <Col xs="3" className="pl-0 pr-0 ml-1">
                      {["select", "radio", "checkbox"].includes(
                        getQuestionTypeById(formQuestions, _.get(rule, ["questionId"], ""))
                      ) ? (
                        <Input
                          className="form-control-sm"
                          type="select"
                          name="answer"
                          value={_.get(rule, ["answer"], "")}
                          disabled={[IS_EMPTY, NOT_EMPTY].includes(_.get(rule, ["operator"], ""))}
                          onChange={(e) =>
                            changeOptionInput(
                              {
                                e,
                                dispatch,
                                path: ["dynamicRules", "rules", idx, "ruleGroup", "rules", idxRule]
                              },
                              sectionRules
                            )
                          }
                        >
                          <option key={"default_answer_id"} id={`default_answer_id`} name={`default_option_id`}>
                            - Answer -
                          </option>
                          {_.map(
                            getQuestionOptionsById(formQuestions, _.get(rule, ["questionId"], "")),
                            (value, id) => (
                              <option key={`answer_${id}`} value={value.id}>
                                {value.name}
                              </option>
                            )
                          )}
                        </Input>
                      ) : (
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="answer"
                          value={_.get(rule, ["answer"], "")}
                          disabled={[IS_EMPTY, NOT_EMPTY].includes(_.get(rule, ["operator"], ""))}
                          onChange={(e) =>
                            changeOptionInput(
                              {
                                e,
                                dispatch,
                                path: ["dynamicRules", "rules", idx, "ruleGroup", "rules", idxRule]
                              },
                              sectionRules
                            )
                          }
                        />
                      )}
                    </Col>
                  </Row>
                  {_.get(dynamicRule, ["ruleGroup", "condition"]) &&
                    _.get(dynamicRule, ["ruleGroup", "rules"], []).length > 1 &&
                    idxRule < _.get(dynamicRule, ["ruleGroup", "rules"], []).length - 1 && (
                      <Row>
                        <Col className="text-center">
                          <Label style={{ color: "#5e72e4", fontSize: "0.75rem", fontWeight: "600" }}>
                            {_.get(dynamicRule, ["ruleGroup", "condition"], "")}
                          </Label>
                        </Col>
                      </Row>
                    )}
                </div>
              ))}
              <Row className="mt-2">
                <Col xs="auto" className="mr-auto">
                  <Button color="link" onClick={() => addDynamicRule(idx, dispatch, sectionRules)} size="sm">
                    <i className="fas fa-plus" /> Add Rule
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    size="sm"
                    color="link"
                    onClick={() => deleteDynamicGroupRule(idx, arrayDynamicRules, dispatch, sectionRules)}
                  >
                    <i id="delete-rule-group-icon" className="fas fa-trash" /> Delete Group
                  </Button>
                </Col>
              </Row>
              <div className="hr">
                <hr className="mb-3 mt-3" />
                {idx !== arrayDynamicRules.length - 1 && <i className="fas fa-link chain-icon" />}
                {arrayDynamicRules.length > 1 && idx < arrayDynamicRules.length - 1 ? (
                  <>
                    {idx === 0 ? (
                      <Input
                        type="select"
                        name="condition"
                        onChange={(e) => changeOptionInput({ e, dispatch, path: ["dynamicRules"] }, sectionRules)}
                        className="form-control-sm"
                        style={{ maxWidth: "108px", marginLeft: "36%", textAlign: "center", textAlignLast: "center" }}
                        value={_.get(json, ["dynamicRules", "condition"], "")}
                      >
                        {_.map(LOGICAL_OPERATORS, (value, id) => (
                          <option key={`logicalOperator_${id}`} value={value}>
                            {value}
                          </option>
                        ))}
                      </Input>
                    ) : (
                      <Input
                        type="text"
                        className="form-control-sm"
                        style={{ maxWidth: "108px", marginLeft: "36%", textAlign: "center" }}
                        value={_.get(json, ["dynamicRules", "condition"], "")}
                        disabled
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </FormGroup>
          ))}           
          {/* <FormGroup className="text-center" style={{ marginLeft: "20%" }}>
            <Button type="button" color="primary" onClick={() => addDynamicGroupRule(dispatch, sectionRules)} size="sm">
              <i className="fas fa-plus" /> Add New Rule Group
            </Button>
          </FormGroup> */}
        </>
      )}
    </>
  );
};

// ********************************** //
// ***  END: FORM QUESTION RULES  *** //
// ********************************** //
