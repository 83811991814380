import { format } from "date-fns";
import { orderBy } from "lodash";
import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  NavItem,
  NavLink,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Row,
  Col
} from "reactstrap";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { getFullNameOrEmail } from "utils/helperFunctions";

const GET_FORM_HISTORY = gql`
  query GetForm($workspaceId: ID!, $formId: ID!) {
    workspace(id: $workspaceId) {
      id
      form(id: $formId) {
        id
        formHistories {
          items {
            id
            createdAt
            autosave
            user {
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`;

const RESTORE_FORM_VERSION = gql`
  mutation RestoreFormVersion($formId: ID, $formHistoryId: ID) {
    restoreFormVersion(formId: $formId, formHistoryId: $formHistoryId) {
      name
      schema
      lastUpdate
    }
  }
`;

export function FormRevisionHistory({ formId, workspaceId, onRevision, onClose }) {
  const [formHistories, setFormHistories] = useState([]);
  const { loading: formLoading, error: formError, data: formHistoryData, refetch } = useQuery(GET_FORM_HISTORY, {
    variables: { formId: formId, workspaceId: workspaceId },
    skip: !formId
  });
  const [restoreFormVersion, { data: restoredFormData, loading: restoring }] = useMutation(RESTORE_FORM_VERSION);

  useEffect(() => {
    if (!formLoading && formHistoryData) {
      setFormHistories(
        orderBy(formHistoryData.workspace.form.formHistories.items, ["createdAt"], ["desc"]).map((item) => {
          return {
            created: format(new Date(item.createdAt), "MMM d, h:mm:ss	a"),
            userName: item.user ? getFullNameOrEmail(item.user) : "",
            id: item.id,
            autosave: item.autosave
          };
        })
      );
    }
  }, [formHistoryData, formLoading]);

  useEffect(() => {
    if (!restoring && restoredFormData) {
      if (onRevision) {
        onRevision(restoredFormData.restoreFormVersion);
      }
      refetch();
    }
  }, [restoredFormData, restoring]);

  const restoreForm = (historyVersionId) => {
    restoreFormVersion({
      variables: {
        formId: formId,
        formHistoryId: historyVersionId
      }
    });
  };

  const createRevisionHistory = () => {
    if (!formHistories.length) {
      return <div className="font-italic">No Revision History!</div>;
    }
    return (
      <NavItem>
        {formHistories.map((item, index) => (
          <div key={item.created}>
            <NavLink>
              <div className="history-date">
                <span>{item.created}</span>

                {index !== 0 ? (
                  <UncontrolledDropdown className="ml-auto mr-0">
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right positionFixed={true}>
                      <DropdownItem
                        onClick={(e) => {
                          e.preventDefault();
                          restoreForm(item.id);
                        }}
                      >
                        Restore this version
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : null}
              </div>
              {index === 0 ? (
                <div>
                  <small>
                    <i>Current Version</i>
                  </small>
                </div>
              ) : null}
              <div>
                <small>{item.userName}</small>
                {item.autosave ? (
                  <>
                    <i className="fas fa-robot ml-2 fa-xs" id={`autosave-${item.id}`} />
                    <UncontrolledTooltip placement="top" target={`autosave-${item.id}`}>
                      Autosave
                    </UncontrolledTooltip>
                  </>
                ) : null}
              </div>
            </NavLink>
            {index < formHistories.length - 1 ? <hr className="my-2" /> : null}
          </div>
        ))}
      </NavItem>
    );
  };

  return (
    <div className="vertical-sidenav-full-container">
      <Card className="mb-0">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="12" className="vertical-sidenav-header">
              <Button close onClick={() => onClose()} />
              <h1 className="mb-0">
                <i className="fas fa-history" /> Revision
              </h1>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Nav navbar vertical>
            {createRevisionHistory()}
          </Nav>
        </CardBody>
      </Card>
    </div>
  );
}

FormRevisionHistory.defaultProps = {};

FormRevisionHistory.propTypes = {
  formId: PropTypes.string,
  workspaceId: PropTypes.string,
  onRevision: PropTypes.func
};

export default FormRevisionHistory;
