import React, { useState } from "react";
import env from "Environment";
import { useMutation } from "@apollo/react-hooks";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { gql } from "apollo-boost";
import { DetailedCheckoutForm } from "components/Stripe/DetailedCheckoutForm";
import { differenceInDays } from "date-fns";
import { get, find } from "lodash";

// reactstrap components
import { Row, Col, Button } from "reactstrap";
import { FEATURE_WHITE_LABEL, WHITE_LABELS } from "utils/constants";
import { stringToBoolean } from "utils/helperFunctions";

const stripePromise = loadStripe(env.STRIPE_PUBLIC_KEY);

const UPDATE_PAYMENT = gql`
  mutation updatePaymentMethod($customerId: ID!, $paymentMethodId: ID!) {
    updatePaymentMethod(customerId: $customerId, paymentMethodId: $paymentMethodId) {
      type
      card {
        last4
      }
      billingDetails {
        name
        email
      }
    }
  }
`;

function getPaymentMethodError(subscription, paymentMethod, hasWhiteLabel, company) {
  if (
    subscription &&
    subscription.latestInvoice.paymentIntent &&
    subscription.latestInvoice.paymentIntent === "requires_payment_method" &&
    paymentMethod &&
    paymentMethod.card
  ) {
    return "Payment failed! Please update payment info!";
  } else if (subscription && subscription.status === "past_due") {
    if (differenceInDays(new Date(subscription.trialEnd * 1000), new Date()) < 5) {
      return `Trial Expired! Please add payment info to continue using ${
        WHITE_LABELS(hasWhiteLabel, company).sinapiName
      }`;
    } else {
      return `Payment is past due, please update payment info to continue using ${
        WHITE_LABELS(hasWhiteLabel, company).sinapiName
      }`;
    }
  } else if (subscription && subscription.status === "trialing" && !paymentMethod) {
    return "Add Payment Information to continue using your account once trial ends. You won't be charged until the trial ends.";
  }
  return "";
}

/*
 * paymentInfo: {
     type
          card {
            last4
          }
          billingDetails {
            name
            email
          }
        }
 */
const StripePaymentInfo = ({ paymentInfo, currSubscription, compProduct, customerId, onSuccessUpdate, user }) => {
  const [showEditPayment, setEditPayment] = useState(false);
  const [updatePayment, { error: updatePaymentError }] = useMutation(UPDATE_PAYMENT);
  const [processingUpdate, setProcessingUpdate] = useState(false);
  const hasWhiteLabel = stringToBoolean(
    get(
      find(get(compProduct, "features", []), (feature) => feature.id.includes(FEATURE_WHITE_LABEL)),
      ["value"]
    )
  );
  const paymentMethodError = getPaymentMethodError(currSubscription, paymentInfo, hasWhiteLabel, user.company);

  if (showEditPayment) {
    return (
      <>
        <Elements stripe={stripePromise}>
          <DetailedCheckoutForm
            btnName="Update"
            onCancel={() => setEditPayment(false)}
            user={user}
            processing={processingUpdate}
            setProcessing={setProcessingUpdate}
            error={updatePaymentError}
            onSuccess={(paymentMethod) => {
              updatePayment({
                variables: {
                  customerId: customerId,
                  paymentMethodId: paymentMethod.id
                }
              }).then(
                () => {
                  if (onSuccessUpdate) {
                    onSuccessUpdate();
                  }
                  setProcessingUpdate(false);
                  setEditPayment(false);
                },
                () => {
                  setProcessingUpdate(false);
                }
              );
            }}
          />
        </Elements>
      </>
    );
  }
  return (
    <>
      {paymentMethodError ? <div className="text-danger">{paymentMethodError}</div> : null}
      <Row className="align-items-center">
        <Col xs="12" sm="4" lg="3" className="mb-3">
          <div className="sub-col-header">Name</div>
          <div className="sub-col-value">
            {paymentInfo && paymentInfo.billingDetails ? paymentInfo.billingDetails.name : "-"}
          </div>
        </Col>
        <Col xs="12" sm="4" lg="3" className="mb-3">
          <div className="sub-col-header">Pay by</div>
          <div className="sub-col-value">{paymentInfo && paymentInfo.type === "card" ? "CreditCard" : "-"}</div>
        </Col>
        <Col xs="12" sm="4" lg="3" className="mb-3">
          {paymentInfo && paymentInfo.type === "card" ? (
            <>
              <div className="sub-col-header">Card Number</div>
              <div className="sub-col-value">************{paymentInfo.card.last4}</div>
            </>
          ) : null}
        </Col>
        <Col xs="12" sm="4" lg="3" className="mb-3">
          <Button
            className="btn-icon"
            color="primary"
            type="button"
            onClick={() => {
              setEditPayment(true);
            }}
          >
            <span className="btn-inner--icon mr-1">
              <i className="fas fa-pencil-alt" />
            </span>
            <span className="btn-inner--text">Update Payment Info</span>
          </Button>
        </Col>
      </Row>
    </>
  );
};
export { StripePaymentInfo };
