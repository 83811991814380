import React from "react";
import { Input, FormGroup, InputGroupAddon, InputGroup } from "reactstrap";
import { getUniqueID, defaultOnChange } from "./HelperFunctions";
import { FieldLabel } from "./FieldLabel";
import { optionWithIcon } from "./fieldUtils";
import { preventPasteNegative, showOnlyNumbers } from "utils/helperFunctions";

const NumberField = (props) => {
  const { id, value, placeholder = "", suffix, prefix, inputFontsize } = props.question;
  const { onChange = defaultOnChange } = props;
  return (
    <>
      <FieldLabel question={props.question} />
      <InputGroup className="input-group-sm">
        {prefix ? <i className={`mt-2 mr-2 ${prefix} fa-fw`} /> : null}
        <Input
          id={id}
          defaultValue={value}
          placeholder={placeholder}
          className="form-control-sm"
          type="number"
          onChange={onChange}
          min="0"
          onPaste={preventPasteNegative}
          onKeyPress={showOnlyNumbers}
          style={{ maxWidth: "80px", height: `calc(${inputFontsize} + 17px)` }}
        />
        {suffix ? (
          <InputGroupAddon addonType="append">
            <span className="input-group-text">{suffix}</span>
          </InputGroupAddon>
        ) : null}
      </InputGroup>
    </>
  );
};

const PreviewNumberField = () => optionWithIcon("", "Number", 7);

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Number Label",
  description: "<p>Description for the input</p>",
  required: false,
  type: "number",
  prefix: "",
  suffix: "",
  dollarValue: true,
  cost: 0,
  cost_type: "+",
  affectCost: "fixed-cost",
  costStructure: ["cost"],
  daily: 0,
  daily_type: "+",
  monthly: 0,
  monthly_type: "+",
  yearly: 0,
  yearly_type: "+"
});

const NumberEditOptions = (json, onInputChange) => {
  return (
    <>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder</div>
        </label>
        <Input
          placeholder=""
          className="form-control-sm"
          type="text"
          name="placeholder"
          defaultValue={json.hasOwnProperty("placeholder") ? json.placeholder : ""}
          onChange={(e) => onInputChange({ e })}
        />
      </FormGroup>
    </>
  );
};

const fieldConfig = {
  key: "number",
  component: NumberField,
  getInitialData: getInitialData,
  previewComponent: PreviewNumberField,
  getEditOptions: NumberEditOptions
};

export default fieldConfig;
