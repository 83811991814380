import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// react library for routing
import { Route, Switch, useLocation, useHistory, Redirect, matchPath } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { get, isEmpty } from "lodash";
import { gql } from "apollo-boost";
import { useMediaQuery } from "react-responsive";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import { routes, adminRoutes, superAdminRoutes, documentationRoutes } from "routes.js";
import FreeHeaderBar from "components/Headers/FreeHeaderBar";
import TrialHeaderBar from "../components/Headers/TrialHeaderBar";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { getLimitSubscriptionPerFeatureType, isEmptyCompanyData, stringToBoolean } from "utils/helperFunctions";
import { useCompanySubscription } from "hooks/useCompanySubscription";
import { FEATURE_WHITE_LABEL, WHITE_LABELS, FEATURE_CONNECTORS } from "utils/constants";

import "./Admin.scss";

const GET_CURRENT_USER = gql`
  query getCurrentUserWithWorkspace($workspaceId: ID) {
    currentUser {
      id
      firstName
      lastName
      email
      phone
      roleId
      workspaces {
        items {
          id
          name
          userRoleId
        }
      }
      company {
        id
        name
        address
        city
        state
        zipcode
        planId
        logoPath
        subdomain
        customDomain {
          id
          domain
        }
        enableCustomDomain
        customApplicationName
        stripeCustomerId
        subscriptions {
          status
          id
          cancelAt
          trialEnd
          items {
            id
            plan {
              product
            }
          }
          latestInvoice {
            id
            status
            paymentIntent {
              status
            }
          }
        }
        paymentMethod {
          type
        }
      }
    }
    currentWorkspace(id: $workspaceId) {
      id
      name
      userRoleId
    }
  }
`;

const IS_AUTHENTICATED_MUTATION = gql`
  {
    isAuthenticated
  }
`;

const Admin = ({ routes: customRoutes, layout, ...props }) => {
  const { loaded, currentUser, currentWorkspace, logo } = useSelector((state) => state.user);
  const companySubscription = useCompanySubscription(
    get(currentUser, ["company", "id"]),
    get(currentUser, ["company", "planId"])
  );
  const isTrial = get(currentUser, ["company", "subscriptions", 0, "status"], "") === "trialing";
  const hasWhiteLabel = getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_WHITE_LABEL);
  const hasConnectors = getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_CONNECTORS);

  const dispatch = useDispatch();
  const { data, loading, refetch } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "no-cache",
    skip: loaded
  });

  const {
    loading: loadingAuth,
    data: dataAuth,
    error: errorAuth,
    refetch: refetchAuth
  } = useQuery(IS_AUTHENTICATED_MUTATION, {
    fetchPolicy: "no-cache"
  });

  const [routeInfo, setRouteInfo] = useState({});
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const mainContent = useRef(null);
  const history = useHistory();
  const location = useLocation();

  const handleMediaQueryChange = (matches) => {
    // matches will be true or false based on the value for the media query
    if (matches) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      document.body.classList.remove("g-sidenav-show");
      document.body.classList.add("g-sidenav-hidden");
      setSidenavOpen(false);
    }
  };
  useMediaQuery({ maxWidth: 1268 }, undefined, handleMediaQueryChange);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1268 });

  useEffect(() => {
    if (isTabletOrMobile) {
      handleMediaQueryChange(isTabletOrMobile);
    }
  }, [isTabletOrMobile]);

  useEffect(() => {
    if (!loaded && !loading && data) {
      dispatch({ type: "SET_CURRENT_USER", payload: data });
    }
  }, [data]);

  useEffect(() => {
    if (hasWhiteLabel) {
      dispatch({
        type: "REFRESH_LOGO",
        payload: WHITE_LABELS(stringToBoolean(hasWhiteLabel), get(currentUser, "company")).sinapiLogo
      });
    }
  }, [currentUser, hasWhiteLabel, dispatch]);

  useEffect(() => {
    if (!loaded && currentUser && !loading) {
      refetch();
    }
  }, [loaded]);

  useEffect(() => {
    if (history.pathname !== location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      if (mainContent && mainContent.current) {
        mainContent.current.scrollTop = 0;
      }
    }
  }, [location]);

  useEffect(() => {
    let currentRoute, routeBreadCrumbs;
    const findRoute = (routes, breadCrumb) => {
      return routes.map((prop, key) => {
        const newBreadCrumb = [...(breadCrumb || []), prop];
        if ((prop.collapse || prop.views) && !prop.component) {
          return findRoute(prop.views, newBreadCrumb);
        }
        const match = matchPath(location.pathname, {
          ...prop,
          path: prop.layout + prop.path
        });
        if (match) {
          currentRoute = prop;
          routeBreadCrumbs = breadCrumb;
          return;
        } else if (prop.views) {
          return findRoute(prop.views, newBreadCrumb);
        }
      });
    };
    findRoute(customRoutes || routes);
    setRouteInfo({
      route: currentRoute,
      breadcrumbs: routeBreadCrumbs
    });
  }, [location]);

  refetchAuth();
  if (!loadingAuth && (!dataAuth || errorAuth || !dataAuth.isAuthenticated)) {
    return <Redirect to="/auth/login" />;
  }

  if (loaded && !currentWorkspace && currentUser && !location.pathname.endsWith("/admin/company-profile")) {
    return <Redirect to="/admin/company-profile" />;
  }

  if (loaded && currentUser && currentWorkspace) {
    if (
      currentUser.company &&
      !isEmpty(currentUser.company.stripeCustomerId) &&
      currentUser.company.subscriptions &&
      currentUser.company.subscriptions.length === 0 &&
      !location.pathname.endsWith("/admin/registration") &&
      currentUser.company.planId !== "agency" &&
      currentUser.company.planId !== "free"
    ) {
      return <Redirect to="/admin/registration" />;
    } else if (
      currentUser.company &&
      !isEmpty(currentUser.company.stripeCustomerId) &&
      currentUser.company.subscriptions &&
      currentUser.company.subscriptions.length > 0 &&
      currentUser.company.subscriptions[0].status !== "active" &&
      currentUser.company.subscriptions[0].status !== "trialing" &&
      !location.pathname.endsWith("/admin/billing") &&
      !location.pathname.endsWith("/admin/registration") &&
      !location.pathname.endsWith("/admin/upgrade")
    ) {
      return <Redirect to="/admin/billing" />;
    }
  }

  if (
    loaded &&
    isEmptyCompanyData(currentUser) &&
    !location.pathname.endsWith("/admin/company-profile") &&
    !location.pathname.endsWith("/admin/registration")
  ) {
    return <Redirect to="/admin/company-profile" />;
  }

  const userRole = currentWorkspace ? currentWorkspace.userRoleId || currentUser.roleId : null;

  const getRoutes = (routes, breadCrumb) => {
    return routes.map((prop, key) => {
      const newBreadCrumb = [...(breadCrumb || []), prop];
      if ((prop.collapse || prop.views) && !prop.component) {
        return getRoutes(prop.views, newBreadCrumb);
      }
      if (prop.roles && !prop.roles.includes(userRole)) {
        return null;
      }
      if (prop.layout === (layout || "/admin")) {
        const extraProps = prop.options || {};
        extraProps.breadCrumbs = breadCrumb;
        let viewRoutes = [];
        if ((prop.collapse || prop.views) && prop.component) {
          viewRoutes = getRoutes(prop.views, newBreadCrumb);
        }
        const newRoute = (
          <Route
            path={prop.layout + prop.path}
            strict={prop.strict || false}
            exact={prop.exact || false}
            render={(props) => {
              const RouteComp = prop.component;
              return <RouteComp {...props} {...extraProps} />;
            }}
            key={key}
          />
        );
        return [newRoute, ...viewRoutes];
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-show");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1 ? "dark" : "light";
  };

  return (
    <>
      <Sidebar
        {...props}
        userRole={userRole}
        routes={(customRoutes || routes).filter((route) => !route.roles || route.roles.includes(userRole))}
        adminRoutes={(customRoutes || adminRoutes).filter((route) => !route.roles || route.roles.includes(userRole))}
        superAdminRoutes={(customRoutes || superAdminRoutes).filter(
          (route) => !route.roles || route.roles.includes(userRole)
        )}
        documentationRoutes={customRoutes ? [] : documentationRoutes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        loaded={hasWhiteLabel ? true : false}
        hasWhiteLabel={hasWhiteLabel}
        hasConnectors={hasConnectors}
        enableCustomDomain={get(currentUser, ["company", "enableCustomDomain"])}
        logo={{
          innerLink: "/admin/dashboard",
          imgSrc: logo,
          imgAlt: `${WHITE_LABELS(hasWhiteLabel, get(currentUser, ["company"], {})).sinapiName} Logo`
        }}
      />
      <div className="main-content" ref={mainContent}>
        {isTrial && (
          <TrialHeaderBar
            company={get(currentUser, ["company"])}
            subscription={companySubscription}
            onUpgrade={() => history.push("/admin/upgrade")}
            showUpgrade={true}
          />
        )}
        {get(currentUser, ["company", "planId"]) === "free" && (
          <FreeHeaderBar onUpgrade={() => history.push("/admin/upgrade")} />
        )}
        <AdminNavbar
          {...props}
          currentRoute={routeInfo.route}
          breadcrumbs={routeInfo.breadcrumbs}
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        {loading || !currentUser ? <LoaderSpinner /> : null}
        {currentUser ? (
          <Switch>
            {getRoutes(customRoutes || routes)}
            {getRoutes(customRoutes || adminRoutes)}
            {getRoutes(customRoutes || superAdminRoutes)}
            {getRoutes(documentationRoutes || [])}
          </Switch>
        ) : null}
      </div>
      {sidenavOpen ? <div className="backdrop d-xl-none" onClick={toggleSidenav} /> : null}
    </>
  );
};

export default Admin;
