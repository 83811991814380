import React from "react";
import { useTheme, createUseStyles } from "react-jss";

import { get } from "lodash";
import { Button, Col, Container } from "reactstrap";

import env from "Environment";
import { RenderFormSection } from "components/FormComponents/RenderFormSection";
import { FormProvider } from "views/pages/forms/FormProvider";

// Creating a namespaced theming object.
let useStyles = createUseStyles((data) => {
  const { fontImport, ...styles } = data;
  return {
    "@import": fontImport || [],
    sinapiForm: {
      ...styles
    }
  };
});
/**
 * Sample card that render one section within a form
 * @param {object} props
 */
function SampleFormContainer({ schema, buttonSchema, showButton, cssTransition, floatingButtonSchema }) {
  const cssTheme = useTheme();
  const classes = useStyles({ theme: cssTheme });
  const section = get(schema, ["sections", 0], []);
  const inputFontsize = get(cssTheme, ["& .form-control", "fontSize"], "14 px");

  let style = { width: "100%", height: "100%", backgroundSize: "cover", display: "grid", justifyContent: "unset" };
  if (showButton) {
    style = {
      ...style,
      backgroundImage: `url(${env.ASSETS_URL}/assets/images/sinapi-ipad-background-theme-preview.jpg)`
    };
  }

  return (
    <>
      <Container
        fluid
        className={"sample-container section-container " + cssTransition + " " + classes.sinapiForm}
        style={style}
      >
        {!showButton && (
          <div style={{ marginLeft: "420px", height: "80px", backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
            <h1 className="text-white mb-0 mt-3  ml-4" style={{ lineHeight: "0.85" }}>
              Sample Form Preview
            </h1>
            <p className="text-white my-0 ml-4">
              Below is a sample form so you can view all the styles at the same time
            </p>
          </div>
        )}
        {showButton && (
          <div style={{ marginLeft: "420px", height: "80px", backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
            <h1 className="mb-0 mt-3  ml-4" style={{ lineHeight: "0.85" }}>
              Button Preview
            </h1>
            <p className="my-0 ml-4">Below you can see an example of the button as it will appear on your website</p>
          </div>
        )}
        <div
          className={"centered-form"}
          style={{
            width: "600px",
            marginLeft: !showButton ? "42%" : "38%"
          }}
        >
          {!showButton && (
            <div className="my-2">
              <FormProvider inputFontsize={inputFontsize}>
                <RenderFormSection
                  section={section}
                  style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
                  sections={[]}
                />
              </FormProvider>
            </div>
          )}
          {showButton && (
            <div className="text-center mx-auto w-50">
              <Button style={{...floatingButtonSchema,marginRight:70}}>
                {get(floatingButtonSchema, ["caption"], "Get Instant Quote")}
              </Button>
              <Button style={buttonSchema} size="xl">
                {get(buttonSchema, ["caption"], "Get Instant Quote")}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

export { SampleFormContainer };
