import React, { useState } from "react";

// reactstrap components
import * as yup from "yup";
import { Formik, Field } from "formik";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";

const formValidation = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  message: yup.string().required()
});

const SupportForm = ({ onSuccess, processing, setProcessing }) => {
  const [sending, setSending] = useState(false);
  return (
    <Formik
      initialValues={{ name: "", email: "", message: "" }}
      validationSchema={formValidation}
      validateOnMount={true}
      onSubmit={(values, actions) => {
        onSuccess(values, actions);
      }}
    >
      {({ errors, ...formikProps }) => (
        <Form>
          <Row className="align-items-center">
            <Col className="col-12">
              <FormGroup>
                <div>
                  <label className="h4" htmlFor="name">
                    Name
                  </label>
                  <Field name="name" id="name" className="form-control" />
                  {sending && errors.name && <div className="text-danger">{errors.name}</div>}
                </div>
              </FormGroup>
            </Col>
            <Col className="col-12">
              <FormGroup>
                <div>
                  <label className="h4" htmlFor="email">
                    Email
                  </label>
                  <Field name="email" id="email" className="form-control" />
                  {sending && errors.email && <div className="text-danger">{errors.email}</div>}
                </div>
              </FormGroup>
            </Col>
            <Col className="col-12">
              <FormGroup>
                <div>
                  <label className="h4" htmlFor="message">
                    Message
                  </label>
                  <Field as="textarea" rows="3" name="message" id="message" className="form-control" />
                  {sending && errors.message && <div className="text-danger">{errors.message}</div>}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col className="col-12 text-center">
              <Button
                disabled={processing}
                className="mt-3 btn-default"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setSending(true);
                  formikProps.validateForm().then((errors) => {
                    if (Object.keys(errors).length === 0) {
                      setProcessing(true);
                      setSending(false);
                      formikProps.submitForm();
                    }
                  });
                }}
              >
                {processing ? "Sending Message..." : "Send Message"}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export { SupportForm };
