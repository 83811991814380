import React from "react";
import { Input, FormGroup } from "reactstrap";
import { getUniqueID, defaultOnChange } from "./HelperFunctions";
import { FieldLabel } from "./FieldLabel";
import _ from "lodash";
import { optionWithIcon } from "./fieldUtils";

const FormAddress = (props) => {
  const { id, fields, value, inputFontsize } = props.question;
  const { onChange = defaultOnChange } = props;
  const questionID = id;

  return (
    <>
      <FieldLabel question={props.question} />
      <div className="form-line">
        {fields.map((field) => {
          const { id, name, label, type } = field;
          return (
            <div key={name}>
              <label className={`form-control-${type}`}>{label}</label>
              <Input
                id={`${name}_${id}`}
                name={questionID}
                value={_.get(value, name)}
                placeholder={_.get(props.question, `${name}Placeholder`, "")}
                type="text"
                className="form-control-sm"
                onChange={onChange}
                style={{ height: `calc(${inputFontsize} + 17px)` }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

const PreviewFormAddress = () => optionWithIcon("far far fa-address-card", "Address");

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Address Label",
  description: "<p>Description for the input</p>",
  required: false,
  type: "address",
  fields: [
    {
      id: `${getUniqueID()}`,
      name: "address",
      label: "Address",
      type: "sub-label"
    },
    {
      id: `${getUniqueID()}`,
      name: "city",
      label: "City",
      type: "sub-label"
    },
    {
      id: `${getUniqueID()}`,
      name: "state",
      label: "State/Province",
      type: "sub-label"
    },
    {
      id: `${getUniqueID()}`,
      name: "zip",
      label: "Zip/Postal Code",
      type: "sub-label"
    }
  ]
});

const AddressEditOptions = (json, onInputChange) => {
  return (
    <>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder Address</div>
        </label>
        <Input
          className="form-control-sm"
          type="text"
          name="addressPlaceholder"
          defaultValue={json.hasOwnProperty("addressPlaceholder") ? json.addressPlaceholder : ""}
          onChange={(e) => onInputChange({ e })}
        />
      </FormGroup>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder City</div>
        </label>
        <Input
          className="form-control-sm"
          type="text"
          name="cityPlaceholder"
          defaultValue={json.hasOwnProperty("cityPlaceholder") ? json.cityPlaceholder : ""}
          onChange={(e) => onInputChange({ e })}
        />
      </FormGroup>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder State/Province</div>
        </label>
        <Input
          className="form-control-sm"
          type="text"
          name="statePlaceholder"
          defaultValue={json.hasOwnProperty("statePlaceholder") ? json.statePlaceholder : ""}
          onChange={(e) => onInputChange({ e })}
        />
      </FormGroup>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder Zip/Postal Code</div>
        </label>
        <Input
          className="form-control-sm"
          type="text"
          name="zipPlaceholder"
          defaultValue={json.hasOwnProperty("zipPlaceholder") ? json.zipPlaceholder : ""}
          onChange={(e) => onInputChange({ e })}
        />
      </FormGroup>
    </>
  );
};

const fieldConfig = {
  key: "address",
  component: FormAddress,
  getInitialData: getInitialData,
  previewComponent: PreviewFormAddress,
  getEditOptions: AddressEditOptions
};

export default fieldConfig;
