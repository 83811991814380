import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle
} from "reactstrap";
import PropTypes from "prop-types";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import ManagementModal from "components/UserManagement/ManagementModal";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import AddWorkspaceToUserModal from "components/UserManagement/AddWorkspaceToUserModal";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { gql } from "apollo-boost";
import { getFullNameOrEmail } from "utils/helperFunctions";

const GET_WORKSPACES = gql`
  query getUserWorkspaces($userId: ID!) {
    user(id: $userId) {
      firstName
      lastName
      invite {
        email
      }
      workspaces {
        count
        items {
          id
          name
        }
      }
      company {
        id
        name
      }
    }
  }
`;

const REMOVE_USER_FROM_WORKSPACE = gql`
  mutation removeUserFromWorkspace($userId: ID!, $workspaceId: ID!) {
    workspaceRemoveUser(userId: $userId, workspaceId: $workspaceId) {
      id
    }
  }
`;

function UserWorkspaces({ breadCrumbs }) {
  const { userId, ...params } = useParams();
  const { loading, data, refetch } = useQuery(GET_WORKSPACES, {
    fetchPolicy: "network-only",
    variables: { userId: userId }
  });
  const [removeWorkspaceMutation, { loading: removingWorkspace, data: removeWorkspaceData }] =
    useMutation(REMOVE_USER_FROM_WORKSPACE);
  const [showModal, setShowModal] = useState();

  useEffect(() => {
    if (removeWorkspaceData && !removingWorkspace) {
      refetch();
    }
  }, [removeWorkspaceData]);

  const removeWorkspace = (workspace) => {
    removeWorkspaceMutation({
      variables: {
        workspaceId: workspace.id,
        userId: userId
      }
    });
    setShowModal(null);
  };

  const getUserName = (data) =>
    data && data.user ? (
      data.user.invite ? (
        <i className="font-weight-400">(Pending Invite)</i>
      ) : (
        getFullNameOrEmail(data.user)
      )
    ) : null;

  return (
    <>
      <SimpleHeader
        name={data && data.user ? data.user.firstName : ""}
        breadCrumbs={breadCrumbs}
        breadCrumbParams={{ ...params, companyName: data && data.user ? data.user.company.name : "Company" }}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h5 className="mb-0">User:</h5>
                <h1 className="mb-0 mt-0">{getUserName(data)}</h1>
              </CardHeader>
              {loading || removingWorkspace ? <LoaderSpinner /> : null}
              <Row>
                <Col sm="10">
                  <h3 className="mb-2 ml-4">Workspaces</h3>
                </Col>
                <Col sm="2" className="text-right">
                  <Button
                    outline
                    color="primary"
                    size="sm"
                    className="mr-4"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowModal({ type: "addNewWorkspace" });
                    }}
                  >
                    + ADD NEW
                  </Button>
                </Col>
              </Row>
              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      Name
                    </th>
                    <th scope="col">Access Date</th>
                    <th />
                  </tr>
                </thead>
                <tbody className="list">
                  {data && data.user && data.user.workspaces.items.length === 0 ? (
                    <tr>
                      <div className="m-4">No Workspaces available.</div>
                    </tr>
                  ) : null}
                  {data && data.user && data.user.workspaces
                    ? data.user.workspaces.items.map((workspace) => (
                        <tr key={workspace.id}>
                          <td className="name">{workspace.name}</td>
                          <td className="access-date">-</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right positionFixed={true}>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowModal({ type: "removeWorkspace", data: workspace });
                                  }}
                                >
                                  Remove
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      {showModal && showModal.type === "removeWorkspace" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={`Remove ${data.user.firstName} ${data.user.lastName} from the workspace ${showModal.data.name}`}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            { label: "Confirm", onClick: () => removeWorkspace(showModal.data) }
          ]}
        >
          {data.user.firstName}&nbsp;{data.user.lastName} will no longer be able to access content inside this
          workspace.
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "addNewWorkspace" ? (
        <AddWorkspaceToUserModal
          companyId={data.user.company.id}
          userId={userId}
          currentUserWorkspaceIds={data.user.workspaces.items.map((u) => u.id)}
          userName={data.user.firstName + " " + data.user.lastName}
          onClose={(success) => {
            setShowModal(null);
            if (success) {
              refetch();
            }
          }}
        />
      ) : null}
    </>
  );
}

UserWorkspaces.defaultProps = {};

UserWorkspaces.propTypes = {
  breadCrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  )
};

export default UserWorkspaces;
