import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select, { components } from "react-select";
import NotificationAlert from "components/NotificationAlert";
import { CSVLink } from "react-csv";

import moment from "moment";
import { format } from "date-fns";
import { get, find, isEmpty, map } from "lodash";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { useUrlQuery } from "hooks/useUrlQuery";

// reactstrap components
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  UncontrolledTooltip
} from "reactstrap";

// core components
import BasicHeader from "components/Headers/BasicHeader";
import { getFullNameOrEmail, numberToCurrency } from "utils/helperFunctions";
import LeadModal from "components/Modals/LeadModal";
import SinapiTableSortable from "components/Shared/SinapiTableSortable";
import ManagementModal from "components/UserManagement/ManagementModal";

import "./display.scss";

const PAGE_SIZE = 10;

const WORKSPACE_LEADS = gql`
  query GetWorkspaceForms(
    $id: ID!
    $offset: Int
    $limit: Int
    $search: String
    $status: [String]
    $startRange: Int
    $endRange: Int
  ) {
    workspace(id: $id) {
      id
      leads(
        offset: $offset
        limit: $limit
        search: $search
        status: $status
        startRange: $startRange
        endRange: $endRange
      ) {
        count
        offset
        items {
          id
          firstName
          lastName
          email
          phone
          address
          city
          state
          zipcode
          value
          additionalInfo
          quoteJsonFile
          progress
          hidden
          form {
            name
            schema
          }
          createdAt
        }
      }
    }
  }
`;

const LEADS_BY_DATE_RANGE = gql`
  query GetLeadsByDateRange($workspaceId: ID!, $startRange: String, $endRange: String, $status: [String] ) {
    workspace(id: $workspaceId) {
      id
      leadsByDateRange(startRange: $startRange, endRange: $endRange, status: $status) {
        count
        items {
          id
          firstName
          lastName
          email
          phone
          address
          city
          state
          zipcode
          value
          progress
          form {
            name
            schema
          }
          createdAt
        }
      }
    }
  }
`;

export const getStatusIcon = (progress) => {
  switch (progress) {
    case "interested":
      return (
        <>
          <i id="completeYes" className="far fa-thumbs-up text-green" />
          <UncontrolledTooltip target="completeYes">Interested</UncontrolledTooltip>
        </>
      );
    case "uninterested":
      return (
        <>
          <i id="completeNo" className="far fa-thumbs-down text-orange" />
          <UncontrolledTooltip target="completeNo">Not Interested</UncontrolledTooltip>
        </>
      );
    case "undecided":
      return (
        <>
          <i id="unDecided" className="far fa-question-circle text-blue" />
          <UncontrolledTooltip target="unDecided">Undecided</UncontrolledTooltip>
        </>
      );
    default:
      return (
        <>
          <i id="inComplete" className="far fa-edit text-grey" />
          <UncontrolledTooltip target="inComplete">Incomplete</UncontrolledTooltip>
        </>
      );
  }
};

const status = ["interested", "uninterested", "undecided", "incomplete"];

const getDropdownValue = (status) => {
  switch (status) {
    case "interested":
      return (
        <>
          <i className="far fa-thumbs-up text-green" /> Interested
        </>
      );
    case "uninterested":
      return (
        <>
          <i className="far fa-thumbs-down text-orange" /> Not Interested
        </>
      );
    case "undecided":
      return (
        <>
          <i className="far fa-question-circle text-blue" /> Undecided
        </>
      );
    case "incomplete":
      return (
        <>
          <i className="far fa-edit text-grey" /> Incomplete
        </>
      );
    default:
      return "";
  }
};

const notificationOptions = () => ({
  place: "bc",
  message: (
    <div className="alert-text ml-2">
      <span className="alert-title" data-notify="title">
        Something went wrong !!
      </span>
      <span data-notify="message" className="ml-2">
        We couldn't find the Lead with the ID, change the Workspace and try again.
      </span>
    </div>
  ),
  type: "danger",
  autoDismiss: 7
});

const headers = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Email", key: "email" },
  { label: "Phone", key: "phone" },
  { label: "Address", key: "address" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Zip Code", key: "zipcode" },
  { label: "Value", key: "value.cost.expected" },
  { label: "Status", key: "progress" },
  { label: "Form Name", key: "form.name" },
  { label: "Created At", key: "createdAt" }
];

const Tables = () => {
  const history = useHistory();
  const query = useUrlQuery();
  const leadId = query.get("id");
  const notificationAlert = useRef(null);
  const { currentWorkspace } = useSelector((state) => state.user);
  const [showLeadWithFormData, setShowLeadWithFormData] = useState();
  const [filterSearch, setFilterSearch] = useState("");
  const [filterStatus, setFilterStatus] = useState([
    { value: "interested", label: getDropdownValue("interested"), chipLabel: getStatusIcon("interested") },
    { value: "uninterested", label: getDropdownValue("uninterested"), chipLabel: getStatusIcon("uninterested") },
    { value: "undecided", label: getDropdownValue("undecided"), chipLabel: getStatusIcon("undecided") }
  ]);
  const [filterStartRange, setFilterStartRange] = useState();
  const [filterEndRange, setFilterEndRange] = useState();
  const [filterValues, setFilterValues] = useState({
    search: "",
    status: ["interested", "uninterested", "undecided"],
    startRange: 0,
    endRange: 0
  });

  const csvLink = useRef(null);
  const [errors, setErrors] = useState();
  const [showModal, setShowModal] = useState();
  const [exportData, setExportData] = useState("");
  const [startRange, setStartRange] = useState(moment().format("YYYY-MM-DD"));
  const [endRange, setEndRange] = useState(moment().format("YYYY-MM-DD"));

  const { loading, data, fetchMore, refetch } = useQuery(WORKSPACE_LEADS, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: currentWorkspace.id,
      offset: 0,
      limit: PAGE_SIZE,
      ...filterValues
    },
    notifyOnNetworkStatusChange: true
  });

  const { data: leadsData, loading: leadsLoading } = useQuery(LEADS_BY_DATE_RANGE, {
    fetchPolicy: "no-cache",
    variables: {
      workspaceId: currentWorkspace.id,
      startRange: moment(startRange).format("YYYY-MM-DD"),
      endRange: moment(endRange).format("YYYY-MM-DD"),
      status: ["interested", "uninterested", "undecided"]
    }
  });

  const handleChange = (status) => {
    setFilterStatus(status);
    setFilterValues({
      search: filterSearch,
      status: isEmpty(status) ? [] : status.map((s) => s.value),
      startRange: filterStartRange ? parseInt(filterStartRange) : 0,
      endRange: filterEndRange ? parseInt(filterEndRange) : 0
    });
  };
  const getFilterValues = () => {
    setFilterValues({
      search: filterSearch,
      status: isEmpty(filterStatus) ? [] : filterStatus.map((status) => status.value),
      startRange: filterStartRange ? parseInt(filterStartRange) : 0,
      endRange: filterEndRange ? parseInt(filterEndRange) : 0
    });
  };
  const clearFilterValues = () => {
    setFilterSearch("");
    setFilterStatus([]);
    setFilterStartRange("");
    setFilterEndRange("");
    setFilterValues({
      search: "",
      status: [],
      startRange: 0,
      endRange: 0
    });
  };
  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-|\./.test(keyValue)) event.preventDefault();
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: "150px"
    }),
    control: (provided, state) => ({
      ...provided,
      border: "unset",
      minHeight: "30px",
      width: "max-content",
      borderColor: "unset",
      boxShadow: "unset",
      "&:hover": {
        borderColor: "unset"
      }
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      marginTop: "-5px",
      minWidth: "40px"
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px"
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      marginTop: "-5px"
    })
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>{get(props, ["data", "chipLabel"], props.data.label)}</components.MultiValue>
  );

  useEffect(() => {
    const lead = find(get(data, ["workspace", "leads", "items"]), (item) => item.id === leadId);
    if (!loading && data && lead && leadId) {
      setShowLeadWithFormData(lead);
    }

    if (!loading && data && !lead && leadId) {
      notificationAlert.current.notificationAlert(notificationOptions());
    }
  }, [data, leadId, loading]);

  const exportDataAction = () => {
    if (exportData) {
      csvLink.current.link.click();
      setShowModal();
    }
  };

  useEffect(() => {
    if (startRange && endRange && moment(startRange).isSameOrBefore(endRange)) {
      setErrors();
    } else {
      setErrors("The provided date is not in a valid range");
    }
  }, [startRange, endRange]);

  useEffect(() => {
    if (!leadsLoading && leadsData) {
      const allLeads = [];
      map(get(leadsData, ["workspace", "leadsByDateRange", "items"]), (lead) =>
        allLeads.push({
          ...lead,
          createdAt: moment(lead.createdAt).format("YYYY-MM-DD"),
          progress: lead.progress === "uninterested" ? "Not Interested" : lead.progress.charAt(0).toUpperCase() + lead.progress.slice(1),
          value: { 
            cost : { 
              expected: numberToCurrency(lead.value.cost.expected) 
            } 
          }
        })
      );
      setExportData(allLeads);
    }
  }, [leadsLoading, leadsData]);

  const openMail = (event, email) => {
    event.preventDefault();
    event.stopPropagation();
    window.location.href = "mailto:"+email;
  }

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <BasicHeader smallHeader={false}>
        <h1 className="text-white d-inline-block">Your Leads</h1>
      </BasicHeader>
      <Container className="mt--9" fluid>
        <Row>
          <Col>
            <div className="form-inline my-2">
              <InputGroup className="mb-2 mr-3" style={{ width: window.innerWidth < 1268 ? "100%" : "55%" }}>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="input-group-text-mobile" style={{ border: "0", backgroundColor: "#e9ecef" }}>
                    <Label className="form-control-label ml-1 mr-1 mb-0">Search</Label>
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="text" onChange={(e) => setFilterSearch(e.target.value)} value={filterSearch}></Input>
                <Button
                  size={`${window.innerWidth < 1268 ? "sm" : "md"}`}
                  color="info"
                  className="ml--1"
                  style={{
                    height: "calc(2.75rem + 2px)",
                    borderTopLeftRadius: "unset",
                    borderBottomLeftRadius: "unset"
                  }}
                  onClick={getFilterValues}
                >
                  Search <i className="fas fa-search" />
                </Button>
              </InputGroup>

              <InputGroup
                className="mb-2 mr-3"
                style={{ width: window.innerWidth < 1268 ? "100%" : "40%", boxShadow: "unset" }}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className={`input-group-text-mobile ${window.innerWidth < 1268 && filterStatus.length === 4 ? "p-0" : ""}`}>
                    <Label className="form-control-label ml-1 mr-1 mb-0">Status</Label>
                  </InputGroupText>
                </InputGroupAddon>
                <InputGroupAddon addonType="prepend">
                  <Select
                    isMulti
                    value={filterStatus}
                    onChange={handleChange}
                    options={status.map((s) => ({ value: s, label: getDropdownValue(s), chipLabel: getStatusIcon(s) }))}
                    className="form-control p-1"
                    placeholder="All"
                    styles={customStyles}
                    components={{ MultiValue }}
                  />
                </InputGroupAddon>
              </InputGroup>

              <InputGroup className="mb-2">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="input-group-text-no-border">
                    <Label className="form-control-label ml-1 mr-1 mb-0">Value</Label>
                  </InputGroupText>
                </InputGroupAddon>
                <InputGroup className="input-group-merge w-auto">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-dollar-sign" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="form-control-md"
                    type="number"
                    min="0"
                    style={{ maxWidth: window.innerWidth < 1268 ? "46px" : "100px", borderRadius: "0", borderRight: "0" }}
                    onChange={(e) => setFilterStartRange(e.target.value)}
                    onKeyPress={onKeyPress}
                    value={filterStartRange}
                  />
                </InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className={`input-group-text ${window.innerWidth < 1268 ? "p-1" : ""}`}>
                    <Label className={`form-control-label ${window.innerWidth < 1268 ? "" : "ml-1 mr-1 "} `}>
                      <i className="fas fa-minus" />
                    </Label>
                  </InputGroupText>
                </InputGroupAddon>
                <InputGroup className={"input-group-merge mr-3 w-auto"}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-dollar-sign" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="form-control-md"
                    type="number"
                    min="0"
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    style={{ maxWidth: window.innerWidth < 1268 ? "46px" : "100px", height: "auto" }}
                    onChange={(e) => setFilterEndRange(e.target.value)}
                    onKeyPress={onKeyPress}
                    value={filterEndRange}
                  />
                  <Button
                    className="mr-0"
                    size={`${window.innerWidth < 1268 ? "sm" : "md"}`}
                    color="info"
                    style={{ height: "calc(2.75rem + 2px)" }}
                    onClick={getFilterValues}
                  >
                    Apply <i className="fas fa-filter" />
                  </Button>
                </InputGroup>
              </InputGroup>

              <div className={"mb-2 mr-3"}>
                <Button size="md" color="info" onClick={clearFilterValues}>
                  Clear All Filters &nbsp; <i className="fas fa-sync-alt" />
                </Button>
              </div>

              <div className={"mb-2 mr-3"}>
                <Button size="md" color="info" onClick={() => setShowModal(true)}>
                  Export <i className="fas fa-table"></i>
                </Button>
              </div>
            </div>
            <SinapiTableSortable
              data={get(data, ["workspace", "leads"])}
              isLoaded={!loading && data ? true : false}
              onClick={(lead) => setShowLeadWithFormData(lead)}
              onLoadMore={(offset) =>
                fetchMore({
                  variables: {
                    limit: PAGE_SIZE,
                    offset: offset
                  },
                  updateQuery: (prev, { fetchMoreResult }) => (!fetchMoreResult ? prev : fetchMoreResult)
                })
              }
              options={{
                limit: PAGE_SIZE,
                emptyLabel: `No Leads.`,
                columns: [
                  {
                    key: "value",
                    label: "",
                    className: "text-black font-weight-900",
                    // tooltip: (row) =>
                    //   row.hidden
                    //     ? "You've reached your limit on leads this month... Good Job! Please upgrade to view all your leads and go sell!"
                    //     : null,
                    render: (row) => (row.value ? `$ ${get(row, ["value", "cost", "expected"], "")}` : "")
                  },
                  {
                    key: "name",
                    label: "Name",
                    sort: "firstName",
                    className: "sort",
                    // tooltip: (row) =>
                    //   row.hidden
                    //     ? "You've reached your limit on leads this month... Good Job! Please upgrade to view all your leads and go sell!"
                    //     : null,
                    // render: (row) =>
                    //   row.hidden ? (
                    //     <hr className="my-1" style={{ borderTop: "2px solid lightgray" }} />
                    //   ) : (
                    //     getFullNameOrEmail(row)
                    //   )
                    render: (row) =>
                      getFullNameOrEmail(row)
                  },
                  {
                    key: "email",
                    label: "Email",
                    className: "sort",
                    sort: "email",
                    // tooltip: (row) =>
                    //   row.hidden
                    //     ? "You've reached your limit on leads this month... Good Job! Please upgrade to view all your leads and go sell!"
                    //     : null,
                    // render: (row) =>
                    //   row.hidden ? <hr className="my-1" style={{ borderTop: "2px solid lightgray" }} /> : <span className="text-body" onClick={(e) => openMail(e, row.email)}>{row.email}</span>
                    render: (row) =>
                      <span className="text-body" onClick={(e) => openMail(e, row.email)}>{row.email}</span>
                  },
                  {
                    key: "phone",
                    label: "Phone",
                    className: "sort",
                    sort: "phone",
                    // tooltip: (row) =>
                    //   row.hidden
                    //     ? "You've reached your limit on leads this month... Good Job! Please upgrade to view all your leads and go sell!"
                    //     : null,
                    // render: (row) =>
                    //   row.hidden ? <hr className="my-1" style={{ borderTop: "2px solid lightgray" }} /> : row.phone
                    render: (row) =>
                      row.phone
                  },
                  {
                    key: "createdAt",
                    label: "Date",
                    sort: "createdAt",
                    className: "sort",
                    // tooltip: (row) =>
                    //   row.hidden
                    //     ? "You've reached your limit on leads this month... Good Job! Please upgrade to view all your leads and go sell!"
                    //     : null,
                    render: (row) => format(new Date(row.createdAt), "MMM d, yyyy - hh:mm a")
                  },
                  {
                    key: "formName",
                    label: "Form Name",
                    sort: "formName",
                    className: "sort",
                    // tooltip: (row) =>
                    //   row.hidden
                    //     ? "You've reached your limit on leads this month... Good Job! Please upgrade to view all your leads and go sell!"
                    //     : null,
                    render: (row) => row.form.name
                  },
                  {
                    key: "status",
                    label: "Status",
                    sort: "progress",
                    className: "sort text-center",
                    render: (row) => getStatusIcon(row.progress)
                  },
                  {
                    key: "button",
                    label: "",
                    // render: (row) =>
                    //   row.hidden ? (
                    //     <Button
                    //       color="danger"
                    //       type="button"
                    //       size="sm"
                    //       style={{ minWidth: "102px" }}
                    //       onClick={() => history.push("/admin/upgrade")}
                    //     >
                    //       Upgrade Plan
                    //     </Button>
                    //   ) : (
                    //     <Button
                    //       color="primary"
                    //       onClick={() => setShowLeadWithFormData(row)}
                    //       size="sm"
                    //       style={{ minWidth: "102px" }}
                    //     >
                    //       <i className="fas fa-user" /> Details
                    //     </Button>
                    //   )
                    render: (row) =>
                      (
                        <Button
                          color="primary"
                          onClick={() => setShowLeadWithFormData(row)}
                          size="sm"
                          style={{ minWidth: "102px" }}
                        >
                          <i className="fas fa-user" /> Details
                        </Button>
                      )
                  }
                ]
              }}
            />
            <CSVLink
              data={exportData}
              headers={headers}
              filename={`leads_${moment(startRange).format("YYYYMMDD")}_${moment(endRange).format("YYYYMMDD")}.csv`}
              ref={csvLink}
            />
          </Col>
        </Row>
        {showLeadWithFormData ? (
          <LeadModal
            showLeadWithFormData={showLeadWithFormData}
            setShowLeadWithFormData={setShowLeadWithFormData}
            refetch={refetch}
            onClose={() => {
              query.delete("id");
              history.replace({
                search: query.toString()
              });
            }}
          />
        ) : null}
      </Container>
      {showModal && (
        <ManagementModal
          modalSize="md"
          onClose={() => setShowModal()}
          header={<div>Export Your Leads</div>}
          buttons={[
            { label: "Close", outline: true, onClick: () => setShowModal() },
            { label: "Export", onClick: () => exportDataAction(), disabled: !!errors }
          ]}
        >
          <label className="form-control-label" style={{ marginLeft: "2.15rem" }}>
            Choose a Date Range
          </label>
          <div className="form-inline" style={{ justifyContent: "center" }}>
            <Input
              className="form-control-sm"
              type="date"
              onChange={(e) => setStartRange(e.target.value)}
              defaultValue={startRange}
            />
            <span>&nbsp;{"to"}&nbsp;</span>
            <Input
              className="form-control-sm"
              type="date"
              onChange={(e) => setEndRange(e.target.value)}
              defaultValue={endRange}
            />
          </div>
          {errors && (
            <div className="text-danger" style={{ marginLeft: "2.15rem" }}>
              {errors}
            </div>
          )}
        </ManagementModal>
      )}
    </>
  );
};

export default Tables;
