import "./ColorPickerField.scss";
import React, { useState, useEffect, useRef } from "react";
import { getUniqueID, defaultOnChange } from "./HelperFunctions";
import { SketchPicker } from "react-color";
import { Input } from "reactstrap";

function parseRGBAString(str) {
  const match = str.match(/^rgba\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+(\.\d+)?)\s*\)/i);
  if (match && match.length > 4) {
    return { r: parseInt(match[1]), g: parseInt(match[2]), b: parseInt(match[3]), a: parseFloat(match[4]) };
  }
  return "";
}

const ColorPickerField = (props) => {
  const colorContainer = useRef(null);
  const [color, setColor] = useState(null);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const { title, description, placeholder, disableAlpha } = props.question;
  useEffect(() => {
    if (props.question.value) {
      if (props.question.value.startsWith("rgba")) {
        setColor({ css: props.question.value, value: parseRGBAString(props.question.value) });
      } else {
        setColor({ css: props.question.value, value: props.question.value });
      }
    }
  }, []);

  useEffect(() => {
    const { onChange = defaultOnChange } = props;
    onChange({
      target: {
        value: color ? color.css : ""
      }
    });
  }, [color]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (colorContainer.current && !colorContainer.current.contains(event.target)) {
        setDisplayColorPicker(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [colorContainer]);

  const handleChangeComplete = (color) => {
    if (disableAlpha) {
      setColor({ css: color.hex, value: color.hex });
    } else {
      const { r, g, b, a } = color.rgb;
      const css = `rgba(${r}, ${g}, ${b}, ${a})`;
      setColor({ value: color.rgb, css: css });
    }
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  return (
    <>
      <label className="form-control-label">
        {title && <div>{title}</div>}
        {description && <small className="text-muted">{description}</small>}
      </label>
      <div className="color-input-container" ref={colorContainer}>
        <Input
          defaultValue={color ? color.css : ""}
          placeholder={placeholder || "Pick a Color"}
          className="form-control-sm color-input"
          type="text"
          onClick={handleClick}
        />
        <div className="color-box" onClick={handleClick}>
          <div className="color" style={{ backgroundColor: color ? color.css : "none" }}></div>
        </div>
        {displayColorPicker ? (
          <SketchPicker
            color={color ? color.value : ""}
            onChangeComplete={handleChangeComplete}
            disableAlpha={disableAlpha || false}
          />
        ) : null}
      </div>
    </>
  );
};

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Color",
  description: "Please Select A Color",
  type: "color-picker"
});

const fieldConfig = {
  key: "color-picker",
  hidden: true,
  component: ColorPickerField,
  getInitialData: getInitialData
};

export default fieldConfig;
