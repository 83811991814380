import React, { useRef, useState } from "react";
import NotificationAlert from "components/NotificationAlert";

import _ from "lodash";

// reactstrap components
import { FormGroup } from "reactstrap";

// core components
import { useDispatch, useSelector } from "react-redux";
import { SinapiDragDropList, SinapiDraggableItem } from "components/DragAndDrop";
import { SinapiFieldRegistry } from "components/FormComponents/fields";
import { formActions } from "reducers";
import { useForm } from "views/pages/forms/FormContext";
import ManagementModal from "components/UserManagement/ManagementModal";

const notificationOptions = (questionReferencedBy) => ({
  place: "bc",
  message: (
    <div className="alert-text ml-2">
      <span className="alert-title" data-notify="title">
        Question Referenced !!
      </span>
      <span data-notify="message">
        The question that you are attempting to delete is referencing with Dynamic Rules to another question(s):{" "}
        <b>{questionReferencedBy.join()}</b>
      </span>
    </div>
  ),
  type: "danger",
  icon: "fas fa-exclamation-triangle"
});

/**
 * Renders all the questions within a section
 * @param {object} props
 */
function FormColumn({ column, sectionId, rowIndex, columnIndex }) {
  const dispatch = useDispatch();
  const { allFields, inputFontsize } = useForm();
  const notificationAlert = useRef(null);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(null);
  const {
    activeQuestion: {
      activeQuestionJSON: { id: activeQuestionId }
    }
  } = useSelector((state) => state.form);

  // **** I'm pretty sure I put the onchange on the wrong inputs *** //
  const onChangeOptionInput = (e) => {
    return dispatch({
      type: formActions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: e.target.value,
        value: e.target.name
      }
    });
  };

  const buildQuestions = (question) => {
    const FieldComponent = SinapiFieldRegistry.getFieldComponent(question.type);
    return FieldComponent({
      question: { ...question, inputFontsize, value: question?.defaultValue },
      onChange: onChangeOptionInput
    });
  };

  const editQuestion = (question) => {
    dispatch({
      type: formActions.EDIT_QUESTION,
      payload: {
        question: question,
        sectionId: sectionId,
        rowIndex: rowIndex,
        columnIndex: columnIndex
      }
    });
  };

  const copyQuestion = (question) => {
    dispatch({
      type: formActions.COPY_QUESTION,
      payload: {
        question: question,
        sectionId: sectionId,
        rowIndex: rowIndex,
        columnIndex: columnIndex
      }
    });
  };

  const deleteQuestion = (question) => {
    let questionReferencedBy = [];

    // Checking if the field is reference by other fields
    for (const field of allFields) {
      if (field.dynamicRules && field.dynamicRules.active) {
        for (const dynamicRule of field.dynamicRules.rules) {
          _.forEach(dynamicRule.ruleGroup.rules, (rule) => {
            if (question.id === rule.questionId) {
              questionReferencedBy.push(field.title);
            }
          });
        }
      }
    }

    if (questionReferencedBy.length === 0) {
      dispatch({
        type: "DELETE_QUESTION_FROM_SECTION",
        payload: {
          sectionId: sectionId,
          rowIndex: rowIndex,
          columnIndex: columnIndex,
          question: question
        }
      });
      setDeleteConfirmModal(null);
    } else {
      notificationAlert.current.notificationAlert(notificationOptions(questionReferencedBy));
    }
  };

  const onDrop = (item, index) => {
    if (item.parentDropAreaId) {
      dispatch({
        type: "MOVE_QUESTION",
        payload: {
          from: {
            sectionId: item.sectionId,
            rowIndex: item.rowIndex,
            columnIndex: item.columnIndex,
            fieldId: item.name
          },
          to: {
            sectionId: sectionId,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
            index: index
          }
        }
      });
    } else {
      const newField = SinapiFieldRegistry.getFieldInitialData(item.name);
      dispatch({
        type: "ADD_NEW_QUESTION",
        payload: {
          sectionId: sectionId,
          rowIndex: rowIndex,
          columnIndex: columnIndex,
          index: index,
          question: newField
        }
      });
    }
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <div className="form-column-questions form-column">
        <SinapiDragDropList
          accept={["field"]}
          items={column.questions || []}
          onDrop={(item, index) => onDrop(item, index)}
          includeDropContainer={true}
          id={`${sectionId}-${rowIndex}-${columnIndex}-column`}
          customDropArea={<div className="field-drop-area">Drop Question Here</div>}
        >
          {(question) => (
            <SinapiDraggableItem
              name={question.id}
              type="field"
              useCustomDragHandle={true}
              parentDropAreaId={`${sectionId}-${rowIndex}-${columnIndex}-column`}
              metadata={{ rowIndex: rowIndex, sectionId: sectionId, columnIndex: columnIndex }}
            >
              {(drag) => (
                <div
                  className={`form-question col-sm-12 ${
                    question.id === activeQuestionId ? "form-question-selected" : ""
                  }`}
                >
                  <div className="question-toolbar">
                    <div
                      className="drag-question field-option-btn question-option"
                      style={{ marginLeft: "4px" }}
                      ref={drag}
                    >
                      <i className="fa fa-arrows-alt" />
                    </div>
                    <div
                      className="edit-question field-option-btn question-option"
                      onClick={() => {
                        editQuestion(question);
                      }}
                    >
                      <i className="fas fa-edit" />
                    </div>
                    {question.id !== "LeadDetailsSectionEmail" && _.get(question, "removable", true) && (
                      <>
                        <div
                          className="copy-question field-option-btn question-option"
                          onClick={() => {
                            copyQuestion(question);
                          }}
                        >
                          <i className="fas fa-copy" />
                        </div>
                        <div
                          className="delete-question field-option-btn question-option"
                          style={{ borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}
                          onClick={() => {
                            // deleteQuestion(question);
                            setDeleteConfirmModal({
                              text: 'Are you sure you want to delete this question?',
                              payload: question
                            })
                          }}
                        >
                          <i className="fa fa-trash-alt" />
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={`form-question-overlay ${
                      question.id === activeQuestionId ? "form-question-overlay-selected" : ""
                    }`}
                    onClick={() => {
                      editQuestion(question);
                    }}
                  >
                    <i className={`far fa-edit${question.type === "textblock" ? " tiny-overlay" : ""}`} /> Edit Question
                  </div>
                  <FormGroup className={`${
                      question.type === "divider" ? "mb-0" : ""
                    }`}>{buildQuestions(question)}</FormGroup>
                </div>
              )}
            </SinapiDraggableItem>
          )}
        </SinapiDragDropList>
      </div>
      {deleteConfirmModal && 
        <ManagementModal modalSize="md"
          classNames={{
            modal: "modal-dialog modal-success modal-dialog-centered",
            body: "bg-gradient-success",
            footer: "bg-gradient-success"
          }}
          buttons={[
            {
              label: "No, let's keep it",
              icon: "fas fa-times mr-2",
              color: "link",
              size: "md",
              onClick: () => setDeleteConfirmModal(null),
              className: "mr-auto text-white"
            },
            {
              label: "Yes, Delete It",
              icon: "fab fa-telegram-plane mr-2",
              color: "secondary",
              size: "md",
              onClick: () => {
                // deleteAction();
                deleteQuestion(deleteConfirmModal.payload)
              }
            }
          ]}>
          <h1 className="text-center text-white">{deleteConfirmModal.text}</h1>
        </ManagementModal>}
    </>
  );
}

export { FormColumn };
