import React from "react";
import _ from "lodash";
// reactstrap components

// core components
import { useDispatch } from "react-redux";
import { SinapiDragDropList } from "components/DragAndDrop";
import { FormColumn } from "./FormColumn";

/**
 * Renders all the questions within a section
 * @param {object} props
 */
function FormRow({ row, sectionId, rowIndex }) {
  const dispatch = useDispatch();

  const onDrop = (sectionId, item, index) => {
    if (item.parentDropAreaId) {
      dispatch({
        type: "MOVE_ROW",
        payload: {
          from: {
            sectionId: item.parentDropAreaId.replace("droparea-", ""),
            fieldId: item.name
          },
          to: {
            sectionId: sectionId,
            index: index
          }
        }
      });
    }
  };

  const getStyle = (columns, index) => {
    const widthPer = (100 / 12) * columns[index];
    return {
      width: widthPer + "%",
      display: "inline-block"
    };
  };

  return (
    <>
      <div className="form-row-columns">
        <SinapiDragDropList
          accept={["column"]}
          items={row.columns || []}
          onDrop={(item, index) => onDrop(row.id, item, index)}
          id={`${sectionId}-${rowIndex}-column`}
          customDropArea={<div className="row-drop-area">Drop Column Here</div>}
          getRowStyle={(question, index) => getStyle(row.format, index)}
        >
          {(column, index) => (
            <FormColumn column={column} sectionId={sectionId} rowIndex={rowIndex} columnIndex={index} />
          )}
        </SinapiDragDropList>
      </div>
    </>
  );
}

export { FormRow };
