const env = {
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  GRAPHQL_ENDPOINT: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  APP_URL: process.env.REACT_APP_URL,
  GRAPHQL_PORT: process.env.REACT_APP_GRAPHQL_PORT,
  ASSETS_URL: process.env.REACT_APP_ASSETS_URL,
  DEV_ASSETS_PREFIX: process.env.REACT_APP_DEV_ASSETS_PREFIX,
  DAYS_LEFT_NOTIFICATION_EMAIL: process.env.REACT_APP_DAYS_LEFT_NOTIFICATION_EMAIL
};
export default env;
