import React from "react";
import { Input } from "reactstrap";
import { getUniqueID, defaultOnChange } from "./HelperFunctions";
import { FieldLabel } from "./FieldLabel";
import { optionWithIcon } from "./fieldUtils";

const FormDate = (props) => {
  const { id, value, inputFontsize } = props.question;
  const { onChange = defaultOnChange } = props;
  return (
    <>
      <FieldLabel question={props.question} />
      <Input
        id={id}
        value={value}
        className="form-control-sm"
        type="date"
        onChange={onChange}
        style={{ height: `calc(${inputFontsize} + 22px)` }}
      />
    </>
  );
};

const PreviewFormDate = () => optionWithIcon("far far fa-calendar", "Date picker");

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Date Label",
  description: "<p>Description for the input</p>",
  required: false,
  type: "date"
});

const fieldConfig = {
  key: "date",
  component: FormDate,
  getInitialData: getInitialData,
  previewComponent: PreviewFormDate,
  getEditOptions: () => {
    return;
  }
};

export default fieldConfig;
