import React from "react";
// reactstrap components
import { Col, Container, Row } from "reactstrap";

const BasicHeader = ({ smallHeader = true, children }) => {
  return (
    <>
      <div
        className={`header header-dark bg-gradient-info mt--3 ${
          smallHeader ? "pb-6" : "pb-8"
        } content__title content__title--calendar border-bottom`}
      >
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col>{children}</Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

BasicHeader.propTypes = {};

export default BasicHeader;
