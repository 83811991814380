import React, { useContext, useReducer } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const SinapiDragAndDropContext = React.createContext();
export const useSinapiDragAndDrop = () => useContext(SinapiDragAndDropContext);

function sinapiDragAndDropReducer(state, { type, payload }) {
  switch (type) {
    case "DONE":
      return { dragging: null };
    case "HOVER_OVER":
      if (
        state.hoverOn &&
        state.hoverOn.index === payload.index &&
        state.hoverOn &&
        payload.dropAreaId === state.hoverOn.dropAreaId
      ) {
        return state;
      }
      return {
        ...state,
        dragging: payload.item,
        hoverOn: {
          index: payload.index,
          dropAreaId: payload.dropAreaId
        },
        style: {}
      };
    default:
      return state;
  }
}

/**
 * Drag and Drop context to keep track of hover states. Usefull when having multiple different dropzones.
 * @param {object} props
 */
export const SinapiDragAndDropProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sinapiDragAndDropReducer, {});

  const onHover = (ref, item, monitor, index, dropAreaId) => {
    const dragIndex = item.index;
    const hoverIndex = index;
    // Don't replace items with themselves
    const sameDropArea = state.hoverOn && dropAreaId === state.hoverOn.dropAreaId;
    if (dragIndex === hoverIndex && sameDropArea) {
      return;
    }
    const hoverBoundingRect = ref.current?.getBoundingClientRect();
    // Get vertical middle
    if (!hoverBoundingRect) {
      return;
    }
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    // Determine mouse position
    const clientOffset = monitor.getClientOffset();
    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY && sameDropArea) {
      return;
    }
    // // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY && sameDropArea) {
      return;
    }
    item.index = hoverIndex;

    dispatch({
      type: "HOVER_OVER",
      payload: {
        dropAreaId: dropAreaId,
        index: hoverIndex,
        item: {
          id: item.name,
          parentDropAreaId: item.parentDropAreaId || null
        }
      }
    });
  };

  const onEnd = (item) => {
    dispatch({
      type: "DONE"
    });
  };

  return (
    <SinapiDragAndDropContext.Provider
      value={{
        onHover: onHover,
        onEnd: onEnd,
        hoverData: state.hoverOn,
        dragging: state.dragging
      }}
    >
      <DndProvider backend={HTML5Backend} context={window}>
        {children}
      </DndProvider>
    </SinapiDragAndDropContext.Provider>
  );
};
