import React from "react";
import "./SinapiDraggableItem.scss";
import PropTypes from "prop-types";
import { useDrag } from "react-dnd";
import { useSinapiDragAndDrop } from "./SinapiDragAndDropProvider";

const style = {
  cursor: "move"
};
/**
 * An wrapper component to make an item draggable, this hooks in with the SinapiDragAndDropProvider.
 * Allows for a custom drag handle, in that case the main component becomes the preview.
 * @param {object} props
 */
function SinapiDraggableItem({ children, name, type, useCustomDragHandle, parentDropAreaId, className, metadata }) {
  const sinapiDnDContext = useSinapiDragAndDrop();
  const [{ opacity }, drag, preview] = useDrag({
    item: { name: name, type: type, parentDropAreaId: parentDropAreaId || null, ...(metadata || {}) },
    end: (item, monitor) => sinapiDnDContext.onEnd(item),
    collect: (monitor) => {
      return { opacity: monitor.isDragging() ? 0.4 : 1 };
    }
  });
  return (
    <div ref={useCustomDragHandle ? preview : drag} style={{ ...style, opacity }} className={className || ""}>
      {useCustomDragHandle ? children(drag) : <div className="draggable-item-wrapper">{children()}</div>}
    </div>
  );
}

SinapiDraggableItem.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  useCustomDragHandle: PropTypes.bool
};

export { SinapiDraggableItem };
