export const actionButtonStyles = [
  {
    title: "Call To Action",
    description: "",
    type: "text",
    cssPath: ["caption"],
    col: 12
  },
  {
    title: "Background",
    description: "",
    type: "color-picker",
    cssPath: ["backgroundColor"],
    col: 12
  },
  {
    title: "Text Color",
    description: "",
    type: "color-picker",
    cssPath: ["color"],
    col: 12
  },
  {
    title: "Font Size",
    description: "",
    type: "number",
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["fontSize"],
    col: 6
  },
  {
    title: "Corner Radius",
    description: "",
    type: "number",
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["borderRadius"],
    col: 6
  },
  {
    title: "Padding (Top/Bottom)",
    description: "",
    type: "number",
    suffix: "px",
    prefix: "fas fa-arrows-alt-v",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["paddingTop"],
    customUpdates: (val) => {
      if (val && val.length > 0) {
        return [
          {
            value: val,
            path: ["paddingBottom"]
          },
          {
            value: val,
            path: ["paddingTop"]
          }
        ];
      }
      return [];
    },
    col: 6
  },
  {
    title: "Padding (Left/Right)",
    description: "",
    type: "number",
    suffix: "px",
    prefix: "fas fa-arrows-alt-h",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["paddingRight"],
    customUpdates: (val) => {
      if (val && val.length > 0) {
        return [
          {
            value: val,
            path: ["paddingRight"]
          },
          {
            value: val,
            path: ["paddingLeft"]
          }
        ];
      }
      return [];
    },
    col: 6
  },
  {
    title: "Font Weight",
    type: "select",
    description: "",
    cssPath: ["fontWeight"],
    options: [
      {
        id: "lighter",
        name: "Lighter"
      },
      {
        id: "normal",
        name: "Normal"
      },
      {
        id: "bold",
        name: "Bold"
      }
    ]
  },
  {
    title: "Border Width",
    type: "number",
    description: "",
    cssPath: ["borderWidth"],
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    col: 12
  },
  {
    title: "Border Color",
    description: "",
    type: "color-picker",
    cssPath: ["borderColor"],
    col: 12
  }
];
