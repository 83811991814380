import React from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../AuthProvider";

/*
 * Check if the user is authenticated, if not it will redirect to the 'auth/login' page.
 * If authenticated it will load the passed in component.
 */
const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { isAuthenticated } = useAuth0();

    const render = (props) => (isAuthenticated === true ? <Component {...props} /> : null);
  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
