import { map, toLower } from "lodash";

import { FORM_NAME } from "utils/constants";
import { googleFonts, getGoogleFontImportUrl } from "utils/googleFontImports";

export const fontStyles = [
  {
    header: "General",
    title: "Font",
    description: ``,
    type: "google-font-typeahead",
    options: map(googleFonts, (value) => ({ id: value, name: value })),
    cssPath: ["fontFamily"],
    parseValueIn: (val) => {
      if (val && val.includes(", sans-serif")) {
        const spl = val.split("', sans-serif");
        return spl[0].substr(1);
      }
      return val;
    },
    customUpdates: (val) => {
      if (val && val.length > 0) {
        return [
          {
            value: getGoogleFontImportUrl(val[0].name),
            path: ["fontImport"]
          },
          {
            value: `'${val[0].name}', sans-serif`,
            path: ["fontFamily"]
          }
        ];
      }
      return [];
    }
  },
  {
    header: "Heading",
    title: "Color",
    description: "",
    type: "color-picker",
    cssPath: ["& .sinapi-form-headline", "color"]
  },
  {
    title: "Font Size",
    description: "",
    type: "number",
    suffix: "px",
    parseValueOut: (val) => (val ? val + "px" : val),
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    cssPath: ["& .sinapi-form-headline", "fontSize"],
    col: 6
  },
  {
    title: "Font Weight",
    type: "select",
    description: "",
    cssPath: ["& .sinapi-form-headline", "fontWeight"],
    options: [
      {
        id: "lighter",
        name: "Lighter"
      },
      {
        id: "normal",
        name: "Normal"
      },
      {
        id: "bold",
        name: "Bold"
      }
    ],
    col: 6
  },
  {
    header: "Sub-Heading",
    title: "Color",
    description: "",
    type: "color-picker",
    cssPath: ["& .sinapi-form-sub-headline", "color"]
  },
  {
    title: "Font Size",
    description: "",
    type: "number",
    suffix: "px",
    parseValueOut: (val) => (val ? val + "px" : val),
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    cssPath: ["& .sinapi-form-sub-headline", "fontSize"],
    col: 6
  },
  {
    title: "Font Weight",
    type: "select",
    description: "",
    cssPath: ["& .sinapi-form-sub-headline", "fontWeight"],
    options: [
      {
        id: "lighter",
        name: "Lighter"
      },
      {
        id: "normal",
        name: "Normal"
      },
      {
        id: "bold",
        name: "Bold"
      }
    ],
    col: 6
  },
  {
    header: "Input Label",
    title: "Color",
    description: ``,
    type: "color-picker",
    cssPath: ["& .label-headline", "color"]
  },
  {
    title: "Size",
    description: "",
    type: "number",
    suffix: "px",
    parseValueOut: (val) => (val ? val + "px" : val),
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    cssPath: ["& .label-headline", "fontSize"],
    col: 6
  },
  {
    title: "Font Weight",
    type: "select",
    description: "",
    cssPath: ["& .label-headline", "fontWeight"],
    options: [
      {
        id: "lighter",
        name: "Lighter"
      },
      {
        id: "normal",
        name: "Normal"
      },
      {
        id: "bold",
        name: "Bold"
      }
    ],
    col: 6
  },
  {
    header: "Input Label Description",
    title: "Color",
    description: "",
    type: "color-picker",
    cssPath: ["& .label-sub-headline", "color"],
    parseValueOut: (val) => (val ? val + "!important" : val)
  },
  {
    title: "Size",
    description: "",
    type: "number",
    suffix: "px",
    parseValueOut: (val) => (val ? val + "px" : val),
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    cssPath: ["& .label-sub-headline", "fontSize"]
  },
  {
    title: "Link Color",
    description: "",
    type: "color-picker",
    cssPath: ["& .label-sub-headline a:link", "color"]
  },
  {
    header: "Option Label",
    title: "Color",
    description: `label color of ${toLower(FORM_NAME)}`,
    type: "color-picker",
    cssPath: ["& .custom-option-label", "color"]
  },
  {
    title: "Size",
    description: "",
    type: "number",
    suffix: "px",
    parseValueOut: (val) => (val ? val + "px !important" : val),
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    cssPath: ["& .custom-option-label", "fontSize"],
    col: 6
  },
  {
    title: "Font Weight",
    type: "select",
    description: "",
    cssPath: ["& .custom-option-label", "fontWeight"],
    options: [
      {
        id: "lighter",
        name: "Lighter"
      },
      {
        id: "normal",
        name: "Normal"
      },
      {
        id: "bold",
        name: "Bold"
      }
    ],
    col: 6
  }
];
