import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// nodejs library that concatenates classes
import classnames from "classnames";

// components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import { get } from "lodash";
import { gql } from "apollo-boost-upload";
import { useMutation, useQuery } from "@apollo/react-hooks";

// core components
import { isAgency } from "utils/helperFunctions";
import { AWS_S3_ASSETS_BUCKET } from "utils/constants";
import AuthHeader from "components/Headers/AuthHeader.jsx";
import LoaderSpinner from "components/Spinner/LoaderSpinner";

const GET_COMPANY_LOGO = gql`
  query companyLogo($domain: String!, $subdomain: String) {
    companyLogo(domain: $domain, subdomain: $subdomain)
  }
`;

const RESET_PASSWORD = gql`
  query reset($token: String!) {
    reset(token: $token)
  }
`;

const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePasswordViaEmail($token: String!, $password: String!) {
    updatePasswordViaEmail(token: $token, password: $password)
  }
`;

const specialCharRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
const PasswordRequirements = {
  passLength: { label: "At least 8 characters in length", test: (password) => (password || "").length > 7 },
  lowercase: { label: "Lower case letters (a-z)", test: (password) => /[a-z]/.test(password || "") },
  uppercase: { label: "Upper case letters (A-Z)", test: (password) => /[A-Z]/.test(password || "") },
  numbers: { label: "Numbers (i.e. 0-9)", test: (password) => /[0-9]/.test(password || "") },
  specialCharacters: {
    label: "Special characters (e.g. !@#$%^&():;*)",
    test: (password) => specialCharRegex.test(password || "")
  }
};

const Reset = () => {
  const { token } = useParams();
  const [error, setError] = useState();
  const [state, setState] = useState({});
  const [passwordErrors, setPasswordErrors] = useState();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePasswordResp, setChangePasswordResp] = useState();
  const [validating, setValidating] = useState(false);

  const splitedHost = window.location.host.split(".");
  const [logo, setLogo] = useState();
  const { loading, data } = useQuery(GET_COMPANY_LOGO, {
    variables: {
      subdomain: splitedHost[0],
      domain: `${splitedHost[splitedHost.length - 2]}.${splitedHost[splitedHost.length - 1]}`
    }
  });

  const { loading: loadingReset, data: dataReset } = useQuery(RESET_PASSWORD, {
    variables: {
      token: token
    }
  });

  const [updatePasswordViaEmail, { error: updateError }] = useMutation(UPDATE_PASSWORD_MUTATION);

  const validatePassword = (val) => {
    setNewPassword(val);    
    const passwordErrors = Object.keys(PasswordRequirements).reduce((req, key) => {
      if (!PasswordRequirements[key].test(val)) {
        return {
          ...req,
          [key]: false
        };
      }
      return req;
    }, {});
    
    if (Object.keys(passwordErrors).length === 0) {
      setPasswordErrors();
    }
    setPasswordErrors(passwordErrors);
  }

  useEffect(() => {
    if (!loading && data) {
      setLogo(
        isAgency
          ? data.companyLogo !== ""
            ? `${AWS_S3_ASSETS_BUCKET}/${data.companyLogo}`
            : null
          : require("assets/img/brand/sinapi-logo.svg")
      );
    }
  }, [loading, data]);

  useEffect(() => {
    if (!loadingReset && dataReset) {
      setError(dataReset.reset? null :"Problem resetting password. Please send another reset link.");
    }
  }, [loadingReset, dataReset]);

  useEffect(() => {
      if(updateError){
        setError(get(updateError,["graphQLErrors",0, "message"]))
      }
  }, [updateError]);

  const validateForm = () => newPassword.length > 0 && confirmPassword.length > 0;

  async function handleSubmit(event) {
    setChangePasswordResp();
    event.preventDefault();
    if(newPassword !== confirmPassword){
      setChangePasswordResp({type: "danger", msg:"Please ensure the password and the confirmation are the same."})
    } else {
      setValidating(true);
      updatePasswordViaEmail({ 
        variables: { 
          token: token,
          password: newPassword
        } 
      }).then(() => {
        setValidating(false);
        setNewPassword("");
        setConfirmPassword("");
        setChangePasswordResp({type: "success", msg:"The password was updated!"})
      });
    }
  }

  return (
    <>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <>
          <AuthHeader loaded={true} />
          <Container className="pb-5" style={{ marginTop: "-10.2em" }}>
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card className="bg-secondary border-0 mb-0">
                  <CardHeader className="bg-transparent">
                    <img src={logo} alt="" style={{ maxWidth: `205px`, margin: "auto", display: "block" }} />                    
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-3">
                    <div className="text-muted text-center mt-1 mb-0">
                    <h5 className="h3 alert-text mb-0">Change Password</h5>
                    </div>                    
                    <br></br>
                    <Form role="form" onSubmit={handleSubmit}>
                      <FormGroup
                        className={classnames("mb-3", {
                          focused: state.focusedEmail
                        })}
                      >
                        {error ? (
                          <div role="alert" style={{ marginTop: "16px" }} className="alert alert-warning fade show">
                            <span className="alert-text ml-1">
                              <strong>{error}</strong>
                            </span>
                          </div>
                        ) : null}
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="New Password"
                            type="password"
                            onChange={(e) => validatePassword(e.target.value)}
                            onFocus={() => setState({ focusedEmail: true })}
                            onBlur={() => setState({ focusedEmail: false })}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={
                          classnames({
                            focused: state.focusedPassword
                          }) + " mb-1"
                        }
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Confirm New Password"
                            type="password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            onFocus={() => setState({ focusedPassword: true })}
                            onBlur={() => setState({ focusedPassword: false })}
                          />
                        </InputGroup>
                      </FormGroup>                
                      {changePasswordResp ? (
                            <div role="alert" style={{ marginTop: "16px" }} className={`alert alert-${changePasswordResp.type} fade show`}>
                              <span className="alert-text ml-1">{changePasswordResp.msg}</span>
                            </div>
                            ) : null}
                      <ul className="password-requirements mt-3">
                        {Object.keys(PasswordRequirements).map((key) => {
                          const passwordValidity = passwordErrors || 
                          {
                            lowercase: false,
                            numbers: false,
                            passLength: false,
                            specialCharacters: false,
                            uppercase: false
                          };
                          const isValidRequirement = passwordValidity[key] !== false;
                          return (
                            !isValidRequirement && (
                              <li key={key} className={`requirement-item`}>
                                {isValidRequirement ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
                                {PasswordRequirements[key].label}
                              </li>
                            )
                          );
                        })}
                      </ul>
                      {(!changePasswordResp || changePasswordResp.type !== 'success') &&
                      <div className="text-center">
                        <Button className="mt-2 mb-4" color="info" type="submit" disabled={!validateForm()} style={!validateForm() ? {opacity: "0.45"} : {}}>
                          {validating ? "Changing !" : "Change"}
                        </Button>                     
                      </div>}
                      <div className="text-center mb-2">                        
                        <a className="text-muted" href="/auth/login">
                        <small>Back to Login</small>
                        </a>                        
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Reset;
