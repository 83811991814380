import React from "react";
import ReactDOM from "react-dom";
// react library for routing

import App from "./App";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/quill/dist/quill.snow.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.0.0";
import "./index.scss";

import thunk from "redux-thunk";
import { applyMiddleware, compose, combineReducers, createStore } from "redux";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import userReducer from "./reducers/userReducer";
import formReducer from "./reducers/formReducer";
import modalReducer from "./reducers/modalReducer";
import alertReducer from "./reducers/alertReducer";
import renderedReducer from "./reducers/renderedReducer";
import { ErrorBoundary } from "./components/ErrorBoundary";
import AlertHeaderBar from "./components/Headers/AlertHeaderBar";
import { createBrowserHistory } from "history";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import $ from "jquery";

const history = createBrowserHistory();

// This is a fix for handling react routes on an S3 bucket
// S3 will reroute all 404's to (domain)/#!(path)
if (history && history.location) {
  const path = (/#!(\/.*)$/.exec(history.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }
}

const allReducers = combineReducers({
  user: userReducer,
  form: formReducer,
  modals: modalReducer,
  alerts: alertReducer,
  render: renderedReducer
});

const allStoreEnhancers = compose(
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : compose()
);

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("state");
    return serializedState === null ? undefined : JSON.parse(serializedState);
  } catch {
    return undefined;
  }
};

const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
};

const persistedState = loadFromLocalStorage();

const store = createStore(allReducers, persistedState, allStoreEnhancers);

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

window.jQuery = $;
require("bootstrap");

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <AlertHeaderBar />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
