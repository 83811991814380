import "./PlanSelectionCard.css";
import React from "react";
import PropTypes from "prop-types";

// reactstrap components
import { Button, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
// core components
import { find, get } from "lodash";
import { stringToBoolean } from "utils/helperFunctions";

const PlanSelectionCard = ({ plans, onPickedPlan, showCheckout, plan, trialPlan, freePlan }) => {
  const pickedPlan = (plan, isTrial, isFreePlan) => {
    if (onPickedPlan) {
      onPickedPlan(plan, isTrial, isFreePlan);
    }
  };

  const getPriceValueByInterval = (price, interval, value) =>
    get(
      find(price, (pr) => pr.interval === interval),
      value
    );

  if (plan && trialPlan) {
    return (
      <Card className="card-pricing border-0 text-center pb-4">
        <CardHeader className="bg-transparent">
          <h2 className="font-weight-bold ls-1 py-0 mb-0">Your Plan</h2>
        </CardHeader>
        <CardBody className="px-lg sinapi-plan-container">
          <Row className="mt-4" style={{ width: "100%" }}>
            <Col key={plan.id}>
              <Card className="bg-gradient-success">
                <CardBody>
                  <Row className="justify-content-center align-items-center">
                    <span className="h1 text-uppercase ls-1 text-white">{plan.name}</span>
                  </Row>
                  <Row className="justify-content-center align-items-center">
                    <ul className="list-unstyled mt-3 mb-4 pr-features" style={{ width: "65%", textAlign: "center" }}>
                      {plan.features.map(
                        (feat) =>
                          stringToBoolean(feat.value) && (
                            <li key={feat.id}>
                              <div className="align-items-center mb-1">
                                <div>
                                  <span className="text-white">{feat.name}</span>
                                </div>
                              </div>
                            </li>
                          )
                      )}
                    </ul>
                  </Row>
                  <Row style={{ textAlign: "center", display: "block" }}>
                    {get(plan, ["trialPeriodDays"]) && !showCheckout ? (
                      <Button
                        className="mb-3"
                        color="primary"
                        type="button"
                        style={{ whiteSpace: "normal" }}
                        onClick={() => pickedPlan(plan, true)}
                      >
                        Start {get(plan, ["trialPeriodDays"])} Day Trial
                      </Button>
                    ) : null}
                    {!get(plan, ["trialPeriodDays"]) || showCheckout ? (
                      <Button
                        className="mb-3"
                        color="primary"
                        type="button"
                        style={{ whiteSpace: "normal" }}
                        onClick={() => pickedPlan(plan)}
                      >
                        {showCheckout ? "Checkout" : "Buy Now"}
                      </Button>
                    ) : null}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  } else if (freePlan) {
    return (
      <Card className="card-pricing border-0 text-center pb-4">
        <CardHeader className="bg-transparent">
          <h2 className="font-weight-bold ls-1 py-0 mb-0">Your Plan</h2>
        </CardHeader>
        <CardBody className="px-lg sinapi-plan-container">
          <Row className="mt-4" style={{ width: "100%" }}>
            <Col key={plan.id}>
              <Card className="bg-gradient-success">
                <CardBody>
                  <Row className="justify-content-center align-items-center">
                    <span className="display-3 text-uppercase ls-1 text-white">{plan.name}</span>
                  </Row>
                  <Row className="justify-content-center align-items-center">
                    <ul className="list-unstyled mt-3 mb-4 pr-features" style={{ width: "65%", textAlign: "center" }}>
                      {plan.features.map(
                        (feat) =>
                          stringToBoolean(feat.value) && (
                            <li key={feat.id}>
                              <div className="align-items-center mb-1">
                                <div>
                                  <span className="text-white">{feat.name}</span>
                                </div>
                              </div>
                            </li>
                          )
                      )}
                    </ul>
                  </Row>
                  <Row style={{ textAlign: "center", display: "block" }}>
                    <Button
                      className="mb-3"
                      color="primary"
                      type="button"
                      style={{ whiteSpace: "normal" }}
                      onClick={() => pickedPlan(plan, false, true)}
                    >
                      {showCheckout ? "Checkout" : "Start Free"}
                    </Button>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  } else {
    return (
      <Card className="card-pricing border-0 text-center pb-4">
        <CardHeader className="bg-transparent">
          <h2 className="font-weight-bold ls-1 py-0 mb-0">Pick A Plan</h2>
        </CardHeader>
        <CardBody className="px-lg sinapi-plan-container">
          <Row className="mt-4" style={{ width: "100%" }}>
            {(plans || []).map(
              (pr) =>
                pr.hidden === false && (
                  <Col xs="4" key={pr.id}>
                    {pr.id === "premium" ? (
                      <Card className="bg-gradient-success">
                        <CardBody>
                          <Row className="justify-content-center align-items-center">
                            <span className="h4 text-uppercase ls-1 text-white">{pr.name}</span>
                          </Row>
                          <Row className="justify-content-center align-items-center">
                            <div className="display-2 text-white">
                              {getPriceValueByInterval(pr.price, "mo", "amount")}
                            </div>
                            <span className="text-muted text-white pt-3">&nbsp;/{"mo"}</span>
                          </Row>
                          <Row className="justify-content-center align-items-center">
                            <span className="text-muted text-white small">or&nbsp;&nbsp;</span>
                            <div className="small text-white font-weight-600">
                              {getPriceValueByInterval(pr.price, "yr", "amount")}
                            </div>
                            <span className="text-muted text-white small">&nbsp;/{"yr"}</span>
                          </Row>
                          <Row className="justify-content-center align-items-center">
                            <ul
                              className="list-unstyled mt-3 mb-4 pr-features"
                              style={{ width: "65%", textAlign: "center" }}
                            >
                              {pr.features.map(
                                (feat) =>
                                  stringToBoolean(feat.value) && (
                                    <li key={feat.id}>
                                      <div className="align-items-center mb-1">
                                        <div>
                                          <span className="text-white">{feat.name}</span>
                                        </div>
                                      </div>
                                    </li>
                                  )
                              )}
                            </ul>
                          </Row>
                          <Row style={{ textAlign: "center", display: "block" }}>
                            {get(pr, ["trialPeriodDays"]) && !showCheckout ? (
                              <Button
                                className="mb-3"
                                color="primary"
                                type="button"
                                style={{ whiteSpace: "normal" }}
                                onClick={() => pickedPlan(pr, true)}
                              >
                                Start {get(pr, ["trialPeriodDays"])} Day Trial
                              </Button>
                            ) : null}
                            {!get(pr, ["trialPeriodDays"]) || showCheckout ? (
                              <Button
                                className="mb-3"
                                color="primary"
                                type="button"
                                style={{ whiteSpace: "normal" }}
                                onClick={() => pickedPlan(pr)}
                              >
                                {showCheckout ? "Checkout" : "Buy Now"}
                              </Button>
                            ) : null}
                          </Row>
                        </CardBody>
                      </Card>
                    ) : (
                      <div className="sinapi-pr">
                        <h4 className="text-uppercase ls-1 text-primary py-2 mb-0">{pr.name}</h4>
                        <div className="justify-content-center align-items-center row">
                          <div className="display-2">{getPriceValueByInterval(pr.price, "mo", "amount")}</div>
                          <span className="text-muted pt-3">&nbsp;/{"mo"}</span>
                        </div>
                        <div className="justify-content-center align-items-center row">
                          <span className="text-muted small">or&nbsp;&nbsp;</span>
                          <div className="small font-weight-600">
                            {getPriceValueByInterval(pr.price, "yr", "amount")}
                          </div>
                          <span className="text-muted small">&nbsp;/{"yr"}</span>
                        </div>
                        <div
                          className="justify-content-center align-items-center"
                          style={{ width: "65%", margin: "auto" }}
                        >
                          <ul className="list-unstyled my-4 pr-features">
                            {pr.features.map(
                              (feat) =>
                                stringToBoolean(feat.value) && (
                                  <li key={feat.id}>
                                    <div className="align-items-center mb-1">
                                      <div>
                                        <span>{feat.name}</span>
                                      </div>
                                    </div>
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                        <div className="pr-footer">
                          {get(pr, ["trialPeriodDays"]) && !showCheckout ? (
                            <Button
                              className="mb-3"
                              color="primary"
                              type="button"
                              style={{ whiteSpace: "normal" }}
                              onClick={() => pickedPlan(pr, true)}
                            >
                              Start {get(pr, ["trialPeriodDays"])} Day Trial
                            </Button>
                          ) : null}
                          {!get(pr, ["trialPeriodDays"]) || showCheckout ? (
                            <Button
                              className="mb-3"
                              color="primary"
                              type="button"
                              style={{ whiteSpace: "normal" }}
                              onClick={() => pickedPlan(pr)}
                            >
                              {showCheckout ? "Checkout" : "Buy Now"}
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </Col>
                )
            )}
          </Row>
        </CardBody>
      </Card>
    );
  }
};

PlanSelectionCard.propTypes = {
  plans: PropTypes.array.isRequired,
  onPickedPlan: PropTypes.func.isRequired,
  plan: PropTypes.object,
  showCheckout: PropTypes.bool,
  trialPlan: PropTypes.bool
};

export { PlanSelectionCard };
