import "./Register.css";
import React, { useReducer, useState } from "react";

// nodejs library that concatenates classes
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { PlanSelectionCard, CheckoutCard } from "components/Registration";
import { useCreateSubscription } from "../../components/Stripe/useCreateSubscription";
import { registrationReducer } from "reducers";
import { useDispatch, useSelector } from "react-redux";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";
import { useUrlQuery } from "hooks/useUrlQuery";
import { Redirect } from "react-router-dom";
import { useEffect } from "react";
import { compact, find, findIndex, flatten, get, head, isEmpty, map, update, words } from "lodash";
import { isAgency } from "utils/helperFunctions";

const GET_PRODUCTS = gql`
  {
    currentUser {
      id
      company {
        id
        stripeCustomerId
        subscriptions {
          discount {
            coupon {
              name
              valid
              percent_off
              amount_off
            }
            id
          }
          status
          id
          items {
            id
            plan {
              product
            }
          }
        }
        invoices {
          id
          status
          lines {
            id
            amount
            price {
              id
              type
            }
          }
        }
        paymentMethod {
          id
          card {
            brand
            last4
          }
        }
      }
    }
    plans {
      id
      name
      description
      stripeId
      trialPeriodDays
      hidden
      features {
        id
        name
        value
      }
      price {
        amount
        currency
        interval
        id
        nickname
      }
    }
  }
`;

const GET_FEATURES_OVERWRITE = gql`
  query GetFeaturesOverwrite($companyId: ID!) {
    companyFeatures(companyId: $companyId) {
      featureId
      value
    }
  }
`;

const SubscribeToPlan = ({ isUpgrade }) => {
  const { currentUser } = useSelector((state) => state.user);
  const { data, loading } = useQuery(GET_PRODUCTS, {
    fetchPolicy: "no-cache"
  });
  const { loading: loadFeatures, data: featuresData } = useQuery(GET_FEATURES_OVERWRITE, {
    fetchPolicy: "no-cache",
    variables: { companyId: currentUser.company.id }
  });
  const [state, dispatch] = useReducer(registrationReducer, {
    newUser: currentUser
  });
  const query = useUrlQuery();
  const pickedPlanId = query.get("plan");
  const [priceNoInterval, setPriceNoInterval] = useState();
  const [createSubscription, { data: newSub, loading: creatingSub }] = useCreateSubscription();
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (newSub && !creatingSub) {
      onPaymentSuccess(newSub);
    }
  }, [newSub]);

  useEffect(() => {
    if (data && pickedPlanId && !state.plan) {
      const pickedPlan = pickedPlanId ? data.plans.find((pl) => pl.id === pickedPlanId) : null;
      selectPlan(pickedPlan, false);
      setPriceNoInterval(head(compact(map(pickedPlan.price, (pr) => (pr.interval === "" ? pr : undefined)))));
    }
  }, [data]);

  function selectPlan(plan, isTrial) {
    dispatch({ type: "PICK_PLAN", payload: { plan: plan, isTrial: isTrial } });
    if (isTrial) {
      const priceMonthly = head(compact(map(plan.price, (pr) => (pr.interval === "mo" ? pr : undefined))));
      createSubscription(null, {
        customerId: currentUser.company.stripeCustomerId,
        priceId: priceNoInterval ? [priceMonthly.id, priceNoInterval.id] : [priceMonthly.id],
        isTrial: isTrial,
        trialPeriodDays: get(plan, ["trialPeriodDays"]),
        userId: currentUser.id,
        planId: plan.id
      });
    }
  }

  function onPaymentSuccess(data) {
    reduxDispatch({ type: "REFETCH_USER" });
    dispatch({ type: "PAYMENT_SUCCESS", payload: data });
  }

  const compInvoices = data && !loading ? data.currentUser.company.invoices : null;
  const compInvoicesLines = flatten(map(compInvoices, (invoice) => invoice.lines));
  const setupCost = find(compInvoicesLines, (line) => get(line, ["price", "type"]) === "one_time");

  const compSubscriptions = data && !loading ? data.currentUser.company.subscriptions : null;
  const compSubscription = compSubscriptions && compSubscriptions.length > 0 ? compSubscriptions[0] : null;
  const onTrial = compSubscription && compSubscription.status === "trialing";
  const isPastDue = compSubscription && compSubscription.status === "past_due";
  const freePlan =
    data && !loading
      ? data.currentUser.company && isEmpty(data.currentUser.company.stripeCustomerId)
        ? true
        : false
      : null;
  const compProduct = compSubscription
    ? data.plans.find((pr) => pr.stripeId === compSubscription.items[0].plan.product)
    : null;

  if (!loadFeatures && featuresData && featuresData.companyFeatures) {
    featuresData.companyFeatures.map((feature) => {
      const product = compProduct && compProduct.features.find((pr) => pr.id === feature.featureId);
      const productNameWords = words(product?.name);
      const idx = findIndex(productNameWords, (val) => product.value === val);

      if (parseInt(feature.value) > 1)
        update(product, "name", (fea) => fea.replace(productNameWords[idx + 1], productNameWords[idx + 1].concat("s")));
      update(product, "name", (fea) => fea.replace(product.value, feature.value));
      update(product, "value", () => feature.value);
      return product;
    });
  }

  if (state.subscription) {
    return (
      <Redirect
        to={{
          pathname: "/admin/dashboard",
          state: {
            popup: {
              title: isUpgrade ? "Congrats!" : "Welcome!",
              description: isUpgrade
                ? "Successfully upgraded your plan, payment went through!"
                : "Welcome, payment successfully went through!"
            }
          }
        }}
      />
    );
  }

  return (
    <>
      {!isUpgrade ? (
        <AuthHeader
          logo={isAgency ? null : require("assets/img/brand/sinapi-logo-white.svg")}
          loaded={isAgency ? false : true}
          title="Create Account"
          lead="Continuation of account setup."
          size="normal"
        />
      ) : null}
      {isUpgrade && !isPastDue ? (
        <AuthHeader
          logo={isAgency ? null : require("assets/img/brand/sinapi-logo-white.svg")}
          loaded={isAgency ? false : true}
          title="Upgrade Account"
          lead=""
          size="normal"
        />
      ) : null}
      {isPastDue ? (
        <AuthHeader
          logo={isAgency ? null : require("assets/img/brand/sinapi-logo-white.svg")}
          loaded={isAgency ? false : true}
          title="Activate Subscription"
          lead=""
          size="normal"
        />
      ) : null}
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col xs={(isPastDue || onTrial) && !state.plan ? "6" : "10"}>
            {data && !loading && !state.plan && state.newUser && !pickedPlanId ? (
              <PlanSelectionCard
                plans={data.plans}
                onPickedPlan={selectPlan}
                showCheckout={isUpgrade ? true : false}
                plan={isUpgrade ? compProduct : null}
                trialPlan={isPastDue || onTrial}
              />
            ) : null}
            {state.isTrial && state.plan && !state.subscription ? <LoaderSpinner /> : null}
            {state.plan && !state.subscription && !state.isTrial ? (
              <CheckoutCard
                plan={state.plan}
                onSuccess={onPaymentSuccess}
                paymentMethod={
                  data.currentUser && data.currentUser.company ? data.currentUser.company.paymentMethod : null
                }
                user={state.newUser}
                isTrial={state.isTrial}
                isPastDue={isPastDue}
                isFreePlan={freePlan}
                onTrial={onTrial}
                setupCost={setupCost}
                isAnUpgrade={pickedPlanId ? true : false}
                subscription={compSubscription}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SubscribeToPlan;
