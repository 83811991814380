export const inputStyles = [
  {
    title: "Input Corner Radius",
    description: "",
    type: "number",
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .form-control", "borderRadius"],
    col: 6
  },
  {
    title: "Input Font Size",
    description: "",
    type: "number",
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .form-control", "fontSize"],
    col: 6
  },
  {
    type: "header",
    header: "Icon/Image Options"
  },
  {
    title: "Corner Radius",
    description: "",
    type: "number",
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .image-option", "borderRadius"],
    col: 6
  },
  {
    title: "Background",
    description: "",
    type: "color-picker",
    cssPath: ["& .image-option", "backgroundColor"],
    col: 6
  },
  {
    title: "Font Size",
    description: "",
    type: "number",
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .image-option", "& .image-option-label", "fontSize"],
    col: 6
  },
  {
    title: "Font Color",
    description: "",
    type: "color-picker",
    cssPath: ["& .image-option", "& .image-option-label", "color"],
    col: 6
  },
  {
    title: "Border Size",
    description: "",
    type: "number",
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .image-option", "borderWidth"],
    col: 6,
    customUpdates: (val) => {
      if (val && val.length > 0) {
        return [
          {
            value: "solid",
            path: ["& .image-option", "borderStyle"]
          },
          {
            value: val,
            path: ["& .image-option", "borderWidth"]
          },
          {
            value: val,
            path: ["& .image-option-selected", "borderWidth"]
          }
        ];
      }
      return [];
    }
  },
  {
    title: "Border Color",
    description: "",
    type: "color-picker",
    cssPath: ["& .image-option", "borderColor"],
    col: 6,
    parseValueIn: (val) => (val ? val : "rgba(255,255,255,0)"),
  },
  {
    title: "Padding (Top/Bottom)",
    description: "",
    type: "number",
    suffix: "px",
    prefix: "fas fa-arrows-alt-v",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .image-option", "paddingTop"],
    customUpdates: (val) => {
      if (val && val.length > 0) {
        return [
          {
            value: val,
            path: ["& .image-option", "paddingBottom"]
          },
          {
            value: val,
            path: ["& .image-option", "paddingTop"]
          }
        ];
      }
      return [];
    },
    col: 6
  },
  {
    title: "Padding (Left/Right)",
    description: "",
    type: "number",
    suffix: "px",
    prefix: "fas fa-arrows-alt-h",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .image-option", "paddingRight"],
    customUpdates: (val) => {
      if (val && val.length > 0) {
        return [
          {
            value: val,
            path: ["& .image-option", "paddingRight"]
          },
          {
            value: val,
            path: ["& .image-option", "paddingLeft"]
          }
        ];
      }
      return [];
    },
    col: 6
  },
  {
    title: "Space between options",
    description: "",
    type: "number",
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .image-option", "marginRight"],
    col: 6
  },
  {
    type: "sub-header",
    title: "Selected Styles"
  },
  {
    title: "Background",
    description: "",
    type: "color-picker",
    cssPath: ["& .image-option-selected", "backgroundColor"]
  },
  {
    title: "Font Color",
    description: "",
    type: "color-picker",
    cssPath: ["& .image-option-selected", "& .image-option-label-selected", "color"]
  },
  {
    title: "Border Color",
    description: "",
    type: "color-picker",
    cssPath: ["& .image-option-selected", "borderColor"],
    col: 6
  }
];
