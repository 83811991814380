import React from "react";
import { getUniqueID } from "./HelperFunctions";
import { FieldLabel } from "./FieldLabel";
import { optionWithIcon } from "./fieldUtils";

const TextBlock = (props) => {
  return <FieldLabel question={props.question} />;
};

const PreviewFormText = () => optionWithIcon("fas fa-italic", "Text block");

const getInitialData = () => ({
  id: getUniqueID(),
  description: "<p>Text block example</p>",
  required: false,
  type: "textblock"
});

const fieldConfig = {
  key: "textblock",
  component: TextBlock,
  getInitialData: getInitialData,
  previewComponent: PreviewFormText
};

export default fieldConfig;
