import React from "react";
import { Row, Col, Button, Container } from "reactstrap";
import { FormSection } from "./FormSection";
import { FormSuccessPageSection } from "./FormSuccessPageSection";
import { SinapiDragDropList } from "components/DragAndDrop";
import { useDispatch } from "react-redux";
import { formActions } from "reducers";
import { useTheme, createUseStyles } from "react-jss";
import { get } from "lodash";
import { getDummyQuotedJSON } from "views/pages/forms/defaultFormData";

// Creating a namespaced theming object.
let useStyles = createUseStyles((data) => {
  const { fontImport, ...styles } = data;
  return {
    "@import": fontImport || [],
    sinapiForm: {
      ...styles
    }
  };
});
/**
 * Render all the sections within a form
 * @param {object} props
 */
function FormContainer({ schema, formType, onCreateSection }) {
  const cssTheme = useTheme();
  const classes = useStyles({ theme: cssTheme });
  const dispatch = useDispatch();
  if (!schema || !schema.sections) {
    return null;
  }

  const onDrop = (item, index) => {
    if (item.parentDropAreaId) {
      dispatch({
        type: formActions.MOVE_SECTION,
        payload: {
          sectionId: item.name,
          to: index
        }
      });
    }
  };

  const onSuccessPageEdit = () => {
    dispatch({
      type: formActions.EDIT_SUCCESS_SECTION
    });
  };
  const maxWidth = get(schema, ["options", "width"], 700);

  return (
    <Container
      fluid
      className={"section-container " + classes.sinapiForm}
      style={{ height: "100%", backgroundSize: "cover" }}
    >
      <div
        className={"centered-form mt-5"}
        style={{
          maxWidth: maxWidth + "px"
        }}
      >
        <SinapiDragDropList
          accept={["section"]}
          items={schema.sections || []}
          onDrop={(item, index) => onDrop(item, index)}
          id={"dropareasection"}
          customDropArea={<div className="section-drop-area">Drop Section Here</div>}
        >
          {(sec, index) => (
            <div key={sec.id}>
              <Row key={sec.id}>
                <Col lg="12">
                  <div className="tab-content form-section-content" id="newForm-wrapper">
                    <FormSection section={sec} hasNext={index !== schema.sections.length} index={index} />
                  </div>
                </Col>
              </Row>
              <Row className="my-3 add-new-section-row">
                <Col>
                  <Button color="primary" onClick={() => onCreateSection(index)}>
                    Add Section
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </SinapiDragDropList>
        <Row>
          <Col lg="12">
            <div className="tab-content form-section-content">
              <FormSuccessPageSection
                successPageData={schema.success ? schema.success : {}}
                onManagePage={onSuccessPageEdit}
                formType={formType}
                cost={{ low: 0, expected: 0, high: 0 }}
                maxWidth={maxWidth}
                quoteJSON={getDummyQuotedJSON()}
                sampleContainer={true}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export { FormContainer };
