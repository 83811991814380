import "./UserRegistrationCard.css";
import React, { useEffect, useState } from "react";

// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";
import { Formik, Form, Field } from "formik";
import { isAgency } from "utils/helperFunctions";
import Label from "reactstrap/lib/Label";
import { gql } from "apollo-boost-upload";
import { useQuery } from "@apollo/react-hooks";
import { AWS_S3_ASSETS_BUCKET } from "utils/constants";

const specialCharRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
const PasswordRequirements = {
  passLength: { label: "At least 8 characters in length", test: (password) => (password || "").length > 7 },
  lowercase: { label: "Lower case letters (a-z)", test: (password) => /[a-z]/.test(password || "") },
  uppercase: { label: "Upper case letters (A-Z)", test: (password) => /[A-Z]/.test(password || "") },
  numbers: { label: "Numbers (i.e. 0-9)", test: (password) => /[0-9]/.test(password || "") },
  specialCharacters: {
    label: "Special characters (e.g. !@#$%^&():;*)",
    test: (password) => specialCharRegex.test(password || "")
  }
};
const FIELDS = ["companyName", "customWorkspace", "email", "firstName", "lastName", "password"];
function getInitialValues(orgUser) {
  return FIELDS.reduce((init, key) => ({ ...init, [key]: orgUser ? orgUser[key] || "" : "" }), {});
}

const GET_COMPANY_LOGO = gql`
  query companyLogo($domain: String!, $subdomain: String) {
    companyLogo(domain: $domain, subdomain: $subdomain)
  }
`;

const UserRegistrationCard = ({ onSignup, onConnectionSignup, signupErrorMsg, isInvite, inviteEmail, pickedPlan }) => {
  const [signupLoading, setSignupLoading] = useState(false);
  const [signupData, setSignupData] = useState(false);
  const [focusField, setFocusField] = useState("");
  const [terms, setTerms] = useState(false);

  const splitedHost = window.location.host.split(".");
  const [logo, setLogo] = useState();
  const { loading, data } = useQuery(GET_COMPANY_LOGO, {
    variables: {
      subdomain: splitedHost[0],
      domain: `${splitedHost[splitedHost.length - 2]}.${splitedHost[splitedHost.length - 1]}`
    }
  });

  useEffect(() => {
    if (!loading && data) {
      setLogo(
        isAgency
          ? data.companyLogo !== ""
            ? `${AWS_S3_ASSETS_BUCKET}/${data.companyLogo}`
            : null
          : require("assets/img/brand/sinapi-logo.svg")
      );
    }
  }, [loading, data]);

  function connectionSignup(type) {
    if (onConnectionSignup) {
      onConnectionSignup(type);
    }
  }

  function handleSubmit(data) {
    if (!onSignup) {
      return;
    }
    onSignup({
      ...data
    });
  }

  return (
    <Card className="bg-secondary border-0 user-registration-card" style={{ width: "60%", margin: "auto" }}>
      {/* {isInvite ? null : (
        <CardHeader className="bg-transparent">
          <div className="text-muted text-center mt-2 mb-4">
            <small>Sign up with</small>
          </div>
          <div className="text-center">
            <Button
              className="btn-neutral btn-icon"
              color="default"
              href="#pablo"
              disabled={!!signupErrorMsg}
              onClick={(e) => {
                e.preventDefault();
                connectionSignup("google-oauth2");
              }}
            >
              <span className="btn-inner--icon mr-1">
                <img alt="..." src={require("assets/img/icons/common/google.svg")} />
              </span>
              <span className="btn-inner--text">Google</span>
            </Button>
          </div>
        </CardHeader>
      )} */}
      <CardHeader className="bg-transparent">
        <img src={logo} alt="" style={{ maxWidth: `205px`, margin: "auto", display: "block" }} />
      </CardHeader>
      <CardBody className="px-lg-5 py-lg-3">
        <br></br>
        {signupErrorMsg ? <div className="text-center text-muted mb-4">{signupErrorMsg}</div> : null}
        {!signupData && !signupErrorMsg ? (
          <>
            {/* <div className="text-center text-muted mb-4">
              {isInvite ? <small>Sign up with credentials</small> : <small>Or sign up with credentials</small>}
            </div> */}
            <Formik
              initialValues={{ ...getInitialValues({ email: inviteEmail || "" }) }}
              enableReinitialize={true}
              validateForm={(values) => {
                const errors = FIELDS.reduce((errors, field) => {
                  if (!values[field]) {
                    errors[field] = field + " is required!";
                  }
                }, {});
                return errors;
              }}
              validateOnMount={true}
              onSubmit={(values, actions) => {
                handleSubmit(values);
              }}
            >
              {(formikProps) => {
                return (
                  <Form>
                    {isInvite ? null : (
                      <FormGroup
                        className={classnames({
                          focused: focusField === "company"
                        })}
                      >
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-building" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Field
                            name="companyName"
                            placeholder="Company Name"
                            className="form-control"
                            onFocus={() => setFocusField("company")}
                            onBlur={() => setFocusField("")}
                          />
                        </InputGroup>
                      </FormGroup>
                    )}
                    {pickedPlan && pickedPlan.customWorkspace ? (
                      <FormGroup
                        className={classnames({
                          focused: focusField === "customWorkspace"
                        })}
                      >
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-building" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Field
                            name="customWorkspace"
                            placeholder="Workspace Name"
                            className="form-control"
                            onFocus={() => setFocusField("customWorkspace")}
                            onBlur={() => setFocusField("")}
                          />
                        </InputGroup>
                      </FormGroup>
                    ) : null}
                    <FormGroup
                      className={classnames({
                        focused: focusField === "firstName"
                      })}
                    >
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-hat-3" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Field
                          name="firstName"
                          placeholder="First Name"
                          className="form-control"
                          onFocus={() => setFocusField("firstName")}
                          onBlur={() => setFocusField("")}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: focusField === "lastName"
                      })}
                    >
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-hat-3" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Field
                          name="lastName"
                          placeholder="Last Name"
                          className="form-control"
                          onFocus={() => setFocusField("lastName")}
                          onBlur={() => setFocusField("")}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: focusField === "email"
                      })}
                    >
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Field
                          name="email"
                          placeholder="Email"
                          readOnly={isInvite}
                          className="form-control"
                          onFocus={() => setFocusField("email")}
                          onBlur={() => setFocusField("")}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: focusField === "password"
                      })}
                    >
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Field
                          name="password"
                          placeholder="Password"
                          className="form-control"
                          type="password"
                          onFocus={() => setFocusField("password")}
                          onBlur={() => setFocusField("")}
                          validate={(val) => {
                            const passwordErrors = Object.keys(PasswordRequirements).reduce((req, key) => {
                              if (!PasswordRequirements[key].test(val)) {
                                return {
                                  ...req,
                                  [key]: false
                                };
                              }
                              return req;
                            }, {});
                            if (Object.keys(passwordErrors).length === 0) {
                              return undefined;
                            }
                            return passwordErrors;
                          }}
                        />
                      </InputGroup>
                      <InputGroup>
                        <div className="custom-control custom-checkbox mt-3">
                          <Field
                            className="custom-control-input"
                            id="terms"
                            name="terms"
                            type="checkbox"
                            onChange={() => setTerms(!terms)}
                            checked={terms}
                          />
                          <Label
                            className="custom-control-label pt-1"
                            style={{ fontSize: "12px", color: "#adb5bd" }}
                            htmlFor={`terms`}
                          >
                            I accept the Sinapi{" "}
                            <a
                              className="text-info"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://sinapi.io/terms/"
                            >
                              Term of Use
                            </a>
                          </Label>
                        </div>
                      </InputGroup>
                      <ul className="password-requirements mt-3">
                        {Object.keys(PasswordRequirements).map((key) => {
                          const passwordValidity = formikProps.errors.password || {};
                          const isValidRequirement = passwordValidity[key] !== false;
                          return (
                            !isValidRequirement && (
                              <li key={key} className={`requirement-item`}>
                                {isValidRequirement ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
                                {PasswordRequirements[key].label}
                              </li>
                            )
                          );
                        })}
                      </ul>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="mt-4"
                        color="info"
                        type="submit"
                        style={{ opacity: !formikProps.isValid || signupLoading || !terms ? 0.3 : 1 }}
                        disabled={!formikProps.isValid || signupLoading || !terms}
                      >
                        Create account
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        ) : null}
      </CardBody>
    </Card>
  );
};

export { UserRegistrationCard };
