import React, { useRef } from "react";
import { useDrop } from "react-dnd";
import { useSinapiDragAndDrop } from "./SinapiDragAndDropProvider";

/**
 * Simple wrapper turning it's child element into a drop area, this hooks in with the SinapiDragAndDropProvider, in
 * order to know when an item has been dropped.
 * @param {object} props
 */
export function SinapiDropAreaWrapper({ children, accept, index, onDrop, dropAreaId, style, isWrapper }) {
  const sinapiDnDContext = useSinapiDragAndDrop();
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: accept,
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        onDrop(item, index);
      }
    },
    hover: (item, monitor) => {
      if (monitor.canDrop()) {
        if (isWrapper) {
          if (monitor.isOver({ shallow: true })) {
            //console.log("isWrapper " + index, " hovering over: " + monitor.isOver({ shallow: true }));
            return;
          }
          const lastChildBoundingRect = ref.current?.lastChild?.getBoundingClientRect();
          const clientOffset = monitor.getClientOffset();
          if (clientOffset.y <= lastChildBoundingRect.bottom) {
            return;
          }
        }
        sinapiDnDContext.onHover(ref, item, monitor, index, dropAreaId);
      }
    }
  });

  drop(ref);
  return (
    <div ref={ref} style={style} className="sinapi-drop-area">
      {children}
    </div>
  );
}
