import React from "react";
import { get } from "lodash";
// react library for routing
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";

function ManagementModal({ children, onClose, header, buttons, modalSize, hideFooter, classNames, styles, customClose }) {
  const toggle = onClose ? () => onClose() : undefined;
  return (
    <Modal
      isOpen={true}
      size={modalSize || "xl"}
      className={`management-modal ${get(classNames, "modal")}`}
      style={get(styles, "modal")}
    >
      {header ? (
        <ModalHeader toggle={toggle} className={get(classNames, "header")} style={{...get(styles, "header")}} close={customClose && customClose}>
          {header}
        </ModalHeader>
      ) : null}
      <ModalBody className={get(classNames, "body")}>{children}</ModalBody>
      {!hideFooter ? (
        <ModalFooter className={get(classNames, "footer")}>
          {!buttons ? <Button onClick={() => onClose()}>Close</Button> : null}
          {buttons
            ? buttons.map(
                (button) =>
                  get(button, "visible", true) && (
                    <Button
                      key={button.label}
                      size={button.size || "lg"}
                      outline={button.outline}
                      color={button.color || "primary"}
                      disabled={button.disabled || false}
                      onClick={(e) => {
                        e.preventDefault();
                        if (button.onClick) {
                          button.onClick();
                        }
                      }}
                      className={button.className}
                    >
                      <i className={button.icon} />
                      {` `}
                      {button.label}
                    </Button>
                  )
              )
            : null}
        </ModalFooter>
      ) : null}
    </Modal>
  );
}

ManagementModal.defaultProps = {};

ManagementModal.propTypes = {
  onClose: PropTypes.func,
  header: PropTypes.object,
  modalSize: PropTypes.oneOf(["sm", "md", "lg"]), // defaults to xl
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      size: PropTypes.string,
      label: PropTypes.string,
      outline: PropTypes.bool,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      icon: PropTypes.string,
      className: PropTypes.string
    })
  ),
  hideFooter: PropTypes.bool
};

export default ManagementModal;
