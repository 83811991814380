import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { get } from "lodash";

export function FieldLabel({ question }) {
  const { title, description, dynamicRules, type, notIcon = false } = question;
  const active = get(dynamicRules, "active", false);

  const descriptionHtml = {
    __html: description && description !== "<p><br></p>" ? description : undefined
  };

  return (
    <label className="form-control-label sinapi-field-label">
      {title && type !== "headline" && (
        <div className="form-inline label-headline">
          {title}
          {active && !notIcon && (
            <div>
              &nbsp;
              <i className="fas fa-bolt fa-xs" id="dynamic-rule" style={{ color: "darkgrey" }} />
              <UncontrolledTooltip placement="right" target="dynamic-rule">
                Dynamic logic applied
              </UncontrolledTooltip>
            </div>
          )}
        </div>
      )}
      {descriptionHtml && (
        <div className="rich-text-editor-description text-muted label-sub-headline" dangerouslySetInnerHTML={descriptionHtml}></div>
      )}
    </label>
  );
}
