import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { formActions } from "reducers";

import { gql } from "apollo-boost";
import { Button, Input } from "reactstrap";
import { useMutation } from "@apollo/react-hooks";

import { FieldLabel } from "./FieldLabel";
import { getUniqueID } from "./HelperFunctions";

const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!, $userId: ID!, $prevId: ID) {
    uploadFile(file: $file, user_id: $userId, prev_id: $prevId) {
      id
      location
    }
  }
`;

const REMOVE_ASSET = gql`
  mutation RemoveAsset($asset_id: ID!) {
    removeAsset(asset_id: $asset_id)
  }
`;

const FileField = (props) => {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const { placeholder = "", userId, prevId, parentPath } = props.question;

  const [uploadedFile, setUploadedFile] = useState();
  const [showErrorMsg, setShowErrorMsg] = useState();
  const [uploadFile] = useMutation(UPLOAD_FILE, {
    onCompleted: (response) => {
      setUploadedFile({ id: response.uploadFile.id, location: response.uploadFile.location });
    }
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileSize = Math.round(file.size / 1024);
    if (fileSize > 1024) {
      setShowErrorMsg("Your image must be smaller than 1 mb to upload");
      return;
    }

    uploadFile({ variables: { file, userId, prevId } });
  };

  useEffect(() => {
    if (uploadedFile) {
      dispatch({
        type: formActions.EDIT_THEME_UPDATE,
        payload: {
          updates: [
            {
              path: [...parentPath, "backgroundImage"],
              value: "url('" + uploadedFile.location + "')"
            },
            {
              path: ["uploadedImageID"],
              value: uploadedFile.id
            }
          ]
        }
      });
    }
  }, [uploadedFile]);

  useEffect(() => {
    inputRef.current.value = props.question.value;
  }, [props.question.value]);

  const [removeAsset] = useMutation(REMOVE_ASSET, {
    onCompleted: () =>
      dispatch({
        type: formActions.EDIT_THEME_UPDATE,
        payload: {
          updates: [
            {
              path: ["backgroundImage"],
              value: undefined
            },
            {
              path: ["uploadedImageID"],
              value: undefined
            }
          ]
        }
      })
  });

  const handleFileDelete = (asset_id) => {
    removeAsset({ variables: { asset_id: asset_id } });
  };

  return (
    <>
      <FieldLabel question={props.question} />
      <Input
        className="form-control-file"
        placeholder={placeholder}
        type="File"
        onChange={handleFileChange}
        ref={inputRef}
      />
      {props.question.fileID && (
        <>
          <small className="text-muted">
            Actual file:{" "}
            <img
              alt=""
              className="img-thumbnail"
              style={{ height: "50px", marginTop: "10px" }}
              src={props.question.value}
            />
          </small>
          <Button color="link" style={{ paddingLeft: "10px" }} onClick={() => handleFileDelete(props.question.fileID)}>
            <i className="fas fa-trash"></i>
          </Button>
        </>
      )}
      {showErrorMsg && (
        <div className="alert-header-bar alert alert-warning fade show alert-dismissible mt-3">
          <span className="ml--2 mt--2" style={{ position: "absolute" }}>
            {showErrorMsg}
          </span>
          <button
            type="button"
            className="close mr--3 mt-1"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowErrorMsg()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
    </>
  );
};

const getInitialData = () => ({
  id: getUniqueID(),
  title: "File",
  description: "Upload a file",
  type: "file"
});

const fieldConfig = {
  key: "file",
  hidden: true,
  component: FileField,
  getInitialData: getInitialData
};

export default fieldConfig;
