import React, { useEffect, useRef, useState } from "react";
import { useTheme, createUseStyles } from "react-jss";

import { get } from "lodash";
import classnames from "classnames";

import { Button, Carousel, CarouselItem, Col, Row, Container } from "reactstrap";

import { RenderFormSection } from "components/FormComponents/RenderFormSection";
import { checkErrorsMSG } from "views/pages/forms/render";

// Creating a namespaced theming object.
let useStyles = createUseStyles((data) => {
  const { fontImport, ...styles } = data;
  return {
    "@import": fontImport || [],
    sinapiForm: {
      ...styles
    }
  };
});

/**
 * Render all the sections within a form
 * @param {object} props
 */
function RenderFormContainer({
  schema,
  sections,
  activeSection,
  saveLeadAction,
  validateForm,
  notificationAlert,
  notificationOptions,
  isNotEmptySection,
  canceledSubscription,
  formError,
  setFormError
}) {
  const cssTheme = useTheme();
  const classes = useStyles({ theme: cssTheme });
  const [activeIndex, setActiveIndex] = useState(activeSection);

  const myRef = useRef(null);

  const scrollToTop = () => {
    document.getElementById("scrollable-container").scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (activeIndex > 0) scrollToTop();
  }, [activeIndex]);

  if (!schema || !schema.sections) {
    return null;
  }

  const typeForm = get(schema, ["options", "type"], "single-page");
  const maxWidth = get(schema, ["options", "width"], 700);

  const checkingRequired = (index) => {
    const hasErrors = validateForm(sections[activeIndex].id);
    if (hasErrors.length === 0) {
      setActiveIndex(index);
    } else {
      if (checkErrorsMSG(notificationAlert)) {
        notificationAlert.current.notificationAlert(
          notificationOptions(
            `Please verify the required fields: ${hasErrors.join(", ")}`,
            "danger",
            "fas fa-exclamation-triangle"
          )
        );
      }
    }
  };

  const next = () => {
    const nextIndex = activeIndex === sections.length - 1 ? 0 : activeIndex + 1;
    checkingRequired(nextIndex);
  };

  const previous = () => {
    const prevIndex = activeIndex === 0 ? sections.length - 1 : activeIndex - 1;
    setActiveIndex(prevIndex);
    scrollToTop();
  };

  const goToIndex = (newIndex) => checkingRequired(newIndex);
  
  return canceledSubscription ? (
    <Container
      id="scrollable-container"
      fluid
      className={"section-container mobile-remove-padding " + classes.sinapiForm}
      style={{ width: "100%", height: "100%", backgroundSize: "cover" }}
    >
      <div className={"centered-form mt-4"} style={{ maxWidth: maxWidth + "px" }}>
        <div className="card">
          <div className="card-body" style={{ padding: window.innerWidth < 768 ? "15px" : "2.5rem" }}>
            <h1 className="text-center sinapi-form-headline">Thanks for your interest!</h1>
            <p className="text-center sinapi-form-sub-headline">
              We are unable to provide an estimate at this time, but please reach out to our team and we'll be happy to
              help in any way we can.
            </p>
          </div>
        </div>
      </div>
    </Container>
  ) : (
    <Container
      id="scrollable-container"
      fluid
      className={"section-container mobile-remove-padding " + classes.sinapiForm}
      style={{ width: "100%", height: "100%", backgroundSize: "cover" }}
    >
      <div
        className={"centered-form mobile-remove-mt mt-4"}
        style={{
          maxWidth: maxWidth + "px"
        }}
      >
        {typeForm === "single-page" ? (
          sections.map(
            (sec) =>
              isNotEmptySection(sec) && (
                <div key={`container-single-page-${sec.id}`}>
                  <RenderFormSection section={sec} formError={formError} setFormError={setFormError} />
                  <div className="card space-separator" />
                </div>
              )
          )
        ) : (
          <div ref={myRef} key="container-multi-step" id="container-multi-step" className="mobile-remove-mt my-2">
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              interval={false}
              slide={false}
              className="carousel-fade"
              keyboard={false}
            >
              {sections.map(
                (sec) =>
                  isNotEmptySection(sec) && (
                    <CarouselItem key={sec.id}>
                      <RenderFormSection
                        section={sec}
                        formError={formError}
                        setFormError={setFormError}
                        // style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
                        activeIndex={activeIndex}
                        typeForm={typeForm}
                        previous={previous}
                        next={next}
                        sections={sections}
                        saveLeadAction={saveLeadAction}
                      />
                    </CarouselItem>
                  )
              )}
            </Carousel>
          </div>
        )}
        {typeForm !== "single-page" && sections.length > 1 && (
          <div className="mt-4 mb-4">
            <ol className="carousel-indicators carousel-indicators--round" style={{ position: "static" }}>
              {sections.map(
                (sec, index) =>
                  isNotEmptySection(sec) && (
                    <li
                      key={`carousel-indicators-${sec.id}-${index}`}
                      onClick={() => goToIndex(index)}
                      className={classnames({ active: activeIndex === index })}
                    />
                  )
              )}
            </ol>
          </div>
        )}
        {typeForm === "single-page" && (
          <Row className="pb-5">
            <Col style={{ textAlign: "center" }}>
              <Button
                outline
                type="button"
                color="primary"
                onClick={() => saveLeadAction()}
                size="lg"
                disabled={formError}
              >
                Complete
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
}

export { RenderFormContainer };
