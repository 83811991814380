import React, { useState, useEffect } from "react";
// react library for routing
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { FormGroup, Button, Row, Col } from "reactstrap";
import { Field, Formik } from "formik";
import { gql } from "apollo-boost";
import ManagementModal from "./ManagementModal";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import * as yup from "yup";

const GET_WORKSPACES = gql`
  query getUsers($companyId: ID!) {
    company(id: $companyId) {
      name
      workspaces {
        count
        items {
          id
          name
        }
      }
    }
    roles {
      name
      code
    }
  }
`;

const INVITE_NEW_USER = gql`
  mutation inviteNewUserToCompany($newUser: UserInviteInput!, $whiteLabel: Boolean) {
    inviteNewUser(newUser: $newUser, whiteLabel: $whiteLabel) {
      id
      email
    }
  }
`;

const newUserValidation = yup.object().shape({
  emailAddress: yup.string().required(),
  role: yup.string().required(),
  workspace: yup.string().required()
});

function InviteNewUserModal({ companyId, hasWhiteLabel, onClose }) {
  const { loading, data } = useQuery(GET_WORKSPACES, {
    fetchPolicy: "no-cache",
    variables: {
      companyId: companyId
    }
  });
  const [success, setSuccess] = useState(null);
  const [inviteUserMutation, { loading: creatingNewUser, data: newUserData, error: newUserError }] = useMutation(
    INVITE_NEW_USER
  );

  useEffect(() => {
    if (!creatingNewUser && newUserData) {
      setSuccess(newUserData.inviteNewUser);
    }
  }, [newUserData]);

  let newUserErrorMsg;
  if (newUserError && newUserError.graphQLErrors && newUserError.graphQLErrors.length > 0) {
    newUserErrorMsg = newUserError.graphQLErrors[0].message;
  }
  if (newUserError) {
    return (
      <ManagementModal
        modalSize="sm"
        onClose={() => onClose(true)}
        header={<div>Unsuccessful inviting new user!</div>}
        buttons={[{ label: "Ok", onClick: () => onClose(true) }]}
      >
        <span>
          Failed inviting new user with error: <br />
          {newUserErrorMsg}
        </span>
      </ManagementModal>
    );
  }

  if (success) {
    return (
      <ManagementModal
        modalSize="sm"
        onClose={() => onClose(true)}
        header={<div>Sucess!</div>}
        buttons={[{ label: "Ok", onClick: () => onClose(true) }]}
      >
        <span>Successfully invited new user, and send email to: {success.email}</span>
      </ManagementModal>
    );
  }

  return (
    <ManagementModal
      modalSize="md"
      onClose={() => onClose()}
      header={<div>Invite User to {data ? data.company.name : ""}</div>}
      hideFooter={true}
    >
      {loading ? <LoaderSpinner /> : null}
      <Formik
        initialValues={{ emailAddress: "", role: "", workspace: "" }}
        validationSchema={newUserValidation}
        onSubmit={(values, actions) => {
          inviteUserMutation({
            variables: {
              newUser: {
                email: values.emailAddress,
                roleId: values.role,
                workspaceId: values.workspace,
                companyId: companyId
              },
              whiteLabel: hasWhiteLabel
            }
          });
        }}
      >
        {({ errors, touched, ...formikProps }) => (
          <>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-address">
                Email Address
              </label>
              <Field name="emailAddress" id="input-address" className="form-control" />
              {errors.emailAddress && touched.emailAddress && <div className="text-danger">{errors.emailAddress}</div>}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-address">
                Role
              </label>
              <Field as="select" name="role" className="form-control">
                <option name="-" value="">
                  -
                </option>
                {data && data.roles
                  ? data.roles.map((role) => (
                      <option key={role.code} value={role.code}>
                        {role.name}
                      </option>
                    ))
                  : null}
              </Field>
              {errors.role && touched.role && <div className="text-danger">{errors.role}</div>}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-address">
                Workspace
              </label>
              <Field as="select" name="workspace" className="form-control">
                <option name="-" value="">
                  -
                </option>
                {data && data.company && data.company.workspaces
                  ? data.company.workspaces.items.map((work) => (
                      <option key={work.id} value={work.id}>
                        {work.name}
                      </option>
                    ))
                  : null}
              </Field>
              {errors.workspace && touched.workspace && <div className="text-danger">{errors.workspace}</div>}
            </FormGroup>
            <Row>
              <Col sm="12" className="text-right">
                <Button
                  size="lg"
                  outline
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="lg"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    formikProps.validateForm().then((errors) => {
                      if (Object.keys(errors).length === 0) {
                        formikProps.submitForm();
                      }
                    });
                  }}
                >
                  Invite User
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </ManagementModal>
  );
}

InviteNewUserModal.defaultProps = {};

InviteNewUserModal.propTypes = {
  companyId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default InviteNewUserModal;
