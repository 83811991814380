export const sectionStyles = [
  {
    header: "Card Body",
    title: "Corner Radius",
    description: "",
    type: "number",
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .card-body", "borderTopRightRadius"],
    customUpdates: (val) => {
      if (val && val.length > 0) {
        return [
          {
            value: val,
            path: ["& .card-body", "borderTopRightRadius"]
          },
          {
            value: val,
            path: ["& .card-body", "borderTopLeftRadius"]
          },
          {
            value: val,
            path: ["& .card-footer", "borderBottomRightRadius"]
          },
          {
            value: val,
            path: ["& .card-footer", "borderBottomLeftRadius"]
          }
        ];
      }
      return [];
    }
  },
  {
    title: "Background",
    description: "",
    type: "color-picker",
    cssPath: ["& .card-body", "backgroundColor"]
  },
  {
    title: "Drop Shadow",
    description: "",
    type: "checkbox",
    options: [
      {
        id: "showDropShadow",
        name: "Show Drop Shadow"
      }
    ],
    parseValueIn: (val) => (val && val !== "none" ? "showDropShadow" : []),
    parseValueOut: (val) =>
      val && val.includes("showDropShadow") ? "0 0 11px 1px rgba(136, 152, 170, 0.35) !important" : "none",
    cssPath: ["& .card-body", "boxShadow"]
  },
  {
    title: "Padding (Top/Bottom)",
    description: "",
    type: "number",
    suffix: "px",
    prefix: "fas fa-arrows-alt-v",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .card-body", "paddingTop"],
    customUpdates: (val) => {
      if (val && val.length > 0) {
        return [
          {
            value: val,
            path: ["& .card-body", "paddingBottom"]
          },
          {
            value: val,
            path: ["& .card-body", "paddingTop"]
          }
        ];
      }
      return [];
    },
    col: 6
  },
  {
    title: "Padding (Left/Right)",
    description: "",
    type: "number",
    suffix: "px",
    prefix: "fas fa-arrows-alt-h",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .card-body", "paddingRight"],
    customUpdates: (val) => {
      if (val && val.length > 0) {
        return [
          {
            value: val,
            path: ["& .card-body", "paddingRight"]
          },
          {
            value: val,
            path: ["& .card-body", "paddingLeft"]
          }
        ];
      }
      return [];
    },
    col: 6
  },
  {
    title: "Border Style",
    description: "",
    type: "select",
    cssPath: ["& .card-body", "borderStyle"],
    options: [
      {
        id: "solid",
        name: "Solid"
      },
      {
        id: "dotted",
        name: "Dotted"
      },
      {
        id: "dashed",
        name: "Dashed"
      }
    ]
  },
  {
    title: "Border Width",
    description: "",
    type: "number",
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .card-body", "borderWidth"]
  },
  {
    title: "Border Color",
    description: "",
    type: "color-picker",
    cssPath: ["& .card-body", "borderColor"]
  },
  {
    header: "Card Footer",
    title: "Background",
    description: "",
    type: "color-picker",
    cssPath: ["& .card-footer", "backgroundColor"]
  },
  {
    title: "Padding (Top/Bottom)",
    description: "",
    type: "number",
    suffix: "px",
    prefix: "fas fa-arrows-alt-v",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .card-footer", "paddingTop"],
    customUpdates: (val) => {
      if (val && val.length > 0) {
        return [
          {
            value: val,
            path: ["& .card-footer", "paddingBottom"]
          },
          {
            value: val,
            path: ["& .card-footer", "paddingTop"]
          }
        ];
      }
      return [];
    },
    col: 6
  },
  {
    title: "Padding (Left/Right)",
    description: "",
    type: "number",
    suffix: "px",
    prefix: "fas fa-arrows-alt-h",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .card-footer", "paddingRight"],
    customUpdates: (val) => {
      if (val && val.length > 0) {
        return [
          {
            value: val,
            path: ["& .card-footer", "paddingRight"]
          },
          {
            value: val,
            path: ["& .card-footer", "paddingLeft"]
          }
        ];
      }
      return [];
    },
    col: 6
  }
];
