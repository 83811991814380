import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import manageRoutes from "./manageRoutes";

import "./Management.scss";

const ManagePage = () => {
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);

  const userRole = currentWorkspace ? currentWorkspace.userRoleId || currentUser.roleId : null;
  for (const route of manageRoutes) {
    if (!route.hidden && (!route.roles || route.roles.includes(userRole))) {
      return <Redirect to={route.layout + route.path} />;
    }
  }
  return <Redirect to="/admin/dashboard" />;
};

export default ManagePage;
