import React, { useEffect, useRef, useState } from "react";
import NotificationAlert from "components/NotificationAlert";
import { Button } from "reactstrap";

import env from "Environment";
import gql from "graphql-tag";
import { get } from "lodash";
import { useMutation } from "@apollo/react-hooks";
import { format, formatDistanceToNow, differenceInDays } from "date-fns";
import { getLimitSubscriptionPerFeatureType, stringToBoolean } from "utils/helperFunctions";
import { FEATURE_WHITE_LABEL, WHITE_LABELS } from "utils/constants";

import "./TrialHeaderBar.scss";

const SEND_EMAIL = gql`
  mutation SendNotificationEmails(
    $companyId: ID
    $whiteLabel: Boolean
    $title: String
    $description: String
    $details: String
  ) {
    sendNotificationEmails(
      companyId: $companyId
      whiteLabel: $whiteLabel
      title: $title
      description: $description
      details: $details
    )
  }
`;

const notificationOptions = (notificationText) => ({
  place: "bc",
  message: (
    <div className="alert-text ml-2">
      <span className="alert-title" data-notify="title">
        {notificationText}
      </span>
    </div>
  ),
  type: "danger",
  icon: "fas fa-exclamation-triangle",
  autoDismiss: 7
});

const TrialHeaderBar = ({ company, subscription, onUpgrade, showUpgrade }) => {
  const notificationAlert = useRef(null);
  const [sendEmail] = useMutation(SEND_EMAIL);
  const [emailError, setEmailError] = useState();
  const isTrial = get(subscription, ["compSubscription", "status"]) === "trialing";
  const isPastDue = get(subscription, ["compSubscription", "status"]) === "past_due";
  const endDate = get(subscription, ["compSubscription", "cancelAt"])
    ? format(new Date(get(subscription, ["compSubscription", "cancelAt"]) * 1000), "MMM d, yyyy")
    : null;
  const hasWhiteLabel = stringToBoolean(getLimitSubscriptionPerFeatureType(subscription, FEATURE_WHITE_LABEL));

  let daysLeft;
  let headerText = "";
  if (isTrial) {
    const trialLeft =
      formatDistanceToNow(new Date(get(subscription, ["compSubscription", "trialEnd"]) * 1000)) + " left";
    headerText = "In Trial Mode - " + trialLeft;
    daysLeft = differenceInDays(new Date(get(subscription, ["compSubscription", "trialEnd"]) * 1000), new Date()) + 1;
  }

  useEffect(() => {
    if (daysLeft && daysLeft == get(env, "DAYS_LEFT_NOTIFICATION_EMAIL")) {
      sendEmail({
        variables: {
          companyId: company.id,
          whiteLabel: hasWhiteLabel,
          title: `Your ${WHITE_LABELS(hasWhiteLabel, company).sinapiName} trial is about to expire.`,
          description:
            "Your trial is about to expire in " +
            daysLeft +
            " days.<br/>Activate your account today to get full access!",
          details:
            "<div style='text-align: center'> <table align='center' border='0' cellspacing='0' cellpadding='0' style='border-radius: 4px;'> <tbody> <tr> <td style='padding:15px 25px; font-family:Arial,sans-serif;background-color:#12cbee;color:#ffffff;border-radius: 4px;font-weight: bold;font-size: 16px'><a href='" +
            env.APP_URL +
            "/admin/upgrade' style='color:#ffffff;text-decoration:none;'>Activate Account</a></td> </tr> </tbody> </table> </div>"
        }
      }).then(({ errors }) => {
        if (errors) setEmailError(errors[0].message);
      });
    }
  }, [daysLeft, sendEmail]);

  useEffect(() => {
    if (emailError) {
      notificationAlert.current.notificationAlert(notificationOptions(emailError));
    }
  }, [emailError]);

  let trialExpired = false;
  if (isPastDue) {
    trialExpired =
      differenceInDays(new Date(get(subscription, ["compSubscription", "trialEnd"]) * 1000), new Date()) < 5;
    if (trialExpired) {
      headerText = "Trial Expired";
    }
  }

  useEffect(() => {
    if (trialExpired)
      sendEmail({
        variables: {
          title: "Your Trial Has Expired",
          description: `Please activate your account to continue using ${
            WHITE_LABELS(hasWhiteLabel, company).sinapiName
          }.`,
          details:
            "<div style='text-align: center'> <table align='center' border='0' cellspacing='0' cellpadding='0' style='border-radius: 4px;'> <tbody> <tr> <td style='padding:15px 25px; font-family:Arial,sans-serif;background-color:#12cbee;color:#ffffff;border-radius: 4px;font-weight: bold;font-size: 16px'><a href='" +
            env.APP_URL +
            "/admin/upgrade' style='color:#ffffff;text-decoration:none;'>Activate Account</a></td> </tr> </tbody> </table> </div>"
        }
      }).then(({ errors }) => {
        if (errors) setEmailError(errors[0].message);
      });
  }, [trialExpired, sendEmail]);

  if (!isTrial && !trialExpired && !endDate) {
    return null;
  }

  if (endDate) {
    headerText = `This account has been disabled and will be inactive on ${endDate} `;
  }

  const upgrade = (e) => {
    e.preventDefault();
    if (onUpgrade) {
      onUpgrade();
    }
  };

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="trial-header-bar alert-default">
        <span style={{ display: "flex" }}>
          {headerText}
          {showUpgrade ? (
            <Button className="upgrade-btn btn-neutral btn-default btn-sm" onClick={upgrade}>
              {endDate ? "Reactivate Account" : "Activate Subscription"}
            </Button>
          ) : null}
        </span>
      </div>
    </>
  );
};

export default TrialHeaderBar;
