import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import classnames from "classnames";
import { formActions } from "reducers";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";

import { createRulesHTML } from "./formElementOptions";
import { useForm } from "views/pages/forms/FormContext";
import { FEATURE_ADVANCED_EDITING, FEATURE_WHITE_LABEL, WHITE_LABELS } from "utils/constants";
import { getLimitSubscriptionPerFeatureType, stringToBoolean } from "utils/helperFunctions";
import ManagementModal from "components/UserManagement/ManagementModal";
import CompanySubscription from "components/Subscription/CompanySubscription";
import { Link } from "react-router-dom";

function SectionOptionsPanel({ activeSectionJSON, company, companySubscription, onClose }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);
  const userRole = currentWorkspace ? currentWorkspace.userRoleId || currentUser.roleId : null;

  const { allFields } = useForm();

  const hasWhiteLabel = stringToBoolean(getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_WHITE_LABEL));

  const renderActiveSectionDynamicRules = (json) => {
    const hasAdvancedEditing =
      getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_ADVANCED_EDITING) === "Advanced";
    return createRulesHTML(json, dispatch, allFields, true, hasAdvancedEditing, setShowModal);
  };

  const closeActiveSection = () => {
    dispatch({
      type: formActions.CLOSE_ACTIVE_SECTION
    });
    if (onClose) {
      onClose();
    }
  };

  const submitActiveSection = () => {
    dispatch({
      type: formActions.SUBMIT_SECTION_BEING_EDITED
    });
    if (onClose) {
      onClose();
    }
  };

  return (
    <div id="ActiveSectionOption" className="vertical-sidenav-full-container">
      <Card className="card-pricing border-0 mb-0">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="12" className="vertical-sidenav-header">
              <Button close onClick={() => closeActiveSection()} />
              <h1 className="mb-0">
                <i className="far fa-edit" /> Section
              </h1>
            </Col>
          </Row>
        </CardHeader>

        <CardBody style={{ padding: "0" }}>
          <Nav tabs className="nav-fill" id="editTabNav">
            <NavItem>
              <NavLink className={classnames({ active: true })}>
                <i className="fas fa-bolt" /> Dynamic Rules
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={"1"} style={{ padding: "1.5rem" }}>
            <TabPane tabId="1">{renderActiveSectionDynamicRules(activeSectionJSON)}</TabPane>
          </TabContent>
        </CardBody>
        <CardFooter>
          <Row className="align-items-center">
            <Col className="text-right" xs="12">
              <Button type="button" color="secondary" onClick={() => closeActiveSection()} className="float-left">
                Cancel
              </Button>
              <Button type="button" color="primary" onClick={() => submitActiveSection()}>
                Apply Changes
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
      {showModal && (
        <ManagementModal
          onClose={() => setShowModal(false)}
          header={<div>ADVANCED EDITING</div>}
          buttons={[{ label: "Close", onClick: () => setShowModal(false) }]}
        >
          {!["consultant"].includes(userRole) ? (
            <>
              <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
                <h2 className="font-weight-400 text-justify">
                  Please{" "}
                  <Link className="font-weight-bold btn btn-primary" to="/admin/upgrade">
                    Upgrade your account
                  </Link>
                  {` to use this Element. This element are not allowed by your current plan.`}
                </h2>
              </CardHeader>
              <CompanySubscription
                companyData={company}
                plans={companySubscription.plans}
                price={companySubscription.compPrice}
                showDetails={false}
              />
            </>
          ) : (
            <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
              <h2 className="font-weight-400 text-justify">
                {`Your plan does not support this feature. If you would like to upgrade to get the most out of ${
                  WHITE_LABELS(hasWhiteLabel, company).sinapiName
                }, contact your account manager for assistance.`}
              </h2>
            </CardHeader>
          )}
        </ManagementModal>
      )}
    </div>
  );
}

export { SectionOptionsPanel };
