import ManagePlans from "../manage/ManagePlans.jsx";
import ManageUsers from "../manage/ManageUsers.jsx";
import AdminManageConnectors from "../manage/AdminManageConnectors";
import AccountAdminCompanies from "./AccountAdminCompanies.jsx";
import CompanyWorkspacesAndUsers from "./CompanyWorkspacesAndUsers.jsx";
import ManagePage from "./ManagePage.jsx";
import UserWorkspaces from "./UserWorkspaces.jsx";
import WorkspaceUsers from "./WorkspaceUsers.jsx";

const manageRoutes = [
  {
    path: "/",
    exact: true,
    hidden: true,
    component: ManagePage,
    icon: "ni ni-single-02 text-white",
    name: "Manage",
    layout: "/manage"
  },
  {
    name: "Plans",
    exact: true,
    path: "/plans",
    icon: "fas fa-cogs text-white",
    roles: ["superAdmin"],
    layout: "/manage",
    component: ManagePlans
  },
  {
    name: "Accounts",
    exact: true,
    path: "/companies",
    icon: "fas fa-users text-white",
    roles: ["superAdmin"],
    layout: "/manage",
    component: AccountAdminCompanies,
    views: [
      {
        name: ":companyName",
        path: "/companies/:companyId",
        exact: true,
        icon: "ni ni-shop text-white",
        layout: "/manage",
        roles: ["superAdmin"],
        component: CompanyWorkspacesAndUsers,
        views: [
          {
            name: "Workspace",
            path: "/companies/:companyId/workspaces/:workspaceId",
            component: WorkspaceUsers,
            roles: ["superAdmin"],
            layout: "/manage"
          },
          {
            name: "User",
            path: "/companies/:companyId/users/:userId",
            component: UserWorkspaces,
            roles: ["superAdmin"],
            layout: "/manage"
          }
        ]
      }
    ]
  },
  {
    name: "Sessions",
    exact: true,
    path: "/users",
    icon: "fas fa-address-card text-white",
    roles: ["superAdmin"],
    layout: "/manage",
    component: ManageUsers
  },
  {
    name: "Connectors",
    exact: true,
    path: "/manage-connectors",
    icon: "fas fa-plug text-white",
    roles: ["superAdmin"],
    layout: "/manage",
    component: AdminManageConnectors
  }
];

export { manageRoutes };
export default manageRoutes;
