import React, { useState } from "react";

import { gql } from "apollo-boost";
import { capitalize, get, isEmpty } from "lodash";
import { useMutation, useQuery } from "@apollo/react-hooks";

import {
  Card,
  CardHeader,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Table
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import ManagementModal from "components/UserManagement/ManagementModal";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { getFullNameOrEmail } from "utils/helperFunctions";

const GET_USER_SESSIONS = gql`
  query ActiveSessions {
    activeSessions {
      id
      firstName
      lastName
      email
      phone
      company {
        id
        planId
        paymentInterval
        intervalPrice
        name
      }
      roleId
    }
  }
`;

const REMOVE_USER_SESSION = gql`
  mutation ClearSession($userId: ID!) {
    clearSession(userId: $userId)
  }
`;

const ManageUsers = () => {
  const [showModal, setShowModal] = useState();
  const [showErrorMsg, setShowErrorMsg] = useState();

  const {
    loading: loadingUsers,
    data: dataUsers,
    error,
    refetch
  } = useQuery(GET_USER_SESSIONS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const [removeUserMutation] = useMutation(REMOVE_USER_SESSION, {
    onCompleted: () => {
      setShowModal(null);
      refetch();
    }
  });

  const usersData = !loadingUsers && dataUsers ? dataUsers.activeSessions : null;

  return (
    <>
      <SimpleHeader name="Active Sessions" parentName="Account" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Users</h3>
                  </Col>
                </Row>
              </CardHeader>
              {loadingUsers ? <LoaderSpinner /> : null}
              <Table hover className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Company</th>
                    <th scope="col">Role</th>
                    <th scope="col">Plan</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list">
                  {isEmpty(usersData) ? (
                    <tr>
                      <td>
                        <div className="m-4">{`No Users.`}</div>
                      </td>
                    </tr>
                  ) : null}
                  {!isEmpty(usersData)
                    ? usersData.map((user) => (
                        <tr key={user.id} >
                          <td>{getFullNameOrEmail(user)}</td>
                          <td>{user.email}</td>
                          <td>{get(user, ["company", "name"])}</td>
                          <td>{user.roleId}</td>
                          <td>{capitalize(get(user, ["company", "planId"]))}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right positionFixed={true}>                                
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowModal({ type: "clearSession", data: user });
                                  }}
                                >
                                  Clear Session
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      {showErrorMsg && (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowErrorMsg()}
          header={<div>Error Message</div>}
          buttons={[{ label: "Close", onClick: () => setShowErrorMsg(null) }]}
        >
          <p>
            {showErrorMsg}
            <br />
            {error && error.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}
          </p>
        </ManagementModal>
      )}
      {showModal && showModal.type === "clearSession" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>Clear User Session</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            {
              label: "Clear",
              onClick: () =>
                removeUserMutation({
                  variables: { userId: showModal.data.id }
                })
            }
          ]}
        >
          <p>
            Are you sure you want to clear{" "}
            <span className="font-weight-bold">"{getFullNameOrEmail(showModal.data)}"</span> user session?
          </p>
        </ManagementModal>
      ) : null}      
    </>
  );
};

export default ManageUsers;
