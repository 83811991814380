import { useEffect, useState } from "react";

import { get, defaultTo, findIndex, update, words } from "lodash";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { getSubscriptionStatusName } from "utils/helperFunctions";

const GET_SUBSCRIPTION_AND_PLANS = gql`
  query getSubscription($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      stripeCustomerId
      subscriptions {
        status
        id
        cancelAt
        trialEnd
        items {
          id
          plan {
            id
            product
          }
        }
        latestInvoice {
          id
          status
          paymentIntent {
            status
          }
        }
      }
      invoices {
        id
        status
        lines {
          id
          amount
          price {
            id
            type
          }
        }
      }
      paymentMethod {
        billingDetails {
          name
        }
      }
    }
    plans {
      id
      name
      description
      stripeId
      trialPeriodDays
      hidden
      canUpgrade
      features {
        id
        name
        value
      }
      price {
        amount
        currency
        interval
        id
      }
    }
  }
`;

const GET_FEATURES_OVERWRITE = gql`
  query GetFeaturesOverwrite($companyId: ID!) {
    companyFeatures(companyId: $companyId) {
      featureId
      value
    }
  }
`;

export function useCompanySubscription(companyId, planId) {
  const [companySubscription, setCompanySubscription] = useState({ loading: true });

  const { loading, data } = useQuery(GET_SUBSCRIPTION_AND_PLANS, {
    fetchPolicy: "no-cache",
    variables: { companyId: companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true
  });

  const { loading: loadFeatures, data: featuresData } = useQuery(GET_FEATURES_OVERWRITE, {
    fetchPolicy: "no-cache",
    variables: { companyId: companyId },
    skip: !companyId,
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (!loading && data) {
      const compSubscription =
        defaultTo(get(data, ["company", "subscriptions"]), []).length > 0
          ? get(data, ["company", "subscriptions"])[0]
          : null;
      const compStatus = getSubscriptionStatusName(compSubscription);
      const endDate = compSubscription && compSubscription.cancelAt ? new Date(compSubscription.cancelAt * 1000) : null;
      const compPayment = get(data, ["company", "paymentMethod"]);
      let compProduct =
        compSubscription && compSubscription.status !== "canceled"
          ? data.plans.find((pr) => pr.stripeId === compSubscription.items[0].plan.product)
          : null;
      let compPrice = compProduct && compProduct.price.find((pr) => pr.id === compSubscription.items[0].plan.id);

      if (!compProduct && !compPrice) {
        compProduct = data.plans.find((pr) => pr.id === planId);
        compPrice = { amount: 0, interval: "unlimited" };
      }

      if (!loadFeatures && featuresData && featuresData.companyFeatures) {
        featuresData.companyFeatures.map((feature) => {
          
          const product = compProduct && compProduct.features.find((pr) => pr.id === feature.featureId);
          const productNameWords = words(product.name);
          const idx = findIndex(productNameWords, (val) => product.value === val);

          if (parseInt(feature.value) > 1)
            update(product, "name", (fea) =>
              fea.replace(productNameWords[idx + 1], productNameWords[idx + 1].concat("s"))
            );
          update(product, "name", (fea) => fea.replace(product.value, feature.value));
          update(product, "value", () => feature.value);
          return product;
        });
      }

      setCompanySubscription({
        compSubscription,
        compStatus,
        compProduct,
        compPrice,
        endDate,
        compPayment,
        plans: data.plans,
        loading
      });
    }
  }, [loading, data, featuresData, loadFeatures, planId]);

  return companySubscription;
}
