import React, { useState } from "react";
import env from "Environment";
import "./CheckoutForm.css";

import { useCreateSubscription } from "./useCreateSubscription";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import { Button } from "reactstrap";
import { get, isEmpty } from "lodash";
import { ShowPaymentMethod } from "./ShowPaymentMethod";
import { DetailedCheckoutForm } from "./DetailedCheckoutForm";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { getFullNameOrEmail } from "utils/helperFunctions";

// test card number: 4000 0025 0000 3155
// testing card numbers for different responses: https://stripe.com/docs/testing#cards-responses
// and https://stripe.com/docs/billing/subscriptions/fixed-price#test
const stripePromise = loadStripe(env.STRIPE_PUBLIC_KEY);

const UPDATE_PAYMENT = gql`
  mutation updatePaymentMethod($customerId: ID!, $paymentMethodId: ID!) {
    updatePaymentMethod(customerId: $customerId, paymentMethodId: $paymentMethodId) {
      type
      card {
        last4
      }
      billingDetails {
        name
        email
      }
    }
  }
`;

const CREATE_CUSTOMER = gql`
  mutation createCustomer($customer: CustomerInput!) {
    createCustomer(customer: $customer) {
      id
    }
  }
`;

const CheckoutForm = ({
  price,
  onSuccess,
  onFailed,
  user,
  isTrial,
  isFreePlan,
  paymentMethod,
  priceNoInterval,
  onTrial,
  totalCharge,
  trialPeriodDays,
  planId,
  interval,
  isAnUpgrade,
  salesRep
}) => {
  const [error, setError] = useState(null);
  const [useNewCard, setUseNewCard] = useState(false);
  const [prices, setPrices] = useState([]);
  const [processingUpdate, setProcessingUpdate] = useState(false);
  const [createSubscription, { data: newSub, error: subError, processingError, loading }] = useCreateSubscription();
  const [updatePayment, { error: updatePaymentError }] = useMutation(UPDATE_PAYMENT);
  const [createCustomer] = useMutation(CREATE_CUSTOMER);

  useEffect(() => {
    if (!loading && (newSub || subError || processingError)) {
      if (newSub && !subError) {
        onSuccess(newSub);
      } else if (subError) {
        onFailed(subError);
      } else if (processingError) {
        setError(processingError);
        setProcessingUpdate(false);
      }
    }
  }, [newSub, subError, processingError]);

  useEffect(() => {
    if (updatePaymentError) {
      setError(get(updatePaymentError, ["graphQLErrors", "0"], updatePaymentError));
      setProcessingUpdate(false);
    }
  }, [updatePaymentError]);

  useEffect(() => {
    if (!price || isEmpty(price)) setPrices("");
    else if (priceNoInterval) setPrices([price.id, priceNoInterval.id]);
    else setPrices([price.id]);
  }, [price, priceNoInterval]);

  if (paymentMethod && !useNewCard) {
    return (
      <>
        <ShowPaymentMethod
          paymentMethod={paymentMethod}
          onNewCard={() => setUseNewCard(true)}
          totalCharge={totalCharge}
          isTrial={isTrial}
          onTrial={onTrial}
          trialPeriodDays={trialPeriodDays}
          isAnUpgrade={isAnUpgrade}
        />
        <Button
          disabled={processingUpdate}
          className="mt-3 btn-default"
          onClick={() => {
            setProcessingUpdate(true);
            createSubscription(paymentMethod, {
              customerId: user.company.stripeCustomerId,
              paymentMethodId: paymentMethod.id,
              priceId: prices,
              trialPeriodDays: trialPeriodDays,
              userId: user.id,
              planId: planId,
              interval: interval,
              commission: parseFloat(salesRep?.commission ?? 0),
              connectStripeId: salesRep?.stripeId
            });
          }}
        >
          {processingUpdate ? "Processing..." : "Start Subscription"}
        </Button>
      </>
    );
  }

  if (!isTrial && !isFreePlan) {
    return (
      <Elements stripe={stripePromise}>
        <DetailedCheckoutForm
          btnName="Start Subscription"
          totalCharge={totalCharge}
          processing={processingUpdate}
          setProcessing={setProcessingUpdate}
          error={error || updatePaymentError}
          user={user}
          isAnUpgrade={isAnUpgrade}
          onCancel={useNewCard ? () => setUseNewCard(false) : null}
          onSuccess={(paymentMethod, stripe) => {
            updatePayment({
              variables: {
                customerId: user.company.stripeCustomerId,
                paymentMethodId: paymentMethod.id
              }
            }).then(({ errors }) => {
              if (!errors) {
                createSubscription(
                  null,
                  {
                    customerId: user.company.stripeCustomerId,
                    paymentMethodId: paymentMethod.id,
                    priceId: prices,
                    isTrial: false,
                    trialPeriodDays: trialPeriodDays,
                    userId: user.id,
                    planId: planId,
                    interval: interval
                  },
                  stripe
                );
              }
            });
          }}
        />
      </Elements>
    );
  }

  if (isFreePlan) {
    return (
      <Elements stripe={stripePromise}>
        <DetailedCheckoutForm
          btnName="Start Subscription"
          totalCharge={totalCharge}
          processing={processingUpdate}
          setProcessing={setProcessingUpdate}
          error={error || updatePaymentError}
          user={user}
          isAnUpgrade={isAnUpgrade}
          onCancel={useNewCard ? () => setUseNewCard(false) : null}
          onSuccess={(paymentMethod, stripe) => {
            createCustomer({
              variables: {
                customer: {
                  id: user.id,
                  name: getFullNameOrEmail(user),
                  email: user.email,
                  companyId: user.company.id
                }
              }
            }).then(({ data }) => {
              if (data) {
                updatePayment({
                  variables: {
                    customerId: data.createCustomer.id,
                    paymentMethodId: paymentMethod.id
                  }
                }).then(({ errors }) => {
                  if (!errors) {
                    createSubscription(
                      null,
                      {
                        customerId: data.createCustomer.id,
                        paymentMethodId: paymentMethod.id,
                        priceId: prices,
                        isTrial: false,
                        trialPeriodDays: trialPeriodDays,
                        userId: user.id,
                        planId: planId,
                        interval: interval
                      },
                      stripe
                    );
                  }
                });
              }
            });
          }}
        />
      </Elements>
    );
  }

  return (
    <Button
      disabled={processingUpdate}
      className="mt-3 btn-default"
      onClick={(e) => {
        e.preventDefault();
        setProcessingUpdate(true);
        createSubscription(null, {
          customerId: user.company.stripeCustomerId,
          priceId: prices,
          isTrial: true,
          trialPeriodDays: trialPeriodDays,
          userId: user.id,
          planId: planId,
          interval: interval
        });
      }}
    >
      {processingUpdate ? "Signing Up..." : "Sign Up"}
    </Button>
  );
};

export { CheckoutForm };
