import React, { useRef, useState } from "react";
import { useHistory } from "react-router";

import { gql } from "apollo-boost";
import { get } from "lodash";
import { useMutation, useQuery } from "@apollo/react-hooks";

import {
  Card,
  CardHeader,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Input
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import ManagementModal from "components/UserManagement/ManagementModal";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import NotificationAlert from "components/NotificationAlert";
import SinapiTable from "components/Shared/SinapiTable";

const PAGE_SIZE = 5;
const GET_SALES_REPS = gql`
  query getSalesReps($offset: Int, $limit: Int, $type: String) {
    salesRep(limit: $limit, offset: $offset, type: $type) {
      count
      offset
      items {
        id
        guid
        type
        archive
        firstName
        lastName
      }
    }
  }
`;

const ARCHIVE_SALES_REP = gql`
  mutation ArchiveSalesRep($salesRepId: ID!, $status: Boolean) {
    archiveSalesRep(salesRepId: $salesRepId, status: $status)
  }
`;

const notificationOptions = {
  place: "bc",
  message: (
    <div>
      Success <b>Copy</b>
    </div>
  ),
  type: "info",
  autoDismiss: 7
};

const ManageCommissions = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState();

  const notificationAlert = useRef(null);
  const handleCopy = (elementId) => {
    var copyText = document.getElementById(elementId);
    copyText.select();
    document.execCommand("copy");
    notificationAlert.current.notificationAlert(notificationOptions);
  };

  const { loading, data, refetch, fetchMore } = useQuery(GET_SALES_REPS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: PAGE_SIZE,
      offset: 0,
      type: "salesRep"
    }
  });

  const {
    loading: loadingReferral,
    data: dataReferral,
    refetch: refetchReferral,
    fetchMore: fetchMoreReferral
  } = useQuery(GET_SALES_REPS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: PAGE_SIZE,
      offset: 0,
      type: "referral"
    }
  });

  const [archiveSalesRep] = useMutation(ARCHIVE_SALES_REP, {
    onCompleted: () => {
      refetch();
      refetchReferral();
      setShowModal();
    }
  });

  return (
    <>
      <NotificationAlert ref={notificationAlert} className="col-12" />
      <SimpleHeader
        name="Manage The Sales Team"
        button={{
          label: "+ New Sales Rep",
          color: "success",
          action: () => history.push(`${history.location.pathname}/new`)
        }}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xs="6">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="mb-0">Sales Reps</h3>
                  </Col>
                </Row>
              </CardHeader>
              {loading ? <LoaderSpinner /> : null}
              <SinapiTable
                data={get(data, "salesRep", {})}
                isLoaded={!loading && data ? true : false}
                onLoadMore={(offset) =>
                  fetchMore({
                    variables: {
                      limit: PAGE_SIZE,
                      offset: offset
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) {
                        return prev;
                      }
                      return fetchMoreResult;
                    }
                  })
                }
                options={{
                  limit: PAGE_SIZE,
                  emptyLabel: `No Sales Reps.`,
                  columns: [
                    {
                      key: "name",
                      label: "Name",
                      render: (row) => `${row.firstName} ${row.lastName ?? ""}`
                    },
                    {
                      key: "options",
                      label: "",
                      className: "text-right",
                      render: (row, _column) => (
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right positionFixed={true}>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(history.location.pathname + "/" + row.id);
                              }}
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowModal({
                                  type: "archiveSalesRep",
                                  data: row,
                                  status: row.archive ? false : true
                                });
                              }}
                            >
                              {`${row.archive ? "Unarchive" : "Archive"}`}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )
                    }
                  ]
                }}
              />
            </Card>
          </Col>
          <Col xs="6">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Referral Partners</h3>
                  </Col>
                </Row>
              </CardHeader>
              {loading ? <LoaderSpinner /> : null}
              <SinapiTable
                data={get(dataReferral, "salesRep", {})}
                isLoaded={!loadingReferral && dataReferral ? true : false}
                onLoadMore={(offset) =>
                  fetchMoreReferral({
                    variables: {
                      limit: PAGE_SIZE,
                      offset: offset
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) {
                        return prev;
                      }
                      return fetchMoreResult;
                    }
                  })
                }
                options={{
                  limit: PAGE_SIZE,
                  emptyLabel: `No Referral Partners.`,
                  columns: [
                    {
                      key: "name",
                      label: "Name",
                      render: (row) => `${row.firstName} ${row.lastName ?? ""}`
                    },
                    {
                      key: "options",
                      label: "",
                      className: "text-right",
                      render: (row, _column) => (
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right positionFixed={true}>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(history.location.pathname + "/" + row.id);
                              }}
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowModal({
                                  type: "archiveSalesRep",
                                  data: row,
                                  status: row.archive ? false : true
                                });
                              }}
                            >
                              {`${row.archive ? "Unarchive" : "Archive"}`}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )
                    }
                  ]
                }}
              />
            </Card>
          </Col>
        </Row>
      </Container>
      {showModal && showModal.type === "salesRepURL" ? (
        <ManagementModal
          modalSize="lg"
          onClose={() => setShowModal(null)}
          header={<div>Sales Rep URL</div>}
          classNames={{ body: "border-bottom", footer: "py-3" }}
        >
          <Row className="mb-4">
            <Col lg="11">
              <Input
                readOnly
                id="input-text-link"
                key="input-text-link"
                type="text"
                defaultValue={showModal.data}
                onClick={() => handleCopy("input-text-link")}
              />
            </Col>
          </Row>
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "archiveSalesRep" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>{`${showModal.data.archive ? "Unarchive" : "Archive"} Sales Rep`}</div>}
          classNames={{ body: "border-bottom", footer: "py-3" }}
          buttons={[
            { label: "No, Cancel", outline: true, className: "mr-auto", onClick: () => setShowModal(null) },
            {
              label: `Yes, ${showModal.data.archive ? "Unarchive" : "Archive"} Rep`,
              onClick: () =>
                archiveSalesRep({
                  variables: { salesRepId: showModal.data.id, status: !showModal.data.archive }
                })
            }
          ]}
        >
          <p>
            {`Are you sure you wish to ${showModal.data.archive ? "Unarchive" : "Archive"}?`}
            <br />
            <span className="font-weight-bold text-info">{`${showModal.data.firstName} ${
              showModal.data.lastName ?? ""
            }`}</span>
          </p>
        </ManagementModal>
      ) : null}
    </>
  );
};

export default ManageCommissions;
