import _ from "lodash";
import * as actions from "./form-actions";
import { getCostPerQuestion } from "utils/helperFunctions";

const initialState = {
  quoteJSON: []
};

/* This is a workaround because the JSON doesn't update the form */
const forceUpdateJSON = (json) => {
  return JSON.parse(JSON.stringify(json));
};

export default function renderedReducer(state = initialState, { type, payload }) {
  const newState = forceUpdateJSON(state);

  switch (type) {
    /******************************
     ** UPDATE QUESTION RENDERED **
     ******************************/
    case actions.INITIALIZE_QUESTION_BEING_RENDERED:
      return initialState;
    case actions.UPDATE_QUESTION_BEING_RENDERED:
      const newQuoteJson = forceUpdateJSON(state.quoteJSON);
      const elementIndex = newQuoteJson.findIndex((element) => element.id === _.get(payload.question, "id", ""));
      const cost = _.isEmpty(payload.value) ? undefined : getCostPerQuestion(payload.question, payload.value);
      const isAddress = _.get(payload.question, "type", "") === "address";
      const isMultiple = ["checkbox", "radio", "select"].includes(payload.question.type);
      const answer = {
        id: payload.key,
        value: payload.value,
        label:
          payload.type === "radio" || payload.type === "checkbox" || payload.type === "select"
            ? payload.type === "radio" || payload.type === "select"
              ? payload.question.options.find((opt) => opt.id === payload.value).name
              : payload.question.options
                  .filter((opt) => payload.value.includes(opt.id))
                  .map((v) => v.name)
                  .join(",")
            : ""
      };
      const data = {
        title: _.get(payload.question, "title", ""),
        description: _.get(payload.question, "description", ""),
        id: _.get(payload.question, "id", ""),
        answer: isAddress ? [answer] : answer,
        cost: cost && !_.isEmpty(cost) ? cost : undefined,
        affect: _.get(payload.question, "affectCost", ""),
        adjust: _.get(payload.question, "questionAdjust", ""),
        type: _.get(payload.question, "type", ""),
        required: _.get(payload.question, "required", ""),
        toContactRecord: _.get(payload.question, "toContactRecord", "") // stored in hubspot
      };

      if (elementIndex === -1) {
        newQuoteJson.push(data);
      } else {
        newQuoteJson[elementIndex].cost = cost;
        if (isAddress) {
          const answerIndex = newQuoteJson[elementIndex].answer.findIndex((answer) => answer.id === payload.key);
          if (answerIndex === -1) {
            newQuoteJson[elementIndex].answer.push(answer);
          } else {
            newQuoteJson[elementIndex].answer[answerIndex].value = payload.value;
          }
        } else {
          newQuoteJson[elementIndex].answer.value = payload.value;
          if (isMultiple) {
            if (payload.question.type === "radio" || payload.question.type === "select") {
              newQuoteJson[elementIndex].answer.label = payload.question.options.find(
                (opt) => opt.id === payload.value
              ).name;
            } else {
              newQuoteJson[elementIndex].answer.label = payload.question.options
                .filter((opt) => payload.value.includes(opt.id))
                .map((v) => v.name)
                .join(",");
            }
          }
        }
      }
      return { ...newState, quoteJSON: newQuoteJson };
    default:
      return state;
  }
}
