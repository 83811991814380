import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as yup from "yup";
import { gql } from "apollo-boost";
import { Formik, Form, Field } from "formik";
import { get, defaultTo } from "lodash";
import { useQuery, useMutation } from "@apollo/react-hooks";

import defaultImage from "../../assets/img/default-image.png";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { AWS_S3_ASSETS_BUCKET } from "../../utils/constants";

// reactstrap components
import { Card, CardHeader, CardBody, FormGroup, Row, Col, Input } from "reactstrap";

const GET_WORKSPACE = gql`
  query getWorkspace($workspaceId: ID) {
    workspace(id: $workspaceId) {
      id
      name
      address
      city
      state
      zipcode
      phone
      logoPath
      company {
        id
      }
    }
  }
`;

const SAVE_WORKSPACE = gql`
  mutation CreateWorkspace(
    $userId: ID!
    $companyId: ID!
    $workspaceId: ID
    $workspace: WorkspaceInput!
    $file: Upload
    $prevLogo: String
  ) {
    createWorkspace(
      userId: $userId
      companyId: $companyId
      workspaceId: $workspaceId
      workspace: $workspace
      file: $file
      prevLogo: $prevLogo
    ) {
      id
    }
  }
`;

const workspaceFormValidation = yup.object().shape({
  name: yup.string().required()
});

const getInitialValues = (orgWorkspace) =>
  ["name", "address", "city", "state", "zipcode", "phone"].reduce(
    (init, key) => ({ ...init, [key]: orgWorkspace ? orgWorkspace[key] || "" : "" }),
    {}
  );

const WorkspaceForm = ({ onSave, workspaceId, setToEdit }) => {
  const reduxDispatch = useDispatch();
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);
  const userRole = currentWorkspace
    ? currentWorkspace.userRoleId || currentUser.roleId
    : currentUser
    ? currentUser.roleId
    : null;

  const [editWorkspace, setEditWorkspace] = useState(setToEdit || false);
  const [newWorkspaceID, setNewWorkspaceID] = useState();
  const [logoPath, setLogoPath] = useState({ src: defaultImage });

  const { loading, data, refetch } = useQuery(GET_WORKSPACE, {
    fetchPolicy: "network-only",
    variables: {
      workspaceId: workspaceId || newWorkspaceID
    },
    skip: !(workspaceId || newWorkspaceID)
  });

  const [createWorkspace, { data: newWorkspace, loading: savingWorks, error: savingError }] =
    useMutation(SAVE_WORKSPACE);

  const [showErrorMsg, setShowErrorMsg] = useState();

  useEffect(() => {
    if (savingError) {
      setShowErrorMsg("Something went wrong while saving.");
    }
  }, [savingError]);

  useEffect(() => {
    if (newWorkspace && !savingWorks) {
      if (onSave) {
        onSave(newWorkspace);
      }
      setNewWorkspaceID(newWorkspace.createWorkspace.id);
      refetch();
      setLogoPath({ src: defaultImage });
      setEditWorkspace(false);
    }
  }, [newWorkspace]);

  let workspace = !loading && data ? data.workspace : null;

  useEffect(() => {
    if (get(workspace, "logoPath")) {
      setLogoPath({ src: `${AWS_S3_ASSETS_BUCKET}/${defaultTo(get(workspace, "logoPath"), "")}` });
    }
  }, [workspace]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileSize = Math.round(file.size / 1024);
    if (fileSize > 1024) {
      setShowErrorMsg("Your image must be smaller than 1 mb to upload");
      return;
    }
    setLogoPath({ file, src: URL.createObjectURL(e.target.files[0]) });
  };

  return (
    <>
      {loading ? <LoaderSpinner /> : null}
      <Formik
        initialValues={{ ...getInitialValues(workspace || {}) }}
        enableReinitialize={true}
        validationSchema={workspaceFormValidation}
        validateOnMount={true}
        onSubmit={(values) => {
          const workspaceData = { ...values };
          createWorkspace({
            variables: {
              userId: currentUser.id,
              companyId: workspaceId ? workspace.company.id : currentUser.company.id,
              workspace: workspaceData,
              workspaceId: workspace ? workspace.id : null,
              file: logoPath.file,
              prevLogo: workspace ? workspace.logoPath : null
            }
          }).then(() => reduxDispatch({ type: "REFETCH_USER" }));
        }}
      >
        {(formikProps) => (
          <>
            {showErrorMsg && (
              <Row className="align-items-center">
                <Col className="col-12">
                  <div className="alert-header-bar alert alert-warning fade show alert-dismissible">
                    <span>
                      {showErrorMsg}
                      <br />
                      {savingError && savingError.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}
                    </span>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setShowErrorMsg()}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Col>
              </Row>
            )}
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Workspace</h3>
                  </Col>
                  {["admin", "superAdmin"].includes(userRole) && (
                    <Col xs="4" className="text-right">
                      {!editWorkspace ? (
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            setEditWorkspace(true);
                          }}
                        >
                          Edit workspace profile
                        </button>
                      ) : null}
                      {editWorkspace ? (
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            formikProps.submitForm();
                          }}
                          disabled={!formikProps.isValid}
                        >
                          Save workspace profile
                        </button>
                      ) : null}
                    </Col>
                  )}
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col lg="6">
                      <Row>
                        <Col>
                          <h6 className="heading-small text-muted mb-4">Workspace Name:</h6>
                          <div className="pl-lg-4">
                            <FormGroup>
                              <Field name="name" readOnly={!editWorkspace} className="form-control" />
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>
                            <div className="heading-small h6 text-muted form-inline pb-0 mb-0">Upload Logo:</div>
                            <small className="text-muted ">Required for branding on Emails and Forms</small>
                          </label>
                          <div className="pl-lg-4">
                            <FormGroup>
                              <Input
                                className="form-control-file"
                                type="File"
                                onChange={handleFileChange}
                                disabled={!editWorkspace}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="6">
                      <div className="text-center" style={{ height: "100%" }}>
                        <>
                          <span style={{ display: "inline-block", height: "100%", verticalAlign: "middle" }} />
                          <img
                            alt=""
                            className="img-thumbnail"
                            style={{ maxHeight: "300px", marginTop: "10px" }}
                            src={logoPath.src}
                          />
                        </>
                      </div>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Information</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-phone">
                            Phone
                          </label>
                          <Field name="phone" readOnly={!editWorkspace} id="input-phone" className="form-control" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-address">
                            Address
                          </label>
                          <Field name="address" readOnly={!editWorkspace} id="input-address" className="form-control" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-city">
                            City
                          </label>
                          <Field name="city" readOnly={!editWorkspace} id="input-city" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-state">
                            State
                          </label>
                          <Field name="state" readOnly={!editWorkspace} id="input-state" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-postal-code">
                            Postal code
                          </label>
                          <Field
                            name="zipcode"
                            readOnly={!editWorkspace}
                            id="input-postal-code"
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </>
        )}
      </Formik>
    </>
  );
};
export default WorkspaceForm;
