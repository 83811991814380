const connectorsConfigs = {
  Hubspot: {
    CLIENT_ID: process.env.REACT_APP_HUBSPOT_CLIENT_ID,
    SCOPE:
      "timeline%20oauth%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.quotes.write%20crm.objects.quotes.read%20crm.schemas.quotes.read%20crm.objects.owners.read"
  },
  Salesforce: {
    CLIENT_ID: process.env.REACT_APP_SALESFORCE_CLIENT_ID,
    SCOPE: "api%20full%20id%20web%20refresh_token"
  },
  Xero: {
    CLIENT_ID: process.env.REACT_APP_ZERO_CLIENT_ID,
    SCOPE: "openid%20profile%20email%20accounting.transactions"
  },
  Keap: {
    CLIENT_ID: process.env.REACT_APP_KEAP_CLIENT_KEY,
    REDIRECT_URL: process.env.REACT_APP_URL + "/admin/connectors"
  },
  GoHighLevel: {
    CLIENT_ID: process.env.REACT_APP_GOHIGHLEVEL_CLIENT_ID,
    REDIRECT_URL: process.env.REACT_APP_URL + "/admin/connectors?connector=GoHighLevel"
  }
};

export default connectorsConfigs;
