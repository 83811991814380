import "./AlertHeaderBar.scss";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

const AlertHeaderBar = () => {
  const { alerts } = useSelector((state) => state.alerts);
  const dispatch = useDispatch();

  const removeAlert = (alert) => {
    dispatch({ type: "REMOVE_ALERT", payload: alert });
  };

  return (
    <div className="alert-container">
      {alerts.map((alert, index) => (
        <div key={index} className="alert-header-bar alert alert-warning fade show alert-dismissible">
          <span>{alert.text}</span>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => removeAlert(alert)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ))}
    </div>
  );
};

export default AlertHeaderBar;
