import React from "react";
import { getUniqueID } from "./HelperFunctions";
import { optionWithIcon } from "./fieldUtils";

const FormHeadline = (props) => {
  const { title, subTitle } = props.question;
  const HeadlineElement = () => React.createElement("h1", { className: "mb-0 sinapi-form-headline" }, title);
  return (
    <>
      <HeadlineElement />
      {subTitle && <label className="form-control-label sinapi-form-sub-headline">{subTitle}</label>}
    </>
  );
};

const PreviewFormHeadlineField = () => optionWithIcon("fas fa-heading", "Head line");

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Headline",
  subTitle: "Headline to their form",
  required: false,
  type: "headline"
});

const fieldConfig = {
  key: "headline",
  component: FormHeadline,
  getInitialData: getInitialData,
  previewComponent: PreviewFormHeadlineField
};

export default fieldConfig;
