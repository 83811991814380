import React, { useState, useCallback, useEffect } from "react";
import { get, debounce } from "lodash";
import {
  Card,
  CardHeader,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import ManagementModal from "components/UserManagement/ManagementModal";
import CompanyForm from "components/Forms/CompanyForm";
import CompanySubscription from "components/Subscription/CompanySubscription";
import SinapiTable from "components/Shared/SinapiTable";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { gql } from "apollo-boost";
import CompanyFeaturesForm from "components/Forms/CompanyFeaturesForm";
import { format } from "date-fns";

const PAGE_SIZE = 50;
const GET_COMPANIES = gql`
  query getCompanies($offset: Int, $limit: Int, $keywords: String) {
    companies(limit: $limit, offset: $offset, keywords: $keywords) {
      count
      offset
      items {
        id
        name
        disabledAt
        deletedAt
      }
    }
  }
`;

const DISABLE_COMPANY = gql`
  mutation DisableCompany($companyId: ID!) {
    disableCompany(companyId: $companyId)
  }
`;

const ENABLE_COMPANY = gql`
  mutation EnableCompany($companyId: ID!) {
    enableCompany(companyId: $companyId)
  }
`;

const REMOVE_COMPANY = gql`
  mutation RemoveCompany($companyId: ID!) {
    removeCompany(companyId: $companyId)
  }
`;

function AccountAdminCompanies() {
  const [showModal, setShowModal] = useState();
  const [keywords, setKeywords] = useState("");
  const history = useHistory();
  const { loading, data, refetch, fetchMore } = useQuery(GET_COMPANIES, {
    fetchPolicy: "network-only",
    variables: {
      limit: PAGE_SIZE,
      offset: 0,
      ...(keywords) && { keywords }
    },
    notifyOnNetworkStatusChange: true
  });
  const [disableCompanyMutation] = useMutation(DISABLE_COMPANY, {
    onCompleted: () => {
      setShowModal(null);
      refetch();
    }
  });
  const [enableCompanyMutation] = useMutation(ENABLE_COMPANY, {
    onCompleted: () => {
      setShowModal(null);
      refetch();
    }
  });
  const [removeCompanyMutation] = useMutation(REMOVE_COMPANY, {
    onCompleted: () => {
      setShowModal(null);
      refetch();
    }
  });

  const debouncedSearchCompany = useCallback(
    debounce((criteria) => {
      setKeywords(criteria)
      refetch();
    }, 500), []
  );

  const onChangeSearchCompany = (event) => {
    debouncedSearchCompany(event.target.value);
  }

  useEffect(() => {
    return () => {
      debouncedSearchCompany.cancel();
    };
  }, [debouncedSearchCompany]);

  return (
    <>
      <SimpleHeader name="Companies" parentName="Account" showSearchBox={true} onChangeSearch={onChangeSearchCompany}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Companies</h3>
                  </Col>
                  <Col xs="4" className="text-right">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModal({ type: "addNewCompany" });
                      }}
                    >
                      + Add Company
                    </button>
                  </Col>
                </Row>
              </CardHeader>
              {loading ? <LoaderSpinner /> : null}
              <SinapiTable
                data={get(data, "companies", {})}
                isLoaded={!loading && data ? true : false}
                onLoadMore={(offset) =>
                  fetchMore({
                    variables: {
                      limit: PAGE_SIZE,
                      offset: offset
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) {
                        return prev;
                      }
                      return fetchMoreResult;
                    }
                  })
                }
                options={{
                  limit: PAGE_SIZE,
                  emptyLabel: `No Companies.`,
                  columns: [
                    { 
                      key: "name", 
                      label: "Name", 
                      render: (row) =>
                        row.deletedAt ? (
                          <span style={{ color: "red" }}>{row.name}</span>
                        ) : row.disabledAt ? (
                          <span style={{ color: "lightgray" }}>{row.name}</span>
                        ) :(
                          row.name
                        ) 
                    },
                    { 
                      key: "disabledAt", 
                      label: "Disable At", 
                      render: (row) =>
                        row.deletedAt && row.disabledAt ? (
                          <span style={{ color: "red" }}>{format(row.disabledAt, "MMM d, yyyy")}</span>
                        ) : row.disabledAt ? (
                          <span style={{ color: "lightgray" }}>{format(row.disabledAt, "MMM d, yyyy")}</span>
                        ) :(
                          row.disabledAt
                        ) 
                    },
                    {
                      key: "options",
                      label: "",
                      className: "text-right",
                      render: (row, column) => (
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right positionFixed={true}>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowModal({ type: "showSubscriptions", data: row });
                              }}
                            >
                              Subscriptions
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowModal({ type: "showCompanyDetails", data: row });
                              }}
                            >
                              Details
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(history.location.pathname + "/" + row.id);
                              }}
                            >
                              Workspaces
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowModal({ type: "showCompanyFeatures", data: row });
                              }}
                            >
                              Features
                            </DropdownItem>
                            {!row.disabledAt && (
                              <DropdownItem
                                href="#pablo"
                                disabled={row.disabledAt}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowModal({ type: "disableCompany", data: row });
                                }}
                              >
                                Disable
                              </DropdownItem>
                            )}
                            {row.disabledAt && (
                              <DropdownItem
                                href="#pablo"
                                disabled={!row.disabledAt}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowModal({ type: "enableCompany", data: row });
                                }}
                              >
                                Enable
                              </DropdownItem>
                            )}
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowModal({ type: "deleteCompany", data: row });
                              }}
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )
                    }
                  ]
                }}
              />
            </Card>
          </div>
        </Row>
      </Container>
      {showModal && showModal.type && showModal.type !== "deleteCompany" && showModal.type !== "disableCompany" && showModal.type !== "enableCompany" ? (
        <ManagementModal
          onClose={() => setShowModal(null)}
          classNames={
            showModal.type === "showCompanyFeatures"
              ? {
                  body: "pb-0",
                  footer: "pt-0"
                }
              : undefined
          }
        >
          {showModal.type === "addNewCompany" ? (
            <CompanyForm setToEdit={true} onSave={refetch} isNew={true} superAdmin={true} />
          ) : null}
          {showModal.type === "showSubscriptions" ? <CompanySubscription companyId={showModal.data.id} /> : null}
          {showModal.type === "showCompanyDetails" ? (
            <CompanyForm companyId={showModal.data.id} showPlan={true} />
          ) : null}
          {showModal.type === "showCompanyFeatures" ? <CompanyFeaturesForm company={showModal.data} /> : null}
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "disableCompany" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>Disable Company</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            {
              label: "Disable",
              onClick: () =>
                disableCompanyMutation({
                  variables: { companyId: showModal.data.id }
                })
            }
          ]}
        >
          <p>
            Are you sure you want to disable <span className="font-weight-bold">"{showModal.data.name}"</span> company?
          </p>
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "enableCompany" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>Enable Company</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            {
              label: "Enable",
              onClick: () =>
                enableCompanyMutation({
                  variables: { companyId: showModal.data.id }
                })
            }
          ]}
        >
          <p>
            Are you sure you want to enable <span className="font-weight-bold">"{showModal.data.name}"</span> company?
          </p>
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "deleteCompany" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>Remove Company</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            {
              label: "Remove",
              onClick: () =>
                removeCompanyMutation({
                  variables: { companyId: showModal.data.id }
                })
            }
          ]}
        >
          <p>
            Are you sure you want to remove <span className="font-weight-bold">"{showModal.data.name}"</span> company?
          </p>
        </ManagementModal>
      ) : null}
    </>
  );
}

AccountAdminCompanies.defaultProps = {};

AccountAdminCompanies.propTypes = {};

export default AccountAdminCompanies;
