import { useRef, useEffect } from "react";

/*
 * useInterval Hook sets up an interval and clears it after un-mounting.
 * It’s a combo of setInterval and clearInterval tied to the component lifecycle.
 * We can pause the Interval if pass a delay equals to null.
 *
 * callback is called once interval is hit
 * delay in seconds: integer
 */
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay * 1000);
      return () => clearInterval(id);
    }
  }, [delay]);
}
