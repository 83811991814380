import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.jsx";
import PublicLayout from "layouts/Public.jsx";
import ExternalApi from "views/ExternalApi.jsx";
import AuthLayout from "layouts/Auth.jsx";
import PrivateRoute from "components/PrivateRoute";
import ApolloClient from "apollo-boost-upload";
import { ApolloProvider } from "@apollo/react-hooks";
import env from "Environment";
import { AuthProvider } from "AuthProvider";
import { AuthContext } from "AuthProvider";

const clientUri = env.GRAPHQL_ENDPOINT || "/api/graphql";
const client = new ApolloClient({
  uri: clientUri,
  credentials: 'include',
});
client.defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all"
  },
  query: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all"
  },
  mutate: {
    errorPolicy: "all"
  }
};

function App() {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <Switch>          
          <PrivateRoute
            strict
            path="/logout"
            render={() => 
              <AuthContext.Consumer>
                {({ logout }) => {
                      logout();
                      return <Redirect to="/auth/login" />;
            }}
              </AuthContext.Consumer>
            }
          />
          <PrivateRoute path="/admin/*" render={(props) => <AdminLayout {...props} />} />
          <PrivateRoute path="/account/*" render={(props) => <AdminLayout {...props} layout="/account" />} />
          <PrivateRoute path="/manage/*" render={(props) => <AdminLayout {...props} layout="/manage" />} />
          <PrivateRoute path="/test-api" render={(props) => <ExternalApi {...props} />} />
          <Route path="/public/*" render={(props) => <PublicLayout {...props} layout="/public" />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="*" to="/auth/login" />
        </Switch>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
