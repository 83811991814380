//Google Fonts Top Twenty Trending
export const googleFonts = {
  roboto: "Roboto",
  piazzolla: "Piazzolla",
  sansitaswashed: "Sansita Swashed",
  opensans: "Open Sans",
  peddana: "Peddana",
  nNotosansjp: "Noto Sans JP",
  lato: "Lato",
  montserrat: "Montserrat",
  chilanka: "Chilanka",
  sourcesanspro: "Source Sans Pro",
  robotocondensed: "Roboto Condensed",
  oswald: "Oswald",
  davidlibre: "David Libre",
  poppins: "Poppins",
  robotomono: "Roboto Mono",
  raleway: "Raleway",
  notosans: "Noto Sans",
  ptsans: "PT Sans",
  robotoslab: "Roboto Slab",
  Merriweather: "Merriweather"
};

export function getGoogleFontImportUrl(familyName, italic, bold) {
  italic = italic !== false;
  bold = bold !== false;

  let importUrl = `url('https://fonts.googleapis.com/css2?family=${familyName}:`;
  if (italic) {
    importUrl += "ital,";
  }
  importUrl += "wght@0,400;"; // Regular weight
  if (bold) {
    importUrl += "0,700;";
  }
  if (italic) {
    importUrl += "1,400";
  }
  return importUrl + "&display=swap')";
}
