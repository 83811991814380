import React, { useState, useEffect } from "react";
import { get, isEmpty, upperCase } from "lodash";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  CardBody,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import ManagementModal from "components/UserManagement/ManagementModal";
import InviteNewUserModal from "components/UserManagement/InviteNewUserModal";
import Profile from "../Profile";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import SinapiTable from "components/Shared/SinapiTable";
import { getFullNameOrEmail, getLimitSubscriptionPerFeatureType, stringToBoolean } from "utils/helperFunctions";
import { useCompanySubscription } from "hooks/useCompanySubscription";
import { FEATURE_NUMBER_OF_USERS, FEATURE_NUMBER_OF_WORKSPACES, FEATURE_WHITE_LABEL } from "utils/constants";
import CompanySubscription from "components/Subscription/CompanySubscription";
import WorkspaceForm from "components/Forms/WorkspaceForm";
import { format } from "date-fns";
import { usePermissionsWithRole } from "hooks/usePermissionsWithRole";

const GET_WORKSPACE = gql`
  query getWorkspace($workspaceId: ID) {
    workspace(id: $workspaceId) {
      id
      name
      userRoleId
    }
  }
`;

const GET_WORKSPACES_AND_USERS = gql`
  query getCompanyWorkspacesAndUsers($companyId: ID!, $paginationOffset: Int) {
    company(id: $companyId) {
      id
      name
      planId
      workspaces(offset: $paginationOffset, limit: 5) {
        count
        offset
        items {
          id
          name
          address
          city
          state
          disabledAt
          deletedAt
        }
      }
      users {
        items {
          id
          firstName
          lastName
          email
          disabledAt
          deletedAt
          pendingInvite {
            uuid
          }
        }
      }
    }
  }
`;

const RETRIGGER_INVITE = gql`
  mutation ($userId: ID!, $whiteLabel: Boolean) {
    retriggerEmailInvite(userId: $userId, whiteLabel: $whiteLabel) {
      id
      email
    }
  }
`;

const REVOKING_INVITE = gql`
  mutation ($userId: ID!, $uuid: ID!) {
    revokingEmailInvite(userId: $userId, uuid: $uuid)
  }
`;

const DISABLE_USER = gql`
  mutation DisableUser($userId: ID!) {
    disableUser(userId: $userId)
  }
`;

const ENABLE_USER = gql`
  mutation EnableUser($userId: ID!) {
    enableUser(userId: $userId)
  }
`;

const REMOVE_USER = gql`
  mutation RemoveUser($userId: ID!) {
    removeUser(userId: $userId)
  }
`;

const DISABLE_WORKSPACE = gql`
  mutation DisableWorkspace($workspaceId: ID!) {
    disableWorkspace(workspaceId: $workspaceId)
  }
`;

const ENABLE_WORKSPACE = gql`
  mutation EnableWorkspace($workspaceId: ID!) {
    enableWorkspace(workspaceId: $workspaceId)
  }
`;

const REMOVE_WORKSPACE = gql`
  mutation RemoveWorkspace($workspaceId: ID!) {
    removeWorkspace(workspaceId: $workspaceId)
  }
`;

function CompanyWorkspacesAndUsers({ breadCrumbs }) {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);
  const userRole = currentWorkspace ? currentWorkspace.userRoleId || currentUser.roleId : null;
  const [showModal, setShowModal] = useState();
  const isSuperAdmin = usePermissionsWithRole("superAdmin");
  const { loading, data, refetch, fetchMore } = useQuery(GET_WORKSPACES_AND_USERS, {
    skip: !currentUser,
    notifyOnNetworkStatusChange: true,
    variables: {
      paginationOffset: 0,
      companyId: companyId || currentUser.company.id
    }
  });

  const [inviteModalData, setInviteModalData] = useState(null);
  const [retriggerUserInvite, { data: userInviteData, error: userInviteError }] = useMutation(RETRIGGER_INVITE);
  const [revokingUserInvite, { data: revokingInviteData, error: revokingInviteError }] = useMutation(REVOKING_INVITE);
  const [disableUserMutation] = useMutation(DISABLE_USER, {
    onCompleted: () => {
      setShowModal(null);
      refetch();
    }
  });
  const [enableUserMutation] = useMutation(ENABLE_USER, {
    onCompleted: () => {
      setShowModal(null);
      refetch();
    }
  });
  const [removeUserMutation] = useMutation(REMOVE_USER, {
    onCompleted: () => {
      setShowModal(null);
      refetch();
    }
  });
  const [disableWorkspaceMutation] = useMutation(DISABLE_WORKSPACE, {
    onCompleted: () => {
      setShowModal(null);
      refetch();
      dispatch({ type: "REFETCH_USER" });
    }
  });
  const [enableWorkspaceMutation] = useMutation(ENABLE_WORKSPACE, {
    onCompleted: () => {
      setShowModal(null);
      refetch();
      dispatch({ type: "REFETCH_USER" });
    }
  });
  const [removeWorkspaceMutation] = useMutation(REMOVE_WORKSPACE, {
    onCompleted: () => {
      setShowModal(null);
      refetch();
      dispatch({ type: "REFETCH_USER" });
    }
  });

  const [getWorkspace, { loading: gettingWorkspace, data: workspaceData }] = useLazyQuery(GET_WORKSPACE, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (workspaceData && workspaceData.workspace && !gettingWorkspace) {
      dispatch({ type: "CHANGE_WORKSPACE", payload: workspaceData.workspace });
      history.push("/admin/dashboard");
    }
  }, [workspaceData, gettingWorkspace, history, dispatch]);

  useEffect(() => {
    if (userInviteData && !userInviteError) {
      setInviteModalData({
        title: "Succesfully invited user.",
        body: <span>Successfully invited new user, and send email to: {userInviteData.retriggerEmailInvite.email}</span>
      });
    } else if (userInviteError) {
      let userInviteErrorMsg;
      if (userInviteError && userInviteError.graphQLErrors && userInviteError.graphQLErrors.length > 0) {
        userInviteErrorMsg = userInviteError.graphQLErrors[0].message;
      }
      setInviteModalData({
        title: "Unsuccessful inviting new user!",
        body: (
          <span>
            Failed inviting new user with error: <br />
            {userInviteErrorMsg}
          </span>
        )
      });
    }
  }, [userInviteData, userInviteError]);

  useEffect(() => {
    if (revokingInviteData && !revokingInviteError) {
      refetch();
      setInviteModalData({
        title: "Succesfully revoking invite user.",
        body: <span>Successfully revoking invite to the user</span>
      });
    } else if (revokingInviteError) {
      let userInviteErrorMsg;
      if (revokingInviteError && revokingInviteError.graphQLErrors && revokingInviteError.graphQLErrors.length > 0) {
        userInviteErrorMsg = revokingInviteError.graphQLErrors[0].message;
      }
      setInviteModalData({
        title: "Unsuccessful revoking invite user!",
        body: (
          <span>
            Failed revoking invite user with error: <br />
            {userInviteErrorMsg}
          </span>
        )
      });
    }
  }, [refetch, revokingInviteData, revokingInviteError]);

  const accessWorkspace = (workspace) => {
    getWorkspace({
      variables: {
        workspaceId: workspace.id
      }
    });
  };

  const hasPrivileges = ["admin", "superAdmin"].includes(userRole);
  const users = get(data, ["company", "users"], {}),
    userLength = get(users, "items", []).length,
    workspaces = get(data, ["company", "workspaces"], {}),
    workspaceLength = get(workspaces, "items", []).length;

  const companySubscription = useCompanySubscription(get(data, ["company", "id"]), get(data, ["company", "planId"])),
    limitUsers = getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_NUMBER_OF_USERS),
    limitWorkspaces = getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_NUMBER_OF_WORKSPACES),
    hasWhiteLabel = stringToBoolean(getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_WHITE_LABEL));

  return (
    <>
      <SimpleHeader name={data && data.company ? data.company.name : ""} breadCrumbs={breadCrumbs} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h5 className="mb-0">Company:</h5>
                <h1 className="mb-0 mt-0">{data && data.company ? data.company.name : null}</h1>
              </CardHeader>
              <CardBody>
                {loading ? <LoaderSpinner /> : null}
                <Row>
                  <Col sm="10">
                    <h3 className="mb-2 ml-4">Workspaces</h3>
                  </Col>
                  <Col sm="2" className="text-right">
                    {hasPrivileges && (
                      <Button
                        outline
                        color="primary"
                        size="sm"
                        className="mr-4"
                        onClick={(e) => {
                          e.preventDefault();
                          if (workspaceLength + 1 > limitWorkspaces && limitWorkspaces !== "-1")
                            setShowModal({
                              type: "planSelectionCard",
                              header: "Create new workspace!",
                              body: "Workspaces"
                            });
                          else setShowModal({ type: "createNewWorkspace" });
                        }}
                      >
                        + ADD NEW
                      </Button>
                    )}
                  </Col>
                </Row>
                <SinapiTable
                  data={workspaces}
                  isLoaded={!loading && data ? true : false}
                  onLoadMore={(offset) =>
                    fetchMore({
                      variables: {
                        paginationOffset: offset
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) {
                          return prev;
                        }
                        return Object.assign({}, prev, {
                          company: {
                            ...prev.company,
                            workspaces: fetchMoreResult.company.workspaces
                          }
                        });
                      }
                    })
                  }
                  options={{
                    limit: 5,
                    emptyLabel: `No Workspaces in ${data && data.company ? data.company.name : ""}.`,
                    columns: [
                      {
                        key: "name",
                        label: "Name",
                        render: (row) =>
                          row.deletedAt ? (
                            <span style={{ color: "red" }}>{row.name}</span>
                          ) : row.disabledAt ? (
                            <span style={{ color: "lightgray" }}>{row.name}</span>
                          ) : (
                            row.name
                          )
                      },
                      {
                        key: "address",
                        label: "Address",
                        render: (row) =>
                          row.deletedAt ? (
                            <span style={{ color: "red" }}>{row.address}</span>
                          ) : row.disabledAt ? (
                            <span style={{ color: "lightgray" }}>{row.address}</span>
                          ) : (
                            row.address
                          )
                      },
                      {
                        key: "city",
                        label: "City",
                        render: (row) =>
                          row.deletedAt ? (
                            <span style={{ color: "red" }}>{row.city}</span>
                          ) : row.disabledAt ? (
                            <span style={{ color: "lightgray" }}>{row.city}</span>
                          ) : (
                            row.city
                          )
                      },
                      {
                        key: "state",
                        label: "State",
                        render: (row) =>
                          row.deletedAt ? (
                            <span style={{ color: "red" }}>{row.state}</span>
                          ) : row.disabledAt ? (
                            <span style={{ color: "lightgray" }}>{row.state}</span>
                          ) : (
                            row.state
                          )
                      },
                      {
                        key: "disabledAt",
                        label: "Disable At",
                        render: (row) =>
                          row.deletedAt && row.disabledAt ? (
                            <span style={{ color: "red" }}>{format(row.disabledAt, "MMM d, yyyy")}</span>
                          ) : row.disabledAt ? (
                            <span style={{ color: "lightgray" }}>{format(row.disabledAt, "MMM d, yyyy")}</span>
                          ) : (
                            row.disabledAt
                          )
                      },
                      {
                        key: "options",
                        label: "",
                        className: "text-right",
                        render: (row, column) => (
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right positionFixed={true}>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => {
                                  e.preventDefault();
                                  let path = history.location.pathname;
                                  if (path.includes("/workspaces")) {
                                    path += "/" + row.id;
                                  } else {
                                    path += "/workspaces/" + row.id;
                                  }
                                  history.push(path);
                                }}
                              >
                                Users
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => {
                                  e.preventDefault();
                                  accessWorkspace(row);
                                }}
                              >
                                Access Workspace
                              </DropdownItem>
                              {hasPrivileges && (
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowModal({ type: "updateWorkspace", data: row });
                                  }}
                                >
                                  Modify
                                </DropdownItem>
                              )}
                              {hasPrivileges && !row.disabledAt && (
                                <DropdownItem
                                  href="#pablo"
                                  disabled={row.disabledAt}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowModal({ type: "disableWorkspace", data: row });
                                  }}
                                >
                                  Disable
                                </DropdownItem>
                              )}
                              {hasPrivileges && row.disabledAt && (
                                <DropdownItem
                                  href="#pablo"
                                  disabled={!row.disabledAt}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowModal({ type: "enableWorkspace", data: row });
                                  }}
                                >
                                  Enable
                                </DropdownItem>
                              )}
                              {hasPrivileges && (
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowModal({ type: "deleteWorkspace", data: row });
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )
                      }
                    ]
                  }}
                />
                <Row className="mt-4">
                  <Col sm="10">
                    <h3 className="mb-2 ml-4">Users</h3>
                  </Col>
                  <Col sm="2" className="text-right">
                    <Button
                      outline
                      color="primary"
                      size="sm"
                      className="mr-4"
                      onClick={(e) => {
                        e.preventDefault();
                        if (userLength + 1 > limitUsers && limitUsers !== "-1")
                          setShowModal({
                            type: "planSelectionCard",
                            header: `Invite User to ${data && data.company ? data.company.name : ""}`,
                            body: "Users"
                          });
                        else setShowModal({ type: "inviteNewUser" });
                      }}
                    >
                      + ADD NEW
                    </Button>
                  </Col>
                </Row>
                <Table className="align-items-center table-flush" responsive striped>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        Name
                      </th>
                      <th className="sort" data-sort="email" scope="col">
                        Email
                      </th>
                      <th scope="col">{hasPrivileges ? "Disable At" : "Access Date"}</th>
                      {isSuperAdmin ? <th scope="col">Deleted At</th> : null}
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {isEmpty(users) ? (
                      <tr>
                        <td>
                          <div className="m-4">{`No Users in ${data && data.company ? data.company.name : ""}.`}</div>
                        </td>
                      </tr>
                    ) : null}
                    {!isEmpty(users)
                      ? users.items.map((user) => (
                          <tr
                            key={user.id}
                            style={{ color: user.deletedAt ? "red" : user.disabledAt ? "lightgray" : "unset" }}
                          >
                            <td className="name">
                              {(user.firstName || "") + " " + (user.lastName || "")}
                              {user.pendingInvite ? <i>(Pending Invite)</i> : null}
                            </td>
                            <td className="name">{user.email}</td>
                            <td className="access-date">
                              {hasPrivileges && user.disabledAt ? format(user.disabledAt, "MMM d, yyyy") : ""}
                            </td>
                            {isSuperAdmin && (
                              <td className="access-date">
                                {user.deletedAt ? format(user.deletedAt, "MMM d, yyyy") : ""}
                              </td>
                            )}
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right positionFixed={true}>
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      history.push(history.location.pathname + "/users/" + user.id);
                                    }}
                                  >
                                    Workspaces
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowModal({ type: "updateProfile", data: user });
                                    }}
                                  >
                                    Modify
                                  </DropdownItem>
                                  {user.pendingInvite ? (
                                    <>
                                      <DropdownItem
                                        onClick={(e) => {
                                          e.preventDefault();
                                          retriggerUserInvite({
                                            variables: { userId: user.id, whiteLabel: hasWhiteLabel }
                                          });
                                        }}
                                      >
                                        Retrigger User Invite
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setShowModal({ type: "revokingUserInvite", data: user });
                                        }}
                                      >
                                        Revoking Invitation
                                      </DropdownItem>
                                    </>
                                  ) : null}
                                  {hasPrivileges && !user.disabledAt && (
                                    <DropdownItem
                                      href="#pablo"
                                      disabled={user.disabledAt || currentUser.id === user.id}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setShowModal({ type: "disableProfile", data: user });
                                      }}
                                    >
                                      Disable
                                    </DropdownItem>
                                  )}
                                  {hasPrivileges && user.disabledAt && (
                                    <DropdownItem
                                      href="#pablo"
                                      disabled={!user.disabledAt || currentUser.id === user.id}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setShowModal({ type: "enableProfile", data: user });
                                      }}
                                    >
                                      Enable
                                    </DropdownItem>
                                  )}
                                  {hasPrivileges && !user.deletedAt && (
                                    <DropdownItem
                                      href="#pablo"
                                      disabled={currentUser.id === user.id}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setShowModal({ type: "deleteProfile", data: user });
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      {showModal && showModal.type === "updateProfile" ? (
        <ManagementModal onClose={() => setShowModal(null)} modalSize="lg">
          <Profile
            hideProfileCard={true}
            hideHeader={true}
            showRole={true}
            userId={showModal.data.id}
            hasChangePass={isSuperAdmin}
          />
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "createNewWorkspace" ? (
        <ManagementModal
          onClose={() => setShowModal(null)}
          header={<div>Create new workspace!</div>}
          buttons={[{ label: "Close", onClick: () => setShowModal(null) }]}
          classNames={{
            body: "pb-0",
            footer: "pt-0"
          }}
        >
          <WorkspaceForm setToEdit={true} onSave={refetch} />
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "updateWorkspace" ? (
        <ManagementModal
          onClose={() => setShowModal(null)}
          header={<div>Update workspace!</div>}
          buttons={[{ label: "Close", onClick: () => setShowModal(null) }]}
          classNames={{
            body: "pb-0",
            footer: "pt-0"
          }}
        >
          <WorkspaceForm workspaceId={showModal.data.id} setToEdit={false} onSave={refetch} />
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "inviteNewUser" ? (
        <InviteNewUserModal
          companyId={companyId || currentUser.company.id}
          hasWhiteLabel={hasWhiteLabel}
          onClose={(invited) => {
            setShowModal(null);
            if (invited) {
              refetch();
            }
          }}
        />
      ) : null}
      {showModal && showModal.type === "revokingUserInvite" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>Revoking user invite to {showModal.data.email}</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            {
              label: "Confirm",
              onClick: () => {
                setShowModal(null);
                revokingUserInvite({
                  variables: { userId: showModal.data.id, uuid: showModal.data.pendingInvite.uuid }
                });
              }
            }
          ]}
        >
          The user will no longer be able to register.
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "disableProfile" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>Disable User</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            {
              label: "Disable",
              onClick: () =>
                disableUserMutation({
                  variables: { userId: showModal.data.id }
                })
            }
          ]}
        >
          <p>
            Are you sure you want to disable{" "}
            <span className="font-weight-bold">"{getFullNameOrEmail(showModal.data)}"</span> user?
          </p>
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "enableProfile" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>Enable User</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            {
              label: "Enable",
              onClick: () =>
                enableUserMutation({
                  variables: { userId: showModal.data.id }
                })
            }
          ]}
        >
          <p>
            Are you sure you want to enable{" "}
            <span className="font-weight-bold">"{getFullNameOrEmail(showModal.data)}"</span> user?
          </p>
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "deleteProfile" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>Remove User</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            {
              label: "Remove",
              onClick: () =>
                removeUserMutation({
                  variables: { userId: showModal.data.id }
                })
            }
          ]}
        >
          <p>
            Are you sure you want to remove{" "}
            <span className="font-weight-bold">"{getFullNameOrEmail(showModal.data)}"</span> user?
          </p>
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "disableWorkspace" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>Disable Workspace</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            {
              label: "Disable",
              onClick: () =>
                disableWorkspaceMutation({
                  variables: { workspaceId: showModal.data.id }
                })
            }
          ]}
        >
          <p>
            Are you sure you want to disable <span className="font-weight-bold">"{showModal.data.name}"</span>{" "}
            workspace?
          </p>
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "enableWorkspace" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>Enable Workspace</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            {
              label: "Enable",
              onClick: () =>
                enableWorkspaceMutation({
                  variables: { workspaceId: showModal.data.id }
                })
            }
          ]}
        >
          <p>
            Are you sure you want to enable <span className="font-weight-bold">"{showModal.data.name}"</span> workspace?
          </p>
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "deleteWorkspace" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>Remove Workspace</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            {
              label: "Remove",
              onClick: () =>
                removeWorkspaceMutation({
                  variables: { workspaceId: showModal.data.id }
                })
            }
          ]}
        >
          <p>
            Are you sure you want to remove <span className="font-weight-bold">"{showModal.data.name}"</span> workspace?
          </p>
        </ManagementModal>
      ) : null}
      {inviteModalData ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setInviteModalData(null)}
          header={<div>{inviteModalData.title}</div>}
          buttons={[{ label: "Ok", onClick: () => setInviteModalData(null) }]}
        >
          {inviteModalData.body}
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "planSelectionCard" ? (
        <ManagementModal
          onClose={() => setShowModal(null)}
          header={<div>{upperCase(showModal.header)}</div>}
          buttons={[{ label: "Close", onClick: () => setShowModal(null) }]}
        >
          <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
            <h2 className="font-weight-400 text-justify">
              Please{" "}
              <Link className="font-weight-bold btn btn-primary" to="/admin/upgrade">
                Upgrade your account
              </Link>
              {` to create additional ${showModal.body}. You have already reached the limit allowed by your current plan.`}
            </h2>
          </CardHeader>
          <CompanySubscription
            companyData={currentUser.company}
            plans={companySubscription.plans}
            price={companySubscription.compPrice}
            showDetails={false}
          />
        </ManagementModal>
      ) : null}
    </>
  );
}

CompanyWorkspacesAndUsers.defaultProps = {};

CompanyWorkspacesAndUsers.propTypes = {};

export default CompanyWorkspacesAndUsers;
