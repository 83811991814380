import React, { useState, useEffect } from "react";
import update from "immutability-helper";
import { useSinapiDragAndDrop } from "./SinapiDragAndDropProvider";
import { SinapiDropAreaWrapper } from "./SinapiDropAreaWrapper";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
/**
 * Renders the list of items, passing each item into the child. This supports
 * dragging and dropping items into a list.
 * @param {object} props
 */
export function SinapiDragDropList({
  children,
  items,
  id,
  onDrop,
  accept,
  customDropArea,
  getRowStyle,
  includeDropContainer
}) {
  const sinapiDndContext = useSinapiDragAndDrop();
  const [list, setList] = useState([...items]);

  useEffect(() => {
    setList([...items]);
  }, [items]);

  const dispatch = useDispatch();

  useEffect(() => {
    // Check if the user is hovering over this drop area - dropAreaId
    if (sinapiDndContext.hoverData && sinapiDndContext.dragging && sinapiDndContext.hoverData.dropAreaId === id) {
      const spliceItems = [];
      // Check if the item being dragged is an item from the list itself, if so we need to remove that item first
      if (sinapiDndContext.dragging.parentDropAreaId && id === sinapiDndContext.dragging.parentDropAreaId) {
        const itemIndex = items.findIndex((item) => item.id === sinapiDndContext.dragging.id);
        if (itemIndex !== -1) {
          spliceItems.push([itemIndex, 1]);
        }
      }
      // Add drop area item to the list
      spliceItems.push([sinapiDndContext.hoverData.index, 0, { dragItem: true }]);
      setList(
        update(items, {
          $splice: spliceItems
        })
      );
    } else if (list.find((item) => item.dragItem) || !sinapiDndContext.dragging) {
      setList(items.filter((item) => !sinapiDndContext.dragging || item.id !== sinapiDndContext.dragging.id));
    }
  }, [sinapiDndContext.hoverData]);

  const openSideNav = () => {
    dispatch({
      type: 'SELECT_ACTIVE_EDIT_OPTION',
      payload: {
        type: 'elements'
      }
    })
  }

  const renderList = () => {
    return list.map((item, index) => {
      if (!item.dragItem) {
        return (
          <SinapiDropAreaWrapper
            key={item.id || index}
            index={index}
            dropAreaId={id}
            accept={accept}
            style={getRowStyle ? getRowStyle(item, index) : {}}
            onDrop={(draggedItem) => {
              if (onDrop) {
                onDrop(draggedItem, index);
              }
            }}
          >
            {children(item, index)}
          </SinapiDropAreaWrapper>
        );
      }
      return (
        <SinapiDropAreaWrapper
          accept={accept}
          key="drop-area"
          index={index}
          isDropArea={true}
          onDrop={(draggedItem) => {
            if (onDrop) {
              onDrop(draggedItem, index);
            }
          }}
          style={getRowStyle ? getRowStyle(item, index) : {}}
          dropAreaId={id}
        >
          {customDropArea ? customDropArea : <div>Drop Item Here</div>}
        </SinapiDropAreaWrapper>
      );
    });
  };

  if (!list || list.length === 0) {
    return (
      <SinapiDropAreaWrapper
        accept={accept}
        key="drop-area"
        index={0}
        isDropArea={true}
        onDrop={(draggedItem) => {
          if (onDrop) {
            onDrop(draggedItem, 0);
          }
        }}
        dropAreaId={id}
      >
        <div className="field-drop-area text-center pt-2" style={{ display: "block" }}>
          Drop Question Here
          <br />
          - or -
          <br />
          <Button color="primary" size="sm" onClick={openSideNav} >+ Add Question</Button>
        </div>
      </SinapiDropAreaWrapper>
    );
  }

  if (includeDropContainer) {
    return (
      <SinapiDropAreaWrapper
        accept={accept}
        index={list.length}
        onDrop={(draggedItem) => {
          if (onDrop) {
            onDrop(draggedItem, list.length);
          }
        }}
        isWrapper={true}
        dropAreaId={id}
        style={{ width: "100%" }}
      >
        {renderList()}
      </SinapiDropAreaWrapper>
    );
  }
  return renderList();
}
