import ManagementPage from "./ManagementPage.jsx";
import CompanyWorkspacesAndUsers from "./CompanyWorkspacesAndUsers.jsx";
import WorkspaceUsers from "./WorkspaceUsers.jsx";
import UserWorkspaces from "./UserWorkspaces.jsx";

const accountRoutes = [
  {
    path: "/",
    exact: true,
    hidden: true,
    component: ManagementPage,
    icon: "ni ni-single-02 text-yellow",
    name: "Account",
    layout: "/account"
  },
  {
    name: "Workspaces And Users",
    path: "/workspaces",
    exact: true,
    icon: "ni ni-shop text-primary",
    layout: "/account",
    roles: ["superAdmin", "admin", "manager"],
    component: CompanyWorkspacesAndUsers,
    views: [
      {
        name: "User",
        path: "/workspaces/users/:userId",
        component: UserWorkspaces,
        roles: ["superAdmin", "admin", "manager"],
        layout: "/account"
      },
      {
        name: "Workspace",
        path: "/workspaces/:workspaceId",
        component: WorkspaceUsers,
        roles: ["superAdmin", "admin"],
        layout: "/account"
      }
    ]
  }
];

export { accountRoutes };
export default accountRoutes;
