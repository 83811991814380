import React from "react";

const CardIcons = {
  mastercard: "mastercard-2.png",
  visa: "visa-2.png",
  amex: "amex.png"
};

const ShowPaymentMethod = ({
  paymentMethod,
  onNewCard,
  isTrial,
  onTrial,
  trialPeriodDays,
  totalCharge,
  isAnUpgrade
}) => {
  const useNewCard = (e) => {
    e.preventDefault();
    if (onNewCard) {
      onNewCard();
    }
  };
  return (
    <>
      {!isTrial ? (
        <div>
          <h4 className="mb-0">{onTrial ? `Charge once trial ends: ` : `Today's charge: `}</h4>
          <span className="font-weight-600 text-info" style={{ fontSize: "1.8rem" }}>{`${totalCharge}.00`}</span>
          {isAnUpgrade && (
            <h5 className="text-muted">
              <small>
                You will be charged simply for the difference in price from you active subscription to the new one
                you've selected. The amount will be pro-rated based on the time left in the billing cycle.
              </small>
            </h5>
          )}
        </div>
      ) : null}
      {isTrial ? <h4 className="mb-0">{trialPeriodDays} Day Trial</h4> : null}
      <div className="mt-3">
        <h4>Payment Method</h4>
        {CardIcons[paymentMethod.card.brand] ? (
          <img width="37px" alt="..." src={require("assets/img/icons/cards/" + CardIcons[paymentMethod.card.brand])} />
        ) : null}
        <span className="ml-2">ending in {paymentMethod.card.last4}</span>
        <a href="" onClick={useNewCard} className="ml-3">
          <small>Use New Card</small>
        </a>
      </div>
    </>
  );
};

export { ShowPaymentMethod };
