import React from "react";
import { Button } from "reactstrap";

import "./TrialHeaderBar.scss";

const FreeHeaderBar = ({ onUpgrade }) => {
  return (
    <div className="trial-header-bar alert-default">
      <span style={{ display: "flex" }}>
        Free Mode - Upgrade to get full access -
        <Button className="upgrade-btn btn-neutral btn-default btn-sm" onClick={() => onUpgrade()}>
          Upgrade Account
        </Button>
      </span>
    </div>
  );
};

export default FreeHeaderBar;
