import React from "react";
import { Input, FormGroup } from "reactstrap";
import { getUniqueID, defaultOnChange } from "./HelperFunctions";
import { FieldLabel } from "./FieldLabel";
import { optionWithIcon } from "./fieldUtils";

const SelectField = (props) => {
  const { id: questionID, options, value, inputFontsize } = props.question;
  const { onChange = defaultOnChange } = props;
  return (
    <>
      <FieldLabel question={props.question} />
      <Input
        type="select"
        id={questionID}
        className="form-control-sm"
        onChange={onChange}
        value={value}
        style={{ height: `calc(${inputFontsize} + 22px)` }}
      >
        {options.map((option, i) => {
          const { name, id } = option;
          return (
            <option key={i} id={`option${id}`} value={id}>
              {name}
            </option>
          );
        })}
      </Input>
    </>
  );
};

const PreviewSelectField = () => optionWithIcon("fas fa-list-ul", "Select Dropdown");

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Select",
  description: "<p>Please Select An Option</p>",
  required: false,
  type: "select",
  dollarValue: true,
  affectCost: "fixed-cost",
  costStructure: ["cost"],
  options: [
    {
      id: `0`,
      name: "- Select Option -",
      cost: 0,
      cost_type: "+",
      daily: 0,
      daily_type: "+",
      monthly: 0,
      monthly_type: "+",
      yearly: 0,
      yearly_type: "+"
    },
    {
      id: `${getUniqueID()}_1`,
      name: "Option 1",
      icon: "",
      cost: 0,
      cost_type: "+",
      daily: 0,
      daily_type: "+",
      monthly: 0,
      monthly_type: "+",
      yearly: 0,
      yearly_type: "+"
    },
    {
      id: `${getUniqueID()}_2`,
      name: "Option 2",
      icon: "",
      cost: 0,
      cost_type: "+",
      daily: 0,
      daily_type: "+",
      monthly: 0,
      monthly_type: "+",
      yearly: 0,
      yearly_type: "+"
    },
    {
      id: `${getUniqueID()}_3`,
      name: "Option 3",
      icon: "",
      cost: 0,
      cost_type: "+",
      daily: 0,
      daily_type: "+",
      monthly: 0,
      monthly_type: "+",
      yearly: 0,
      yearly_type: "+"
    }
  ]
});

const SelectEditOptions = (json, onInputChange) => {
  return (
    <>
      <FormGroup>
        <label className="form-control-label">
          <div>Prefix</div>
        </label>
        <Input
          placeholder="Default input"
          className="form-control-sm"
          type="text"
          name="prefix"
          value={json.hasOwnProperty("prefix") ? json.prefix : ""}
          onChange={(e) => onInputChange({ e })}
        />
      </FormGroup>
      <FormGroup>
        <label className="form-control-label">
          <div>Suffix</div>
        </label>
        <Input
          placeholder="Default input"
          className="form-control-sm"
          type="text"
          name="suffix"
          value={json.hasOwnProperty("suffix") ? json.suffix : ""}
          onChange={(e) => onInputChange({ e })}
        />
      </FormGroup>
    </>
  );
};

const fieldConfig = {
  key: "select",
  component: SelectField,
  getInitialData: getInitialData,
  previewComponent: PreviewSelectField,
  getEditOptions: SelectEditOptions
};

export default fieldConfig;
