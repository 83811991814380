import React, { useEffect, useState } from "react";

// reactstrap components
import { get, compact, find, head, isEmpty, map } from "lodash";
import { Card, CardHeader, CardBody, Col, Input, Label } from "reactstrap";
import { CheckoutForm } from "../Stripe/CheckoutForm";

const CheckoutCard = ({
  plan,
  onFailed,
  onSuccess,
  user,
  isTrial,
  isPastDue,
  isFreePlan,
  onTrial,
  paymentMethod,
  setupCost,
  isAnUpgrade,
  salesRep,
  subscription
}) => {
  const [price, setPrice] = useState([]);
  const [priceNoInterval, setPriceNoInterval] = useState();
  const [priceId, setPriceId] = useState(
    head(compact(map(plan.price, (pr) => (pr.interval === "mo" ? pr.id : undefined))))
  );

  useEffect(() => {
    if (!setupCost) setPriceNoInterval(head(compact(map(plan.price, (pr) => (pr.interval === "" ? pr : undefined)))));
  }, [plan, isTrial, setupCost]);

  useEffect(() => {
    if(subscription?.discount?.coupon) {
      const { percent_off, amount_off, valid } = subscription?.discount?.coupon;
      let totalPrice = find(plan.price, (price) => price.id === priceId);
      
      if(valid) {
        if(percent_off) {
          const cleanPrice = totalPrice.amount.replace("$", "");
          const discounted = cleanPrice * (percent_off/100);
          const discountedPrice = cleanPrice - discounted;
          totalPrice = {
            ...totalPrice,
            amount: `$${discountedPrice >= 0 ? discountedPrice  : 0 }`
          }
        } else if (amount_off) {
          const cleanPrice = totalPrice.amount.replace("$", "");
          const discountedPrice = cleanPrice - (amount_off/100);
          totalPrice = {
            ...totalPrice,
            amount: `$${discountedPrice >= 0 ? discountedPrice  : 0 }`
          }
        }
      }

      setPrice(totalPrice);
    } else {
      setPrice(find(plan.price, (price) => price.id === priceId));
    }
    
  }, [plan, priceId]);

  const getTotalCharge = (price) =>
    !price || isEmpty(price)
      ? "$ 0"
      : priceNoInterval
      ? `$${Number(priceNoInterval.amount.replace(/[^0-9\.]+/g, "")) + Number(price.amount.replace(/[^0-9\.]+/g, ""))}`
      : price.amount;

  return (
    <Card className="card-pricing border-0 mb-4">
      <CardHeader className="bg-transparent text-center">
        {isPastDue ? (
          <>
            <h4 className="text-uppercase ls-1 py-3 mb-0">Your trial has expired</h4>
            <p>Please activate your subscription to continue using Sinapi</p>
          </>
        ) : (
          <h4 className="text-uppercase ls-1 py-3 mb-0">Checkout</h4>
        )}
      </CardHeader>
      <CardBody className="px-lg-4 sinapi-plan-container">
        <Col sm="6">
          <Card className="text-center">
            <CardHeader>
              <h4>ORDER SUMMARY</h4>
              {!plan.hidden && (
                <div className="form-inline" style={{ justifyContent: "center" }}>
                  <Label className="form-control-label mb-0 mr-2">Billing Period</Label>
                  <Input
                    type="select"
                    className="form-control-sm"
                    onChange={(e) => setPriceId(e.target.value)}
                    value={priceId}
                  >
                    {plan.price.map((option, i) =>
                      option.nickname !== "disabled" && option.interval !== "" ? (
                        <option key={i} id={`option${option.id}`} value={option.id}>
                          {option.interval === "yr" ? "Year" : option.interval === "mo" ? "Month" : option.interval}
                        </option>
                      ) : null
                    )}
                  </Input>
                </div>
              )}
            </CardHeader>
            <CardBody>
              <div className="products">
                <h4 className="ls-1 pt-1 mb-0">{plan.name}</h4>
                {!price || isEmpty(price) ? (
                  <div className="justify-content-center align-items-center row">
                    <div className="display-4" style={{ fontSize: "1.35rem" }}>
                      $0
                    </div>
                  </div>
                ) : (
                  <div className="justify-content-center align-items-center row">
                    <div className="display-4" style={{ fontSize: "1.35rem" }}>
                      {price.amount}
                    </div>
                    <span className="text-muted">&nbsp;/{price.interval}</span>
                  </div>
                )}
              </div>
              {priceNoInterval && (
                <div className="products mt-3">
                  <h4 className="ls-1 pt-1 mb-0">Setup Cost</h4>
                  <div className="justify-content-center align-items-center row">
                    <div className="display-4" style={{ fontSize: "1.35rem" }}>
                      {priceNoInterval.amount}
                    </div>
                    <span className="text-muted">&nbsp;/once</span>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col sm="6">
          <CheckoutForm
            price={price}
            priceNoInterval={priceNoInterval}
            totalCharge={getTotalCharge(price)}
            onFailed={onFailed}
            onSuccess={onSuccess}
            user={user}
            isTrial={isTrial}
            isFreePlan={isFreePlan}
            paymentMethod={paymentMethod}
            onTrial={onTrial}
            trialPeriodDays={get(plan, ["trialPeriodDays"])}
            planId={plan.id}
            interval={plan.hidden ? "-" : price.interval === "yr" ? "year" : "month"}
            isAnUpgrade={isAnUpgrade}
            salesRep={salesRep}
          />
        </Col>
      </CardBody>
    </Card>
  );
};

export { CheckoutCard };
