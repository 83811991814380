/**
 * FieldRegistry class keeps track of all the registered fields. Fields are registered on initialization or
 * through the 'addField' method.
 */
export class FieldRegistry {
  constructor(initialFields) {
    this.registry = {};
    for (const field of initialFields) {
      this.registry[field.key] = field;
    }
  }

  getFieldComponent(key) {
    const field = this.registry[key];
    if (field && field.component) {
      return field.component;
    }
    return null;
  }

  getFieldInitialData(key) {
    const field = this.registry[key];
    if (field && field.getInitialData) {
      return field.getInitialData();
    }
    return null;
  }

  getAllFields() {
    return Object.keys(this.registry)
      .map((key) => this.registry[key])
      .filter((field) => !field.hidden);
  }

  addField(key, fieldData) {
    if (!this.registry[key]) {
      this.registry[key] = fieldData;
    }
  }
}
