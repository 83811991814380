import "./Register.css";
import React, { useEffect, useRef, useState } from "react";
import { useParams, Redirect } from "react-router-dom";

// nodejs library that concatenates classes
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { UserRegistrationCard } from "components/Registration";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { isAgency } from "utils/helperFunctions";

const GET_INVITATION = gql`
  query getInvitation($invitationId: ID!) {
    getInvite(id: $invitationId) {
      email
      user {
        id
        company {
          name
        }
      }
    }
  }
`;

const REGISTER_USER = gql`
  mutation RegisterUser($user: InvitedUserInput) {
    registerInvitedUser(user: $user) {
      firstName
      lastName
      email
    }
  }
`;

const LOGIN_USER = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
    }
  }
`;

/*
 * If register?plan=premium the plan by id 'premium' will be automatically selected.
 */
const RegisterForInvite = () => {
  const { inviteCode } = useParams();
  const { data, loading } = useQuery(GET_INVITATION, {
    variables: {
      invitationId: inviteCode
    },
    skip: !inviteCode
  });
  const loginInfo = useRef();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [registerUser, { data: newUser, error: registerError, loading: signupLoading }] = useMutation(REGISTER_USER);
  const [loginUser] = useMutation(LOGIN_USER, { onCompleted: () => setIsAuthenticated(true) });
  let registerErrorMsg;
  if (registerError && registerError.graphQLErrors && registerError.graphQLErrors.length > 0) {
    registerErrorMsg = registerError.graphQLErrors[0].message;
  }

  useEffect(() => {
    if (newUser && !signupLoading && loginInfo.current) {
      const timer = setTimeout(() => {
        autoLogin();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [newUser]);

  function onSignup({ firstName, lastName, email, password }) {
    loginInfo.current = { email: email, password: password };
    registerUser({
      variables: {
        user: { firstName: firstName, lastName: lastName, email: email, password: password, invitationId: inviteCode }
      }
    });
  }

  function autoLogin() {
    loginUser({
      variables: {
        email: loginInfo.current.email,
        password: loginInfo.current.password
      }
    });    
  }

  if (loading) {
    return <LoaderSpinner />;
  }

  if (isAuthenticated) {
    return <Redirect to="/admin/dashboard" />;
  }

  return (
    <>
      <AuthHeader
        loaded={isAgency ? false : true}
        title={`You are invited to join ${data.getInvite.user.company.name}`}
        lead="Please finish creating your account."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col xs="12">
            <UserRegistrationCard
              isInvite={true}
              inviteEmail={data.getInvite.email}
              onSignup={onSignup}
              signupErrorMsg={registerErrorMsg}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RegisterForInvite;
