import React, { useEffect } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { get, isArray, join, map, sum } from "lodash";
import { Card, CardBody, Button, Container, Table, FormGroup, CardFooter, Col } from "reactstrap";
import InnerHTML from "dangerously-set-html-content";

import "./FormSuccessPageSection.scss";
import { formHasCost, numberToCurrency } from "utils/helperFunctions";

// Creating a namespaced theming object.
let useStyles = createUseStyles((data) => {
  const { fontImport, ...styles } = data;
  return {
    "@import": fontImport || [],
    sinapiForm: {
      ...styles
    }
  };
});

export function FormSuccessPageSection({
  cost,
  schema,
  maxWidth,
  successPageData,
  onManagePage,
  quoteJSON,
  onConfirm,
  notInterested,
  startOverAction,
  confirmationMSG,
  backAction,
  sampleContainer,
  sendEmailConfirmation,
}) {
  const manageSuccessPage = () => {
    if (onManagePage) {
      onManagePage();
    }
  };

  useEffect(() => {
    if (sendEmailConfirmation) {
      sendEmailConfirmation();
    }
  }, []);

  const allowBackButton = get(schema, ["options", "allowBackAfterPrice"], "allow");
  const cssTheme = useTheme();
  const classes = useStyles({ theme: cssTheme });
  const style = sampleContainer
    ? { width: "100%", height: "100%", backgroundColor: "unset", backgroundImage: "unset" }
    : { width: "100%", height: "100%", backgroundSize: "cover" };
  return (
    <Container fluid className={"section-container mobile-remove-padding " + classes.sinapiForm} style={style}>
      <div
        className={"centered-form mt-4 mobile-remove-mt"}
        style={{
          maxWidth: maxWidth + "px",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "1.5rem",
          height: "fit-content",
          width: window.innerWidth < 768 ? "inherit" : "unset"
        }}
      >
        <Card
          className="form-success-page"
          style={{ marginBottom: "30px", width: window.innerWidth < 768 ? "unset" : maxWidth + "px" }}
        >
          <CardBody className="mobile-set-padding-15">
            {onManagePage && (
              <div className="question-toolbar">
                <div className="field-option-btn" onClick={manageSuccessPage}>
                  Manage Success Page
                  <i className="fas fa-cog" />
                </div>
              </div>
            )}
            <FormGroup>
              <h1 className="text-center sinapi-form-headline">{get(successPageData, ["section", "header"])}</h1>
              <p className="text-center sinapi-form-sub-headline">{get(successPageData, ["section", "subHeader"])}</p>
            </FormGroup>
            <div
              className="separator mt-4 mb-3"
              style={{ borderTop: "1px solid lightgray", position: "unset", height: "auto" }}
            />
            {formHasCost(cost) !== 0 || sampleContainer ? (
              <>
                {!get(successPageData, ["section", "hidePrice"], false) && (
                  <>
                    <h4 className="text-center sinapi-form-sub-headline" style={{ fontWeight: "600" }}>
                    {get(successPageData, ["section", "priceHeader"], "We estimate your services will cost around:")}
                    </h4>
                    {get(cost, ["cost", "expected"], 0) !== 0 ? (
                      <FormGroup style={{ marginBottom: "0.5rem" }}>
                        <div id="costs-primary">
                          <FormGroup style={{ marginBottom: "0.5rem" }}>
                            <div id="costs-one-time">
                              <h1 className="text-center">
                                <span className="display-3 text-center text-green costs-expected">
                                  {numberToCurrency(get(cost, ["cost", "expected"], 0))}
                                </span>
                              </h1>
                            </div>
                            {(get(cost, ["cost", "expected"], 0) !== get(cost, ["cost", "low"], 0) ||
                              get(cost, ["cost", "expected"], 0) !== get(cost, ["cost", "high"], 0)) && (
                              <p className="text-center form-control-label" style={{ fontWeight: "300" }}>
                                With a range around{" "}
                                <span className="text-blue">
                                  <strong>
                                    <span className="costs-low">{numberToCurrency(get(cost, ["cost", "low"], 0))}</span> -{" "}
                                    <span className="costs-high">{numberToCurrency(get(cost, ["cost", "high"], 0))}</span>
                                  </strong>
                                </span>
                              </p>
                            )}
                          </FormGroup>
                        </div>
                        <div id="costs-secondary">
                        {(get(cost, ["daily", "expected"], 0) !== 0 ||
                            get(cost, ["monthly", "expected"], 0) !== 0 ||
                            get(cost, ["yearly", "expected"], 0) !== 0) && (
                            <>
                              <hr className="my-3" style={{ width: 60 }} />
                              <h4 className="text-center sinapi-form-sub-headline" style={{ fontWeight: "600" }}>
                                With an additional
                              </h4>
                              {get(cost, ["daily", "expected"], 0) !== 0 && (
                                <FormGroup style={{ marginBottom: "0.5rem" }}>
                                  <div id="costs-per-day">
                                    <h1 className="text-center">
                                      <span className="text-center text-green costs-expected">
                                        {numberToCurrency(get(cost, ["daily", "expected"], 0))} <small style={{ fontWeight: "600" }}>/day</small>
                                      </span>
                                    </h1>
                                    {(get(cost, ["daily", "expected"], 0) !== get(cost, ["daily", "low"], 0) ||
                                      get(cost, ["daily", "expected"], 0) !== get(cost, ["daily", "high"], 0)) && (
                                      <p className="text-center form-control-label" style={{ fontWeight: "300" }}>
                                        With a range around{" "}
                                        <span className="text-blue">
                                          <strong id="per-day-cost-range">
                                            <span className="costs-low">{numberToCurrency(get(cost, ["daily", "low"], 0))}</span><small>/day</small> -{" "}
                                            <span className="costs-high">{numberToCurrency(get(cost, ["daily", "high"], 0))}</span><small>/day</small>
                                          </strong>
                                        </span>
                                      </p>
                                    )}
                                  </div>
                                </FormGroup>
                              )}
                              {get(cost, ["monthly", "expected"], 0) !== 0 && (
                                <FormGroup style={{ marginBottom: "0.5rem" }}>
                                  <div id="costs-per-month">
                                    <h1 className="text-center">
                                      <span className="text-center text-green costs-expected">
                                        {numberToCurrency(get(cost, ["monthly", "expected"], 0))} <small style={{ fontWeight: "600" }}>/month</small>
                                      </span>
                                    </h1>
                                    {(get(cost, ["monthly", "expected"], 0) !== get(cost, ["monthly", "low"], 0) ||
                                      get(cost, ["monthly", "expected"], 0) !== get(cost, ["monthly", "high"], 0)) && (
                                      <p className="text-center form-control-label" style={{ fontWeight: "300" }}>
                                        With a range around{" "}
                                        <span className="text-blue">
                                          <strong id="per-month-cost-range">
                                            <span className="costs-low">{numberToCurrency(get(cost, ["monthly", "low"], 0))}</span><small>/mo</small> -{" "}
                                            <span className="costs-high">{numberToCurrency(get(cost, ["monthly", "high"], 0))}</span><small>/mo</small>
                                          </strong>
                                        </span>
                                      </p>
                                    )}
                                  </div>
                                </FormGroup>
                              )}
                              {get(cost, ["yearly", "expected"], 0) !== 0 && (
                                <FormGroup style={{ marginBottom: "0.5rem" }}>
                                  <div id="costs-per-year">
                                    <h1 className="text-center">
                                      <span className="text-center text-green costs-expected">
                                        {numberToCurrency(get(cost, ["yearly", "expected"], 0))} <small style={{ fontWeight: "600" }}>/year</small>
                                      </span>
                                    </h1>
                                    {(get(cost, ["yearly", "expected"], 0) !== get(cost, ["yearly", "low"], 0) ||
                                      get(cost, ["yearly", "expected"], 0) !== get(cost, ["yearly", "high"], 0)) && (
                                      <p className="text-center form-control-label" style={{ fontWeight: "300" }}>
                                        With a range around{" "}
                                        <span className="text-blue">
                                          <strong>
                                            <span className="costs-low">{numberToCurrency(get(cost, ["yearly", "low"], 0))}</span><small>/yr</small> -{" "}
                                            <span className="costs-high">{numberToCurrency(get(cost, ["yearly", "high"], 0))}</span><small>/yr</small>
                                          </strong>
                                        </span>
                                      </p>
                                    )}
                                  </div>
                                </FormGroup>
                              )}
                            </>
                          )}
                      </div>
                      </FormGroup>
                    ) : (
                      <>
                        {get(cost, ["daily", "expected"], 0) !== 0 && (
                          <FormGroup style={{ marginBottom: "0.5rem" }}>
                            <div id="costs-per-day">
                              <h1 className="text-center">
                                <span className="display-3 text-center text-green costs-expected">
                                  {numberToCurrency(get(cost, ["daily", "expected"], 0))} <small style={{ fontWeight: "600" }}>/day</small>
                                </span>
                              </h1>
                              {(get(cost, ["daily", "expected"], 0) !== get(cost, ["daily", "low"], 0) ||
                                get(cost, ["daily", "expected"], 0) !== get(cost, ["daily", "high"], 0)) && (
                                <p className="text-center form-control-label" style={{ fontWeight: "300" }}>
                                  With a range around{" "}
                                  <span className="text-blue">
                                    <strong>
                                      <span className="costs-low">{numberToCurrency(get(cost, ["daily", "low"], 0))}</span><small>/day</small> -{" "}
                                      <span className="costs-high">{numberToCurrency(get(cost, ["daily", "high"], 0))}</span><small>/day</small>
                                    </strong>
                                  </span>
                                </p>
                              )}
                            </div>
                          </FormGroup>
                        )}
                        {get(cost, ["monthly", "expected"], 0) !== 0 && (
                          <FormGroup style={{ marginBottom: "0.5rem" }}>
                            <div id="costs-per-month">
                              <h1 className="text-center">
                                <span className="display-3 text-center text-green costs-expected">
                                  {numberToCurrency(get(cost, ["monthly", "expected"], 0))} <small style={{ fontWeight: "600" }}>/month</small>
                                </span>
                              </h1>
                              {(get(cost, ["monthly", "expected"], 0) !== get(cost, ["monthly", "low"], 0) ||
                                get(cost, ["monthly", "expected"], 0) !== get(cost, ["monthly", "high"], 0)) && (
                                <p className="text-center form-control-label" style={{ fontWeight: "300" }}>
                                  With a range around{" "}
                                  <span className="text-blue">
                                    <strong>
                                      <span className="costs-low">{numberToCurrency(get(cost, ["monthly", "low"], 0))}</span><small>/mo</small> -{" "}
                                      <span className="costs-high">{numberToCurrency(get(cost, ["monthly", "high"], 0))}</span><small>/mo</small>
                                    </strong>
                                  </span>
                                </p>
                              )}
                            </div>
                          </FormGroup>
                        )}
                        {get(cost, ["yearly", "expected"], 0) !== 0 && (
                          <FormGroup style={{ marginBottom: "0.5rem" }}>
                            <div id="costs-per-year">
                              <h1 className="text-center">
                                <span className="display-3 text-center text-green costs-expected">
                                  {numberToCurrency(get(cost, ["yearly", "expected"], 0))} <small style={{ fontWeight: "600" }}>/year</small>
                                </span>
                              </h1>
                              {(get(cost, ["yearly", "expected"], 0) !== get(cost, ["yearly", "low"], 0) ||
                                get(cost, ["yearly", "expected"], 0) !== get(cost, ["yearly", "high"], 0)) && (
                                <p className="text-center form-control-label" style={{ fontWeight: "300" }}>
                                  With a range around{" "}
                                  <span className="text-blue">
                                    <strong>
                                      <span className="costs-low">{numberToCurrency(get(cost, ["yearly", "low"], 0))}</span><small>/yr</small> -{" "}
                                      <span className="costs-high">{numberToCurrency(get(cost, ["yearly", "high"], 0))}</span><small>/yr</small>
                                    </strong>
                                  </span>
                                </p>
                              )}
                            </div>
                          </FormGroup>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <FormGroup>
                <p className="text-center sinapi-form-sub-headline" style={{ width: "85%", margin: "auto" }}>
                  Based on your responses we are unable to provide an immediate estimate. We'll connect with you
                  shortly, and look forward to helping you.
                </p>
              </FormGroup>
            )}
            <FormGroup>
              <p className="text-center sinapi-form-sub-headline">{get(successPageData, ["section", "footer"])}</p>
            </FormGroup>
            <div className="success-footer-buttons">
              {confirmationMSG ? (
                <>
                  <div role="alert" style={{ marginTop: "16px", borderColor: "#eee" }} className="alert alert-secondary fade show">
                    <InnerHTML html={confirmationMSG} />
                  </div>
                  <Button className="mb-2" outline color="primary" onClick={startOverAction}>
                    Start Over
                  </Button>
                </>
              ) : (
                <>
                  <Button className="mb-2" outline color="secondary" onClick={notInterested}>
                    {(successPageData && successPageData.section && successPageData.section.notInterestedButtonText) ||
                      ""}
                  </Button>
                  <Button className="mb-2" outline color="primary" onClick={onConfirm}>
                    {(successPageData && successPageData.section && successPageData.section.confirmButtonText) || ""}
                  </Button>
                </>
              )}
            </div>
            {successPageData && successPageData.section && successPageData.section.showOrderTable && quoteJSON ? (
              <>
                <div
                  className="separator mt-4 mb-3"
                  style={{ borderTop: "1px solid lightgray", position: "unset", height: "auto" }}
                />
                <FormGroup>
                  <p className="text-center font-weight-600 sinapi-form-sub-headline">
                    {get(successPageData, ["section", "orderTable"])}
                  </p>
                </FormGroup>
                <Table className="align-items-center table-flush" responsive striped>
                  <thead>
                    <tr>
                      <th scope="col" className="btn-outline-primary">
                        Question
                      </th>
                      <th scope="col" className="btn-outline-primary">
                        Answer
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {quoteJSON.map((question, index) => {
                      const isMultiple = question.type === "radio" || question.type === "checkbox" || question.type === "select"
                      return <tr key={index}>
                      <td>
                        <span className="form-control-label" style={{ fontWeight: "300" }}>
                          {get(question, "title")}
                        </span>
                      </td>
                      <td>
                        <span className="form-control-label" style={{ fontWeight: "300" }}>
                          {isArray(get(question, ["answer"]))
                            ? join(map(get(question, ["answer"]), (answer) => answer.value))
                            : isArray(get(question, ["answer", isMultiple ? "label" : "value"]))
                            ? join(get(question, ["answer", isMultiple ? "label" : "value"]))
                            : get(question, ["answer", isMultiple ? "label" : "value"])}
                        </span>
                      </td>
                    </tr>
                    })}
                  </tbody>
                </Table>
              </>
            ) : null}
          </CardBody>
          <CardFooter style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
            <Col xs="12" className="pl-0 pr-0">
              {(!confirmationMSG && allowBackButton !== "deny") && (
                <Button type="button" color="secondary" onClick={backAction}>
                  Back
                </Button>
              )}
            </Col>
          </CardFooter>
        </Card>
      </div>
    </Container>
  );
}
