import React, { useState, useEffect } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Table, Pagination, PaginationItem, PaginationLink, Col, Row, Card, UncontrolledTooltip } from "reactstrap";

import { useSortableData } from "hooks/useSortableData";
import LoaderSpinner from "components/Spinner/LoaderSpinner";

import "./SinapiTable.scss";

function pagination(current, last, delta) {
  delta = delta || 2;
  const left = current - delta;
  const right = current + delta;
  const range = [];

  // get the available ranges, this way we don't get any above the total, or below 0
  for (let i = 1; i <= last; i++) {
    if (i >= left && i <= right) {
      range.push(i);
    }
  }

  return range;
}

function SinapiTableSortable({ data, options, isLoaded, onLoadMore, onClick }) {
  const [paginationNumbers, setPaginationNumbers] = useState([]);

  useEffect(() => {
    if (isLoaded && data && data.count) {
      const currentPage = Math.ceil((data.offset + 1) / options.limit) || 1;
      setPaginationNumbers(pagination(currentPage, Math.ceil(data.count / options.limit)));
    }
  }, [data, isLoaded, options]);

  const onNextPage = (goToLast) => {
    if (data.offset + options.limit < data.count && onLoadMore) {
      let newOffset = data.offset + options.limit;
      if (goToLast) {
        // ex: 115 / 15 = 7.667, start offset at (7 * 15) = 105;
        const secondLastPage = Math.floor(data.count / options.limit);
        newOffset = options.limit * secondLastPage;
      }
      onLoadMore(newOffset);
    }
  };

  const onPreviousPage = (goToFirst) => {
    if (data.offset > 0 && onLoadMore) {
      const newOffset = goToFirst ? 0 : data.offset - options.limit;
      onLoadMore(newOffset < 0 ? 0 : newOffset);
    }
  };

  const goToPage = (pageNum) => {
    onLoadMore((pageNum - 1) * options.limit);
  };

  const currentPage = data && data.offset ? Math.ceil((data.offset + 1) / options.limit) || 1 : 1;

  const { items, requestSort, sortConfig } = useSortableData(get(data, "items", []));

  const getClassNamesFor = (name) => (!sortConfig ? "" : sortConfig.key === name ? sortConfig.direction : "");

  const getStyle = (style) => (style ? { ...style, cursor: "pointer" } : { cursor: "pointer" });

  const onClickRow = (event, row) => {
    event.preventDefault();
    event.stopPropagation();
    onClick(row);
  }

  return (
    <>
      <Card>
        <Table hover className="align-items-center table-flush sinapi-table" responsive striped>
          <thead className="thead-light">
            <tr>
              {options.columns.map((column) => (
                <th
                  key={column.key}
                  className={`${get(column, "className", "")} ${getClassNamesFor(column.sort)}`}
                  style={getStyle(column.style)}
                  onClick={() => requestSort(column.sort)}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          {!isLoaded ? (
            <tbody>
              <tr>
                <td>
                  <LoaderSpinner />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {(!data || data.count === 0) && options.emptyLabel ? (
                <tr>
                  <td>
                    <div className="m-4">{options.emptyLabel}</div>
                  </td>
                </tr>
              ) : null}
              {data && data.count > 0
                ? items.map((row, index) => (
                    <tr className="pointer" onClick={(e) => onClickRow(e, row)} key={row.id || index}>
                      {options.columns.map((column) =>
                        !column.render ? (
                          <td
                            className={column.key + ` ${column.className || ""}`}
                            id={`${column.key}_${index}`}
                            key={`${column.key}_${index}`}
                          >
                            {row[column.key] || ""}
                            {column.tooltip && column.tooltip(row) ? (
                              <UncontrolledTooltip
                                placement="top"
                                delay={{ hide: 0 }}
                                key={`${column.key}_${index}_tooltip`}
                                target={`${column.key}_${index}`}
                              >
                                {column.tooltip(row)}
                              </UncontrolledTooltip>
                            ) : null}
                          </td>
                        ) : (
                          <td
                            className={column.key + ` ${column.className || ""}`}
                            id={`${column.key}_${index}`}
                            key={`${column.key}_${index}`}
                          >
                            {column.render(row, column)}
                            {column.tooltip && column.tooltip(row) ? (
                              <UncontrolledTooltip
                                placement="top"
                                delay={{ hide: 0 }}
                                key={`${column.key}_${index}_rendered_tooltip`}
                                target={`${column.key}_${index}`}
                              >
                                {column.tooltip(row)}
                              </UncontrolledTooltip>
                            ) : null}
                          </td>
                        )
                      )}
                    </tr>
                  ))
                : null}
            </tbody>
          )}
        </Table>
        {options.limit && data && data.count > options.limit ? (
          <Row>
            <Col xs="12">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-center mb-3 mt-4 sinapi-table-pagination"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={data.offset === 0 ? "disabled" : ""}>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        onPreviousPage(true);
                      }}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">First</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={data.offset === 0 ? "disabled" : ""}>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        onPreviousPage();
                      }}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {paginationNumbers.map((num) => (
                    <PaginationItem className={currentPage === num ? "active" : ""} key={num}>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          goToPage(num);
                        }}
                      >
                        {num}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem className={data.offset + options.limit >= data.count ? "disabled" : ""}>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        onNextPage();
                      }}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={data.offset + options.limit >= data.count ? "disabled" : ""}>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        onNextPage(true);
                      }}
                    >
                      <i className="fas fa-angle-right" />
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Last</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </Col>
          </Row>
        ) : null}
      </Card>
    </>
  );
}

SinapiTableSortable.defaultProps = {};

SinapiTableSortable.propTypes = {
  data: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array
  }),
  options: PropTypes.shape({
    limit: PropTypes.number,
    emptyLabel: PropTypes.string,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        key: PropTypes.string,
        sort: PropTypes.string,
        render: PropTypes.func, // (row, column) => React.Element
        className: PropTypes.string
      })
    )
  }),
  isLoaded: PropTypes.bool,
  onLoadMore: PropTypes.func, // (offset, limit) => void,
  onClickRow: PropTypes.func
};

export default SinapiTableSortable;
