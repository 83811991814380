import {get} from "lodash"
const initialState = { loaded: false };

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "SET_CURRENT_USER":
      return { ...state, currentUser: payload.currentUser, currentWorkspace: payload.currentWorkspace, loaded: true };
    case "SET_CURRENT_USER_LOGIN":
      return { 
        ...state, 
        currentUser: payload.login, 
        currentWorkspace: get(payload, ["login","workspaces","items", 0]),
        loaded: true 
      };
    case "REFRESH_LOGO":
      return { ...state, logo: payload };
    case "REFETCH_USER":
      return { ...state, loaded: false };
    case "CHANGE_WORKSPACE":
      return { ...state, currentWorkspace: payload };
    case "LOGOUT":
      return { ...initialState };
    case "RESET_USER":
      return { ...initialState };

    default:
      return state;
  }
}