const initialState = "";

export default function modealReducer(state = initialState, { type, payload }) {
    switch (type) {
        case "SHOW_MODAL":
            return payload;
        case "HIDE_MODAL":
            return "";
        default:
            return state;
    }
}