import React, { useRef, useState } from "react";
import { Input, FormGroup } from "reactstrap";
import { getUniqueID, defaultOnChange } from "./HelperFunctions";
import { FieldLabel } from "./FieldLabel";
import { optionWithIcon } from "./fieldUtils";
import { isEmpty, findIndex, map } from "lodash";

const FormDate = (props) => {
  const values = useRef([]);
  const { id, separator, value, inputFontsize } = props.question;
  const { onChange = defaultOnChange } = props;
  const [disableEnd, setDisableEnd] = useState(value && value.length === 2 ? false : true);

  const onDateRanteChange = (event) => {
    if (isEmpty(values.current) && !isEmpty(value)) {
      values.current = [
        { id: `start_${event.target.name.split("_")[1]}`, value: value[0] },
        { id: `end_${event.target.name.split("_")[1]}`, value: value[1] }
      ];
    }
    if (isEmpty(values.current)) {
      values.current = [{ id: event.target.name, value: event.target.value }];
    } else {
      const idx = findIndex(values.current, (value) => value.id === event.target.name);
      idx !== -1
        ? (values.current[idx] = { ...values.current[idx], value: event.target.value })
        : (values.current = [...values.current, { id: event.target.name, value: event.target.value }]);
    }
    if (event.target.name.startsWith("start")) setDisableEnd(false);
    onChange({ target: { id: id, value: map(values.current, (input) => input.value), type: "daterange" } });
  };

  return (
    <>
      <FieldLabel question={props.question} />
      <div className="form-inline">
        <Input
          id={id}
          name={`start_${id}`}
          className="form-control-sm"
          type="date"
          onChange={onDateRanteChange}
          value={isEmpty(value) ? "" : value[0]}
          style={{ height: `calc(${inputFontsize} + 22px)` }}
        />
        <span>&nbsp;{separator}&nbsp;</span>
        <Input
          id={id}
          name={`end_${id}`}
          className="form-control-sm"
          type="date"
          onChange={onDateRanteChange}
          defaultValue={isEmpty(value) ? "" : value[1]}
          style={{ height: `calc(${inputFontsize} + 22px)` }}
          disabled={disableEnd}
        />
      </div>
    </>
  );
};

const PreviewFormDateRange = () => optionWithIcon("far far fa-calendar-alt", "DateRange picker");

const getInitialData = () => ({
  id: getUniqueID(),
  title: "DateRange Label",
  description: "<p>Description for the input</p>",
  required: false,
  separator: "",
  type: "daterange"
});

const DateRangeEditOptions = (json, onInputChange) => {
  return (
    <>
      <FormGroup>
        <label className="form-control-label">
          <div>Separator</div>
        </label>
        <Input
          placeholder=""
          className="form-control-sm"
          type="text"
          name="separator"
          defaultValue={json.hasOwnProperty("separator") ? json.separator : ""}
          onChange={(e) => onInputChange({ e })}
        />
      </FormGroup>
    </>
  );
};

const fieldConfig = {
  key: "daterange",
  component: FormDate,
  getInitialData: getInitialData,
  previewComponent: PreviewFormDateRange,
  getEditOptions: DateRangeEditOptions
};

export default fieldConfig;
