import "./SinapiTable.scss";
import React, { useState, useEffect } from "react";
import { Table, Pagination, PaginationItem, PaginationLink, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import LoaderSpinner from "components/Spinner/LoaderSpinner";

function pagination(current, last, delta) {
  delta = delta || 2;
  const left = current - delta;
  const right = current + delta;
  const range = [];

  // get the available ranges, this way we don't get any above the total, or below 0
  for (let i = 1; i <= last; i++) {
    if (i >= left && i <= right) {
      range.push(i);
    }
  }

  return range;
}

function SinapiTable({ data, options, isLoaded, onLoadMore }) {
  const [paginationNumbers, setPaginationNumbers] = useState([]);
  useEffect(() => {
    if (isLoaded && data.count) {
      const currentPage = Math.ceil((data.offset + 1) / options.limit) || 1;
      setPaginationNumbers(pagination(currentPage, Math.ceil(data.count / options.limit)));
    }
  }, [data]);
  const onNextPage = (goToLast) => {
    if (data.offset + options.limit < data.count && onLoadMore) {
      let newOffset = data.offset + options.limit;
      if (goToLast) {
        // ex: 115 / 15 = 7.667, start offset at (7 * 15) = 105;
        const secondLastPage = Math.floor(data.count / options.limit);
        newOffset = options.limit * secondLastPage;
      }
      onLoadMore(newOffset);
    }
  };

  const onPreviousPage = (goToFirst) => {
    if (data.offset > 0 && onLoadMore) {
      const newOffset = goToFirst ? 0 : data.offset - options.limit;
      onLoadMore(newOffset < 0 ? 0 : newOffset);
    }
  };

  const goToPage = (pageNum) => {
    onLoadMore((pageNum - 1) * options.limit);
  };

  const currentPage = data && data.offset ? Math.ceil((data.offset + 1) / options.limit) || 1 : 1;

  return (
    <>
      <Table className="align-items-center table-flush sinapi-table" responsive striped>
        <thead className="thead-light">
          <tr>
            {options.columns.map((column) => (
              <th
                scope="col"
                data-sort={column.sort}
                key={column.key}
                className={column.className}
                style={column.style}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        {!isLoaded ? (
          <tbody className="list">
            <tr>
              <td>
                <LoaderSpinner />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody className="list">
            {(!data || data.count === 0) && options.emptyLabel ? (
              <tr>
                <td>
                  <div className="m-4">{options.emptyLabel}</div>
                </td>
              </tr>
            ) : null}
            {data && data.count > 0
              ? data.items.map((row, index) => (
                  <tr key={row.id || index}>
                    {options.columns.map((column) => {
                      if (!column.render) {
                        return (
                          <td className={column.key + ` ${column.className || ""}`} key={column.key}>
                            {row[column.key] || ""}
                          </td>
                        );
                      } else {
                        return (
                          <td className={column.key + ` ${column.className || ""}`} key={column.key}>
                            {column.render(row, column)}
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))
              : null}
          </tbody>
        )}
      </Table>
      {options.limit && data && data.count > options.limit ? (
        <Row>
          <Col xs="12">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-center mb-3 mt-4 sinapi-table-pagination"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem className={data.offset === 0 ? "disabled" : ""}>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      onPreviousPage(true);
                    }}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">First</span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={data.offset === 0 ? "disabled" : ""}>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      onPreviousPage();
                    }}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {paginationNumbers.map((num) => (
                  <PaginationItem className={currentPage === num ? "active" : ""} key={num}>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        goToPage(num);
                      }}
                    >
                      {num}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem className={data.offset + options.limit >= data.count ? "disabled" : ""}>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      onNextPage();
                    }}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={data.offset + options.limit >= data.count ? "disabled" : ""}>
                  <PaginationLink
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      onNextPage(true);
                    }}
                  >
                    <i className="fas fa-angle-right" />
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Last</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </Col>
        </Row>
      ) : null}
    </>
  );
}

SinapiTable.defaultProps = {};

SinapiTable.propTypes = {
  data: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array
  }),
  options: PropTypes.shape({
    limit: PropTypes.number,
    emptyLabel: PropTypes.string,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        key: PropTypes.string,
        sort: PropTypes.string,
        render: PropTypes.func, // (row, column) => React.Element
        className: PropTypes.string
      })
    )
  }),
  isLoaded: PropTypes.bool,
  onLoadMore: PropTypes.func // (offset, limit) => void
};

export default SinapiTable;
