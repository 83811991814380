import "./FormThemePanel.scss";
import React, { useEffect, useState } from "react";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import classnames from "classnames";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Row,
  Col,
  FormGroup,
  CardBody,
  Collapse,
  ListGroupItem,
  ListGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import { SinapiFieldRegistry } from "components/FormComponents/fields";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useSelector, useDispatch } from "react-redux";
import { usePermissionsWithRole } from "hooks/usePermissionsWithRole";
import { formActions } from "reducers";
import { gql } from "apollo-boost";
import { themeOptions } from "./options";
import { ThemeProvider } from "react-jss";
import { get } from "lodash";
import { ThemePreview } from "./ThemePreview";
import { actionButtonStyles } from "./options/actionButtonStyles";
import ManagementModal from "components/UserManagement/ManagementModal";
import CompanySubscription from "components/Subscription/CompanySubscription";
import { getLimitSubscriptionPerFeatureType, reformatTheme, stringToBoolean } from "utils/helperFunctions";
import { FEATURE_CUSTOMIZE_THEMES, FEATURE_WHITE_LABEL, WHITE_LABELS } from "utils/constants";
import { Link } from "react-router-dom";

const SAVE_THEME = gql`
  mutation SaveFormTheme($themeId: ID, $workspaceId: ID!, $theme: ThemeInput!) {
    saveFormTheme(workspaceId: $workspaceId, themeId: $themeId, theme: $theme) {
      id
      type
      name
      styleSchema
    }
  }
`;

const MAKE_GLOBAL_THEME = gql`
  mutation MakeFormThemeGlobal($themeId: ID!, $workspaceId: ID!) {
    makeFormThemeGlobal(workspaceId: $workspaceId, themeId: $themeId) {
      id
    }
  }
`;

const REMOVE_THEME = gql`
  mutation RemoveFormTheme($themeId: ID, $workspaceId: ID!, $type: String!) {
    removeFormTheme(workspaceId: $workspaceId, themeId: $themeId, type: $type)
  }
`;

const GET_THEMES = gql`
  query GetThemes($workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
      formThemes {
        id
        type
        name
        styleSchema
      }
    }
    globalFormThemes {
      id
      type
      name
      styleSchema
    }
  }
`;

const GET_CURRENT_USER = gql`
  {
    currentUser {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

function FormThemePanel({
  onClose,
  activeTab,
  activeTheme,
  onUpdateActiveTheme,
  actionButtonSchema,
  setActionButtonSchema,
  setEditedButtonSchema,
  setCssTransition,
  companySubscription,
  company,
  floatingButtonSchema,
  setFloatingButtonSchema
}) {
  const accordionItems = ["General Styles", "Font", "Cards", "Inputs", "Buttons"];
  const [saveThemeMutation, { loading: savingTheme, data: savedTheme }] = useMutation(SAVE_THEME);
  const [removeThemeMutation, { loading: removingTheme, data: removeSuccess }] = useMutation(REMOVE_THEME);
  const [closedGroups, setClosedGroups] = useState(accordionItems);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [featureModal, setFeatureModal] = useState(null);
  const [makeThemeGlobalMutation] = useMutation(MAKE_GLOBAL_THEME);
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);
  const userRole = currentWorkspace ? currentWorkspace.userRoleId || currentUser.roleId : null;
  const isSuperAdmin = usePermissionsWithRole("superAdmin");
  const [activeButton, setActiveButton] = useState("")
  const {
    data,
    loading: loadingThemes,
    refetch
  } = useQuery(GET_THEMES, {
    variables: {
      workspaceId: currentWorkspace.id
    },
    skip: !currentWorkspace.id
  });
  const { editTheme } = useSelector((state) => state.form);
  const hasWhiteLabel = stringToBoolean(getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_WHITE_LABEL));
  const hasCustomThemes = stringToBoolean(
    getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_CUSTOMIZE_THEMES)
  );

  const dispatch = useDispatch();
  const { loading: userLoading, data: userData } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "network-only"
  });
  const userId = !userLoading && userData ? get(userData, ["currentUser", "id"], 0) : 0;

  useEffect(() => {
    if (!savingTheme && savedTheme && editTheme) {
      dispatch({
        type: formActions.FINISH_EDIT_THEME,
        payload: {
          ...savedTheme.saveFormTheme
        }
      });
      refetch();
    }
  }, [savedTheme]);

  useEffect(() => {
    if (!removingTheme && removeSuccess && removeSuccess.removeFormTheme) {
      refetch();
    }
  }, [removeSuccess]);

  const handleOnChange = (e, question, parentPath, buttonUpdate = false, buttonType = "static") => {
    const val = question.parseValueOut ? question.parseValueOut(e.target.value, e.target.name) : e.target.value;
    const updates = [];
    if (question.customUpdates) {
      for (const item of question.customUpdates(val)) {
        updates.push(item);
      }
    } else {
      updates.push({
        path: [...parentPath, ...question.cssPath],
        value: val
      });
    }

    
    if (!buttonUpdate) {
      dispatch({
        type: formActions.EDIT_THEME_UPDATE,
        payload: {
          updates: updates
        }
      });
    } else {
      let updatesObj = {};
      for (const update of updates) {
        updatesObj = { ...updatesObj, [update.path]: update.value };
      }
      if (buttonType === "static") {
        setActionButtonSchema({ ...actionButtonSchema, ...updatesObj });
      } else {
        setFloatingButtonSchema({ ...floatingButtonSchema, ...updatesObj });
      }
    }
  };

  const buildQuestion = (question, parentPath, data, userId, buttonUpdate, buttonType) => {
    const FieldComponent = SinapiFieldRegistry.getFieldComponent(question.type);
    return (
      <FieldComponent
        question={{
          ...question,
          userId,
          parentPath,
          prevId: question.type === "file" ? get(editTheme, ["styleSchema", "uploadedImageID"]) : undefined,
          fileID: question.type === "file" ? data.uploadedImageID : undefined,
          value: question.parseValueIn
            ? question.parseValueIn(get(data, [...parentPath, ...question.cssPath]))
            : get(data, [...parentPath, ...question.cssPath])
        }}
        onChange={(e) => handleOnChange(e, question, parentPath, buttonUpdate, buttonType)}
      />
    );
  };

  const onSelectActiveTheme = (t) => {
    onUpdateActiveTheme({
      id: t.id,
      type: t.type,
      styleSchema: t.styleSchema
    });
  };

  const onSaveTheme = () => {
    setCssTransition();
    saveThemeMutation({
      variables: {
        themeId: editTheme.id || null,
        workspaceId: currentWorkspace.id,
        theme: {
          name: "test",
          styleSchema: editTheme.styleSchema
        }
      }
    });
  };

  const onSaveButton = () => {
    setEditedButtonSchema(true);
    dispatch({ type: "CHANGE_EDIT_QUESTION_TAB", payload: "1" });
  };

  const onMakeThemeGlobal = (theme) => {
    makeThemeGlobalMutation({
      variables: {
        themeId: theme.id,
        workspaceId: currentWorkspace.id
      }
    });
  };

  const onEditTheme = (t) => {
    dispatch({
      type: formActions.EDIT_THEME,
      payload: {...t, styleSchema: reformatTheme(t.styleSchema)}
    });
  };

  const onCloneTheme = (t) => {
    dispatch({
      type: formActions.EDIT_THEME,
      payload: {
        isNew: true,
        styleSchema: {
          ...t.styleSchema
        }
      }
    });
  };

  const onClearTheme = () => {
    dispatch({
      type: formActions.UPDATE_ACTIVE_THEME,
      payload: globalThemes[0] || null
    });
  };

  const onRemoveTheme = (t) => {
    removeThemeMutation({
      variables: {
        themeId: t.id,
        type: t.type,
        workspaceId: currentWorkspace.id
      }
    }).then(() => {
      onClearTheme();
      setConfirmationModal(null);
    });
  };

  const onNewTheme = () => {
    dispatch({
      type: formActions.EDIT_THEME,
      payload: {
        isNew: true,
        styleSchema: get(globalThemes, ["0", "styleSchema"], {})
      }
    });
  };

  const close = () => {
    setCssTransition();
    if (editTheme) {
      dispatch({
        type: formActions.CANCEL_EDIT_THEME
      });
      if (activeTab !== 1) dispatch({ type: "CHANGE_EDIT_QUESTION_TAB", payload: "1" });
    } else {
      onClose();
    }
  };

  const toggleCollapse = (label) => {
    if (closedGroups.includes(label)) {
      setClosedGroups(accordionItems.filter((g) => g !== label));
    } else {
      setClosedGroups([...closedGroups, label]);
    }
  };

  const toggleActiveButton = label => {
    if(activeButton === label) {
      setActiveButton("")
    } else {
      setActiveButton(label)
    }
  }

  const themes = get(data, ["workspace", "formThemes"], []);
  const globalThemes = get(data, ["globalFormThemes"], []);

  return (
    <>
      <div className="vertical-sidenav-full-container">
        <Card className="card-pricing border-0 mb-0">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="12" className="vertical-sidenav-header">
                <Button
                  close
                  onClick={() => {
                    setCssTransition();
                    onClose();
                  }}
                />
                <h1 className="mb-0">
                  <i className="fas fa-paint-brush" /> Styles
                </h1>
              </Col>
            </Row>
          </CardHeader>
          {!editTheme && 
          <Nav tabs className="nav-fill" id="editTabNav" fixed="top">
            <NavItem style={{ width: "50%" }}>
              <NavLink
                className={classnames({
                  active: activeTab === "1"
                })}
                onClick={() => dispatch({ type: "CHANGE_EDIT_QUESTION_TAB", payload: "1" })}
              >
                <i className="fas fa-pencil-alt" /> Form Styles
              </NavLink>
            </NavItem>
            <NavItem style={{ width: "50%" }}>
              <NavLink
                className={classnames({
                  active: activeTab === "2"
                })}
                onClick={() => dispatch({ type: "CHANGE_EDIT_QUESTION_TAB", payload: "2" })}
              >
                <i className="fas fa-bolt" /> Website Buttons
              </NavLink>
            </NavItem>
          </Nav>}
          <CardBody
            className="p-0"
            style={{
              backgroundColor: "white"
            }}
          >
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {loadingThemes ? <LoaderSpinner /> : null}
                {!editTheme ? (
                  <div>
                    {globalThemes.map((t) => (
                      <ThemeProvider theme={t.styleSchema} key={`global_${t.id}`}>
                        <ThemePreview
                          active={get(activeTheme, "id") === t.id && get(activeTheme, "type") === t.type}
                          theme={t.styleSchema}
                          onClick={() => onSelectActiveTheme(t)}
                          onDelete={
                            isSuperAdmin
                              ? (e) => {
                                  e.stopPropagation();
                                  onRemoveTheme(t);
                                }
                              : null
                          }
                        />
                      </ThemeProvider>
                    ))}
                    {themes.map((t) => (
                      <ThemeProvider theme={t.styleSchema} key={`theme_${t.id}`}>
                        <ThemePreview
                          active={get(activeTheme, "id") === t.id && get(activeTheme, "type") === t.type}
                          theme={t.styleSchema}
                          onClick={() => onSelectActiveTheme(t)}
                          allowGlobalEdit={isSuperAdmin}
                          onMakeGlobal={(e) => {
                            e.stopPropagation();
                            onMakeThemeGlobal(t);
                          }}
                          onEdit={(e) => {
                            e.stopPropagation();
                            onEditTheme(t);
                          }}
                          onClone={(e) => {
                            e.stopPropagation();
                            onCloneTheme(t);
                          }}
                          onDelete={(e) => {
                            e.stopPropagation();
                            setConfirmationModal(t);
                          }}
                        />
                      </ThemeProvider>
                    ))}{" "}
                  </div>
                ) : null}
                {editTheme ? (
                  <div
                    style={{
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem"
                    }}
                  >
                    <ListGroup flush className="ml--4 mr--4">
                      {themeOptions.map((type, index) => {
                        const isClosed = closedGroups.includes(type.label);
                        return [
                          <ListGroupItem
                            color="light"
                            key={index}
                            className="mb-0 p-2 pl-4"
                            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.08)", cursor: "pointer" }}
                            onClick={() => toggleCollapse(type.label)}
                          >
                            {type.label}
                            <Button
                              close
                              aria-label={isClosed ? "Expand" : "Close"}
                              onClick={() => toggleCollapse(type.label)}
                            >
                              <i aria-hidden className={isClosed ? "fas fa-angle-right" : "fas fa-angle-down"} />
                            </Button>
                          </ListGroupItem>,
                          <Collapse isOpen={!isClosed} key={index + "content"}>
                            <ListGroupItem className="p-4">
                              <Row className="mt-0">
                                {type.options.map((field, idx) => (
                                  <Col key={idx} xs={field.col || 12}>
                                    {field.header && <h3 className="style-header">{field.header}</h3>}
                                    {field.type === "sub-header" && <h3 style={{color: "#686868", fontWeight: "300"}}>{field.title}</h3>}
                                    {(field.type !== "header" && field.type !== "sub-header") && 
                                      <FormGroup
                                        key={field.cssPath.join("")}
                                        style={type.options.length === idx + 1 ? { marginBottom: "0rem" } : {}}
                                      >
                                        {buildQuestion(field, type.cssPath, editTheme.styleSchema, userId)}
                                      </FormGroup>}
                                  </Col>
                                ))}
                              </Row>
                            </ListGroupItem>
                          </Collapse>
                        ];
                      })}
                    </ListGroup>
                  </div>
                ) : null}
              </TabPane>
              <TabPane tabId="2">
                <Card className="border-0 mb-0 shadow-none">
                  {/* {actionButtonStyles.map((field) => (
                    <FormGroup className="ml-4 mr-4" style={{ marginBottom: "1rem" }} key={field.cssPath.join("")}>
                      {buildQuestion(field, [], actionButtonSchema, userId, true)}
                    </FormGroup>
                  ))} */}
                  <ListGroup flush>
                    <ListGroupItem color="light" className="mb-0 p-2 pl-4" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.08)", cursor: "pointer" }} onClick={() => toggleActiveButton("static")}>
                      Standard Button
                      <Button
                        close
                        aria-label={activeButton === "static" ? "Expand" : "Close"}
                      >
                        <i aria-hidden className={activeButton === "static" ? "fas fa-angle-down" : "fas fa-angle-right"} />
                      </Button>
                    </ListGroupItem>
                    <Collapse isOpen={activeButton === "static"} className="p-4">
                      <Row className="m-0">
                        {actionButtonStyles.map((field, index) => (
                          <Col key={index} xs={field.col} className="p-0">
                            <FormGroup style={{ marginBottom: "1rem" }} key={field.cssPath.join("")}>
                              {buildQuestion(field, [], actionButtonSchema, userId, true)}
                            </FormGroup>
                          </Col>
                        ))}
                      </Row>
                    </Collapse>
                    <ListGroupItem color="light" className="mb-0 p-2 pl-4" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.08)", cursor: "pointer" }} onClick={() => toggleActiveButton("floating")}>
                      Floating Button
                      <Button
                        close
                        aria-label={activeButton === "floating" ? "Expand" : "Close"}
                      >
                        <i aria-hidden className={activeButton === "floating" ? "fas fa-angle-down" : "fas fa-angle-right"} />
                      </Button>                      
                    </ListGroupItem>
                    <Collapse isOpen={activeButton === "floating"} className="p-4">
                      <Row className="m-0">
                        {actionButtonStyles.map((field,index) => (
                          <Col key={index} xs={field.col} className="p-0">
                            <FormGroup style={{ marginBottom: "1rem" }} key={field.cssPath.join("")}>
                              {buildQuestion(field, [], floatingButtonSchema, userId, true, "floating")}
                            </FormGroup>
                          </Col>
                        ))}
                      </Row>
                    </Collapse>
                  </ListGroup>
                </Card>
              </TabPane>
            </TabContent>
          </CardBody>
          <CardFooter className="card-footer-sidenav">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row className="align-items-center">
                  <Col className="text-right" xs="12">
                    <Button type="button" color="secondary" onClick={close} className="float-left">
                      Close
                    </Button>
                    {!editTheme ? (
                      <Button
                        type="button"
                        color="primary"
                        onClick={() => (hasCustomThemes ? onNewTheme() : setFeatureModal(true))}
                      >
                        + New Theme
                      </Button>
                    ) : null}
                    {editTheme ? (
                      <Button type="button" color="primary" onClick={onSaveTheme}>
                        Save Theme
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row className="align-items-center">
                  <Col className="text-right" xs="12">
                    <Button type="button" color="secondary" onClick={close} className="float-left">
                      Cancel
                    </Button>
                    <Button type="button" color="primary" onClick={onSaveButton}>
                      Save Button
                    </Button>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </CardFooter>
        </Card>
      </div>
      {confirmationModal ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setConfirmationModal(null)}
          header={<div>Remove Custom Theme</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setConfirmationModal(null) },
            { label: "Remove", onClick: () => onRemoveTheme(confirmationModal) }
          ]}
        >
          Are you sure you want to remove the custom theme?
        </ManagementModal>
      ) : null}
      {featureModal && (
        <ManagementModal
          onClose={() => setFeatureModal(null)}
          header={<div>ADVANCED EDITING</div>}
          buttons={[{ label: "Close", onClick: () => setFeatureModal(null) }]}
        >
          {!["consultant"].includes(userRole) ? (
            <>
              <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
                <h2 className="font-weight-400 text-justify">
                  Please{" "}
                  <Link className="font-weight-bold btn btn-primary" to="/admin/upgrade">
                    Upgrade your account
                  </Link>
                  {` to use these Elements. These elements are not allowed by your current plan.`}
                </h2>
              </CardHeader>
              <CompanySubscription
                companyData={company}
                plans={companySubscription.plans}
                price={companySubscription.compPrice}
                showDetails={false}
              />
            </>
          ) : (
            <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
              <h2 className="font-weight-400 text-justify">
                {`Your plan does not support this feature. If you would like to upgrade to get the most out of ${
                  WHITE_LABELS(hasWhiteLabel, company).sinapiName
                }, contact your account manager for assistance.`}
              </h2>
            </CardHeader>
          )}
        </ManagementModal>
      )}
    </>
  );
}

export { FormThemePanel };
