import React, { useState, useEffect, useRef } from "react";
import NotificationAlert from "components/NotificationAlert";

import { gql } from "apollo-boost";
import { isEmpty, remove } from "lodash";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { Card, CardHeader, Row, Col, Table, Button, Input, Container } from "reactstrap";
import ManagementModal from "components/UserManagement/ManagementModal";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { getPosition, isInt, isFloat } from "utils/helperFunctions";

const notificationOptions = (message) => ({
  place: "bc",
  message: (
    <div className="alert-text ml-2">
      <span data-notify="message">{message}</span>
    </div>
  ),
  type: "danger",
  icon: "fas fa-exclamation-triangle",
  autoDismiss: 5
});

const GET_FEATURES = gql`
  query getFeatures {
    features {
      id
      example
    }
  }
`;

const GET_PLAN_FEATURES_OVERWRITE = gql`
  query getPlanFeaturesOverwrite($companyId: ID!) {
    planFeaturesOverwrite(companyId: $companyId) {
      id
      companyId
      featureId
      value
      example
    }
  }
`;

const SAVE_FEATURE_OVERWRITE = gql`
  mutation SaveFeatureOverwrite($id: ID, $companyId: ID!, $featureId: ID!, $value: String!) {
    saveFeatureOverwrite(id: $id, companyId: $companyId, featureId: $featureId, value: $value)
  }
`;

const REMOVE_FEATURE_OVERWRITE = gql`
  mutation RemoveFeatureOverwrite($featureOverwriteId: ID!) {
    removeFeatureOverwrite(featureOverwriteId: $featureOverwriteId)
  }
`;

const CompanyFeaturesForm = ({ company }) => {
  const [featuresOverwriteData, setFeaturesOverwriteData] = useState();
  const [editingFeature, setEditingFeature] = useState();
  const [editFeature, setEditFeature] = useState();
  const [showModal, setShowModal] = useState();
  const [error, setError] = useState(false);
  const notificationAlert = useRef(null);

  const { loading: loadingFeatures, data: dataFeatures } = useQuery(GET_FEATURES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const { data, loading, refetch } = useQuery(GET_PLAN_FEATURES_OVERWRITE, {
    fetchPolicy: "network-only",
    variables: {
      companyId: company.id
    },
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (!loading && data) setFeaturesOverwriteData(data.planFeaturesOverwrite);
  }, [data, loading]);

  const [saveFeatureOverwrite] = useMutation(SAVE_FEATURE_OVERWRITE, {
    onCompleted: () => {
      refetch();
      setEditingFeature();
      setEditFeature();
    }
  });

  const [removeFeatureOverwrite] = useMutation(REMOVE_FEATURE_OVERWRITE, {
    onCompleted: () => {
      refetch();
      setShowModal();
    }
  });

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <Container className="mt-2 mb-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{company.name}</h3>
                    <h4 className="text-muted mb-0">Company Features Overwrite</h4>
                  </Col>
                  <Col xs="4" className="text-right">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        setFeaturesOverwriteData([
                          ...featuresOverwriteData,
                          { id: "", featureId: "", example: "", value: "" }
                        ]);
                        setEditFeature({ id: "", featureId: "", example: "", value: "" });
                        setEditingFeature({ id: "" });
                      }}
                    >
                      + New Feature Overwrite
                    </button>
                  </Col>
                </Row>
              </CardHeader>
              {loading ? <LoaderSpinner /> : null}
              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Feature</th>
                    <th scope="col">value</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list">
                  {isEmpty(featuresOverwriteData) ? (
                    <tr>
                      <td>
                        <div className="m-4">{`No Features Overwrite.`}</div>
                      </td>
                    </tr>
                  ) : null}
                  {!isEmpty(featuresOverwriteData)
                    ? featuresOverwriteData.map((feature) => (
                        <tr key={feature.id}>
                          <td className="featureId">
                            {editingFeature && editingFeature.id === feature.id ? (
                              <Input
                                type="select"
                                onChange={(e) => {
                                  const idxFirst = getPosition(e.target.selectedOptions[0].outerHTML, '"', 1) + 1;
                                  const idxSecond = getPosition(e.target.selectedOptions[0].outerHTML, '"', 2);
                                  setEditFeature({
                                    id: editFeature.id,
                                    companyId: company.id,
                                    featureId: e.target.value,
                                    example: e.target.selectedOptions[0].outerHTML.substring(idxFirst, idxSecond),
                                    value: editFeature.value
                                  });
                                }}
                                value={editFeature.featureId}
                                name={editFeature.example}
                              >
                                <option key={"default_feature_id"} name={`default_option`} id={`default_option`}>
                                  - Select Feature -
                                </option>
                                {!loadingFeatures &&
                                  dataFeatures.features.map((feature) => (
                                    <option key={feature.id} name={feature.example} value={feature.id}>
                                      {feature.id}
                                    </option>
                                  ))}
                              </Input>
                            ) : (
                              feature.featureId
                            )}
                          </td>
                          <td className="value">
                            {editingFeature && editingFeature.id === feature.id ? (
                              <>
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={editFeature.value}
                                  placeholder={editFeature.example}
                                  style={{ borderColor: error ? "red" : "#dee2e6" }}
                                  onChange={(e) =>
                                    setEditFeature({
                                      id: editFeature.id,
                                      companyId: company.id,
                                      featureId: editFeature.featureId,
                                      example: editFeature.example,
                                      value: e.target.value
                                    })
                                  }
                                  onBlur={(e) => {
                                    const validator = editFeature.example.split(" ")[0];
                                    switch (validator) {
                                      case "Boolean":
                                        if (["0", "1"].includes(editFeature.value)) {
                                          setError(false);
                                        } else {
                                          notificationAlert.current.notificationAlert(
                                            notificationOptions("Invalid Boolean Value !!")
                                          );
                                          setError(true);
                                        }
                                        return;
                                      case "Float":
                                        if (isInt(editFeature.value) || isFloat(editFeature.value)) {
                                          setError(false);
                                        } else {
                                          notificationAlert.current.notificationAlert(
                                            notificationOptions("Invalid Float Value !!")
                                          );
                                          setError(true);
                                        }
                                        return;
                                      case "Integer":
                                        if (isInt(editFeature.value)) {
                                          setError(false);
                                        } else {
                                          notificationAlert.current.notificationAlert(
                                            notificationOptions("Invalid Integer Value !!")
                                          );
                                          setError(true);
                                        }
                                        return;
                                      default:
                                        const validKeys = validator.split("/");
                                        if (validKeys.includes(editFeature.value)) {
                                          setError(false);
                                        } else {
                                          notificationAlert.current.notificationAlert(
                                            notificationOptions("Only 'Advanced' and 'Basic' words are allowed")
                                          );
                                          setError(true);
                                        }
                                        return;
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              feature.value
                            )}
                          </td>
                          <td className="text-right">
                            {editingFeature && editingFeature.id === feature.id ? (
                              <>
                                <Button
                                  size="sm"
                                  color="success"
                                  disabled={error}
                                  onClick={() => {
                                    saveFeatureOverwrite({
                                      variables: {
                                        id: editFeature.id,
                                        companyId: company.id,
                                        featureId: editFeature.featureId,
                                        example: editFeature.example,
                                        value: editFeature.value
                                      }
                                    });
                                  }}
                                >
                                  <i className="fas fa-check" />
                                </Button>
                                <Button
                                  size="sm"
                                  style={{ marginLeft: 8 }}
                                  onClick={() => {
                                    setEditFeature();
                                    setEditingFeature();
                                    remove(featuresOverwriteData, (feature) => feature.id === "");
                                  }}
                                >
                                  <i className="fas fa-times" />
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  size="sm"
                                  color="primary"
                                  disabled={editingFeature ? true : false}
                                  onClick={() => {
                                    setEditFeature({
                                      id: feature.id,
                                      companyId: feature.companyId,
                                      featureId: feature.featureId,
                                      example: feature.example,
                                      value: feature.value
                                    });
                                    setEditingFeature({ id: feature.id });
                                  }}
                                >
                                  <i className="fas fa-edit" />
                                </Button>
                                <Button
                                  size="sm"
                                  color="warning"
                                  disabled={editingFeature ? true : false}
                                  onClick={() => {
                                    setShowModal({
                                      type: "removeFeatureOverwrite",
                                      id: feature.id,
                                      companyId: feature.companyId,
                                      feature: feature.featureId
                                    });
                                  }}
                                >
                                  <i className="fas fa-trash" />
                                </Button>
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      {showModal && showModal.type === "removeFeatureOverwrite" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal()}
          header={<div>Remove "{showModal.feature}" feature ?</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal() },
            {
              label: "Remove",
              onClick: () =>
                removeFeatureOverwrite({
                  variables: { featureOverwriteId: showModal.id }
                })
            }
          ]}
        >
          Are you sure you want to remove "{showModal.feature}" feature to the company "{showModal.name}"?
        </ManagementModal>
      ) : null}
    </>
  );
};
export default CompanyFeaturesForm;
