import "./SuccessPageOptionsPanel.scss";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Row,
  Col,
  FormGroup,
  CardBody,
  Input,
  Label,
  CardFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Collapse
} from "reactstrap";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import { formActions } from "reducers";
import { RichTextEditorSummer } from "components/Shared/RichTextEditor/RichTextEditorSummer";
import { EQUALS, IS_EMPTY, NOT_EMPTY, NOT_EQUAL, OPERATORS_SYMBOL } from "utils/constants";
import { getDefaultSuccessRule } from "views/pages/forms/defaultFormData";
import jsonLogic from "json-logic-js";
import classNames from "classnames";
import { Fragment } from "react";

function SuccessPageOptionsPanel({ activeQuestionJSON, onClose }) {
  const dispatch = useDispatch();

  const addSuccessRule = () => {
    dispatch({
      type: formActions.ADD_SUCCESS_SECTION,
      payload: getDefaultSuccessRule()
    });
  };

  const activateSuccessRule = (e) => {
    dispatch({
      type: formActions.ACTIVATE_DYNAMIC_SUCCESS_SECTION,
      payload: {
        key: e.target.name,
        value: e.target.checked,
        section: getDefaultSuccessRule()
      }
    });
  };

  const activateRedirect = (e) => {
    dispatch({
      type: formActions.ACTIVATE_REDIRECT,
      payload: {
        key: e.target.name,
        value: e.target.checked,
        path: ["section"]
      }
    });
  };

  const removeSuccessRule = (idx) => {
    dispatch({
      type: formActions.REMOVE_RULE_SUCCESS_SECTION,
      payload: idx
    });
  };

  const changeSuccessMessage = ({ key, value, path }) =>
    dispatch({
      type: formActions.UPDATE_QUESTION_BEING_EDITED,
      payload: { key, value, path }
    });

  const changeRedirectLink = ({ key, value, path }) =>
    dispatch({
      type: formActions.UPDATE_QUESTION_BEING_EDITED,
      payload: { key, value, path }
    });

  const changeNumberInput = ({ key, value, path }, ruleGroupIdx, ruleIdx) => {
    dispatch({
      type: formActions.UPDATE_QUESTION_BEING_EDITED,
      payload: { key, value, path }
    });
    const varJsonLogic = jsonLogic;
    let vRg = [...violateRg];
    const ruleGroups = get(activeQuestionJSON, ["section", "dynamicRules", "ruleGroups"], []);
    let err = { ...errors };
    for (const [rgIdx, ruleGroup] of ruleGroups.entries()) {
      if (rgIdx !== ruleGroupIdx) {
        let jLogic = { and: [] };
        for (const rule of ruleGroup.rules) {
          const operator =
            rule.operator === IS_EMPTY
              ? OPERATORS_SYMBOL[EQUALS]
              : rule.operator === NOT_EMPTY
              ? OPERATORS_SYMBOL[NOT_EQUAL]
              : OPERATORS_SYMBOL[rule.operator];
          jLogic.and.push({ [operator]: [value, Number(rule.answer)] });
        }

        if (varJsonLogic.apply(jLogic)) {
          err = {
            ruleGroupIndx: [...errors.ruleGroupIndx, `rG${ruleGroupIdx}`],
            ruleIndx: [...errors.ruleIndx, `rG${ruleGroupIdx}ru${ruleIdx}`]
          };
          vRg.push(rgIdx);
        } else {
          if (vRg.includes(rgIdx)) {
            vRg = vRg.filter((i) => i !== rgIdx);
            err = {
              ruleGroupIndx: errors.ruleGroupIndx.filter((i) => i !== `rG${ruleGroupIdx}`),
              ruleIndx: errors.ruleIndx.filter((i) => i !== `rG${ruleGroupIdx}ru${ruleIdx}`)
            };
          }
        }
      }
    }
    setViolateRg(vRg);
    setErrors(err);
  };

  const closeActiveQuestionDetails = () => {
    dispatch({
      type: formActions.CLOSE_ACTIVE_QUESTION
    });
    if (onClose) {
      onClose();
    }
  };

  const changeOptionInput = (e) => {
    return dispatch({
      type: formActions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: e.target.name,
        value: e.target.value,
        path: ["section"]
      }
    });
  };

  const toggleCheckbox = (e) => {
    dispatch({
      type: formActions.UPDATE_QUESTION_BEING_EDITED,
      payload: {
        key: e.target.name,
        value: e.target.checked,
        path: ["section"]
      }
    });
  };

  const submitActiveQuestionDetails = () => {
    dispatch({
      type: formActions.SUBMIT_SUCCESS_PAGE_EDITED
    });
    if (onClose) {
      onClose();
    }
  };

  const [collapse, setCollapse] = useState();
  const [errors, setErrors] = useState({ ruleGroupIndx: [], ruleIndx: [] });
  const [violateRg, setViolateRg] = useState([]);
  const toggleRule = (idx) => {
    setCollapse(collapse === Number(idx) ? -1 : Number(idx));
  };

  const costOptions = [
    {
      id: "cost",
      label: "One Time"
    },
    {
      id: "daily",
      label: "Daily"
    },
    {
      id: "monthly",
      label: "Monthly"
    },
    {
      id: "yearly",
      label: "yearly"
    }
  ];

  return (
    <div className="vertical-sidenav-full-container success-page-options-panel">
      <Card className="card-pricing border-0 mb-0">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="12" className="vertical-sidenav-header">
              <Button close onClick={() => closeActiveQuestionDetails()} />
              <h1 className="mb-0">
                <i className="far fa-edit" /> Success Page
              </h1>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <label className="form-control-label">
              <div>Header</div>
            </label>
            <Input
              name="header"
              className="form-control-sm"
              value={get(activeQuestionJSON, ["section", "header"], "")}
              onChange={changeOptionInput}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label">
              <div>Sub-Header</div>
            </label>
            <Input
              name="subHeader"
              type="textarea"
              rows={2}
              className="form-control-sm"
              value={get(activeQuestionJSON, ["section", "subHeader"], "")}
              onChange={changeOptionInput}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label">
              <div>Price Header</div>
            </label>
            <Input
              name="priceHeader"
              className="form-control-sm"
              value={get(activeQuestionJSON, ["section", "priceHeader"], "")}
              onChange={changeOptionInput}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label">
              <div>Footer</div>
            </label>
            <Input
              name="footer"
              className="form-control-sm"
              value={get(activeQuestionJSON, ["section", "footer"], "")}
              onChange={changeOptionInput}
            />
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs="6">
                <label className="form-control-label">
                  <div>Not Interested Button</div>
                </label>
                <Input
                  placeholder="Not interested..."
                  className="form-control-sm"
                  type="text"
                  defaultValue={get(activeQuestionJSON, ["section", "notInterestedButtonText"], "")}
                  name="notInterestedButtonText"
                  onChange={(e) => changeOptionInput(e)}
                />
              </Col>
              <Col xs="6">
                <label className="form-control-label">
                  <div>Confirm Button</div>
                </label>
                <Input
                  placeholder="Confirm..."
                  className="form-control-sm"
                  type="text"
                  defaultValue={get(activeQuestionJSON, ["section", "confirmButtonText"], "")}
                  name="confirmButtonText"
                  onChange={(e) => changeOptionInput(e)}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <div className="custom-control custom-checkbox mb-2">
              <Input
                className="custom-control-input"
                id="display_hide_price_checkbox"
                name="hidePrice"
                type="checkbox"
                onChange={toggleCheckbox}
                checked={get(activeQuestionJSON, ["section", "hidePrice"], false)}
              />
              <Label className="custom-control-label" htmlFor="display_hide_price_checkbox">
                Hide Price
              </Label>
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label">Order Table</label>
            <div className="custom-control custom-checkbox display-order-table-option">
              <Input
                className="custom-control-input"
                id="display_table_checkbox"
                name="showOrderTable"
                type="checkbox"
                onChange={toggleCheckbox}
                checked={get(activeQuestionJSON, ["section", "showOrderTable"], false)}
              />
              <Label className="custom-control-label" htmlFor="display_table_checkbox">
                Display
              </Label>
            </div>
            {get(activeQuestionJSON, ["section", "showOrderTable"], false) && (
              <Input
                name="orderTable"
                className="form-control-sm"
                value={get(activeQuestionJSON, ["section", "orderTable"], "")}
                onChange={changeOptionInput}
              />
            )}
          </FormGroup>
          <FormGroup>
            <div className="custom-control custom-checkbox mb-2">
              <Input
                className="custom-control-input"
                id="has_interest_checkbox"
                name="hasCustomSuccessText"
                type="checkbox"
                onChange={toggleCheckbox}
                checked={get(activeQuestionJSON, ["section", "hasCustomSuccessText"], false)}
              />
              <Label className="custom-control-label form-control-label" htmlFor="has_interest_checkbox">
                Add Custom Success Message
              </Label>
            </div>
            {get(activeQuestionJSON, ["section", "hasCustomSuccessText"], false) ? (
              <>
                <FormGroup
                  style={{ borderLeftColor: "#f1f1f1", borderLeftStyle: "solid", borderLeftWidth: "10px" }}
                  className="mb-0"
                >
                  <Row className={`custom-control custom-checkbox mb-2 ml-3`}>
                    <Input
                      className="custom-control-input"
                      id="has_dynamic_checkbox"
                      name="hasDynamicSuccessText"
                      type="checkbox"
                      onChange={activateSuccessRule}
                      checked={get(activeQuestionJSON, ["section", "hasDynamicSuccessText"], false)}
                    />
                    <Label className="custom-control-label form-control-label" htmlFor="has_dynamic_checkbox">
                      Use Dynamic Logic
                    </Label>
                  </Row>
                  <Row className={`custom-control custom-checkbox mb-2 ml-3`}>
                    <Input
                      className="custom-control-input"
                      id="has_redirect_checbox"
                      name="hasRedirect"
                      type="checkbox"
                      onChange={activateRedirect}
                      checked={get(activeQuestionJSON, ["section", "hasRedirect"], false)}
                    />
                    <Label className="custom-control-label form-control-label" htmlFor="has_redirect_checbox">
                      Redirect After Form Submission
                    </Label>
                  </Row>
                </FormGroup>

                {get(activeQuestionJSON, ["section", "hasDynamicSuccessText"], false) ? (
                  <>
                    <Card className="optionItem mb-0">
                      <div className="optionHeader">
                        <Row className="pr-0">
                          <Col xs={4} className="my-auto pr-0">
                            <div className="optionHeader mb-0">
                              <div className="nameSuccess my-auto" style={{ display: "flex" }}>
                                <label className="form-control-label text-green my-auto mr-auto">PRICE</label>
                                <label className="form-control-label my-auto mr-auto">TO</label>
                                <label className="form-control-label my-auto mr-auto">COMPARE:</label>
                              </div>
                            </div>
                          </Col>
                          <Col xs={4}>
                            <FormGroup className="my-auto mr-auto">
                              <Input
                                type="select"
                                className="form-control-sm"
                                name="costToCompare"
                                onChange={(e) =>
                                  changeSuccessMessage({ key: e.target.name, value: e.target.value, path: ["section"] })
                                }
                                value={get(activeQuestionJSON, ["section", "costToCompare"], "cost")}
                              >
                                {costOptions.map((option) => {
                                  return (
                                    <option key={option.id} value={option.id}>
                                      {option.label}
                                    </option>
                                  );
                                })}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                    {get(activeQuestionJSON, ["section", "dynamicRules", "ruleGroups"], []).map((ruleGroup, idx) => (
                      <Card className="optionItem mb-0" style={{ width: 475 }} key={`rules_${idx}`}>
                        {errors.ruleGroupIndx.includes(`rG${idx}`) && (
                          <small className="text-red pb-2">Price ranges cannot overlap each other</small>
                        )}
                        <div className="optionHeader">
                          <div className="nameSuccess" style={{ display: "flex" }}>
                            <label className="form-control-label my-auto mr-auto">IF</label>
                            <label className="form-control-label text-green my-auto mr-auto">PRICE</label>
                            <label className="form-control-label my-auto mr-auto">IS BETWEEN</label>
                            {ruleGroup.rules.map((rule, ruleIdx) => (
                              <Fragment key={`rG${idx}ru${ruleIdx}`}>
                                <InputGroup className="my-auto mr-auto" style={{ width: 90 }}>
                                  <InputGroupAddon addonType="prepend" className="form-control-sm p-0">
                                    <InputGroupText style={{ padding: "0.625rem 0.5rem" }}>
                                      <i className="fas fa-dollar-sign" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    className={classNames("form-control-sm", "number-without-arrows", {
                                      "is-invalid":
                                        errors.ruleIndx.includes(`rG${idx}ru${ruleIdx}`) &&
                                        errors.ruleGroupIndx.includes(`rG${idx}`)
                                    })}
                                    onChange={(e) =>
                                      changeNumberInput(
                                        {
                                          key: "answer",
                                          value: e.target.value,
                                          path: ["section", "dynamicRules", "ruleGroups", idx, "rules", ruleIdx]
                                        },
                                        idx,
                                        ruleIdx
                                      )
                                    }
                                    type="number"
                                    min="0"
                                    value={get(rule, "answer", "0")}
                                  />
                                </InputGroup>
                                {ruleIdx !== 1 && <label className="form-control-label my-auto mr-auto">-</label>}
                              </Fragment>
                            ))}
                            <label className="form-control-label my-auto">THEN</label>
                          </div>
                          <div className="buttons">
                            {idx > 0 && (
                              <Button
                                size="sm"
                                color="link"
                                onClick={() => {
                                  removeSuccessRule(idx, dispatch);
                                }}
                              >
                                <i className="fas fa-trash"></i>
                              </Button>
                            )}
                            <Button size="sm" color="link" data-event={idx} onClick={() => toggleRule(idx)}>
                              <i className="fas fa-chevron-down"></i>
                            </Button>
                          </div>
                        </div>
                        <Collapse isOpen={collapse === idx} className="optionBody">
                          <Row className="ml-1 pl-3 pb-3 pt-3" style={{ width: 475 }}>
                            {!get(activeQuestionJSON, ["section", "hasRedirect"], false) ? (
                              <>
                                <label className="form-control-label">Show this when customer is interested:</label>
                                <RichTextEditorSummer
                                  id={idx * 2}
                                  className="rich-text-editor-435width bg-white"
                                  defaultValue={get(ruleGroup, ["interestedText"], "")}
                                  onChange={(value) =>
                                    changeSuccessMessage({
                                      key: "interestedText",
                                      value,
                                      path: ["section", "dynamicRules", "ruleGroups", idx]
                                    })
                                  }
                                />
                                <div className="separator mb-3" style={{ position: "unset", height: "auto" }} />
                                <label className="form-control-label">Show this when customer is not interested:</label>
                                <RichTextEditorSummer
                                  id={idx * 2 + 1}
                                  className="rich-text-editor-435width bg-white"
                                  defaultValue={get(ruleGroup, "notInterestedText", "")}
                                  onChange={(value) =>
                                    changeSuccessMessage({
                                      key: "notInterestedText",
                                      value,
                                      path: ["section", "dynamicRules", "ruleGroups", idx]
                                    })
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <FormGroup className="input-w-90">
                                  <label className="form-control-label">
                                    When customer <span className="text-green">is interested,</span> redirect to:
                                  </label>
                                  <Input
                                    name="interestedLink"
                                    className="form-control-sm"
                                    value={get(ruleGroup, ["interestedLink"], "")}
                                    onChange={(e) =>
                                      changeRedirectLink({
                                        key: e.target.name,
                                        value: e.target.value,
                                        path: ["section", "dynamicRules", "ruleGroups", idx]
                                      })
                                    }
                                  />
                                </FormGroup>
                                <FormGroup className="input-w-90">
                                  <label className="form-control-label">
                                    When customer <span className="text-red">is not interested,</span> redirect to:
                                  </label>
                                  <Input
                                    name="notInterestedLink"
                                    className="form-control-sm"
                                    value={get(ruleGroup, ["notInterestedLink"], "")}
                                    onChange={(e) =>
                                      changeRedirectLink({
                                        key: e.target.name,
                                        value: e.target.value,
                                        path: ["section", "dynamicRules", "ruleGroups", idx]
                                      })
                                    }
                                  />
                                </FormGroup>
                              </>
                            )}
                          </Row>
                        </Collapse>
                      </Card>
                    ))}
                    <Card className="optionItem mb-0" style={{ width: 475 }}>
                      <div className="optionHeader mb-0">
                        <div
                          className="nameSuccess"
                          style={{ display: "flex" }}
                          data-event={99}
                          onClick={() => toggleRule(99)}
                        >
                          <label className="form-control-label ml-1">BY DEFAULT SHOW THIS:</label>
                        </div>
                        <div className="buttons">
                          <Button
                            size="sm"
                            color="link"
                            data-event={99}
                            onClick={() => {
                              toggleRule(99);
                              //setLabel("");
                            }}
                          >
                            <i className="fas fa-chevron-down"></i>
                          </Button>
                        </div>
                      </div>
                      <Collapse isOpen={collapse === 99} className="optionBody">
                        <Row className="ml-1 pl-3 pb-3 pt-3" style={{ width: 475 }}>
                          {!get(activeQuestionJSON, ["section", "hasRedirect"], false) ? (
                            <>
                              <label className="form-control-label">Show this when customer is interested:</label>
                              <RichTextEditorSummer
                                id={get(activeQuestionJSON, ["section", "dynamicRules", "ruleGroups"], []).length * 2}
                                className="rich-text-editor-435width bg-white"
                                defaultValue={get(activeQuestionJSON, ["section", "interestedText"], "")}
                                onChange={(value) =>
                                  changeOptionInput({ target: { name: "interestedText", value: value } })
                                }
                              />
                              <div className="separator mb-3" style={{ position: "unset", height: "auto" }} />
                              <label className="form-control-label">Show this when customer is not interested:</label>
                              <RichTextEditorSummer
                                id={
                                  get(activeQuestionJSON, ["section", "dynamicRules", "ruleGroups"], []).length * 2 + 1
                                }
                                className="rich-text-editor-435width bg-white"
                                defaultValue={get(activeQuestionJSON, ["section", "notInterestedText"], "")}
                                onChange={(value) =>
                                  changeOptionInput({ target: { name: "notInterestedText", value: value } })
                                }
                              />
                            </>
                          ) : (
                            <>
                              <FormGroup className="input-w-90">
                                <label className="form-control-label">
                                  When customer <span className="text-green">is interested,</span> redirect to:
                                </label>
                                <Input
                                  name="interestedLink"
                                  className="form-control-sm"
                                  value={get(activeQuestionJSON, ["section", "interestedLink"], "")}
                                  onChange={changeOptionInput}
                                />
                              </FormGroup>
                              <FormGroup className="input-w-90">
                                <label className="form-control-label">
                                  When customer <span className="text-red">is not interested,</span> redirect to:
                                </label>
                                <Input
                                  name="notInterestedLink"
                                  className="form-control-sm"
                                  value={get(activeQuestionJSON, ["section", "notInterestedLink"], "")}
                                  onChange={changeOptionInput}
                                />
                              </FormGroup>
                            </>
                          )}
                        </Row>
                      </Collapse>
                    </Card>
                    <Button
                      color="primary"
                      size="sm"
                      className="ml-3 mt-3"
                      style={{ borderRadius: 30, padding: "0.45rem 0.75rem" }}
                      onClick={() => addSuccessRule()}
                    >
                      <i className="fas fa-plus" /> Add Rule
                    </Button>
                  </>
                ) : !get(activeQuestionJSON, ["section", "hasRedirect"], false) ? (
                  <>
                    <small className="form-text text-muted">Show this when customer is interested:</small>
                    <RichTextEditorSummer
                      id="0"
                      className="rich-text-editor-480width bg-white"
                      defaultValue={get(activeQuestionJSON, ["section", "interestedText"], "")}
                      onChange={(value) => changeOptionInput({ target: { name: "interestedText", value: value } })}
                    />
                    <div className="separator mb-3" style={{ position: "unset", height: "auto" }} />
                    <small className="form-text text-muted">Show this when customer is not interested:</small>
                    <RichTextEditorSummer
                      id="1"
                      className="rich-text-editor-480width bg-white"
                      defaultValue={get(activeQuestionJSON, ["section", "notInterestedText"], "")}
                      onChange={(value) => changeOptionInput({ target: { name: "notInterestedText", value: value } })}
                    />
                  </>
                ) : (
                  <>
                    <FormGroup className="input-w-90">
                      <label className="form-control-label">
                        When customer <span className="text-green">is interested,</span> redirect to:
                      </label>
                      <Input
                        name="interestedLink"
                        className="form-control-sm"
                        value={get(activeQuestionJSON, ["section", "interestedLink"], "")}
                        onChange={changeOptionInput}
                      />
                    </FormGroup>
                    <FormGroup className="input-w-90">
                      <label className="form-control-label">
                        When customer <span className="text-red">is not interested,</span> redirect to:
                      </label>
                      <Input
                        name="notInterestedLink"
                        rows={2}
                        className="form-control-sm"
                        value={get(activeQuestionJSON, ["section", "notInterestedLink"], "")}
                        onChange={changeOptionInput}
                      />
                    </FormGroup>
                  </>
                )}
              </>
            ) : null}
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Row className="align-items-center">
            <Col className="text-right" xs="12">
              <Button
                className="float-left"
                type="button"
                color="secondary"
                onClick={() => closeActiveQuestionDetails()}
              >
                Cancel
              </Button>
              <Button
                type="button"
                color="primary"
                onClick={() => submitActiveQuestionDetails()}
                disabled={errors.ruleGroupIndx.length > 0}
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </div>
  );
}

export { SuccessPageOptionsPanel };
