import React, { useState, useEffect } from "react";
import { googleFonts, getGoogleFontImportUrl } from "utils/googleFontImports";
import { getUniqueID, defaultOnChange } from "./HelperFunctions";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { map, sortBy } from "lodash";
import { createUseStyles } from "react-jss";

const googleFontsKeyValue = map(googleFonts, (value, key) => {
  return {
    key: key,
    name: value
  };
});

const useStyles = createUseStyles(
  googleFontsKeyValue.reduce(
    (obj, font) => {
      return {
        ...obj,
        "@import": [...obj["@import"], getGoogleFontImportUrl(font.name)],
        [font.key]: {
          fontFamily: `'${font.name}', sans-serif`
        }
      };
    },
    { "@import": [] }
  )
);

const GoogleFontTypeaheadField = (props) => {
  const [selected, setSelected] = useState([]);
  const classes = useStyles();
  const { title, description } = props.question;

  useEffect(() => {
    if (props.question.value) {
      setSelected(googleFontsKeyValue.filter((font) => font.name === props.question.value));
    }
  }, []);

  useEffect(() => {
    const { onChange = defaultOnChange } = props;
    onChange({
      target: {
        value: selected
      }
    });
  }, [selected]);

  return (
    <>
      <label className="form-control-label">
        {title && <div>{title}</div>}
        {description && <small className="text-muted">{description}</small>}
      </label>
      <Typeahead
        id={"typeahead-" + title.replaceAll(" ", "")}
        labelKey="name"
        onChange={(s) => setSelected(s)}
        options={sortBy(googleFontsKeyValue, "name")}
        placeholder={"Select a font"}
        selected={selected}
        size="sm"
        renderMenu={(results, menuProps) => (
          <Menu {...menuProps}>
            {results.map((result, index) => (
              <MenuItem option={result} position={index} className={classes[result.key]} key={result.key}>
                {result.name}
              </MenuItem>
            ))}
          </Menu>
        )}
      />
    </>
  );
};

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Typeahead",
  description: "Please Select An Option",
  type: "google-font-typeahead"
});

const fieldConfig = {
  key: "google-font-typeahead",
  hidden: true,
  component: GoogleFontTypeaheadField,
  getInitialData: getInitialData
};

export default fieldConfig;
