import React, { useEffect, useState } from "react";

import { gql } from "apollo-boost";
import { isEmpty, remove } from "lodash";
import { useMutation, useQuery } from "@apollo/react-hooks";

import {
  Card,
  CardHeader,
  Container,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Table,
  Button,
  Input,
  UncontrolledTooltip
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import ManagementModal from "components/UserManagement/ManagementModal";
import PlanForm from "components/Forms/PlanForm";
import LoaderSpinner from "components/Spinner/LoaderSpinner";

const GET_PLANS = gql`
  query getPlans {
    plans {
      id
      name
      description
      stripeId
      trialPeriodDays
      hidden
      customWorkspace
      hierarchy
      features {
        id
        name
        value
      }
      price {
        id
        amount
        currency
        interval
      }
    }
    features {
      id
      name
      example
    }
  }
`;

const REMOVE_PLAN = gql`
  mutation RemovePlan($planId: ID!) {
    removePlan(planId: $planId)
  }
`;

const SAVE_FEATURE = gql`
  mutation SaveFeature($featureId: ID!, $name: String!, $example: String) {
    saveFeature(featureId: $featureId, name: $name, example: $example)
  }
`;

const REMOVE_FEATURE = gql`
  mutation RemoveFeature($featureId: ID!) {
    removeFeature(featureId: $featureId)
  }
`;

const ManagePlans = () => {
  const [showModal, setShowModal] = useState();
  const [editFeature, setEditFeature] = useState();
  const [editingFeature, setEditingFeature] = useState();
  const [featuresData, setFeaturesData] = useState();

  const { loading, data, refetch } = useQuery(GET_PLANS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const [removePlan] = useMutation(REMOVE_PLAN, {
    onCompleted: () => {
      refetch();
      setShowModal();
    }
  });

  const [saveFeature] = useMutation(SAVE_FEATURE, {
    onCompleted: () => {
      refetch();
      setEditingFeature();
      setEditFeature();
    }
  });

  const [removeFeature] = useMutation(REMOVE_FEATURE, {
    onCompleted: () => {
      refetch();
      setShowModal();
    }
  });

  const plansData = !loading && data ? data.plans : null;

  useEffect(() => {
    if (!loading && data) {
      setFeaturesData(data.features);
    }
  }, [data, loading]);

  return (
    <>
      <SimpleHeader name="Manage" parentName="Account" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Plans</h3>
                  </Col>
                  <Col xs="4" className="text-right">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModal({ type: "addNewPlan" });
                      }}
                    >
                      + New Plan
                    </button>
                  </Col>
                </Row>
              </CardHeader>
              {loading ? <LoaderSpinner /> : null}
              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Trial Days</th>
                    <th scope="col">Hierarchy</th>
                    <th scope="col">Hidden</th>
                    <th scope="col">Custom Workspace</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list">
                  {isEmpty(plansData) ? (
                    <tr>
                      <td>
                        <div className="m-4">{`No Plans.`}</div>
                      </td>
                    </tr>
                  ) : null}
                  {!isEmpty(plansData)
                    ? plansData.map((plan) => (
                        <tr key={plan.id}>
                          <td className="name">{plan.id}</td>
                          <td className="name">{plan.name}</td>
                          <td className="name">{plan.description}</td>
                          <td className="name text-center">{plan.trialPeriodDays}</td>
                          <td className="name text-center">{plan.hierarchy}</td>
                          <td className="name text-center">
                            <i className={!plan.hidden ? "far fa-eye" : "far fa-eye-slash"} />
                          </td>
                          <td className="name text-center">
                            <i className={plan.customWorkspace ? "far fa-check-square" : "far fa-square"} />
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right positionFixed={true}>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowModal({ type: "updatePlan", data: plan });
                                  }}
                                >
                                  Modify
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowModal({ type: "deletePlan", data: plan });
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      <Container className="mt-2 mb-3" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Features</h3>
                  </Col>
                  <Col xs="4" className="text-right">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        setFeaturesData([...featuresData, { id: "", name: "", example: "" }]);
                        setEditFeature({ id: "", name: "", example: "" });
                        setEditingFeature({ id: "" });
                      }}
                    >
                      + New Feature
                    </button>
                  </Col>
                </Row>
              </CardHeader>
              {loading ? <LoaderSpinner /> : null}
              <Table className="align-items-center table-flush" responsive striped>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col" id="name-column">
                      Name
                      <UncontrolledTooltip placement="left" target={`name-column`}>
                        If use # symbol, this will be replaced with the feature value
                      </UncontrolledTooltip>
                    </th>
                    <th scope="col">Example</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list">
                  {isEmpty(featuresData) ? (
                    <tr>
                      <td>
                        <div className="m-4">{`No Features.`}</div>
                      </td>
                    </tr>
                  ) : null}
                  {!isEmpty(featuresData)
                    ? featuresData.map((feature) => (
                        <tr key={feature.id}>
                          <td className="name">
                            {editingFeature && editingFeature.id === feature.id && feature.id === "" ? (
                              <Input
                                className="form-control"
                                type="text"
                                value={editFeature.id}
                                onChange={(e) =>
                                  setEditFeature({
                                    id: e.target.value,
                                    name: editFeature.name,
                                    example: editFeature.example
                                  })
                                }
                              />
                            ) : (
                              feature.id
                            )}
                          </td>
                          <td className="name">
                            {editingFeature && editingFeature.id === feature.id ? (
                              <Input
                                className="form-control"
                                type="text"
                                value={editFeature.name}
                                onChange={(e) =>
                                  setEditFeature({
                                    id: editFeature.id,
                                    name: e.target.value,
                                    example: editFeature.example
                                  })
                                }
                              />
                            ) : (
                              feature.name
                            )}
                          </td>
                          <td className="name">
                            {editingFeature && editingFeature.id === feature.id ? (
                              <Input
                                className="form-control"
                                type="text"
                                value={editFeature.example}
                                onChange={(e) =>
                                  setEditFeature({
                                    id: editFeature.id,
                                    name: editFeature.name,
                                    example: e.target.value
                                  })
                                }
                              />
                            ) : (
                              feature.example
                            )}
                          </td>
                          <td className="text-right">
                            {editingFeature && editingFeature.id === feature.id ? (
                              <>
                                <Button
                                  size="sm"
                                  color="success"
                                  onClick={() =>
                                    saveFeature({
                                      variables: {
                                        featureId: editFeature.id,
                                        name: editFeature.name,
                                        example: editFeature.example
                                      }
                                    })
                                  }
                                >
                                  <i className="fas fa-check" />
                                </Button>
                                <Button
                                  size="sm"
                                  style={{ marginLeft: 8 }}
                                  onClick={() => {
                                    setEditFeature();
                                    setEditingFeature();
                                    remove(featuresData, (feature) => feature.id === "");
                                  }}
                                >
                                  <i className="fas fa-times" />
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  size="sm"
                                  color="primary"
                                  disabled={editingFeature ? true : false}
                                  onClick={() => {
                                    setEditFeature({ id: feature.id, name: feature.name, example: feature.example });
                                    setEditingFeature({ id: feature.id });
                                  }}
                                >
                                  <i className="fas fa-edit" />
                                </Button>
                                <Button
                                  size="sm"
                                  color="warning"
                                  disabled={editingFeature ? true : false}
                                  onClick={() => {
                                    setShowModal({ type: "removeFeature", data: feature.id });
                                  }}
                                >
                                  <i className="fas fa-trash" />
                                </Button>
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      {showModal && showModal.type && (showModal.type === "addNewPlan" || showModal.type === "updatePlan") ? (
        <ManagementModal onClose={() => setShowModal(null)}>
          {showModal.type === "addNewPlan" ? (
            <PlanForm onSave={refetch} setToEdit={true} features={featuresData} />
          ) : null}
          {showModal.type === "updatePlan" ? (
            <PlanForm onSave={refetch} planData={showModal.data} features={featuresData} />
          ) : null}
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "deletePlan" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal(null)}
          header={<div>Remove Plan</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal(null) },
            {
              label: "Remove",
              onClick: () =>
                removePlan({
                  variables: { planId: showModal.data.id }
                })
            }
          ]}
        >
          <p>
            Are you sure you want to remove <span className="font-weight-bold">"{showModal.data.name}"</span> plan?
          </p>
        </ManagementModal>
      ) : null}
      {showModal && showModal.type === "removeFeature" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal()}
          header={<div>Remove "{showModal.data}" feature?</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal() },
            {
              label: "Remove",
              onClick: () =>
                removeFeature({
                  variables: { featureId: showModal.data }
                })
            }
          ]}
        >
          Are you sure you want to remove "{showModal.data}" feature?
        </ManagementModal>
      ) : null}
    </>
  );
};

export default ManagePlans;
