import React, { useState, useEffect } from "react";
import { Input, FormGroup } from "reactstrap";
import { getUniqueID, defaultOnChange } from "./HelperFunctions";
import { Typeahead } from "react-bootstrap-typeahead";
import { sortBy } from "lodash";

const TypeaheadField = (props) => {
  const [selected, setSelected] = useState([]);
  const { title, description, options, placeholder } = props.question;

  useEffect(() => {
    const { onChange = defaultOnChange } = props;
    onChange({
      target: {
        value: selected
      }
    });
  }, [selected]);
  return (
    <>
      <label className="form-control-label">
        {title && <div>{title}</div>}
        {description && <small className="text-muted">{description}</small>}
      </label>
      <Typeahead
        id={"typeahead-" + title.replaceAll(" ", "")}
        labelKey="name"
        onChange={(s) => setSelected(s)}
        options={sortBy(options, "name")}
        placeholder={placeholder || ""}
        selected={selected}
        size="sm"
      />
    </>
  );
};

const PreviewTypeaheadField = () => {
  return (
    <>
      <Typeahead labelKey="name" options={[]} placeholder="Select Option" />
    </>
  );
};

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Typeahead",
  description: "Please Select An Option",
  type: "typeahead",
  options: [
    {
      id: `${getUniqueID()}1`,
      name: "Option 1",
      icon: "",
      cost: {
        low: 0,
        expected: 0,
        high: 0
      }
    },
    {
      id: `${getUniqueID()}2`,
      name: "Option 2",
      icon: "",
      cost: {
        low: 0,
        expected: 0,
        high: 0
      }
    },
    {
      id: `${getUniqueID()}3`,
      name: "Option 3",
      icon: "",
      cost: {
        low: 0,
        expected: 0,
        high: 0
      }
    }
  ]
});

const TypeaheadEditOptions = (json, onInputChange) => {
  return (
    <>
      <FormGroup>
        <label className="form-control-label">
          <div>Prefix</div>
        </label>
        <Input
          placeholder="Default input"
          className="form-control-sm"
          type="text"
          name="prefix"
          value={json.hasOwnProperty("prefix") ? json.prefix : ""}
          onChange={(e) => onInputChange({ e })}
        />
      </FormGroup>
      <FormGroup>
        <label className="form-control-label">
          <div>Suffix</div>
        </label>
        <Input
          placeholder="Default input"
          className="form-control-sm"
          type="text"
          name="suffix"
          value={json.hasOwnProperty("suffix") ? json.suffix : ""}
          onChange={(e) => onInputChange({ e })}
        />
      </FormGroup>
    </>
  );
};

const fieldConfig = {
  key: "typeahead",
  hidden: true,
  component: TypeaheadField,
  getInitialData: getInitialData,
  previewComponent: PreviewTypeaheadField,
  getEditOptions: TypeaheadEditOptions
};

export default fieldConfig;
