import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import { useAuth0 } from "../../AuthProvider";
import { get } from "lodash";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";
import { useDispatch } from "react-redux";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import { isAgency } from "utils/helperFunctions";
import { gql } from "apollo-boost-upload";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { AWS_S3_ASSETS_BUCKET } from "utils/constants";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { useIsAuthenticated } from "hooks/useIsAuthenticated";

const GET_COMPANY_LOGO = gql`
  query companyLogo($domain: String!, $subdomain: String) {
    companyLogo(domain: $domain, subdomain: $subdomain)
  }
`;

const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

const Login = () => {
  const { login, loginError, clearLoginError } = useAuth0();
  const authenticated = useIsAuthenticated();

  const dispatch = useDispatch();
  const [sendForgotEmail] = useMutation(FORGOT_PASSWORD_MUTATION);

  const [state, setState] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [changePasswordResp, setChangePasswordResp] = useState();
  const [validating, setValidating] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const host = window.location.host;
  const [logo, setLogo] = useState();
  const { loading, data } = useQuery(GET_COMPANY_LOGO, {
    variables: {
      subdomain: host.substring(0, host.lastIndexOf(".", host.lastIndexOf(".") - 1)),
      domain: host.substring(host.lastIndexOf(".", host.lastIndexOf(".") - 1) + 1, host.length)
    }
  });

  useEffect(() => {
    if (!loading && data) {
      setLogo(
        isAgency
          ? data.companyLogo !== ""
            ? `${AWS_S3_ASSETS_BUCKET}/${data.companyLogo}`
            : null
          : require("assets/img/brand/sinapi-logo.svg")
      );
    }
  }, [loading, data]);

  useEffect(() => {
    if (location && location.state && location.state.alert) {
      dispatch({ type: "ADD_ALERT", payload: location.state.alert });
      history.replace({
        pathname: location.pathname,
        state: {}
      });
    }
  }, []);

  useEffect(() => {
    if (authenticated && authenticated.errorAuth) {
      setChangePasswordResp({ type: "danger", msg: get(authenticated, ["errorAuth", "graphQLErrors", 0, "message"]) });
    }
  }, [authenticated]);

  if (authenticated && authenticated.dataAuth && !authenticated.errorAuth) {
    return <Redirect to="/admin/dashboard" />;
  }

  function validateForm() {
    return email.length > 0 && (password.length > 0 || forgotPassword);
  }

  const handleForgotPassword = (email) => {
    sendForgotEmail({
      variables: {
        email: email
      }
    }).then(({ errors }) => {
      if (errors) {
        setChangePasswordResp({ type: "danger", msg: errors[0].message });
      } else {
        setChangePasswordResp({ type: "success", msg: "Password Reset Email Successfully Sent!" });
      }
    });
  };

  async function handleSubmit(event) {
    clearLoginError();
    setChangePasswordResp();
    event.preventDefault();
    if (forgotPassword) {
      handleForgotPassword(email);
    } else {
      setValidating(true);
      login({
        variables: {
          email: email,
          password: password
        }
      }).then(() => authenticated && authenticated.refetchAuth());
    }
  }

  return (
    <>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <>
          <AuthHeader loaded={true} />
          <Container className="pb-5" style={{ marginTop: "-10.2em" }}>
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card className="bg-secondary border-0 mb-0">
                  <CardHeader className="bg-transparent">
                    <img src={logo} alt="" style={{ maxWidth: `205px`, margin: "auto", display: "block" }} />
                    {/* <div className="text-muted text-center mt-2 mb-3">
                  <small>Sign in with</small>
                </div>
                <div className="btn-wrapper text-center">
                  {false ? (
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="btn-inner--icon mr-1">
                        <img alt="..." src={require("assets/img/icons/common/github.svg")} />
                      </span>
                      <span className="btn-inner--text">Github</span>
                    </Button>
                  ) : null}
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      loginConnection("google-oauth2");
                    }}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img alt="..." src={require("assets/img/icons/common/google.svg")} />
                    </span>
                    <span className="btn-inner--text">Google</span>
                  </Button>
                </div> */}
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-3">
                    <br></br>
                    {!forgotPassword ? (
                      <>
                        {/* <div className="text-center text-muted mb-4">
                      <small>Or sign in with credentials</small>
                    </div> */}
                        <Form role="form" onSubmit={handleSubmit}>
                          <FormGroup
                            className={classnames("mb-3", {
                              focused: state.focusedEmail
                            })}
                          >
                            {loginError ? (
                              <div role="alert" style={{ marginTop: "16px" }} className="alert alert-warning fade show">
                                <span className="alert-text ml-1">
                                  <strong>Oops... Let's try again</strong>
                                  <br />
                                  {/* Your username and password don't match */}
                                  {loginError}
                                </span>
                              </div>
                            ) : null}
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-83" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Email"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                onFocus={() => setState({ focusedEmail: true })}
                                onBlur={() => setState({ focusedEmail: false })}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup
                            className={
                              classnames({
                                focused: state.focusedPassword
                              }) + " mb-1"
                            }
                          >
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-lock-circle-open" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Password"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                onFocus={() => setState({ focusedPassword: true })}
                                onBlur={() => setState({ focusedPassword: false })}
                              />
                            </InputGroup>
                          </FormGroup>
                          <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                            <input className="custom-control-input" id=" customCheckLogin" type="checkbox" />
                            <label className="custom-control-label" htmlFor=" customCheckLogin">
                              <span className="text-muted">Remember me</span>
                            </label>
                          </div>
                          {changePasswordResp ? (
                            <div
                              role="alert"
                              style={{ marginTop: "16px" }}
                              className={`alert alert-${changePasswordResp.type} fade show`}
                            >
                              <span className="alert-text ml-1">{changePasswordResp.msg}</span>
                            </div>
                          ) : null}
                          <div className="text-center">
                            <Button className="my-4" color="info" type="submit" disabled={!validateForm()}>
                              {validating ? "Signing in!" : "Sign in"}
                            </Button>
                            <div>
                              <a
                                className="text-muted"
                                href="#pablo"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setForgotPassword(true);
                                }}
                              >
                                <small>Forgot password ?</small>
                              </a>{" "}
                              {/* {!isAgency && (
                                <>
                                  <small>| </small>
                                  <a className="text-muted" href="/auth/register">
                                    <small>Create an account</small>
                                  </a>
                                </>
                              )} */}
                            </div>
                          </div>
                        </Form>
                      </>
                    ) : null}
                    {forgotPassword ? (
                      <>
                        <div className="text-center text-muted mb-4">
                          <small>
                            Please enter your email address. We will send you an email to reset your password.
                          </small>
                        </div>
                        <Form role="form" onSubmit={handleSubmit}>
                          <FormGroup
                            className={classnames("mb-3", {
                              focused: state.focusedEmail
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-83" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Email"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                onFocus={() => setState({ focusedEmail: true })}
                                onBlur={() => setState({ focusedEmail: false })}
                              />
                            </InputGroup>
                            {changePasswordResp ? (
                              <div
                                role="alert"
                                style={{ marginTop: "16px" }}
                                className={`alert alert-${changePasswordResp.type} fade show`}
                              >
                                <span className="alert-text ml-1">{changePasswordResp.msg}</span>
                              </div>
                            ) : null}
                          </FormGroup>
                          <div className="text-center">
                            {(!changePasswordResp || changePasswordResp.type !== "success") && (
                              <Button className="my-4" color="info" type="submit" disabled={!validateForm()}>
                                Send Email
                              </Button>
                            )}
                            <Button
                              className="my-4 btn-neutral"
                              color="default"
                              onClick={(e) => {
                                e.preventDefault();
                                setChangePasswordResp();
                                setForgotPassword(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Form>
                      </>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Login;
