import React, { useState, useEffect } from 'react';
import _ from "lodash"
import { Container, Button, Card, CardHeader, ListGroup, ListGroupItem, Collapse, Row, Col, FormGroup, Input, Label } from 'reactstrap';

const FormVisualization = ({schema, setOverviewMode}) => {
	const [openQuestions, setOpenQuestions] = useState([]);
	const [formQuestions, setFormQuestions] = useState([]);
	const affectCost = {
		"fixed-cost": "Fixed Cost",
		"multiplier": "Multiplier",
		"adjustment": "Adjustment"
	}

	useEffect(() => {
		const questions = []
		if (schema && schema.sections) {
			schema.sections.forEach(section => {
				section.rows.forEach(row => {
					row.columns.forEach(column => {
						column.questions.forEach(question => {
							questions.push(question)
						})
					})
				})
			})
			setFormQuestions(questions)
		}
	}, [schema])

	const handleQuestionClick = questionId => {
		if(openQuestions.includes(questionId)) {
			setOpenQuestions(openQuestions.filter(q => q !== questionId))
		} else {
			setOpenQuestions([...openQuestions, questionId])
		}
	}

	const getQuestionTypeById = (questions, id) =>
		_.get(
			_.find(questions, (q) => q.id === id),
			"type",
			""
		);
		
	const getQuestionOptionsById = (questions, id) =>
		_.get(
			_.find(questions, (q) => q.id === id),
			"options",
			[]
		);

	const getQuestionOptionLabel = (question, optionId) => {
		const option = question.options.find(opt => opt.id === optionId);
		return option ? option.name : ""
	}

	const getCostCol = question => {
		const hasOptions = ["select", "checkbox", "radio"].includes(_.get(question, ["type"], ""));
		let costArr = [];
		if (!hasOptions) {
			costArr = [<Row>{getCost(question).map(col => col)}</Row>]
		} else {
			const options = _.get(question, ["options"]);
			const defaultValue = _.get(question, ["defaultvalue"], "")
			_.forEach(options, (option, idx) => {
				if (question.type === "select" && idx === 0) return;
				let  optCol = [];
				optCol.push(<Col xs={12}><h5 className="mb-0">{option.name} {defaultValue === option.id && <span>${`(Default)`}</span>}</h5></Col>)
				optCol = [...optCol, ...getCost(option)]
				costArr.push(<Row className="mb-3">{optCol.map(col => col)}</Row>)
			})
		}
		return costArr
	}

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 0,
		maximumFractionDigits: 1
	});
	
	const getCost = question => {
		const cost = _.get(question, ["cost"])
		const daily = _.get(question, ["daily"])
		const monthly = _.get(question, ["monthly"])
		const yearly = _.get(question, ["yearly"])
		const costArr = [];
		if (typeof cost === "object") { 
			costArr.push(<Col xs={3}><span>One Time</span></Col>)
			costArr.push(<Col xs={3}><span>{formatter.format(cost.low)}</span></Col>)
			costArr.push(<Col xs={3}><span>{formatter.format(cost.expected)}</span></Col>)
			costArr.push(<Col xs={3}><span>{formatter.format(cost.high)}</span></Col>)
		} else if (cost || cost === 0 || cost === "0") {
			costArr.push(<Col xs={3}><span>One Time</span></Col>)
			costArr.push(<Col xs={9}><span>{formatter.format(cost)}</span></Col>)
		}

		if (typeof daily === "object") {
			costArr.push(<Col xs={3}><span>Daily</span></Col>)
			costArr.push(<Col xs={3}><span>{formatter.format(daily.low)}</span></Col>)
			costArr.push(<Col xs={3}><span>{formatter.format(daily.expected)}</span></Col>)
			costArr.push(<Col xs={3}><span>{formatter.format(daily.high)}</span></Col>)
		} else if (daily || daily === 0 || daily === "0") {
			costArr.push(<Col xs={3}><span>Daily</span></Col>)
			costArr.push(<Col xs={9}><span>{formatter.format(daily)}</span></Col>)
		}

		if (typeof monthly === "object") {
			costArr.push(<Col xs={3}><span>Monthly</span></Col>)
			costArr.push(<Col xs={3}><span>{formatter.format(monthly.low)}</span></Col>)
			costArr.push(<Col xs={3}><span>{formatter.format(monthly.expected)}</span></Col>)
			costArr.push(<Col xs={3}><span>{formatter.format(monthly.high)}</span></Col>)
		} else if (monthly || monthly === 0 || monthly === "0") {
			costArr.push(<Col xs={3}><span>Monthly</span></Col>)
			costArr.push(<Col xs={9}><span>{formatter.format(monthly)}</span></Col>)
		}

		if (typeof yearly === "object") {
			costArr.push(<Col xs={3}><span>Yearly</span></Col>)
			costArr.push(<Col xs={3}><span>{formatter.format(yearly.low)}</span></Col>)
			costArr.push(<Col xs={3}><span>{formatter.format(yearly.expected)}</span></Col>)
			costArr.push(<Col xs={3}><span>{formatter.format(yearly.high)}</span></Col>)
		} else if (yearly || yearly === 0 || yearly === "0") {
			costArr.push(<Col xs={3}><span>Yearly</span></Col>)
			costArr.push(<Col xs={9}><span>{formatter.format(yearly)}</span></Col>)
		}

		return costArr
	}

	const toggleExpandAll = (expandAll = true) => {
		if (expandAll) {
			const questions = formQuestions.map(q => q.id);
			setOpenQuestions(questions)
		} else {
			setOpenQuestions([])
		}
	}

	if (!schema || !schema.sections) {
    return null;
  }
	 
	return <Container fluid className="visualization-container">
		<div className="mt-4">
			<div style={{display: "flex"}}>
				<h1 className="mb-0">Form Overview</h1>
				<button onClick={() => setOverviewMode(false)} style={{background: 'transparent', borderColor: 'transparent', color: 'black', marginLeft: 'auto', cursor: "pointer"}}><i className="fas fa-times fa-2x"></i></button>
			</div>
			<Button size="sm" color="primary" outline type="button" onClick={() => toggleExpandAll()} className="visualization-button">Expand All</Button>
			|
			<Button size="sm" color="primary" outline type="button" onClick={() => toggleExpandAll(false)} className="visualization-button">Collapse All</Button>
			{schema.sections.map(section => 
				<Card key={section.id} className="mt-3 visualization-card">
					<ListGroup flush key={section.id + "questions"}>
						{section.rows.map(row => 
							row.columns.map(column => 
								column.questions.map(question => {
									const isActive = openQuestions.includes(question.id);
									const hasCost = _.get(question, ["dollarValue"])
									const hasDynamicRules = _.get(question, ["dynamicRules", "active"], false);
									const arrDynamicRules = _.get(question, ["dynamicRules", "rules"], []);
									return hasCost && <>
										<ListGroupItem 
											className="question-header" color="light" 
											onClick={() => handleQuestionClick(question.id)}
											key={question.id + "header"}
										>
											{question.title}
											&nbsp;
											{hasDynamicRules && <i className="fas fa-bolt fa-xs" id="dynamic-rule" style={{ color: "black" }} />}
											<Button
												close
											>
												<i aria-hidden className={isActive ? "fas fa-angle-down" : "fas fa-angle-right"} />
											</Button>
										</ListGroupItem>
										<Collapse className="small question-details" isOpen={isActive} key={question.id + "content"}>
											<div className="ml-4 mt-2">
												<Row>
													<Col xs={4}>
														{getCostCol(question).map(c => c)}
													</Col>
													<Col xs={3}>
														<h5 className="mb-0">Description</h5>
														<p className="mb-4">{question.description.replace(/<\/?[^>]+(>|$)/g, "")}</p>
														<h5 className="mb-0">Question is required?</h5>
														<p className="mb-4">{question.required ? "Yes" : "No"}</p>
														<h5 className="mb-0">How will this affect the cost?</h5>
														<p className="mb-4">{affectCost[question.affectCost]}</p>
													</Col>
													<Col xs={5}>
														<h5 className="mb-0">Dynamic Rules</h5>
														<p className="mb-4">{hasDynamicRules ? "Enabled" : "Disabled"}</p>
														{hasDynamicRules && 
															<>
																<FormGroup className="form-inline mb-3">
																	<Input
																		className="form-control-sm col-6"
																		name="action"
																		value={_.get(question, ["dynamicRules", "action"], "show")}
																		style={{
																			maxWidth: "100px"
																		}}
																		disabled
																	/>
																	<Label className="form-control-label col-6 justify-content-start">
																		<div>This question when:</div>
																	</Label>
																</FormGroup>
																{arrDynamicRules.map((dynamicRule, idx )=> 
																	<FormGroup key={`dynamicRule_${idx}`} className="mb-0">
																		{_.get(dynamicRule, ["ruleGroup", "rules"], []).map((rule, idxRule) => (
																			<div key={`dynamicRuleRow_${idx}_${idxRule}`} className="ml-2">
																				<Row>
																					<Col xs={1}></Col>
																					<Col xs="4" className="pl-0 pr-0">
																						<Input
																							name="questionId"
																							value={formQuestions.length > 0 ? formQuestions.find(q => q.id === _.get(rule, ["questionId"], "")).title : ""}
																							className="form-control-sm"
																							disabled
																						>
																						</Input>
																					</Col>
																					<Col xs="3" className="pl-0 pr-0 ml-1">
																						<Input
																							name="operator"
																							className="form-control-sm"
																							value={_.get(rule, ["operator"], "")}
																							disabled
																						>
																						</Input>
																					</Col>
																					<Col xs="3" className="pl-0 pr-0 ml-1">
																						{["select", "radio", "checkbox"].includes(
																							getQuestionTypeById(formQuestions, _.get(rule, ["questionId"], ""))
																						) ? (
																							<Input
																								className="form-control-sm"
																								name="answer"
																								value={formQuestions.length > 0 ? getQuestionOptionLabel(formQuestions.find(question => question.id === _.get(rule, ["questionId"], "")), _.get(rule, ["answer"], "")) : ""}
																								disabled
																							>
																							</Input>
																						) : (
																							<Input
																								className="form-control-sm"
																								type="text"
																								name="answer"
																								value={_.get(rule, ["answer"], "")}
																								disabled
																							/>
																						)}
																					</Col>
																				</Row>
																				{_.get(dynamicRule, ["ruleGroup", "condition"]) &&
																					_.get(dynamicRule, ["ruleGroup", "rules"], []).length > 1 &&
																					idxRule < _.get(dynamicRule, ["ruleGroup", "rules"], []).length - 1 && (
																						<Row>
																							<Col className="text-center">
																								<Label style={{ color: "#5e72e4", fontSize: "0.75rem", fontWeight: "600" }}>
																									{_.get(dynamicRule, ["ruleGroup", "condition"], "")}
																								</Label>
																							</Col>
																						</Row>
																					)}
																			</div>
																		))}
																		<div className="hr">
																			<hr className="mb-3 mt-4" />
																			{idx !== arrDynamicRules.length - 1 && <i className="fas fa-link chain-icon" />}
																			{arrDynamicRules.length > 1 && idx < arrDynamicRules.length - 1 ? (
																				<>
																					{idx === 0 ? (
																						<Input
																							name="condition"
																							className="form-control-sm"
																							style={{ maxWidth: "108px", marginLeft: "36%", textAlign: "center", textAlignLast: "center" }}
																							value={_.get(question, ["dynamicRules", "condition"], "")}
																							disabled
																						>
																						</Input>
																					) : (
																						<Input
																							type="text"
																							className="form-control-sm"
																							style={{ maxWidth: "108px", marginLeft: "36%", textAlign: "center" }}
																							value={_.get(question, ["dynamicRules", "condition"], "")}
																							disabled
																						/>
																					)}
																				</>
																			) : (
																				<></>
																			)}
																		</div>
																	</FormGroup>	
																)}
															</>
														}
													</Col>
												</Row>
											</div>
										</Collapse>
									</>
								})
							)
						)}
					</ListGroup>
				</Card>
			)}
		</div>
	</Container>
}

export default FormVisualization