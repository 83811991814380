// src/views/ExternalApi.js

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const GET_COMPANY = gql`
  {
    company(id: 1) {
      name
      address
      users {
        items {
          firstName
        }
      }
    }
  }
`;
/*
 * External api test, to get api token and fetch from /api/external
 */
const ExternalApi = () => {
  const { loading, data } = useQuery(GET_COMPANY);

  if (loading) {
    return <div>Loading</div>;
  }

  return <>{data && <code>{JSON.stringify(data, null, 2)}</code>}</>;
};

export default ExternalApi;
