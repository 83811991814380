import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { gql } from "apollo-boost";
import { get, isEmpty } from "lodash";
import { useQuery } from "@apollo/react-hooks";

// core components
import AnalyticsHeader from "./analytic/AnalyticsHeader";
import AnalyticsContainer from "./analytic/AnalyticsContainer";
import { numberToCurrency } from "utils/helperFunctions";

const PAGE_SIZE = 6;

const GET_LEADS = gql`
  query LeadsByParam($paramId: ID!, $param: String, $offset: Int, $limit: Int, $startRange: String, $endRange: String) {
    leadsByParam(
      paramId: $paramId
      param: $param
      offset: $offset
      limit: $limit
      startRange: $startRange
      endRange: $endRange
    ) {
      count
      offset
      totalCost
      totalCostPerWeek
      totalCostPerMonth
      totalCostPerYear
      todayDate
      lastWeek
      lastMonth
      lastYear
      items {
        id
        firstName
        lastName
        email
        phone
        address
        city
        state
        zipcode
        value
        additionalInfo
        quoteJsonFile
        progress
        createdAt
        form {
          name
          schema
          counter
          status
        }
      }
    }
  }
`;

function Analytic() {
  const { formId } = useParams();
  const [endRange, setEndRange] = useState("");
  const [startRange, setStartRange] = useState("");

  const isValidDateRange = () =>
    isEmpty(startRange) && isEmpty(endRange) ? true : !isEmpty(startRange) && !isEmpty(endRange) ? true : false;

  const { loading, data, fetchMore, refetch } = useQuery(GET_LEADS, {
    fetchPolicy: "cache-and-network",
    variables: {
      paramId: formId,
      param: "form_id",
      offset: 0,
      limit: PAGE_SIZE,
      startRange: isValidDateRange() ? startRange : "",
      endRange: isValidDateRange() ? endRange : ""
    },
    notifyOnNetworkStatusChange: true
  });

  return (
    <>
      <AnalyticsHeader
        name={get(data, ["leadsByParam", "items", 0, "form", "name"])}
        count={get(data, ["leadsByParam", "count"], 0)}
        totalCost={numberToCurrency(get(data, ["leadsByParam", "totalCost"], 0.0))}
        totalOpens={get(data, ["leadsByParam", "items", 0, "form", "counter"], 0)}
        status={get(data, ["leadsByParam", "items", 0, "form", "status"], "draft")}
        startRange={startRange}
        endRange={endRange}
        setStartRange={setStartRange}
        setEndRange={setEndRange}
      />
      <AnalyticsContainer
        data={data}
        refetch={refetch}
        loading={loading}
        fetchMore={fetchMore}
        pageSize={PAGE_SIZE}
        totalCostPerWeek={get(data, ["leadsByParam", "totalCostPerWeek"])}
        totalCostPerMonth={get(data, ["leadsByParam", "totalCostPerMonth"])}
        totalCostPerYear={get(data, ["leadsByParam", "totalCostPerYear"])}
        todayDate={get(data, ["leadsByParam", "todayDate"])}
        lastWeek={get(data, ["leadsByParam", "lastWeek"])}
        lastMonth={get(data, ["leadsByParam", "lastMonth"])}
        lastYear={get(data, ["leadsByParam", "lastYear"])}
      />
    </>
  );
}

export default Analytic;
