import "./FormSection.scss";
import React, { useState } from "react";

// reactstrap components
import { Button, Card, CardBody, CardFooter, Col } from "reactstrap";
import classnames from "classnames";
import { get } from "lodash";
// core components
import { useSelector, useDispatch } from "react-redux";
import { activeSectionSelector } from "reducers/selectors/formSelectors";
import { SinapiDragDropList, SinapiDraggableItem } from "components/DragAndDrop";
import { FormRow } from "./FormRow";
import { formActions } from "reducers";
import ManagementModal from "components/UserManagement/ManagementModal";
import { useEffect } from "react";

/**
 * Renders all the questions within a section
 * @param {object} props
 */
function FormSection({ section, hasNext, index }) {
  const dispatch = useDispatch();
  const [showColumnOptions, setShowColumnOptions] = useState(null);
  const activeSection = useSelector((state) => activeSectionSelector(state));
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(null);
  const [leadEmailRowIndex, setLeadEmailRowIndex] = useState();

  const updateRowColumns = (rowIndex, format) => {
    dispatch({
      type: formActions.UPDATE_ROW_FORMAT,
      payload: {
        sectionId: section.json.id,
        rowIndex: rowIndex,
        format: format
      }
    });
    setShowColumnOptions(false);
  };

  const copyRow = (row) => {
    dispatch({
      type: formActions.COPY_ROW_FROM_SECTION,
      payload: {
        row: row,
        sectionId: section.json.id
      }
    });
  };

  const deleteRow = (row) => {
    dispatch({
      type: formActions.DELETE_ROW_FROM_SECTION,
      payload: {
        row: row,
        sectionId: section.json.id
      }
    });
  };

  const editSection = (section) => {
    dispatch({
      type: formActions.EDIT_SECTION,
      payload: {
        section,
        sectionId: section.id
      }
    });
  };

  const copySection = (section) => {
    dispatch({
      type: formActions.COPY_SECTION,
      payload: section.id
    });
  };

  const deleteSection = (section) => {
    dispatch({
      type: formActions.DELETE_SECTION,
      payload: section.id
    });
  };

  const onDrop = (sectionId, item, index) => {
    if (item.parentDropAreaId) {
      dispatch({
        type: formActions.MOVE_ROW,
        payload: {
          from: {
            sectionId: item.parentDropAreaId.replace("droparea-", ""),
            rowId: item.name.replace("row-", "")
          },
          to: {
            sectionId: sectionId,
            index: index
          }
        }
      });
    }
  };

  useEffect(() => {
    if (section.json) {
      section.json.rows.forEach((row, rowIndex) => {
        row.columns.forEach(column => {
          column.questions.forEach(question => {
            if (question.id === "LeadDetailsSectionEmail") {
              setLeadEmailRowIndex(rowIndex)
            }
          })
        })
      })
    }
  }, [section])

  
  const deleteAction = () => {
    switch(deleteConfirmModal.type) {
      case "section":
        deleteSection(deleteConfirmModal.payload);
        break;
      case "row":
        deleteRow(deleteConfirmModal.payload);
        break;
      default:
        setDeleteConfirmModal(null);
    }
    setDeleteConfirmModal(null);
  }
  
  const renderQuestions = (json) => {
    return (
      <SinapiDragDropList
        accept={["row"]}
        items={json.rows || []}
        onDrop={(item, index) => onDrop(json.id, item, index)}
        id={"droparea-" + json.id}
        customDropArea={<div className="row-drop-area">Drop Row Here</div>}
      >
        {(row, index) => (
          <SinapiDraggableItem
            name={"row-" + index}
            type="row"
            useCustomDragHandle={true}
            parentDropAreaId={"droparea-" + json.id}
          >
            {(drag) => (
              <div className="form-row" onMouseLeave={() => showColumnOptions && setShowColumnOptions(false)}>
                <div className="row-toolbar">
                  <div className="options-wrapper left bg-info">
                    <div className="drag-question row-option-btn" ref={drag}>
                      <i className="fa fa-arrows-alt" />
                    </div>
                    <div className="option-divider" />
                    <div
                      className={classnames("change-row-columns row-option-btn", {
                        active: showColumnOptions && showColumnOptions.index === index
                      })}
                      onClick={() => {
                        setShowColumnOptions(
                          showColumnOptions
                            ? null
                            : {
                                index: index
                              }
                        );
                      }}
                    >
                      <i className="fas fa-columns"></i>
                    </div>
                    <div className="option-divider" />
                    {index !== leadEmailRowIndex && 
                      <>
                        <div
                          className="copy-question row-option-btn"
                          onClick={() => {
                            copyRow(row);
                          }}
                        >
                          <i className="fa fa-copy" />
                        </div>
                        <div className="option-divider" />
                        <div
                          className="delete-question row-option-btn"
                          onClick={() => {
                            // deleteRow(row);
                            setDeleteConfirmModal({
                              type: 'row',
                              text: 'Are you sure you want to delete this row?',
                              payload: row
                            });
                          }}
                        >
                          <i className="fa fa-trash-alt" />
                        </div>
                      </>                      
                    }
                    <div className="show-options">
                      <i className="fas fa-chevron-left" />
                    </div>
                  </div>
                  <div
                    className={classnames("row-column-options", {
                      active: showColumnOptions && showColumnOptions.index === index
                    })}
                  >
                    <div className="row" onClick={() => updateRowColumns(index, [12])}>
                      <div className="col-sm-12 column-option">
                        <div className="column-filler" />
                      </div>
                    </div>
                    <div className="row" onClick={() => updateRowColumns(index, [6, 6])}>
                      <div className="col-sm-6 column-option">
                        <div className="column-filler" />
                      </div>
                      <div className="col-sm-6 column-option">
                        <div className="column-filler" />
                      </div>
                    </div>
                    <div className="row" onClick={() => updateRowColumns(index, [4, 4, 4])}>
                      <div className="col-sm-4 column-option">
                        <div className="column-filler" />
                      </div>
                      <div className="col-sm-4 column-option">
                        <div className="column-filler" />
                      </div>
                      <div className="col-sm-4 column-option">
                        <div className="column-filler" />
                      </div>
                    </div>
                    <div className="row" onClick={() => updateRowColumns(index, [8, 4])}>
                      <div className="col-sm-8 column-option">
                        <div className="column-filler" />
                      </div>
                      <div className="col-sm-4 column-option">
                        <div className="column-filler" />
                      </div>
                    </div>
                    <div className="row" onClick={() => updateRowColumns(index, [4, 8])}>
                      <div className="col-sm-4 column-option">
                        <div className="column-filler" />
                      </div>
                      <div className="col-sm-8 column-option">
                        <div className="column-filler" />
                      </div>
                    </div>
                  </div>
                </div>
                <FormRow row={row} sectionId={json.id} rowIndex={index} />
              </div>
            )}
          </SinapiDraggableItem>
        )}
      </SinapiDragDropList>
    );
  };

  section = section ? { json: section } : activeSection;

  return (
    <>
      <SinapiDraggableItem
      name={section.json.id}
      type="section"
      useCustomDragHandle={true}
      parentDropAreaId={"dropareasection"}
    >
      {(drag) => (
        <>
          <Card>
            <CardBody>
              <div className="form-rows">{renderQuestions(section.json)}</div>
            </CardBody>
            <CardFooter>
            <Col className="text-right pr-0 pl-0" xs={12}>
                {index !== 0 && (
                  <Button
                    outline
                    className="btn-icon float-left"
                    color="secondary"
                    type="button"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-chevron-left"></i>
                    </span>
                    <span className="btn-inner--text">Back</span>
                  </Button>
                )}
                {hasNext && (
                  <Button
                    outline
                    className="btn-icon"
                    color="primary"
                    type="button"
                  >
                    <span className="btn-inner--text">Continue</span>
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Button>
                )}
                {!hasNext && (
                  <Button outline type="button" color="primary">
                    Complete
                  </Button>
                )}
              </Col>
            </CardFooter>
          </Card>
          <div className="section-toolbar">
            <div className="options-wrapper right">
              <div className="drag-section row-option-btn" ref={drag}>
                <i className="fa fa-arrows-alt" />
              </div>
              <div
                className="dynamic-rules-section row-option-btn"
                onClick={() => {
                  editSection(section.json);
                }}
              >
                <i className="fa fa-bolt" />
              </div>
              <div
                className="copy-section row-option-btn"
                onClick={() => {
                  copySection(section.json);
                }}
              >
                <i className="fa fa-copy" />
              </div>
              {get(section, ["json", "id"]) !== "LeadDetailsSection" && (
                <>
                  <div className="option-divider" />
                  <div
                    className="delete-section row-option-btn"
                    onClick={() => {
                        setDeleteConfirmModal({
                          type: 'section',
                          text: 'Are you sure you wish to delete this section?',
                          payload: section.json
                        })
                    }}
                  >
                    <i className="fa fa-trash-alt" />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
        )}
      </SinapiDraggableItem>
      {deleteConfirmModal && 
        <ManagementModal modalSize="md"
          classNames={{
            modal: "modal-dialog modal-success modal-dialog-centered",
            body: "bg-gradient-success",
            footer: "bg-gradient-success"
          }}
          buttons={[
            {
              label: "No, let's keep it",
              icon: "fas fa-times mr-2",
              color: "link",
              size: "md",
              onClick: () => setDeleteConfirmModal(null),
              className: "mr-auto text-white"
            },
            {
              label: "Yes, Delete It",
              icon: "fa fa-check mr-2",
              color: "secondary",
              size: "md",
              onClick: () => {
                deleteAction();
              }
            }
          ]}>
          <h1 className="text-center text-white">{deleteConfirmModal.text}</h1>
        </ManagementModal>}
    </>
  );
}

export { FormSection };
