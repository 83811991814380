import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { get } from "lodash";
import classnames from "classnames";
import { formActions } from "reducers";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";

import { GlobalEditOptions, createOptionsHTML, createRulesHTML } from "./formElementOptions";
import { useForm } from "views/pages/forms/FormContext";
import { getLimitSubscriptionPerFeatureType, stringToBoolean } from "utils/helperFunctions";
import { FEATURE_ADVANCED_EDITING, FEATURE_WHITE_LABEL, WHITE_LABELS } from "utils/constants";
import ManagementModal from "components/UserManagement/ManagementModal";
import CompanySubscription from "components/Subscription/CompanySubscription";
import { Link } from "react-router-dom";

function FieldOptionsPanel({ activeQuestionJSON, company, companySubscription, activeTab, onClose }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);
  const userRole = currentWorkspace ? currentWorkspace.userRoleId || currentUser.roleId : null;

  const { allFields, allFieldsWithCost } = useForm();

  const hasWhiteLabel = stringToBoolean(getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_WHITE_LABEL));

  const hasAdvancedEditing =
    getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_ADVANCED_EDITING) === "Advanced";

  const renderActiveQuestionOptions = (json) => {
    return createOptionsHTML(json, dispatch, allFieldsWithCost, hasAdvancedEditing, setShowModal);
  };

  const renderActiveQuestionDetails = (json) => {
    return GlobalEditOptions(json, dispatch, allFieldsWithCost, hasAdvancedEditing, setShowModal);
  };

  const renderActiveQuestionDynamicRules = (json) => {
    return createRulesHTML(json, dispatch, allFields, false, hasAdvancedEditing, setShowModal);
  };

  const closeActiveQuestionDetails = () => {
    dispatch({
      type: formActions.CLOSE_ACTIVE_QUESTION
    });
    if (onClose) {
      onClose();
    }
  };

  const submitActiveQuestionDetails = () => {
    dispatch({
      type: formActions.SUBMIT_QUESTION_BEING_EDITED
    });
    if (onClose) {
      onClose();
    }
  };
  const showOptionsPanel = () => {
    switch (get(activeQuestionJSON, ["type"], "")) {
      case "select":
      case "radio":
      case "checkbox":
        return (
          <NavItem style={{ width: "33%" }}>
            <NavLink
              className={classnames({
                active: activeTab === "2"
              })}
              onClick={() => dispatch({ type: "CHANGE_EDIT_QUESTION_TAB", payload: "2" })}
            >
              <i className="fas fa-list-ul" /> Options
            </NavLink>
          </NavItem>
        );
      default:
        return <></>;
    }
  };
  return (
    <div id="ActiveQuestionOption" className="vertical-sidenav-full-container">
      <Card className="card-pricing border-0 mb-0">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="12" className="vertical-sidenav-header">
              <Button close onClick={() => closeActiveQuestionDetails()} />
              <h1 className="mb-0">
                <i className="far fa-edit" /> Details
              </h1>
            </Col>
          </Row>
        </CardHeader>

        <CardBody style={{ padding: "0" }}>
          {activeQuestionJSON.type !== "email" && (
            <Nav tabs className="nav-fill" id="editTabNav">
              <NavItem style={{ width: "33%" }}>
                <NavLink
                  className={classnames({
                    active: activeTab === "1"
                  })}
                  onClick={() => dispatch({ type: "CHANGE_EDIT_QUESTION_TAB", payload: "1" })}
                >
                  <i className="fas fa-pencil-alt" /> Details
                </NavLink>
              </NavItem>
              {showOptionsPanel()}
              <NavItem style={{ width: "33%" }}>
                <NavLink
                  className={classnames({
                    active: activeTab === "3"
                  })}
                  onClick={() => dispatch({ type: "CHANGE_EDIT_QUESTION_TAB", payload: "3" })}
                >
                  <i className="fas fa-bolt" /> Dynamic Rules
                </NavLink>
              </NavItem>
            </Nav>
          )}
          <TabContent activeTab={activeTab} style={{ padding: "1.5rem" }}>
            <TabPane tabId="1">{renderActiveQuestionDetails(activeQuestionJSON)}</TabPane>
            <TabPane tabId="2">{renderActiveQuestionOptions(activeQuestionJSON)}</TabPane>
            <TabPane tabId="3">{renderActiveQuestionDynamicRules(activeQuestionJSON)}</TabPane>
          </TabContent>
        </CardBody>
        <CardFooter className="card-footer-sidenav">
          <Row className="align-items-center">
            <Col className="text-right" xs="12">
              <Button
                type="button"
                color="secondary"
                onClick={() => closeActiveQuestionDetails()}
                className="float-left"
              >
                Cancel
              </Button>
              <Button type="button" color="primary" onClick={() => submitActiveQuestionDetails()}>
                Apply Changes
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
      {showModal && (
        <ManagementModal
          onClose={() => setShowModal(false)}
          header={<div>ADVANCED EDITING</div>}
          buttons={[{ label: "Close", onClick: () => setShowModal(false) }]}
        >
          {!["consultant"].includes(userRole) ? (
            <>
              <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
                <h2 className="font-weight-400 text-justify">
                  Please{" "}
                  <Link className="font-weight-bold btn btn-primary" to="/admin/upgrade">
                    Upgrade your account
                  </Link>
                  {` to use this Element. This element are not allowed by your current plan.`}
                </h2>
              </CardHeader>
              <CompanySubscription
                companyData={company}
                plans={companySubscription.plans}
                price={companySubscription.compPrice}
                showDetails={false}
              />
            </>
          ) : (
            <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
              <h2 className="font-weight-400 text-justify">
                {`Your plan does not support this feature. If you would like to upgrade to get the most out of ${
                  WHITE_LABELS(hasWhiteLabel, company).sinapiName
                }, contact your account manager for assistance.`}
              </h2>
            </CardHeader>
          )}
        </ManagementModal>
      )}
    </div>
  );
}

export { FieldOptionsPanel };
