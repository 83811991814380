import React, { useState, useEffect } from "react";

import { gql } from "apollo-boost";
import { isEmpty, remove } from "lodash";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { Card, CardHeader, CardBody, Row, Col, Table, Button, Input } from "reactstrap";
import ManagementModal from "components/UserManagement/ManagementModal";

const GET_PLAN_FEATURES = gql`
  query getPlanFeatures($planId: ID!) {
    planFeatures(planId: $planId) {
      planId
      featureId
      value
    }
  }
`;

const SAVE_PLAN_FEATURES = gql`
  mutation SavePlanFeature($planId: ID!, $featureId: ID!, $value: String!) {
    savePlanFeature(planId: $planId, featureId: $featureId, value: $value)
  }
`;

const REMOVE_PLAN_FEATURES = gql`
  mutation RemovePlanFeature($planId: ID!, $featureId: ID!) {
    removePlanFeature(planId: $planId, featureId: $featureId)
  }
`;

const PlanFeatureForm = ({ planId, features }) => {
  const [planFeatures, setPlanFeatures] = useState();
  const [editFeature, setEditFeature] = useState();
  const [editPlanFeatures, setEditPlanFeatures] = useState();
  const [showModal, setShowModal] = useState();

  const { data, loading, refetch } = useQuery(GET_PLAN_FEATURES, {
    fetchPolicy: "network-only",
    variables: { planId: planId },
    skip: !planId,
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (!loading && data) setPlanFeatures(data.planFeatures);
  }, [data, loading]);

  const [savePlanFeature] = useMutation(SAVE_PLAN_FEATURES, {
    onCompleted: () => {
      refetch();
      setEditPlanFeatures();
      setEditFeature();
    }
  });

  const [removeFeature] = useMutation(REMOVE_PLAN_FEATURES, {
    onCompleted: () => {
      refetch();
      setShowModal();
    }
  });

  return (
    <>
      <Card className="mb-0 mt-2">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Features</h3>
            </Col>
            <Col xs="4" className="text-right">
              <button
                disabled={!planId || editPlanFeatures}
                className="btn btn-sm btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  setPlanFeatures([...planFeatures, { planId: planId, featureId: "default_option", value: "" }]);
                  setEditFeature({ id: "default_option", value: "" });
                  setEditPlanFeatures({ id: "default_option" });
                }}
              >
                + Add Feature
              </button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table className="align-items-center table-flush" responsive striped>
            <thead className="thead-light">
              <tr>
                <th scope="col">Feature</th>
                <th scope="col">Value</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody className="list">
              {isEmpty(data) ? (
                <tr>
                  <td>
                    <div className="m-4">{`No Features.`}</div>
                  </td>
                </tr>
              ) : (
                planFeatures &&
                planFeatures.map((plan) => (
                  <tr key={plan.featureId}>
                    <td className="py-1">
                      {editPlanFeatures &&
                      editPlanFeatures.id === plan.featureId &&
                      plan.featureId === "default_option" ? (
                        <Input
                          type="select"
                          onChange={(e) => setEditFeature({ id: e.target.value, value: editFeature.value })}
                          value={editFeature.id}
                        >
                          <option key={"default_feature_id"} id={`default_option`}>
                            - Select Feature -
                          </option>
                          {features.map((feature) => (
                            <option key={feature.id} value={feature.id}>
                              {feature.id}
                            </option>
                          ))}
                        </Input>
                      ) : (
                        plan.featureId
                      )}
                    </td>
                    <td className="py-1">
                      {editPlanFeatures && editPlanFeatures.id === plan.featureId ? (
                        <Input
                          className="form-control"
                          type="text"
                          value={editFeature.value}
                          onChange={(e) => setEditFeature({ id: editFeature.id, value: e.target.value })}
                        />
                      ) : (
                        plan.value
                      )}
                    </td>
                    <td className="py-1 text-right">
                      {editPlanFeatures && editPlanFeatures.id === plan.featureId ? (
                        <>
                          <Button
                            size="sm"
                            color="success"
                            onClick={() =>
                              savePlanFeature({
                                variables: { planId: planId, featureId: editFeature.id, value: editFeature.value }
                              })
                            }
                          >
                            <i className="fas fa-check" />
                          </Button>
                          <Button
                            size="sm"
                            style={{ marginLeft: 8 }}
                            onClick={() => {
                              setEditFeature();
                              setEditPlanFeatures();
                              remove(planFeatures, (feature) => feature.featureId === "default_option");
                            }}
                          >
                            <i className="fas fa-times" />
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            size="sm"
                            color="primary"
                            disabled={editPlanFeatures ? true : false}
                            onClick={() => {
                              setEditFeature({ id: plan.featureId, value: plan.value });
                              setEditPlanFeatures({ id: plan.featureId });
                            }}
                          >
                            <i className="fas fa-edit" />
                          </Button>
                          <Button
                            size="sm"
                            color="warning"
                            disabled={editPlanFeatures ? true : false}
                            onClick={() => {
                              setShowModal({ type: "removeFeature", data: plan.featureId });
                            }}
                          >
                            <i className="fas fa-trash" />
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      {showModal && showModal.type === "removeFeature" ? (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal()}
          header={<div>Remove "{showModal.data}" feature?</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal() },
            {
              label: "Remove",
              onClick: () =>
                removeFeature({
                  variables: { planId: planId, featureId: showModal.data }
                })
            }
          ]}
        >
          Are you sure you want to remove "{showModal.data}" feature?
        </ManagementModal>
      ) : null}
    </>
  );
};
export default PlanFeatureForm;
