export const UPDATE_FORM_NAME = "UPDATE_FORM_NAME";
export const UPDATE_ROW_FORMAT = "UPDATE_ROW_FORMAT";
export const MOVE_ROW = "MOVE_ROW";
export const MOVE_QUESTION = "MOVE_QUESTION";
export const ADD_NEW_QUESTION = "ADD_NEW_QUESTION";
export const EDIT_QUESTION = "EDIT_QUESTION";
export const COPY_QUESTION = "COPY_QUESTION";
export const EDIT_SECTION = "EDIT_SECTION";
export const CLOSE_ACTIVE_SECTION = "CLOSE_ACTIVE_SECTION";
export const TOGGLE_SECTION_DYNAMIC_RULES = "TOGGLE_SECTION_DYNAMIC_RULES";
export const UPDATE_SECTION_BEING_EDITED = "UPDATE_SECTION_BEING_EDITED";
export const SUBMIT_SECTION_BEING_EDITED = "SUBMIT_SECTION_BEING_EDITED";
export const TOGGLE_SECTION_ADD_DYNAMIC_RULE = "TOGGLE_SECTION_ADD_DYNAMIC_RULE";
export const TOGGLE_SECTION_ADD_DYNAMIC_GROUP_RULE = "TOGGLE_SECTION_ADD_DYNAMIC_GROUP_RULE";
export const DELETE_DYNAMIC_RULE_FROM_SECTION = "DELETE_DYNAMIC_RULE_FROM_SECTION";
export const DELETE_DYNAMIC_GROUP_RULE_FROM_SECTION = "DELETE_DYNAMIC_GROUP_RULE_FROM_SECTION";
export const ADD_NEW_SECTION = "ADD_NEW_SECTION";
export const COPY_SECTION = "COPY_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";
export const COPY_ROW_FROM_SECTION = "COPY_ROW_FROM_SECTION";
export const DELETE_ROW_FROM_SECTION = "DELETE_ROW_FROM_SECTION";
export const MOVE_SECTION = "MOVE_SECTION";
export const EDIT_SUCCESS_SECTION = "EDIT_SUCCESS_SECTION";
export const ADD_SUCCESS_SECTION = "ADD_SUCCESS_SECTION";
export const REMOVE_RULE_SUCCESS_SECTION = "REMOVE_RULE_SUCCESS_SECTION";
export const ACTIVATE_DYNAMIC_SUCCESS_SECTION = "ACTIVATE_DYNAMIC_SUCCESS_SECTION";
export const SUBMIT_SUCCESS_PAGE_EDITED = "SUBMIT_SUCCESS_PAGE_EDITED";
export const UPDATE_QUESTION_BEING_EDITED = "UPDATE_QUESTION_BEING_EDITED";
export const UPDATE_QUESTION_PRICE_ADJUSTED = "UPDATE_QUESTION_PRICE_ADJUSTED";
export const CLOSE_ACTIVE_QUESTION = "CLOSE_ACTIVE_QUESTION";
export const SUBMIT_QUESTION_BEING_EDITED = "SUBMIT_QUESTION_BEING_EDITED";
export const UPDATE_FORM_OPTIONS = "UPDATE_FORM_OPTIONS";
export const UPDATE_ACTIVE_THEME = "UPDATE_ACTIVE_THEME";
export const EDIT_THEME = "EDIT_THEME";
export const EDIT_THEME_UPDATE = "EDIT_THEME_UPDATE";
export const FINISH_EDIT_THEME = "FINISH_EDIT_THEME";
export const CANCEL_EDIT_THEME = "CANCEL_EDIT_THEME";
export const TOGGLE_QUESTION_COST_RANGE = "TOGGLE_QUESTION_COST_RANGE";
export const TOGGLE_QUESTION_IS_REQUIRED = "TOGGLE_QUESTION_IS_REQUIRED";
export const TOGGLE_QUESTION_COST_AFFECT = "TOGGLE_QUESTION_COST_AFFECT";
export const TOGGLE_QUESTION_COST_STRUCTURE = "TOGGLE_QUESTION_COST_STRUCTURE";
export const TOGGLE_QUESTION_DYNAMIC_RULES = "TOGGLE_QUESTION_DYNAMIC_RULES";
export const TOGGLE_QUESTION_ADD_DYNAMIC_RULE = "TOGGLE_QUESTION_ADD_DYNAMIC_RULE";
export const TOGGLE_QUESTION_ADD_DYNAMIC_GROUP_RULE = "TOGGLE_QUESTION_ADD_DYNAMIC_GROUP_RULE";
export const ADD_OPTION_FROM_QUESTION = "ADD_OPTION_FROM_QUESTION";
export const MOVE_OPTION_FROM_QUESTION = "MOVE_OPTION_FROM_QUESTION";
export const DELETE_OPTION_FROM_QUESTION = "DELETE_OPTION_FROM_QUESTION";
export const DELETE_DYNAMIC_RULE_FROM_QUESTION = "DELETE_DYNAMIC_RULE_FROM_QUESTION";
export const DELETE_DYNAMIC_GROUP_RULE_FROM_QUESTION = "DELETE_DYNAMIC_GROUP_RULE_FROM_QUESTION";
export const UPDATE_QUESTION_BEING_RENDERED = "UPDATE_QUESTION_BEING_RENDERED";
export const INITIALIZE_QUESTION_BEING_RENDERED = "INITIALIZE_QUESTION_BEING_RENDERED";
export const ACTIVATE_REDIRECT = "ACTIVATE_REDIRECT";
export const TOGGLE_QUESTION_TO_CONTACT_RECORD = "TOGGLE_QUESTION_TO_CONTACT_RECORD";