import React from "react";
import { RenderFormColumn } from "./RenderFormColumn";

function RenderFormRow({ row, formError, setFormError }) {
  return (
    <div className="form-row">
      <div className="form-row-columns">
        {row.columns.map((column, idxc) => (
          <div key={`row_${idxc}`} className="column-container">
            <RenderFormColumn column={column} formError={formError} setFormError={setFormError} />
          </div>
        ))}
      </div>
    </div>
  );
}

export { RenderFormRow };
