import React, { useState, useEffect } from "react";

import * as yup from "yup";
import { gql } from "apollo-boost";
import classnames from "classnames";
import { Formik, Form, Field } from "formik";
import { omitBy } from "lodash";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import PlanFeatureForm from "./PlanFeatureForm";
import { useMutation } from "@apollo/react-hooks";

const SAVE_PLAN = gql`
  mutation SavePlan($planId: ID, $plan: PlanInput!) {
    savePlan(planId: $planId, plan: $plan) {
      id
    }
  }
`;

const planFormValidation = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  description: yup.string().required()
});

const getInitialValues = (plan) =>
  ["id", "name", "description", "trialPeriodDays", "hidden", "customWorkspace", "hierarchy", "stripeId"].reduce(
    (init, key) => ({
      ...init,
      [key]: plan ? plan[key] || "" : key === "hidden" || key === "customWorkspace" ? false : ""
    }),
    {}
  );

const PlanForm = ({ planData, onSave, setToEdit = false, features }) => {
  const [editPlan, setEditPlan] = useState(setToEdit);
  const [planId, setPlanId] = useState(planData && planData.id);
  const [showErrorMsg, setShowErrorMsg] = useState();

  const [savePlan, { data, loading, error }] = useMutation(SAVE_PLAN);

  useEffect(() => {
    if (error) {
      setShowErrorMsg("Something went wrong while saving.");
    }
  }, [error]);

  useEffect(() => {
    if (data && !loading) {
      onSave(data);
      setEditPlan(false);
      setPlanId(data.savePlan.id);
    }
  }, [data, loading, onSave]);

  return (
    <>
      <Formik
        initialValues={{ ...getInitialValues(planData) }}
        enableReinitialize={true}
        validationSchema={planFormValidation}
        validateOnMount={true}
        onSubmit={(values) => {
          savePlan({
            variables: {
              plan: omitBy(values, (val) => val === ""),
              planId: planId
            }
          });
        }}
      >
        {(formikProps) => (
          <>
            {showErrorMsg && (
              <Row className="align-items-center">
                <Col className="col-12">
                  <div className="alert-header-bar alert alert-warning fade show alert-dismissible">
                    <span>
                      {showErrorMsg}
                      <br />
                      {error && error.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}
                    </span>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setShowErrorMsg()}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Col>
              </Row>
            )}
            <Card className="mb-0 mt-2">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Plan</h3>
                  </Col>
                  <Col xs="4" className="text-right">
                    {!editPlan && (
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setEditPlan(true);
                        }}
                      >
                        Edit Plan
                      </button>
                    )}
                    {editPlan && (
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          formikProps.submitForm();
                        }}
                        disabled={!formikProps.isValid}
                      >
                        Save Plan
                      </button>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-id">
                            Id
                          </label>
                          <Field name="id" readOnly={planId} id="input-id" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col lg="5">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-name">
                            Name
                          </label>
                          <Field name="name" readOnly={!editPlan} id="input-name" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-stripeId">
                            Stripe ID
                          </label>
                          <Field name="stripeId" readOnly={!editPlan} id="input-stripeId" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-desc">
                            Description
                          </label>
                          <Field name="description" readOnly={!editPlan} id="input-desc" className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-trial-days">
                            Trial Period Days
                          </label>
                          <Field
                            type="number"
                            min="0"
                            name="trialPeriodDays"
                            readOnly={!editPlan}
                            id="input-trial-days"
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-hierarchy">
                            Hierarchy
                          </label>
                          <Field
                            name="hierarchy"
                            type="number"
                            min="1"
                            readOnly={!editPlan}
                            id="input-hierarchy"
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="5" lg="2">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-hidden">
                            Hidden
                          </label>
                          <InputGroup className={classnames("input-group-merge")} style={{ width: "60%" }}>
                            <Field
                              name="hidden"
                              type="checkbox"
                              disabled={!editPlan}
                              id="input-hidden"
                              className="form-control"
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i className={!formikProps.values.hidden ? "far fa-eye" : "far fa-eye-slash"} />
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="5" lg="3">
                        <FormGroup className="text-center">
                          <label className="form-control-label" htmlFor="input-customWorkspace">
                            Custom Workspace Name
                          </label>
                          <InputGroup className="input-group-merge m-auto" style={{ width: "60%" }}>
                            <Field
                              name="customWorkspace"
                              type="checkbox"
                              disabled={!editPlan}
                              id="input-customWorkspace"
                              className="form-control"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <PlanFeatureForm planId={planId} features={features} />
          </>
        )}
      </Formik>
    </>
  );
};
export default PlanForm;
