export function getDefaultFormJSON() {
  const defaultId = "00000" + new Date().valueOf();
  return {
    id: `${defaultId}1`,
    name: "Marks Standard Quote",
    userId: "946",
    display: "cards",
    createdDate: "01/22/2019",
    modifiedDate: "01/24/2019",
    customCSS: "",
    header: "",
    footer: "",
    logo: "/images/logo.png",
    schema: {
      options: {
        width: 700,
        type: "multi-step",
        frequency: "instantly",
        emailFollowup: "active",
        customCodeHeader: "",
        customCodeFooter: "",
      },
      success: {
        type: "section",
        section: {
          header: "Thanks For Your Time!",
          subHeader: "We'll reach out to you shortly to answers any questions.",
          orderTable: "Requested Services",
          priceHeader: "We estimate your services will cost around:",
          footer: "",
          notInterestedButtonText: "Not Interested",
          confirmButtonText: "Let's Get Started",
          showOrderTable: true,
          hasCustomSuccessText: false,
          hidePrice: false,
          interestedText: "<p>Thank you, We look forward to working together.</p>",
          notInterestedText: "<p>Thank you, We appreciate your feedback.</p>",
          hasDynamicSuccessText: false,
          dynamicRules: [
            {
              answer: "0",
              operator: "Equals To",
              interestedText: "<p>Thank you, We look forward to working together.</p>",
              notInterestedText: "<p>Thank you, We appreciate your feedback.</p>"
            }
          ]
        }
      },
      sections: [
        {
          id: "DefaultSection",
          visible: true,
          title: "New Slide",
          description: "",
          value: "",
          rows: [
            {
              format: [12],
              columns: [
                {
                  questions: []
                }
              ]
            }
          ]
        },
        {
          id: "LeadDetailsSection",
          rows: [
            {
              format: [6, 6],
              columns: [
                {
                  questions: [
                    {
                      id: "LeadDetailsSectionFirstName",
                      type: "text",
                      title: "First Name",
                      required: true,
                      removable: true
                    }
                  ]
                },
                {
                  questions: [
                    {
                      id: "LeadDetailsSectionLastName",
                      type: "text",
                      title: "Last Name",
                      required: true,
                      removable: true
                    }
                  ]
                }
              ]
            },
            {
              format: [12],
              columns: [
                {
                  questions: [
                    {
                      id: "LeadDetailsSectionEmail",
                      type: "email",
                      title: "Email",
                      required: true,
                      removable: false
                    }
                  ]
                }
              ]
            },
            {
              format: [12],
              columns: [
                {
                  questions: [
                    {
                      id: "LeadDetailsSectionPhone",
                      type: "phone",
                      title: "Contact Phone",
                      required: true,
                      removable: true
                    }
                  ]
                }
              ]
            },
            {
              format: [12],
              columns: [
                {
                  questions: [
                    {
                      id: "LeadDetailsSectionAddress",
                      type: "address",
                      title: "Address",
                      required: false,
                      removable: true,
                      fields: [
                        {
                          id: "LeadDetailsSectionAddress",
                          name: "address",
                          label: "Address",
                          type: "sub-label"
                        },
                        {
                          id: "LeadDetailsSectionAddress",
                          name: "city",
                          label: "City",
                          type: "sub-label"
                        },
                        {
                          id: "LeadDetailsSectionAddress",
                          name: "state",
                          label: "State/Province",
                          type: "sub-label"
                        },
                        {
                          id: "LeadDetailsSectionAddress",
                          name: "zip",
                          label: "Zip/Postal Code",
                          type: "sub-label"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          title: "Tell Us About You",
          value: "",
          visible: true,
          description: ""
        }
      ]
    }
  };
}

export const getDefaultButtonJSON = () => ({
  backgroundColor: "rgba(247, 250, 252, 1)",
  borderRadius: "6px",
  color: "rgba(0, 0, 0, 1)",
  fontSize: "14px",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "25px",
  paddingRight: "25px",
  borderWidth: "0px",
  borderColor: "rgba(255, 255, 255, 1)",
  borderStyle: "solid"
});

export const getDefaultFloatinButtonJSON = () => ({
  backgroundColor: "rgba(247, 250, 252, 1)",
  color: "rgba(0, 0, 0, 1)",
  fontSize: "14px",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "25px",
  paddingRight: "25px",
  position: "fixed",
  borderRadius: "64px",
  right: "10px",
  bottom: "10px",
  zIndex: 9,
  borderWidth: "0px",
  borderColor: "rgba(255, 255, 255, 1)",
  borderStyle: "solid"
});

export const getDefaultSchemaJSON = (forSample = false) => ({
  options: {
    width: 700,
    type: "multi-step",
    frequency: "instantly",
    emailFollowup: "active",
    customCodeHeader: "",
    customCodeFooter: "",
  },
  success: {},
  sections: [
    {
      id: "Section",
      rows: [
        {
          format: [12],
          columns: [
            {
              questions: [
                {
                  id: "Section-Headline",
                  type: "headline",
                  title: "Headline",
                  required: false,
                  subTitle: "Sub-Headline to their form"
                }
              ]
            }
          ]
        },
        {
          format: [12],
          columns: [
            {
              questions: [
                {
                  id: "Section-Text",
                  type: "text",
                  title: "Text Label",
                  prefix: "",
                  suffix: "",
                  required: false,
                  description: "<p>Description for the input with a <a href='#' rel='noopener noreferrer'>Link</a></p>",
                  placeholder: forSample ? "Input Value" : ""
                }
              ]
            }
          ]
        },
        {
          format: [12],
          columns: [
            {
              questions: [
                {
                  id: "Section-Radio",
                  type: "radio",
                  style: "inline",
                  title: "Radio Buttons",
                  options: [
                    {
                      id: "Option_1",
                      icon: "",
                      name: "Option 1"
                    },
                    {
                      id: "Option_2",
                      icon: "",
                      name: "Option 2"
                    },
                    {
                      id: "Option_3",
                      icon: "",
                      name: "Option 3"
                    }
                  ],
                  required: false,
                  description: "<p>Please Select An Option</p>",
                  dollarValue: false
                }
              ]
            }
          ]
        },
        {
          format: [12],
          columns: [
            {
              questions: [
                {
                  id: "Section-Radio-Image-Option",
                  type: "radio",
                  style: "inline",
                  title: "Radio Buttons with Image/Icon Options",
                  hasOptionImages: true,
                  defaultValue: "OptionImage_2",
                  options: [
                    {
                      id: "OptionImage_1",
                      icon: "",
                      name: "Option 1"
                    },
                    {
                      id: "OptionImage_2",
                      icon: "",
                      name: "Option 2"
                    },
                    {
                      id: "OptionImage_3",
                      icon: "",
                      name: "Option 3"
                    }
                  ],
                  required: false,
                  description: "<p>Please Select An Option</p>",
                  dollarValue: false
                }
              ]
            }
          ]
        }
      ],
      title: "New Slide",
      value: "",
      visible: true,
      description: ""
    }
  ]
});

export const getDummyQuotedJSON = () => [
  {
    title: "Service 1",
    answer: {
      id: "SectionService1",
      value: "Answer 1"
    }
  },
  {
    title: "Service 2",
    answer: {
      id: "SectionService2",
      value: "Answer 2"
    }
  },
  {
    title: "Service 3",
    answer: {
      id: "SectionService3",
      value: "Answer 3"
    }
  }
];

export const getDefaultSuccessRule = () => [
  {
    answer: "0",
    operator: "Greater Or Equal To",
    interestedText: "<p>Thank you, We look forward to working together.</p>",
    notInterestedText: "<p>Thank you, We appreciate your feedback.</p>"
  },
  {
    answer: "0",
    operator: "Less Or Equal To",
    interestedText: "<p>Thank you, We look forward to working together.</p>",
    notInterestedText: "<p>Thank you, We appreciate your feedback.</p>"
  }
];
