import React, { useEffect, useRef, useState } from "react";
import NotificationAlert from "components/NotificationAlert";

// nodejs library that concatenates classes
import { useSelector } from "react-redux";

// reactstrap components
import { get } from "lodash";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Container, Row, Card, CardBody } from "reactstrap";

// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";
import { SupportForm } from "components/Forms/SupportForm";
import { getFullNameOrEmail, getLimitSubscriptionPerFeatureType, stringToBoolean } from "utils/helperFunctions";
import { useCompanySubscription } from "hooks/useCompanySubscription";
import { FEATURE_WHITE_LABEL, WHITE_LABELS } from "utils/constants";

const SEND_EMAIL = gql`
  mutation SendSupportEmail($email: String, $title: String, $description: String, $details: String, $header: String) {
    sendSupportEmail(email: $email, title: $title, description: $description, details: $details, header: $header)
  }
`;

const notificationOptions = (notificationText, type, icon) => ({
  place: "bc",
  message: (
    <div className="alert-text ml-2">
      <span className="alert-title" data-notify="title">
        {notificationText}
      </span>
    </div>
  ),
  type: type,
  icon: icon,
  autoDismiss: 7
});

const SupportPage = () => {
  const notificationAlert = useRef(null);
  const [sendEmail] = useMutation(SEND_EMAIL);
  const [emailError, setEmailError] = useState();
  const [processing, setProcessing] = useState(false);
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);
  const companyId = get(currentUser, ["company", "id"]);
  const companyName = get(currentUser, ["company", "name"]);
  const userId = get(currentUser, ["id"]);
  const userName = getFullNameOrEmail(currentUser);
  const userEmail = get(currentUser, ["email"]);
  const workspaceId = get(currentWorkspace, ["id"]);
  const workspaceName = get(currentWorkspace, ["name"]);
  const companySubscription = useCompanySubscription(
    get(currentUser, ["company", "id"]),
    get(currentUser, ["company", "planId"])
  );
  const hasWhiteLabel = stringToBoolean(getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_WHITE_LABEL));

  useEffect(() => {
    if (emailError) {
      notificationAlert.current.notificationAlert(
        notificationOptions(emailError, "danger", "fas fa-exclamation-triangle")
      );
    }
  }, [emailError]);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <AuthHeader
        title={`Contact Support`}
        lead="Reach out to our support team with the form below to receive assistance."
        size="slim"
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Card className="card-pricing border-0 mb-4">
            <CardBody className="px-lg-4 sinapi-plan-container">
              <SupportForm
                processing={processing}
                setProcessing={setProcessing}
                onSuccess={(values, actions) => {
                  sendEmail({
                    variables: {
                      email: values.email,
                      title: `${WHITE_LABELS(hasWhiteLabel, currentUser.company).sinapiName} Support Request`,
                      header: `<p style="font-size: 16px; padding: 0; margin: 0;">You have received a support request from: ${values.name} (${values.email})</p>`,
                      details: "<div style='text-align: center; font-size: 17px;'>" + values.message + "</div>",
                      description: `<p>Contact details:</p>
                      <p style="margin: 0px; text-align: left; font-size: 14px;">- Company: ${companyName} (${companyId})</p>
                      <p style="margin: 0px; text-align: left; font-size: 14px;">- Workspace: ${workspaceName} (${workspaceId})</p>
                      <p style="margin: 0px; text-align: left; font-size: 14px;">- User: ${userName} - ${userEmail} (${userId})</p>
                       <hr>`
                    }
                  }).then(({ errors }) => {
                    if (errors) {
                      setEmailError(errors[0].message);
                      actions.setSubmitting(false);
                    } else {
                      setProcessing(false);
                      actions.resetForm({});
                      actions.setSubmitting(true);
                      notificationAlert.current.notificationAlert(
                        notificationOptions(
                          "Your email was sent, we will review and contact you soon.",
                          "success",
                          "fas fa-envelope-open-text"
                        )
                      );
                    }
                  });
                }}
              />
            </CardBody>
          </Card>
        </Row>
      </Container>
    </>
  );
};

export default SupportPage;
