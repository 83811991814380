import React, { useState } from "react";
import { useHistory } from "react-router";
import { Line } from "react-chartjs-2";

import { get, map, round, sum } from "lodash";
import classnames from "classnames";
import moment from "moment";

// reactstrap components
import { Card, CardBody, Container, Row, Col, CardHeader, Nav, NavItem, NavLink, Button } from "reactstrap";

import LeadModal from "components/Modals/LeadModal";
import SinapiTableSortable from "components/Shared/SinapiTableSortable";
import { chartExample1 } from "variables/charts.jsx";
import { formHasCost, getFullNameOrEmail, numberToCurrency } from "utils/helperFunctions";
import { getStatusIcon } from "views/pages/leads/display";
import { useEffect } from "react";
import { set } from "date-fns/esm";
import { useAuth0 } from "AuthProvider";

const AnalyticsContainer = ({
  data,
  refetch,
  loading,
  fetchMore,
  pageSize,
  totalCostPerWeek,
  totalCostPerMonth,
  totalCostPerYear,
  todayDate,
  lastWeek,
  lastMonth,
  lastYear
}) => {
  const history = useHistory();
  const chartOptions = ["week", "month", "year"];
  const [activeNav, setActiveNav] = useState(0);
  const [showLeadWithFormData, setShowLeadWithFormData] = useState();
  const [leadsTableData, setLeadsTableData] = useState();

  const { user } = useAuth0();

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };

  useEffect(() => {
    setLeadsTableData(data)
  },[data])

  const getDateRangeType = (startDate, endDate, type, format) => {
    const momentDates = [...Array(1 + moment(endDate).diff(moment(startDate), format)).keys()].map((n) =>
      moment(moment(startDate)).add(n, format)
    );
    return momentDates.map((date) => moment(date).format(type));
  };

  const DAYS_OF_WEEKS = getDateRangeType(lastWeek, todayDate, "ddd", "days");
  const DAYS_OF_MONTHS = getDateRangeType(lastMonth, todayDate, "DD", "days");
  const MONTHS_OF_YEAR = getDateRangeType(lastYear, todayDate, "MMM", "months");

  const getWeekData = () => DAYS_OF_WEEKS.map((val) => get(totalCostPerWeek, val, 0));
  const getMonthData = () => DAYS_OF_MONTHS.map((val) => get(totalCostPerMonth, val, 0));
  const getYearData = () => MONTHS_OF_YEAR.map((val) => get(totalCostPerYear, val, 0));

  const totalQuoted = round(
    activeNav === 0
      ? sum(map(totalCostPerWeek))
      : activeNav === 1
      ? sum(map(totalCostPerMonth))
      : sum(map(totalCostPerYear)),
    2
  );

  const chartData = {
    week: () => ({
      labels: DAYS_OF_WEEKS,
      datasets: [
        {
          label: "Performance",
          borderColor: "cyan",
          pointRadius: 3,
          pointHoverRadius: 5,
          data: getWeekData()
        }
      ]
    }),
    month: () => ({
      labels: DAYS_OF_MONTHS,
      datasets: [
        {
          label: "Performance",
          data: getMonthData()
        }
      ]
    }),
    year: () => ({
      labels: MONTHS_OF_YEAR,
      datasets: [
        {
          label: "Performance",
          data: getYearData()
        }
      ]
    })
  };

  const showRow = row => {
    const rows = get(leadsTableData, "leadsByParam");
    const {items} = rows
    const newItems = [...items]
    const r = items.findIndex(item => item.id === row.id)
    newItems[r] = {
      ...row,
      hidden: false
    }
    setLeadsTableData({...leadsTableData, leadsByParam: {...rows, items: newItems}});
  }

  const hiddenOptions = row => {
    return user.currentUser.roleId === "superAdmin" ? <><Button
    color="primary"
    onClick={() => showRow(row)}
    size="sm"
    style={{ minWidth: "85px" }}
  >
    <i className="fas fa-eye" /> Show
  </Button></> : <hr className="my-1" style={{ borderTop: "2px solid lightgray" }} />
  }

  return (
    <Container className="mt--6" fluid>
      <Row>
        <Col xl="6">
          <Card className="bg-default">
            <CardHeader className="bg-transparent" style={{ padding: "1.2rem 1.2rem" }}>
              <Row className="align-items-center">
                <div className="col-5">
                  <h6 className="text-light text-uppercase ls-1 mb-1">Your Leads</h6>
                  <h5 className="h3 text-white mb-0">Total {numberToCurrency(totalQuoted)} Quoted</h5>
                </div>
                <div className="col-7" style={{ paddingRight: "0", paddingLeft: "0" }}>
                  <Nav className="justify-content-end" pills>
                    <NavItem className="mr-md-0" style={{ paddingRight: "0.5rem" }}>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: activeNav === 0
                        })}
                        href="#pablo"
                        onClick={(e) => toggleNavs(e, 0)}
                      >
                        <span className="d-none d-md-block">Week</span>
                        <span className="d-md-none">W</span>
                      </NavLink>
                    </NavItem>
                    <NavItem className="ml-sm-2" style={{ paddingRight: "0.5rem" }}>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: activeNav === 1
                        })}
                        data-toggle="tab"
                        href="#pablo"
                        onClick={(e) => toggleNavs(e, 1)}
                      >
                        <span className="d-none d-md-block">Month</span>
                        <span className="d-md-none">M</span>
                      </NavLink>
                    </NavItem>
                    <NavItem className="ml-sm-2" style={{ paddingRight: "1rem" }}>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: activeNav === 2
                        })}
                        data-toggle="tab"
                        href="#pablo"
                        onClick={(e) => toggleNavs(e, 2)}
                      >
                        <span className="d-none d-md-block">Year</span>
                        <span className="d-md-none">Y</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="chart">
                <Line
                  data={chartData[chartOptions[activeNav]]}
                  options={chartExample1.options}
                  id="chart-sales-dark"
                  className="chart-canvas"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="6">
          <SinapiTableSortable
            data={get(leadsTableData, "leadsByParam")}
            isLoaded={!loading && data ? true : false}
            onClick={(lead) => setShowLeadWithFormData(lead)}
            onLoadMore={(offset) =>
              fetchMore({
                variables: {
                  limit: pageSize,
                  offset: offset
                },
                updateQuery: (prev, { fetchMoreResult }) => (!fetchMoreResult ? prev : fetchMoreResult)
              })
            }
            options={{
              limit: pageSize,
              emptyLabel: `No Leads.`,
              columns: [
                {
                  key: "value",
                  label: "",
                  className: "text-black font-weight-900",
                  // tooltip: (row) =>
                  //   row.hidden
                  //     ? "You've reached your limit on leads this month... Good Job! Please upgrade to view all your leads and go sell!"
                  //     : null,
                  render: (row) => (row.value ? `${numberToCurrency(formHasCost(get(row, "value", 0)))}` : "")
                },
                {
                  key: "name",
                  label: "Name",
                  sort: "firstName",
                  className: "sort",
                  style: { borderTopLeftRadius: "calc(0.375rem - 1px)", borderTop: "unset" },
                  // tooltip: (row) =>
                  //   row.hidden
                  //     ? "You've reached your limit on leads this month... Good Job! Please upgrade to view all your leads and go sell!"
                  //     : null,
                  // render: (row) =>
                  //   row.hidden ? hiddenOptions(row) : (
                  //     getFullNameOrEmail(row)
                  //   )
                  render: (row) =>
                    getFullNameOrEmail(row)
                },
                {
                  key: "createdAt",
                  label: "Date",
                  sort: "createdAt",
                  className: "sort",
                  // tooltip: (row) =>
                  //   row.hidden
                  //     ? "You've reached your limit on leads this month... Good Job! Please upgrade to view all your leads and go sell!"
                  //     : null,
                  render: (row) => moment(row.createdAt).format("MMM DD, YYYY - hh:mm a")
                },
                {
                  key: "formName",
                  label: "Form Name",
                  // tooltip: (row) =>
                  //   row.hidden
                  //     ? "You've reached your limit on leads this month... Good Job! Please upgrade to view all your leads and go sell!"
                  //     : null,
                  render: (row) => row.form.name
                },
                {
                  key: "status",
                  label: "",
                  className: "text-center px-0",
                  render: (row) => getStatusIcon(row.progress)
                },
                {
                  key: "button",
                  label: "",
                  style: { borderTopRightRadius: "calc(0.375rem - 1px)", borderTop: "unset" },
                  // render: (row) =>
                  //   row.hidden ? (
                  //     <Button
                  //       color="danger"
                  //       type="button"
                  //       size="sm"
                  //       style={{ minWidth: "102px" }}
                  //       onClick={() => history.push("/admin/upgrade")}
                  //     >
                  //       Upgrade Plan
                  //     </Button>
                  //   ) : (
                  //     <Button
                  //       color="primary"
                  //       onClick={() => setShowLeadWithFormData(row)}
                  //       size="sm"
                  //       style={{ minWidth: "85px" }}
                  //     >
                  //       <i className="fas fa-user" /> Details
                  //     </Button>
                  //   )
                    render: (row) =>
                    (
                      <Button
                        color="primary"
                        onClick={() => setShowLeadWithFormData(row)}
                        size="sm"
                        style={{ minWidth: "85px" }}
                      >
                        <i className="fas fa-user" /> Details
                      </Button>
                    )  
                }
              ]
            }}
          />
        </Col>
      </Row>
      {showLeadWithFormData ? (
        <LeadModal
          showLeadWithFormData={showLeadWithFormData}
          setShowLeadWithFormData={setShowLeadWithFormData}
          refetch={refetch}
        />
      ) : null}
    </Container>
  );
};

export default AnalyticsContainer;
