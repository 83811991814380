import React, { useState } from "react";

import ReactSummernote from "react-summernote";

import $ from "jquery";

import "./RichTextEditorSummer.scss";
import "react-summernote/dist/react-summernote.css";

/**
 * Simple wrapper for wysiwyg editor, takes default Value, and onChange callback.
 * Value is html string.
 * @param {object} props
 */
export function RichTextEditorSummer({ id, className = "", defaultValue, onChange, height = '6em' }) {
  const [content, setContent] = useState(defaultValue);
  const onBlurHandle = (editor) => {
    let content = editor.target.innerHTML;
    content = content.replace(/^\s+/gi, '');
    content = content.replace(/<p><br><\/p>/gi, ' ');
    setContent(content);
  }
  const onChangeHandle = (value) => {
    let content = value.replace(/^\s+/gi, '');
    content = value.replace(/<p><br><\/p>/gi, '');
    onChange(content);
  }
  return (
    <div className={className}>
      <ReactSummernote
        id={id}
        onInit={(contents) => {
          if(defaultValue) {
            contents.reset();
            contents.summernote("pasteHTML", defaultValue);
          }
          contents.summernote("codeview.activate");
          contents.summernote("codeview.deactivate");
          
        }}
        options={{
          height: height,
          dialogsInBody: true,
          codeviewFilter: false,
          codeviewIframeFilter: true,
          toolbar: ["bold", "italic", "underline", "link", 'fontsize', ['para', ['ul', 'ol']],['code', ['codeview']]],
          disableDragAndDrop: true
        }}
        value={content}
        onChange={onChangeHandle}
        onBlur={onBlurHandle}
      />
    </div>
  );
}
