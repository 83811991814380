import React from "react";
import { useDispatch, useSelector } from "react-redux";

import jsonLogic from "json-logic-js";
import { head, find, forEach, get, isArray, isEmpty, join, lowerCase, toNumber, union } from "lodash";
import { FormGroup } from "reactstrap";

import { SinapiFieldRegistry } from "components/FormComponents/fields";
import { UPDATE_QUESTION_BEING_RENDERED } from "reducers/form-actions";
import { useForm } from "views/pages/forms/FormContext";
import { CONTAINS, EQUALS, IS_EMPTY, NOT_EMPTY, NOT_EQUAL, OPERATORS_SYMBOL } from "utils/constants";
import { getFormJSONValue } from "../../views/pages/forms/render";

const onChangeOptionInput = (question, dispatch) => (e) =>
  dispatch({
    type: UPDATE_QUESTION_BEING_RENDERED,
    payload: {
      key: e.target.id,
      value: e.target.value,
      type: question.type,
      question
    }
  });

const buildQuestions = (question, dispatch, applyRule, quoteJSON, inputFontsize) => {
  const findRule = applyRule.get(question.id);
  const FieldComponent = SinapiFieldRegistry.getFieldComponent(question.type);
  let quoteValue;
  if (question.type !== "address") {
    quoteValue = getFormJSONValue(quoteJSON, question.id);
    const questionValue = quoteJSON.find(val => val.id === question.id)
    if (!questionValue && question.hasOwnProperty('defaultValue') && question.defaultValue !== ""){
      dispatch({
        type: UPDATE_QUESTION_BEING_RENDERED,
        payload: {
          key: question.id,
          value: question.type === "checkbox" ? [question.defaultValue] : question.defaultValue,
          type: question.type,
          question
        }
      });
    } 
  } else {
    const address = getFormJSONValue(quoteJSON, question.id, "address");
    const city = getFormJSONValue(quoteJSON, question.id, "city");
    const state = getFormJSONValue(quoteJSON, question.id, "state");
    const zip = getFormJSONValue(quoteJSON, question.id, "zip");
    quoteValue = { address, city, state, zip };
  }
  
  const questionDisplayed = localStorage.getItem("questionDisplayed");
  const questionDetails = [];
  if(questionDisplayed === null) {
    questionDetails.push({
      id: question.id,
      flag: "show"
    })
    localStorage.setItem("questionDisplayed", JSON.stringify(questionDetails));
  }

  const setDisplayFlag = (funcQuestions, questionId, flag) => {
    const arrQuestionDisplay = JSON.parse(funcQuestions);
    const elementIndex = arrQuestionDisplay.findIndex((element) => element?.id === questionId);
    if (elementIndex === -1) {
      arrQuestionDisplay.push({
        id: question?.id,
        flag,
      })
    } else {
      arrQuestionDisplay[elementIndex].flag = flag;
    }
    localStorage.setItem("questionDisplayed", JSON.stringify(arrQuestionDisplay));
  }

  let isQuestionDisplay = false;
  if(findRule &&
    ((findRule.rule && findRule.action === "hidden") || (!findRule.rule && findRule.action === "show")) ) {
      isQuestionDisplay = true;
      if(questionDisplayed) {
        setDisplayFlag(questionDisplayed, question.id, "hide");
      }
  } else {
    isQuestionDisplay = false
    if(questionDisplayed) {
      setDisplayFlag(questionDisplayed, question.id, "show");
    }
  }

  return isQuestionDisplay ?  null : (
    <FormGroup>
      <FieldComponent
        question={{ ...question, value: quoteValue, notIcon: true, inputFontsize }}
        onChange={onChangeOptionInput(question, dispatch)}
      />
    </FormGroup>
  );
};

export const getJsonRuleLogic = (allFields, quoteJSON, jsonRuleLogic, applyRuleSet, formError, setFormError) => {
  // Checking the field condition/answer for the dynamic rule
  for (const field of allFields || []) {
    if (field.dynamicRules && field.dynamicRules.active) {
      const action = get(field, ["dynamicRules", "action"], "show");
      const groupCondition = lowerCase(field.dynamicRules.condition);
      forEach(field.dynamicRules.rules, (dynamicRule) => {
        forEach(dynamicRule, (ruleGroup) => {
          const jsonRuleCondition = ruleGroup.condition ? lowerCase(ruleGroup.condition) : undefined;
          forEach(ruleGroup.rules, (rule) => {
            const answerQuote = find(quoteJSON, (quote) => quote.id === rule.questionId) || {};
            const quoteValue = isArray(get(answerQuote, ["answer", "value"]))
              ? join(get(answerQuote, ["answer", "value"]))
              : get(answerQuote, ["answer", "value"], "");
            // if (["checkbox", "radio", "select"].includes(answerQuote.type)) {
            //   const questionOptions = allFields.find(f => f.id === rule.questionId).options
            //   rule.answer = questionOptions.find(opt => opt.id === rule.answer || opt.name === rule.answer).id
            // }
            let valueAnswer;
            switch (answerQuote.type) {
              case "number":
                valueAnswer = [toNumber(quoteValue), toNumber(rule.answer)];
                break;
              case "date":
                const quoteDate = isEmpty(quoteValue) ? "" : Date.parse(quoteValue.concat("T00:00:00"));
                const answerDate = isEmpty(rule.answer) ? "" : Date.parse(rule.answer);
                valueAnswer = [quoteDate, answerDate];
                break;
              default:
                valueAnswer = [quoteValue, rule.answer];
                break;
            }
            const operator =
              rule.operator === IS_EMPTY
                ? OPERATORS_SYMBOL[EQUALS]
                : rule.operator === NOT_EMPTY
                ? OPERATORS_SYMBOL[NOT_EQUAL]
                : OPERATORS_SYMBOL[rule.operator];
            const ruleLogicOperator =
              operator !== OPERATORS_SYMBOL[CONTAINS]
                ? { operator: operator, value: valueAnswer }
                : { operator: operator, value: [valueAnswer[1], valueAnswer[0].split(",")] };
            const updatedObj = jsonRuleLogic.get(field.id);
            if (!updatedObj) {
              jsonRuleLogic.set(field.id, {
                action,
                groupCondition,
                questionId: field.id,
                ruleLogic: [{ ruleQuestion: rule.questionId, logic: ruleLogicOperator }],
                ruleCondition: jsonRuleCondition
              });
            } else {
              const prevRuleLogic = find(
                updatedObj.ruleLogic,
                (ruleLogic) => ruleLogic.ruleQuestion === rule.questionId
              );
              const ruleLogic = prevRuleLogic
                ? [
                    {
                      ruleQuestion: rule.questionId,
                      logic: isArray(prevRuleLogic.logic)
                        ? union(prevRuleLogic.logic, [ruleLogicOperator])
                        : [
                            { operator: prevRuleLogic.logic.operator, value: prevRuleLogic.logic.value },
                            ruleLogicOperator
                          ]
                    }
                  ]
                : [
                    ...updatedObj.ruleLogic,
                    { ruleQuestion: rule.questionId, logic: { operator: operator, value: valueAnswer } }
                  ];
              jsonRuleLogic.set(field.id, { ...updatedObj, ruleLogic: ruleLogic });
            }
          });
        });
      });
    }
  }
  if (jsonRuleLogic.size > 0) {
    jsonRuleLogic.forEach((value, key) => {
      let ruleApply = [];
      try {
        forEach(value.ruleLogic, (rule) => {
          if (find(quoteJSON, (quote) => quote.id === rule.ruleQuestion)) {
            if (value.ruleCondition) {
              if (isArray(rule.logic)) {
                const rules = [];
                forEach(rule.logic, (logic) => {
                  const logicValue = { [logic.operator]: logic.value };
                  rules.push(logicValue);
                });
                ruleApply.push(jsonLogic.apply({ [value.ruleCondition]: rules }));
              } else {
                ruleApply.push(jsonLogic.apply({ [rule.logic.operator]: rule.logic.value }));
              }
            } else {
              ruleApply.push(jsonLogic.apply({ [rule.logic.operator]: rule.logic.value }));
            }
          } else {
            ruleApply.push(value.action === "show" ? false : true);
          }
        });
        applyRuleSet.set(key, {
          action: value.action,
          rule: value.groupCondition
            ? jsonLogic.apply({ [value.groupCondition]: ruleApply })
            : isArray(ruleApply)
            ? head(ruleApply)
            : ruleApply
        });
      } catch (e) {
        if (!formError) setFormError(true);
      }
    });
  }
};

function RenderFormColumn({ column, formError, setFormError }) {
  const dispatch = useDispatch();
  const { allFields, inputFontsize } = useForm();
  const { quoteJSON } = useSelector((state) => state.render);
  const jsonRuleLogic = new Map();
  const applyRuleSet = new Map();
  getJsonRuleLogic(allFields, quoteJSON, jsonRuleLogic, applyRuleSet, formError, setFormError);
  
  return (
    <div className="form-inline-block">
      <div className="form-question col-sm-12">
        {column.questions.map((question) => (
          <div key={`column_${question.id}`}>
            {buildQuestions(question, dispatch, applyRuleSet, quoteJSON, inputFontsize)}
          </div>
        ))}
      </div>
    </div>
  );
}

export { RenderFormColumn };
