import React, { useState } from "react";
import { Input, FormGroup, FormFeedback } from "reactstrap";
import { validateEmail } from "utils/helperFunctions";
import { getUniqueID, defaultOnChange } from "./HelperFunctions";
import { FieldLabel } from "./FieldLabel";
import { optionWithIcon } from "./fieldUtils";

const FormEmail = (props) => {
  const { id, value, placeholder = "", inputFontsize } = props.question;
  const { onChange = defaultOnChange } = props;
  const [error, setError] = useState(false);
  return (
    <>
      <FieldLabel question={props.question} />
      <Input
        id={id}
        name={id}
        invalid={error}
        value={value}
        placeholder={placeholder}
        className="form-control-sm"
        type="email"
        onChange={onChange}
        onBlur={({ currentTarget }) => setError(validateEmail(currentTarget.value))}
        style={{ height: `calc(${inputFontsize} + 17px)` }}
      />
      <FormFeedback className="mt-2">Invalid email address.</FormFeedback>
    </>
  );
};

const PreviewFormEmail = () => optionWithIcon("far fa-envelope", "Email");

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Email Label",
  description: "<p>Description for the input</p>",
  required: false,
  type: "email"
});

const EmailEditOptions = (json, onInputChange) => {
  return (
    <>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder</div>
        </label>
        <Input
          placeholder=""
          className="form-control-sm"
          type="email"
          name="placeholder"
          defaultValue={json.hasOwnProperty("placeholder") ? json.placeholder : ""}
          onChange={(e) => onInputChange({ e })}
        />
      </FormGroup>
    </>
  );
};

const fieldConfig = {
  key: "email",
  component: FormEmail,
  getInitialData: getInitialData,
  previewComponent: PreviewFormEmail,
  getEditOptions: EmailEditOptions
};

export default fieldConfig;
