import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

function NavIcon({ children, onClick, title, iconClass, disabled, location, show }) {
  if (!show) {
    return null;
  }
  return (
    <Button onClick={() => onClick()} className="nav-icon-button" disabled={!!disabled}>
      <i className={iconClass}></i>
      <span className="nav-icon-button-title">{title}</span>
    </Button>
  );
}

NavIcon.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  title: PropTypes.string,
  iconClass: PropTypes.string,
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  hasContent: PropTypes.bool
};

NavIcon.defaultProps = {
  hasContent: true,
  disabled: false,
  show: true
};

export { NavIcon };
