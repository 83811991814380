import React from "react";
import { getUniqueID } from "./HelperFunctions";
import { optionWithIcon } from "./fieldUtils";

const DividerField = ({question}) => {
  return (
      <div 
        className={`d-flex justify-content-`+question.alignment} 
        style={{ 
          paddingTop: question.spacingTop + "px",
          paddingBottom: question.spacingBottom + "px",
        }}
      >
        <hr 
          className="p-0 m-0"  
          style={{ 
            width: question.widthValue + "" + question.widthUnit,
            backgroundColor: question.dividerColor,
            height: question.widthThickness + "px"
          }}
        />
      </div>
  );
};

const PreviewDividerField = () => optionWithIcon("fas fa-minus", "Divider");

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Divider",
  required: false,
  type: "divider",
  alignment: "center",
  dividerColor: "rgba(51,51,51,1)",
  widthValue: "100",
  widthUnit: "px",
  widthThickness: "1",
  spacingTop: "15",
  spacingBottom: "15",
});

const fieldConfig = {
  key: "divider",
  component: DividerField,
  getInitialData: getInitialData,
  previewComponent: PreviewDividerField
};

export default fieldConfig;
