import React, { useState } from "react";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";

import * as yup from "yup";
import { get, toInteger } from "lodash";
import { gql } from "apollo-boost";
import { Field, Formik } from "formik";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, CardHeader, Col, FormGroup, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import { getDefaultButtonJSON, getDefaultFloatinButtonJSON, getDefaultFormJSON } from "views/pages/forms/defaultFormData";
import { useCompanySubscription } from "hooks/useCompanySubscription";
import { getLimitSubscriptionPerFeatureType, stringToBoolean } from "utils/helperFunctions";
import { FEATURE_NUMBER_OF_FORMS, FEATURE_WHITE_LABEL, WHITE_LABELS } from "utils/constants";
import ManagementModal from "./ManagementModal";
import CompanySubscription from "components/Subscription/CompanySubscription";
import { Link } from "react-router-dom";

const newFormValidation = yup.object().shape({
  name: yup.string().required()
});

const CREATE_FORM = gql`
  mutation UpdateForm($workspaceId: ID!, $form: FormInput!) {
    updateForm(workspaceId: $workspaceId, form: $form) {
      id
    }
  }
`;

const CURRENT_WORKSPACE_FORMS = gql`
  query GetWorkspaceForms($id: ID!) {
    workspace(id: $id) {
      id
      forms {
        id
      }
    }
  }
`;

const NewFormModal = ({ onClose }) => {
  const [editForm, setEditForm] = useState(null);
  const [cancel, setCancel] = useState(null);
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);
  const userRole = currentWorkspace ? currentWorkspace.userRoleId || currentUser.roleId : null;

  const { loading, data } = useQuery(CURRENT_WORKSPACE_FORMS, {
    variables: { id: currentWorkspace.id }
  });

  const [updateForm] = useMutation(CREATE_FORM, {
    onCompleted: (response) => {
      setEditForm({ id: response.updateForm.id });
    }
  });

  const companySubscription = useCompanySubscription(currentUser.company.id, currentUser.company.planId);
  const limitForms = toInteger(getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_NUMBER_OF_FORMS));
  const hasWhiteLabel = stringToBoolean(getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_WHITE_LABEL));

  const forms = get(data, ["workspace", "forms"], []);

  if (editForm) {
    return <Redirect to={`/admin/form-edit/${editForm.id}`} />;
  }

  if (cancel) {
    return <Redirect to={`/admin/forms`} />;
  }

  return (
    <>
      {!loading &&
        data &&
        (limitForms !== -1 && forms.length >= limitForms && companySubscription ? (
          <ManagementModal
            onClose={() => (onClose ? onClose() : setCancel(true))}
            header={<div>BUILDING A NEW FORM</div>}
            buttons={[
              {
                label: "Close",
                onClick: () => (onClose ? onClose() : setCancel(true))
              }
            ]}
          >
            {!["consultant"].includes(userRole) ? (
              <>
                <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
                  <h2 className="font-weight-400 text-justify">
                    Please{" "}
                    <Link className="font-weight-bold btn btn-primary" to="/admin/upgrade">
                      Upgrade your account
                    </Link>
                    {` to create additional Forms. You have already reached the limit allowed by your current plan.`}
                  </h2>
                </CardHeader>
                <CompanySubscription
                  companyData={currentUser.company}
                  plans={companySubscription.plans}
                  price={companySubscription.compPrice}
                  showDetails={false}
                />
              </>
            ) : (
              <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
                <h2 className="font-weight-400 text-justify">
                  {`Your plan does not support this feature. If you would like to upgrade to get the most out of ${
                    WHITE_LABELS(hasWhiteLabel, get(currentUser, ["company"])).sinapiName
                  }, contact your account manager for assistance.`}
                </h2>
              </CardHeader>
            )}
          </ManagementModal>
        ) : (
          <Modal isOpen={true} size="sm" className="management-modal">
            <ModalHeader style={{ backgroundColor: "#172b4d" }}>
              <div>
                <h2 className="mb-0 text-white">Building a new form</h2>
              </div>
            </ModalHeader>
            <ModalBody>
              <Formik
                initialValues={{ name: "" }}
                validationSchema={newFormValidation}
                onSubmit={(values) => {
                  updateForm({
                    variables: {
                      workspaceId: currentWorkspace.id,
                      form: {
                        name: values.name,
                        schema: getDefaultFormJSON().schema,
                        formType: "quote",
                        themeId: 0,
                        themeType: "global_form_theme",
                        actionButtonSchema: getDefaultButtonJSON(),
                        actionFloatingButtonSchema: getDefaultFloatinButtonJSON()
                      }
                    }
                  });
                }}
              >
                {({ errors, ...formikProps }) => (
                  <>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-name">
                        Put A Form Name
                      </label>
                      <Field name="name" id="input-name" className="form-control" />
                      {errors.name && <div className="text-danger">{errors.name}</div>}
                    </FormGroup>
                    <Row>
                      <Col sm="12" className="text-right">
                        <Button
                          size="lg"
                          outline
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            onClose ? onClose() : setCancel(true);
                          }}
                        >
                          Nevermind
                        </Button>
                        <Button
                          size="lg"
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            formikProps.validateForm().then((errors) => {
                              if (Object.keys(errors).length === 0) {
                                formikProps.submitForm();
                              }
                            });
                          }}
                        >
                          Create Form
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </Formik>
            </ModalBody>
          </Modal>
        ))}
    </>
  );
};

export default NewFormModal;
