import React from "react";
import { Input, FormGroup } from "reactstrap";
import { getUniqueID, defaultOnChange } from "./HelperFunctions";
import { FieldLabel } from "./FieldLabel";
import { optionWithIcon } from "./fieldUtils";

const FormTextarea = (props) => {
  const { id, value, placeholder = "" } = props.question;
  const { onChange = defaultOnChange } = props;
  return (
    <>
      <FieldLabel question={props.question} />
      <Input
        id={id}
        value={value}
        placeholder={placeholder}
        className="form-control-sm"
        type="textarea"
        onChange={onChange}
      />
    </>
  );
};

const PreviewFormTextarea = () => optionWithIcon("fas fa-align-justify", "Multi line input");

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Textarea Label",
  description: "<p>Description for the input</p>",
  required: false,
  type: "textarea",
  prefix: "",
  suffix: ""
});

const TextareaEditOptions = (json, onInputChange) => {
  return (
    <>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder</div>
        </label>
        <Input
          placeholder=""
          className="form-control-sm"
          type="text"
          name="placeholder"
          defaultValue={json.hasOwnProperty("placeholder") ? json.placeholder : ""}
          onChange={(e) => onInputChange({ e })}
        />
      </FormGroup>
    </>
  );
};

const fieldConfig = {
  key: "textarea",
  component: FormTextarea,
  getInitialData: getInitialData,
  previewComponent: PreviewFormTextarea,
  getEditOptions: TextareaEditOptions
};

export default fieldConfig;
