import React from "react";
import { getUniqueID } from "./HelperFunctions";
import { optionWithIcon } from "./fieldUtils";
import { AWS_S3_ASSETS_BUCKET } from "utils/constants";

import defaultImage from "../../../assets/img/default-image.png";

const ImageField = ({question}) => {
    let imageSrc = defaultImage;
    if(question?.imageSrc) {
        if(typeof question?.imageSrc === "string") {
            imageSrc = `${AWS_S3_ASSETS_BUCKET}/${question?.imageSrc}`
            
        } else {
            imageSrc = URL.createObjectURL(question?.imageSrc)
        }
    }

    const imageSizeValue = `${question.imageSizeValue}${question.imageSizeUnit}`

    let activeSizeStyle = { width: imageSizeValue }

    if(question.activeSize !== "width") {
        activeSizeStyle = { height: imageSizeValue }
    }

    return (
        <div 
        className={`d-flex `+question.alignment} 
        style={{ 
            paddingTop: question.spacingTop + "px",
            paddingBottom: question.spacingBottom + "px",
        }}
        >
        <img src={imageSrc} 
            style={activeSizeStyle}
            alt="ImageField" />
        </div>
    );
};

const PreviewImageField = () => optionWithIcon("fas fa-image", "Image");

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Image",
  required: false,
  type: "image-field",
  alignment: "justify-center",
  imageSizeValue: "100",
  imageSizeUnit: "px",
  spacingTop: "15",
  spacingBottom: "15",
  spacingLeft: "15",
  spacingRight: "15",
  imageSrc: "",
  activeSize: "width"
});

const fieldConfig = {
  key: "image-field",
  component: ImageField,
  getInitialData: getInitialData,
  previewComponent: PreviewImageField
};

export default fieldConfig;
