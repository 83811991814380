import React from "react";
import { Input, FormGroup } from "reactstrap";
import { getUniqueID, defaultOnChange } from "./HelperFunctions";
import { FieldLabel } from "./FieldLabel";
import { optionWithIcon } from "./fieldUtils";

const FormText = (props) => {
  const { id, value, placeholder = "", inputFontsize } = props.question;
  const { onChange = defaultOnChange } = props;
  return (
    <>
      <FieldLabel question={props.question} />
      <Input
        id={id}
        defaultValue={value}
        placeholder={placeholder}
        className="form-control-sm"
        type="text"
        onChange={onChange}
        style={{ height: `calc(${inputFontsize} + 17px)` }}
      />
    </>
  );
};

const PreviewFormText = () => optionWithIcon("fas fa-font", "Single line input");

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Text Label",
  description: "<p>Description for the input</p>",
  required: false,
  type: "text",
  prefix: "",
  suffix: ""
});

const TextEditOptions = (json, onInputChange) => {
  return (
    <>
      <FormGroup>
        <label className="form-control-label">
          <div>Placeholder</div>
        </label>
        <Input
          placeholder=""
          className="form-control-sm"
          type="text"
          name="placeholder"
          defaultValue={json.hasOwnProperty("placeholder") ? json.placeholder : ""}
          onChange={(e) => onInputChange({ e })}
        />
      </FormGroup>
    </>
  );
};

const fieldConfig = {
  key: "text",
  component: FormText,
  getInitialData: getInitialData,
  previewComponent: PreviewFormText,
  getEditOptions: TextEditOptions
};

export default fieldConfig;
