import React from "react";
import PropTypes from "prop-types";
import { SinapiDraggableItem } from "../DragAndDrop";

function DraggableField({ fieldData, className }) {
  const FieldComp = fieldData.previewComponent;
  return (
    <SinapiDraggableItem name={fieldData.key} type="field" className={className}>
      {() => <FieldComp />}
    </SinapiDraggableItem>
  );
}

DraggableField.propTypes = {
  fieldData: PropTypes.object.isRequired
};

export { DraggableField };
