export const buttonStyles = [
  {
    header: "Primary Button",
    title: "Background",
    description: "",
    type: "color-picker",
    cssPath: ["& .btn-outline-primary", "backgroundColor"]
  },
  {
    title: "Text Color",
    description: "",
    type: "color-picker",
    cssPath: ["& .btn-outline-primary", "color"]
  },
  {
    title: "Font Size",
    description: "",
    type: "number",
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .btn-outline-primary", "fontSize"],
    col: 6
  },
  {
    title: "Border Width",
    description: "",
    type: "number",
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .btn-outline-primary", "borderWidth"],
    col: 6
  },
  {
    title: "Border Color",
    description: "",
    type: "color-picker",
    cssPath: ["& .btn-outline-primary", "borderColor"]
  },
  {
    title: "Border Style",
    description: "",
    type: "select",
    cssPath: ["& .btn-outline-primary", "borderStyle"],
    options: [
      {
        id: "solid",
        name: "Solid"
      },
      {
        id: "dotted",
        name: "Dotted"
      },
      {
        id: "dashed",
        name: "Dashed"
      }
    ]
  },
  {
    header: "Secondary Button",
    title: "Background",
    description: "",
    type: "color-picker",
    cssPath: ["& .btn-outline-secondary", "backgroundColor"]
  },
  {
    title: "Text Color",
    description: "",
    type: "color-picker",
    cssPath: ["& .btn-outline-secondary", "color"]
  },
  {
    title: "Font Size",
    description: "",
    type: "number",
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) || null : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .btn-outline-secondary", "fontSize"],
    col: 6
  },
  {
    title: "Border Width",
    description: "",
    type: "number",
    suffix: "px",
    parseValueIn: (val) => (val ? parseInt(val.replace("px", "")) : null),
    parseValueOut: (val) => (val ? val + "px" : val),
    cssPath: ["& .btn-outline-secondary", "borderWidth"],
    col: 6
  },
  {
    title: "Border Color",
    description: "",
    type: "color-picker",
    cssPath: ["& .btn-outline-secondary", "borderColor"]
  },
  {
    title: "Border Style",
    description: "",
    type: "select",
    cssPath: ["& .btn-outline-secondary", "borderStyle"],
    options: [
      {
        id: "solid",
        name: "Solid"
      },
      {
        id: "dotted",
        name: "Dotted"
      },
      {
        id: "dashed",
        name: "Dashed"
      }
    ]
  }
];
