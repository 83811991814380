import { useEffect, useState } from "react";

import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";


const IS_AUTHENTICATED_MUTATION = gql`
  {
    isAuthenticated  
  }
`;

export function useIsAuthenticated() {
  const [authenticated, setAuthenticated] = useState();

  const { loading, data, error, refetch } = useQuery(IS_AUTHENTICATED_MUTATION, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (!loading && data) {
      setAuthenticated({dataAuth: data.isAuthenticated, refetchAuth: () =>refetch(), errorAuth: error});
    }
  }, [loading, data, refetch, error]);

  return authenticated;
}
