import React, { useRef, useState } from "react";
import { getUniqueID, defaultOnChange } from "./HelperFunctions";
import { FieldLabel } from "./FieldLabel";
import { optionWithIcon } from "./fieldUtils";
import { isEmpty } from "lodash";
import { getImageURL } from "./RadioField";
import defaultImage from "../../../assets/img/default-image.png";
import { get } from "lodash";
import classNames from "classnames";

const RenderOption = ({ option, value = [], onChange }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { optionImage, name, id } = option;
  const activeSize = get(option, ["optionImage", "activeSize"], "width");
  const width = optionImage?.width || "100";
  const height = optionImage?.height || "100";
  const size = optionImage?.size || "%";
  const imgSrc = get(optionImage, ["imagePath"], false) ? getImageURL(optionImage) : defaultImage;
  const optionWidth = optionImage?.optionWidth || 0;
  const optionWidthUnit = optionImage?.optionWidthUnit || 'px';

  const checkHover = (val) => {
    if (!value.includes(id)) {
      setIsHovered(val);
    }
  };

  return !option.hasIcon ? (
    <div
      key={id}
      className={classNames("image-option", "custom-control", {
        "image-option-selected": id === value || isHovered,
        "image-option-checked": value.includes(id)
      })}
      onMouseEnter={() => checkHover(true)}
      onMouseLeave={() => checkHover(false)}
    >
      <input
        id={id}
        name={id}
        value={id}
        type="checkbox"
        onChange={(e) => onChange(e)}
        checked={value && value.includes(id)}
      />
      <label
        htmlFor={id}
        className={classNames("image-option-label", {
          "image-option-label-selected": id === value || isHovered
        })}
        style={{
          width: optionWidth === 0 ? "100px" : `${optionWidth}${optionWidthUnit}`,
        }}
      >
        <div className="image-wrapper">
          <img
            src={imgSrc}
            alt=""
            style={{
              width: activeSize === "height" ? "auto" : `${width + size}`,
              height: activeSize === "width" ? "auto" : `${height + size}`,
              alignSelf: "center"
            }}
          />
        </div>
        <span className="mt-1 mx-auto text-center">{name}</span>
      </label>
    </div>
  ) : (
    <div
      key={id}
      className={classNames("image-option", "custom-control", {
        "image-option-selected": id === value || isHovered,
        "image-option-checked": value.includes(id)
      })}
      onMouseEnter={() => checkHover(true)}
      onMouseLeave={() => checkHover(false)}
    >
      <input
        id={id}
        name={id}
        value={id}
        type="checkbox"
        onChange={(e) => onChange(e)}
        checked={value && value.includes(id)}
      />
      <label
        htmlFor={id}
        className={classNames("image-option-label", {
          "image-option-label-selected": id === value || isHovered
        })}
        style={{
          width: optionWidth === 0 ? "" : `${optionWidth}${optionWidthUnit}`,
        }}
      >
        <div className="image-wrapper">
          <i
            className={option.iconClass || "fas fa-smile-wink"}
            style={{
              fontSize: Number(option.iconSize) || 60,
              color: option.iconColor || "rgba(51, 51, 51, 1)",
              alignSelf: "center"
            }}
          ></i>
        </div>
        <span className="mt-1 mx-auto text-center">{name}</span>
      </label>
    </div>
  );
};

const CheckboxesField = (props) => {
  const values = useRef([]);
  const { onChange = defaultOnChange } = props;

  const { id: questionID, options, value, hasOptionImages, floatClass } = props.question;
  const onCheckboxChange = (event) => {
    if (isEmpty(values.current) && !isEmpty(value)) {
      values.current = [...value];
    }
    if (event.target.checked && !values.current.includes(event.target.name)) {
      values.current = [...values.current, event.target.name];
    } else if (!event.target.checked) {
      values.current = values.current.filter((v) => v !== event.target.name);
    }
    onChange({ target: { id: questionID, value: values.current, type: event.target.type } });
  };
  return (
    <>
      <FieldLabel question={props.question} />
      <div className={classNames("custom-checkbox-list", floatClass)} style={{ display: "flex", flexWrap: "wrap" }}>
        {options.map((option, i) => {
          const { name, id } = option;
          return !hasOptionImages ? (
            <div key={id} className="custom-control custom-checkbox mb-3 mr-4">
              <input
                className="custom-control-input"
                id={`option_${id}`}
                name={id}
                type="checkbox"
                onChange={onCheckboxChange}
                checked={value && value.includes(id)}
              />
              <label
                className="custom-control-label custom-option-label"
                htmlFor={`option_${id}`}
                style={{ height: "auto" }}
              >
                {name && name}
              </label>
            </div>
          ) : (
            <RenderOption option={option} questionID={questionID} value={value} onChange={onCheckboxChange} key={id} />
          );
        })}
      </div>
    </>
  );
};

const PreviewCheckboxesField = () => optionWithIcon("far fa-check-square", "Multiple choice");

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Checkboxes",
  description: "<p>Please Select An Option</p>",
  required: false,
  type: "checkbox",
  style: "inline",
  dollarValue: true,
  affectCost: "fixed-cost",
  costStructure: ["cost"],
  options: [
    {
      id: `${getUniqueID()}_1`,
      name: "Option 1",
      icon: "",
      cost: 0,
      cost_type: "+",
      daily: 0,
      daily_type: "+",
      monthly: 0,
      monthly_type: "+",
      yearly: 0,
      yearly_type: "+"
    },
    {
      id: `${getUniqueID()}_2`,
      name: "Option 2",
      icon: "",
      cost: 0,
      cost_type: "+",
      daily: 0,
      daily_type: "+",
      monthly: 0,
      monthly_type: "+",
      yearly: 0,
      yearly_type: "+"
    },
    {
      id: `${getUniqueID()}_3`,
      name: "Option 3",
      icon: "",
      cost: 0,
      cost_type: "+",
      daily: 0,
      daily_type: "+",
      monthly: 0,
      monthly_type: "+",
      yearly: 0,
      yearly_type: "+"
    }
  ]
});

const fieldConfig = {
  key: "checkbox",
  component: CheckboxesField,
  getInitialData: getInitialData,
  previewComponent: PreviewCheckboxesField,
  getEditOptions: () => {
    return;
  }
};

export default fieldConfig;
