import React, { useState } from "react";
import { useSelector } from "react-redux";

import moment from "moment";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { get, map, sum, isEmpty } from "lodash";

// core components
import AnalyticsHeader from "../forms/analytic/AnalyticsHeader";
import CardForms from "components/Forms/CardForms";
import AnalyticsContainer from "../forms/analytic/AnalyticsContainer";
import { numberToCurrency } from "utils/helperFunctions";
import ManagementModal from "components/UserManagement/ManagementModal";
import PublishCodeContainer from "../forms/publish/PublishCodeContainer";
import { Button } from "reactstrap";

const PAGE_SIZE = 6;

const CURRENT_USER_FORMS = gql`
  query GetWorkspaceForms($id: ID!, $limit: Int) {
    workspace(id: $id) {
      id
      forms(limit: $limit) {
        id
        name
        description
        lastUpdate
        status
        publishedAt
        renderGuid
        floatingButtonSchema
        buttonSchema
        formHash
        workspaceHash
        userId
        lead {
          count
          items {
            value
            createdAt
          }
          totalCost
          interestedCost
        }
      }
    }
  }
`;

const GET_LEADS = gql`
  query LeadsByParam($paramId: ID!, $param: String, $offset: Int, $limit: Int, $startRange: String, $endRange: String) {
    leadsByParam(
      paramId: $paramId
      param: $param
      offset: $offset
      limit: $limit
      startRange: $startRange
      endRange: $endRange
    ) {
      count
      offset
      totalCost
      activeForms
      conversionRate
      totalCostPerWeek
      totalCostPerMonth
      totalCostPerYear
      todayDate
      lastWeek
      lastMonth
      lastYear
      items {
        id
        firstName
        lastName
        email
        phone
        address
        city
        state
        zipcode
        value
        additionalInfo
        quoteJsonFile
        progress
        hidden
        createdAt
        form {
          name
          schema
        }
      }
    }
  }
`;

function Analytic() {
  const { currentWorkspace, currentUser } = useSelector((state) => state.user);
  const [endRange, setEndRange] = useState(moment().format("YYYY-MM-DD"));
  const [startRange, setStartRange] = useState(moment().subtract(1, "months").add(1, "days").format("YYYY-MM-DD"));
  const [codeModal, setCodeModal] = useState(null);
  const [buttonTo, setButtonTo] = useState("popup");

  const isValidDateRange = () =>
    isEmpty(startRange) && isEmpty(endRange) ? true : !isEmpty(startRange) && !isEmpty(endRange) ? true : false;

  const {
    loading: loadingForms,
    data: formsData,
    refetch: refetchForms
  } = useQuery(CURRENT_USER_FORMS, {
    variables: { id: currentWorkspace.id, limit: 4 }
  });

  const { loading, data, fetchMore, refetch } = useQuery(GET_LEADS, {
    fetchPolicy: "network-only",
    variables: {
      paramId: currentWorkspace.id,
      param: "workspace_id",
      offset: 0,
      limit: PAGE_SIZE,
      startRange: isValidDateRange() ? startRange : "",
      endRange: isValidDateRange() ? endRange : ""
    }
  });
  
  return (
    <>
      <AnalyticsHeader
        name="Recent Activity"
        count={!loading ? get(data, ["leadsByParam", "count"]) : 0}
        totalCost={!loading ? numberToCurrency(get(data, ["leadsByParam", "totalCost"], 0)) : 0.0}
        totalOpens={!loading ? sum(map(get(data, ["leadsByParam", "activeForms"]))) : 0}
        conversionRate={!loading ? get(data, ["leadsByParam", "conversionRate"]) : 0}
        startRange={startRange}
        endRange={endRange}
        setStartRange={setStartRange}
        setEndRange={setEndRange}
      />
      <AnalyticsContainer
        data={data}
        refetch={refetch}
        loading={loading}
        fetchMore={fetchMore}
        pageSize={PAGE_SIZE}
        totalCostPerWeek={get(data, ["leadsByParam", "totalCostPerWeek"])}
        totalCostPerMonth={get(data, ["leadsByParam", "totalCostPerMonth"])}
        totalCostPerYear={get(data, ["leadsByParam", "totalCostPerYear"])}
        todayDate={get(data, ["leadsByParam", "todayDate"])}
        lastWeek={get(data, ["leadsByParam", "lastWeek"])}
        lastMonth={get(data, ["leadsByParam", "lastMonth"])}
        lastYear={get(data, ["leadsByParam", "lastYear"])}
      />
      <CardForms
        currentWorkspace={currentWorkspace}
        isDataReady={loadingForms}
        refetchData={refetchForms}
        cardFormsData={formsData}
        setCodeModal={setCodeModal}
      />
      {codeModal && 
        <ManagementModal
          modalSize="lg"
          classNames={{
            modal: "modal-dialog modal-dialog-centered",
            header: "text-white",
            footer: "bg-gradient-light py-3"
          }}
          customClose={<Button style={{background: 'transparent', borderColor: 'transparent', color: 'white'}} onClick={() => setCodeModal(null)}><i className="fas fa-times fa-2x"></i></Button>}
          styles={{ modal: { maxWidth: "850px" }, header: { backgroundColor: "#172b4d" } }}
          header={
            <div>
              <h3 className="ml-3 mb-0 display-3 text-white">Add Your Form to Your Website</h3>
            </div>
          }
          buttons={
            [{
              label: "Close",
              color: "secondary",
              size: "md",
              onClick: () => setCodeModal(null),
              className: "mr-auto"
            }]  
          }
        >
          <PublishCodeContainer
            formId={codeModal.formId}
            buttonTo={buttonTo}
            setButtonTo={setButtonTo}
            workspaceId={codeModal.workspaceId}
            company={currentUser.company}
            guid={codeModal.guid}
            userId={codeModal.userId}
            actionButtonSchema={codeModal.buttonSchema}
            floatingButtonSchema={codeModal.floatingButtonSchema}
            onClose={() => setCodeModal(null)}
          />
        </ManagementModal>
      }
    </>
  );
}

export default Analytic;
