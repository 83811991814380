import React, { useState } from "react";

import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane
} from "reactstrap";

import classnames from "classnames";
import { get, isArray, join, map } from "lodash";
import { getFullNameOrEmail, numberToCurrency } from "utils/helperFunctions";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import ManagementModal from "components/UserManagement/ManagementModal";

const REMOVE_LEAD = gql`
  mutation RemoveLead($leadId: ID!) {
    removeLead(leadId: $leadId)
  }
`;

const forceUpdateJSON = (json) => {
  return JSON.parse(JSON.stringify(json));
};

const LeadModal = ({ showLeadWithFormData, setShowLeadWithFormData, refetch, onClose = () => {} }) => {
  const [showModal, setShowModal] = useState();
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [removeLeadMutation] = useMutation(REMOVE_LEAD, {
    onCompleted: () => {
      refetch();
      setShowLeadWithFormData();
      onClose();
    }
  });

  return (
    <>
      <Modal isOpen={true} size="lg">
        <ModalHeader className="bg-info py-4">
          <span className="text-white">{showLeadWithFormData.form.name}</span>
        </ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
                style={{ cursor: "pointer" }}
              >
                Lead Information
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
                style={{ cursor: "pointer" }}
              >
                Questions / Answers
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Container className="mt-4" fluid>
                <Row>
                  <Col xs="12">
                    <Label className="form-control-label" style={{ display: "block" }}>
                      Name{" "}
                      <Input
                        type="text"
                        defaultValue={getFullNameOrEmail(showLeadWithFormData)}
                        readOnly
                        className="form-control-sm"
                      />
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="7">
                    <Label className="form-control-label" style={{ display: "block" }}>
                      Email{" "}
                      <Input
                        type="text"
                        defaultValue={showLeadWithFormData.email}
                        readOnly
                        className="form-control-sm"
                      />
                    </Label>
                  </Col>
                  <Col xs="5">
                    <Label className="form-control-label" style={{ display: "block" }}>
                      Phone{" "}
                      <Input
                        type="text"
                        defaultValue={showLeadWithFormData.phone}
                        readOnly
                        className="form-control-sm"
                      />
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <Label className="form-control-label" style={{ display: "block" }}>
                      Address{" "}
                      <Input
                        type="text"
                        defaultValue={showLeadWithFormData.address}
                        readOnly
                        className="form-control-sm"
                      />
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="5">
                    <Label className="form-control-label" style={{ display: "block" }}>
                      City{" "}
                      <Input
                        type="text"
                        defaultValue={showLeadWithFormData.city}
                        readOnly
                        className="form-control-sm"
                      />
                    </Label>
                  </Col>
                  <Col xs="5">
                    <Label className="form-control-label" style={{ display: "block" }}>
                      State{" "}
                      <Input
                        type="text"
                        defaultValue={showLeadWithFormData.state}
                        readOnly
                        className="form-control-sm"
                      />
                    </Label>
                  </Col>
                  <Col xs="2">
                    <Label className="form-control-label" style={{ display: "block" }}>
                      Zip Code
                      <Input
                        type="text"
                        defaultValue={showLeadWithFormData.zipcode}
                        readOnly
                        className="form-control-sm"
                      />
                    </Label>
                  </Col>
                </Row>
              </Container>
            </TabPane>
            <TabPane tabId="2">
              <Container
                className="mt-3"
                fluid
                style={{ overflow: "auto", height: "450px" }}
                id="custom-scrollbar-style"
              >
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <h4 className="text-center sinapi-form-sub-headline" style={{ fontWeight: "600" }}>
                        We estimate your services will cost around:
                      </h4>
                      <h1 className="text-center">
                        <span className="text-center text-green">
                          {numberToCurrency(get(showLeadWithFormData, ["value", "cost", "expected"], 0))}
                        </span>
                      </h1>
                      {(get(showLeadWithFormData, ["value", "cost", "expected"], 0) !==
                        get(showLeadWithFormData, ["value", "cost", "low"], 0) ||
                        get(showLeadWithFormData, ["value", "cost", "expected"], 0) !==
                          get(showLeadWithFormData, ["value", "cost", "high"], 0)) && (
                        <p className="text-center form-control-label" style={{ fontWeight: "300" }}>
                          With a range around{" "}
                          <span className="text-blue">
                            <strong>
                              {numberToCurrency(get(showLeadWithFormData, ["value", "cost", "low"], 0))} -{" "}
                              {numberToCurrency(get(showLeadWithFormData, ["value", "cost", "high"], 0))}
                            </strong>
                          </span>
                        </p>
                      )}
                    </FormGroup>
                    {get(showLeadWithFormData, ["daily", "expected"], 0) !== 0 && (
                      <FormGroup style={{ marginBottom: "0.5rem" }}>
                        <h4 className="text-center sinapi-form-sub-headline" style={{ fontWeight: "400" }}>
                          Plus an additional{" "}
                          <strong>
                            {numberToCurrency(get(showLeadWithFormData, ["value", "daily", "expected"], 0))} <small style={{ fontWeight: "600" }}>per day</small>
                          </strong>
                        </h4>
                        {(get(showLeadWithFormData, ["value", "daily", "expected"], 0) !==
                          get(showLeadWithFormData, ["value", "daily", "low"], 0) ||
                          get(showLeadWithFormData, ["value", "daily", "expected"], 0) !==
                            get(showLeadWithFormData, ["value", "daily", "high"], 0)) && (
                          <p className="text-center form-control-label" style={{ fontWeight: "300" }}>
                            With a range around{" "}
                            <span className="text-blue">
                              <strong>
                                {numberToCurrency(get(showLeadWithFormData, ["value", "daily", "low"], 0))}<small>/day</small> -{" "}
                                {numberToCurrency(get(showLeadWithFormData, ["value", "daily", "high"], 0))}<small>/day</small>
                              </strong>
                            </span>
                          </p>
                        )}
                      </FormGroup>
                    )}
                    {get(showLeadWithFormData, ["value", "monthly", "expected"], 0) !== 0 && (
                      <FormGroup style={{ marginBottom: "0.5rem" }}>
                        <h4 className="text-center sinapi-form-sub-headline" style={{ fontWeight: "400" }}>
                          Plus an additional{" "}
                          <strong>
                            {numberToCurrency(get(showLeadWithFormData, ["value", "monthly", "expected"], 0))} <small style={{ fontWeight: "600" }}>per month</small>
                          </strong>
                        </h4>
                        {(get(showLeadWithFormData, ["value", "monthly", "expected"], 0) !==
                          get(showLeadWithFormData, ["value", "monthly", "low"], 0) ||
                          get(showLeadWithFormData, ["value", "monthly", "expected"], 0) !==
                            get(showLeadWithFormData, ["value", "monthly", "high"], 0)) && (
                          <p className="text-center form-control-label" style={{ fontWeight: "300" }}>
                            With a range around{" "}
                            <span className="text-blue">
                              <strong>
                                {numberToCurrency(get(showLeadWithFormData, ["value", "monthly", "low"], 0))}<small>/mo</small> -{" "}
                                {numberToCurrency(get(showLeadWithFormData, ["value", "monthly", "high"], 0))}<small>/mo</small>
                              </strong>
                            </span>
                          </p>
                        )}
                      </FormGroup>
                    )}
                    {get(showLeadWithFormData, ["value", "yearly", "expected"], 0) !== 0 && (
                      <FormGroup style={{ marginBottom: "0.5rem" }}>
                        <h4 className="text-center sinapi-form-sub-headline" style={{ fontWeight: "400" }}>
                          Plus an additional{" "}
                          <strong>
                            {numberToCurrency(get(showLeadWithFormData, ["value", "yearly", "expected"], 0))} <small style={{ fontWeight: "600" }}>per year</small>
                          </strong>
                        </h4>
                        {(get(showLeadWithFormData, ["value", "yearly", "expected"], 0) !==
                          get(showLeadWithFormData, ["value", "yearly", "low"], 0) ||
                          get(showLeadWithFormData, ["value", "yearly", "expected"], 0) !==
                            get(showLeadWithFormData, ["value", "yearly", "high"], 0)) && (
                          <p className="text-center form-control-label" style={{ fontWeight: "300" }}>
                            With a range around{" "}
                            <span className="text-blue">
                              <strong>
                                {numberToCurrency(get(showLeadWithFormData, ["value", "yearly", "low"], 0))}<small>/yr</small> -{" "}
                                {numberToCurrency(get(showLeadWithFormData, ["value", "yearly", "high"], 0))}<small>/yr</small>
                              </strong>
                            </span>
                          </p>
                        )}
                      </FormGroup>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <Table className="align-items-center table-flush" responsive striped>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Question</th>
                          <th scope="col">Answer</th>
                        </tr>
                      </thead>
                      <tbody className="list">
                        {forceUpdateJSON(showLeadWithFormData.quoteJsonFile).quoteJSON.map((question, index) => {
                          const isMultiple = question.type === "radio" || question.type === "checkbox" || question.type === "select"
                          return <tr key={index}>
                          <td>
                            <span className="text-muted">{get(question, "title")}</span>
                          </td>
                          <td>
                            <span className="text-muted">
                              {isArray(get(question, ["answer"]))
                                ? join(map(get(question, ["answer"]), (answer) => answer.value))
                                : isArray(get(question, ["answer", isMultiple ? "label" : "value"]))
                                ? join(get(question, ["answer", isMultiple ? "label" : "value"]))
                                : get(question, ["answer", isMultiple ? "label" : "value"])}
                            </span>
                          </td>
                        </tr>
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Container>
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Col className="text-right" xs="12">
            <Button color="link" className="float-left text-danger" onClick={() => setShowModal(true)}>
              Delete this Lead
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setShowLeadWithFormData();
                onClose();
              }}
            >
              Close
            </Button>
          </Col>
        </ModalFooter>
      </Modal>
      {showModal && (
        <ManagementModal
          modalSize="sm"
          onClose={() => setShowModal()}
          header={<div>Delete Lead</div>}
          buttons={[
            { label: "Nevermind", outline: true, onClick: () => setShowModal() },
            {
              label: "Delete",
              color: "danger",
              onClick: () =>
                removeLeadMutation({
                  variables: { leadId: showLeadWithFormData.id }
                })
            }
          ]}
        >
          <p>Are you sure you wish to delete this lead?</p>
        </ManagementModal>
      )}
    </>
  );
};

export default LeadModal;
