import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { get } from "lodash";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  Nav,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Button
} from "reactstrap";
import { useAuth0 } from "AuthProvider";

function renderBreadCrumbs(breadCrumbs, breadCrumbParams) {
  breadCrumbParams = breadCrumbParams || {};
  return breadCrumbs.map((crumb) => {
    let path = crumb.path === "/" ? crumb.layout : crumb.layout + crumb.path;
    let name = crumb.name;
    Object.keys(breadCrumbParams).forEach((key) => {
      path = path.replace(":" + key, breadCrumbParams[key]);
      name = name.replace(":" + key, breadCrumbParams[key]);
    });
    return (
      <BreadcrumbItem key={path}>
        <Link to={path}>{name}</Link>
      </BreadcrumbItem>
    );
  });
}

function AdminNavbar(props) {
  const { logout } = useAuth0();
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);
  const userRole = currentWorkspace ? currentWorkspace.userRoleId || currentUser.roleId : null;

  const dispatch = useDispatch();
  const history = useHistory();
  const { toggleSidenav } = props;

  const compSubscriptions = currentUser && currentUser.company ? currentUser.company.subscriptions : null;
  const compSubscription = compSubscriptions && compSubscriptions.length > 0 ? compSubscriptions[0] : null;
  const onTrial = compSubscription && compSubscription.status === "trialing";
  const isPastDue = compSubscription && compSubscription.status === "past_due";

  const changeWorkspace = (workspace) => {
    if (workspace) {
      dispatch({ type: "CHANGE_WORKSPACE", payload: workspace });
      history.push("/admin/dashboard");
    }
  };

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand",
          { "navbar-dark bg-gradient-info": props.theme === "dark" },
          { "navbar-light bg-secondary": props.theme === "light" }
        )}
      >
        <Container fluid>
          {/* <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
            {props.currentRoute ? props.currentRoute.name : ""}
          </h6>{" "} */}
          <Breadcrumb className="d-none d-xl-inline-block" listClassName="breadcrumb-links breadcrumb-dark">
            <BreadcrumbItem>
              <Link to="/admin/dashboard">
                <i className="fas fa-home" />
              </Link>
            </BreadcrumbItem>
            {props.breadcrumbs ? renderBreadCrumbs(props.breadcrumbs) : null}
            {/* {parentName ? (
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        {parentName}
                      </a>
                    </BreadcrumbItem>
                  ) : null}
            */}
            <BreadcrumbItem aria-current="page" className="active">
              {props.currentRoute ? props.currentRoute.name : ""}
            </BreadcrumbItem>
          </Breadcrumb>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-xl-auto d-xl-none d-inline-block" navbar>
              <Button size="sm" color="link" className="sidenav-toggler sidenav-toggler-dark" onClick={toggleSidenav}>
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line ml-0" />
                <i className="sidenav-toggler-line ml-0" />
              </Button>
            </Nav>
            {/* <Nav className="align-items-center ml-xl-auto" navbar>
              <UncontrolledDropdown nav style={{ cursor: "pointer" }}>
                <DropdownToggle className="nav-link" color="" tag="a">
                  <i className="ni ni-bell-55" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-xl py-0 overflow-hidden" right>
                  <div className="px-3 py-3">
                    <h6 className="text-sm text-muted m-0">
                      You have <strong className="text-info">0</strong> notifications.
                    </h6>
                  </div>

                  <ListGroup flush></ListGroup>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav> */}
            <Nav className="align-items-center ml-auto" navbar>
              <UncontrolledDropdown nav style={{ cursor: "pointer" }}>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    {/* <span className="avatar avatar-sm rounded-circle"> */}
                      {/* <img alt="..." src={user.picture || require("assets/img/theme/team-4.jpg")} /> */}
                      {/* <img alt="..." src={require("assets/img/theme/team-4.jpg")} /> */}
                      <i className="fas fa-user-circle fa-2x" />
                    {/* </span> */}
                    <Media className="ml-2 d-none d-lg-block">
                      {/* <span className="mb-0 text-sm font-weight-bold">{user.name}</span> */}
                      <span className="mb-0 text-sm font-weight-bold"></span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  {currentUser && currentUser.workspaces && currentUser.workspaces.items.length > 1 ? (
                    <>
                      <DropdownItem className="noti-title" header tag="div">
                        <h6 className="text-overflow m-0">Workspace:</h6>
                        <span className="dropdown-item text-capitalize px-0 pb-0">
                          <UncontrolledDropdown>
                            <DropdownToggle tag="span" data-toggle="dropdown">
                              <i className="fas fa-caret-down mr-2" /> {get(currentWorkspace, "name")}
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow">
                              {currentUser.workspaces.items.map((workspace) => (
                                <DropdownItem
                                  key={workspace.id}
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    changeWorkspace(workspace);
                                  }}
                                >
                                  {workspace.name}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </span>
                      </DropdownItem>
                    </>
                  ) : (
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">Workspace:</h6>
                      <span className="dropdown-item text-capitalize px-0 pb-0">{get(currentWorkspace, "name")}</span>
                    </DropdownItem>
                  )}
                  <DropdownItem divider />
                  <DropdownItem href="/admin/profile">
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  {!["consultant"].includes(userRole) && (
                    <DropdownItem href={onTrial || isPastDue ? "/admin/upgrade" : "/admin/billing"}>
                      <i className="fas fa-check-circle" />
                      <span>Subscription</span>
                    </DropdownItem>
                  )}
                  <DropdownItem href={"/admin/support"}>
                    <i className="fas fa-headset" />
                    <span>Support</span>
                  </DropdownItem>
                  {/* <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Settings</span>
                  </DropdownItem>
                  <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem>
                  <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="ni ni-support-16" />
                    <span>Support</span>
                  </DropdownItem> */}
                  <DropdownItem divider />
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      logout();
                    }}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  theme: "dark"
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  theme: PropTypes.oneOf(["dark", "light"])
};

export default AdminNavbar;
