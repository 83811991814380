import { generalStyles } from "./generalStyles";
import { fontStyles } from "./fontStyles";
import { buttonStyles } from "./buttonStyles";
import { inputStyles } from "./inputStyles";
import { sectionStyles } from "./sectionStyles";

export const themeOptions = [
  {
    label: "General Styles",
    cssPath: [],
    options: generalStyles
  },
  {
    label: "Font",
    cssPath: [],
    options: fontStyles
  },
  {
    label: "Cards",
    cssPath: [],
    options: sectionStyles
  },
  {
    label: "Inputs",
    cssPath: [],
    options: inputStyles
  },
  {
    label: "Buttons",
    cssPath: [],
    options: buttonStyles
  }
];
