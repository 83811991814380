import { createSelector } from "reselect";

/*
 * See here for documentation on custom selectors: https://react-redux.js.org/api/hooks
 */
const formSelector = (state) => state.form;
const getFormSectionsSelector = (state) =>
  state.form.formJSON && state.form.formJSON.schema ? state.form.formJSON.schema.sections || [] : null;

const activeSectionSelector = createSelector(getFormSectionsSelector, formSelector, (sections, formState) => {
  if (sections && formState.activeSectionId) {
    return {
      json: sections.find((sec) => sec.id === formState.activeSectionId),
      hasChanges: formState.updatedSections.includes(formState.activeSectionId)
    };
  }
  return { hasChanges: false, json: {} };
});

const activeEditOptionSelector = createSelector(formSelector, (formState) => formState.activeEditOption);

export { activeSectionSelector, activeEditOptionSelector };
