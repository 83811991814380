/* eslint-disable no-loop-func */
import _ from "lodash";
import { format } from "date-fns";
import update from "immutability-helper";
import * as actions from "./form-actions";
import { getUniqueID } from "components/FormComponents/fields/HelperFunctions";
import { IS_EMPTY, NOT_EMPTY } from "utils/constants";
import { getDefaultSuccessRule } from "views/pages/forms/defaultFormData";

const initialState = {
  oldFormJSON: {},
  formJSON: {},
  quoteJSON: [],
  updatedAt: null,
  activeSectionId: null,
  activeQuestion: { show: false, activeTab: "1", activeQuestionJSON: {} },
  activeSection: { show: false, activeSectionJSON: {} },
  activeEditOption: {
    type: null,
    activeTab: null
  },
  updatedSections: [],
  isNewForm: true,
  isDirty: false,
  loading: true
};

/* This is a workaround because the JSON doesn't update the form */
const forceUpdateJSON = (json) => {
  return JSON.parse(JSON.stringify(json));
};

export default function formReducer(state = initialState, { type, payload }) {
  const newState = forceUpdateJSON(state);
  let sectionIndex, updatedSections, itemIndex, newSectionIndex, sections, row;
  switch (type) {
    case "RESET_FORM":
      return initialState;
    case actions.UPDATE_FORM_NAME:
      return {
        ...state,
        formJSON: {
          ...state.formJSON,
          name: payload.name
        }
      };
    case "UPDATE_FORM_JSON":
      return {
        ...state,
        oldFormJSON: forceUpdateJSON(payload),
        formJSON: forceUpdateJSON(payload),
        updatedSections: [],
        loading: false,
        isDirty: false,
        activeSectionId:
          payload.schema && payload.schema.sections && payload.schema.sections.length > 0
            ? payload.schema.sections[0].id
            : null,
        updatedAt: payload.lastUpdate ? format(new Date(payload.lastUpdate), "kk:mm:ss MMM d, yyyy") : null
      };
    case "UPDATED_FORM":
      return {
        ...state,
        updatedAt: payload.lastUpdate ? format(new Date(payload.lastUpdate), "kk:mm:ss MMM d, yyyy") : null,
        updatedSections: [],
        isDirty: false,
        formJSON: {
          ...state.formJSON,
          schema: payload.schema,
          options: payload.options
        },
        oldFormJSON: {
          ...state.oldFormJSON,
          schema: forceUpdateJSON(payload.schema)
        }
      };
    case "UPDATE_LOADING_STATUS":
      return { ...state, loading: payload };
    /* ****************************
     *** ACTIVE SECTIONS UPDATES ***
     **************************** */
    case actions.ADD_NEW_SECTION:
      // payload is new section
      return update(state, {
        formJSON: {
          schema: {
            sections: {
              $splice: [[payload.index, 0, payload.section]]
            }
          }
        },
        updatedSections: {
          $push: [payload.section.id]
        }
      });
    case actions.UPDATE_FORM_OPTIONS:
      updatedSections = [...state.updatedSections];
      if (!state.updatedSections.includes("form-options")) {
        updatedSections.push("form-options");
      }
      return update(state, {
        formJSON: {
          schema: {
            options: {
              $set: payload
            }
          }
        },
        updatedSections: {
          $set: updatedSections
        }
      });
    case actions.UPDATE_ACTIVE_THEME:
      updatedSections = [...state.updatedSections];
      if (!state.updatedSections.includes("form-theme")) {
        updatedSections.push("form-theme");
      }
      return update(state, {
        formJSON: {
          activeTheme: {
            $set: payload
          }
        },
        updatedSections: {
          $set: updatedSections
        }
      });
    case actions.EDIT_THEME:
      return update(state, {
        editTheme: {
          $set: { ...payload }
        }
      });
    case actions.EDIT_THEME_UPDATE:
      const theme = _.cloneDeep(state.editTheme);
      if (theme) {
        for (const update of payload.updates) {
          _.set(theme.styleSchema, update.path, update.value);
        }
        return update(state, {
          editTheme: {
            $set: theme
          }
        });
      }
      return state;
    case actions.CANCEL_EDIT_THEME:
      return update(state, {
        editTheme: { $set: null }
      });
    case actions.FINISH_EDIT_THEME:
      updatedSections = [...state.updatedSections];
      if (!state.updatedSections.includes("form-theme")) {
        updatedSections.push("form-theme");
      }
      return update(state, {
        editTheme: { $set: null },
        formJSON: {
          activeTheme: {
            $set: {
              ...payload
            }
          }
        },
        updatedSections: {
          $set: updatedSections
        }
      });
    case actions.COPY_SECTION:
      sectionIndex = state.formJSON.schema.sections.findIndex((sec) => sec.id === payload);
      updatedSections = [...state.updatedSections];
      if (!state.updatedSections.includes(payload.sectionId)) {
        updatedSections.push(payload.sectionId);
      }
      const allSections = _.get(newState, ["formJSON", "schema", "sections"], []);
      const copiedSection = _.cloneDeep(allSections[sectionIndex]);
      _.set(copiedSection, "id", `${getUniqueID()}_${sectionIndex}`);
      _.unset(copiedSection, "dynamicRules");
      const copiedSectionRows = _.get(newState, ["formJSON", "schema", "sections", sectionIndex, "rows"], []);
      _.forEach(copiedSectionRows, (row, rowIdx) => {
        _.forEach(_.get(row, "columns", []), (col, colIdx) => {
          _.forEach(_.get(col, "questions", []), (_question, questionIdx) => {
            const getRowPath = (value) => ["rows", rowIdx, "columns", colIdx, "questions", questionIdx, value];
            _.unset(copiedSection, getRowPath("dynamicRules"));
            _.set(copiedSection, getRowPath("id"), `${getUniqueID()}_${rowIdx}_${colIdx}_${questionIdx}`);
            _.set(copiedSection, getRowPath("removable"), true);
            _.set(copiedSection, getRowPath("title"), `${_.get(copiedSection, getRowPath("title"), "")}`);

            if (_.has(_.get(copiedSection, ["rows", rowIdx, "columns", colIdx, "questions", questionIdx]), "options")) {
              _.forEach(_.get(copiedSection, getRowPath("options"), []), (_opt, optIdx) => {
                _.set(
                  copiedSection,
                  ["rows", rowIdx, "columns", colIdx, "questions", questionIdx, "options", optIdx, "id"],
                  `${getUniqueID()}_${rowIdx}_${colIdx}_${questionIdx}_${optIdx}`
                );
              });
            }
          });
        });
      });

      return update(state, {
        formJSON: {
          schema: {
            sections: {
              $splice: [[sectionIndex + 1, 0, copiedSection]]
            }
          }
        },
        updatedSections: {
          $set: updatedSections
        }
      });
    case actions.DELETE_SECTION:
      // payload is new section
      const sectionsLength = state.formJSON.schema.sections.length;
      sectionIndex = state.formJSON.schema.sections.findIndex((sec) => sec.id === payload);
      updatedSections = [...state.updatedSections];
      if (!state.updatedSections.includes(payload.sectionId)) {
        updatedSections.push(payload.sectionId);
      }
      const afterDelete = update(state, {
        formJSON: {
          schema: {
            sections: {
              $splice: [[sectionIndex, 1]]
            }
          }
        },
        updatedSections: {
          $set: updatedSections
        }
      });
      return sectionsLength === 1
        ? update(afterDelete, {
            formJSON: {
              schema: {
                sections: {
                  $push: [
                    {
                      id: "00000" + new Date().valueOf(),
                      visible: true,
                      title: "New Slide",
                      description: "",
                      value: "",
                      rows: [
                        {
                          format: [12],
                          columns: [
                            {
                              questions: []
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              }
            }
          })
        : afterDelete;
    case actions.COPY_ROW_FROM_SECTION:
      sectionIndex = state.formJSON.schema.sections.findIndex((sec) => sec.id === payload.sectionId);
      const rowIdx = state.formJSON.schema.sections[sectionIndex].rows.findIndex((row) => row === payload.row);
      updatedSections = [...state.updatedSections];
      if (!newState.updatedSections.includes(payload.sectionId)) {
        updatedSections.push(state.activeSectionId);
      }
      const rowsPath = ["formJSON", "schema", "sections", sectionIndex, "rows"];
      const rows = _.get(newState, rowsPath, []);
      const copiedRow = _.cloneDeep(rows[rowIdx]);
      _.forEach(_.get(copiedRow, "columns", []), (col, colIdx) => {
        _.forEach(_.get(col, "questions", []), (_question, questionIdx) => {
          _.unset(copiedRow, ["columns", colIdx, "questions", questionIdx, "dynamicRules"]);
          _.set(
            copiedRow,
            ["columns", colIdx, "questions", questionIdx, "id"],
            `${getUniqueID()}_${rowIdx}_${colIdx}_${questionIdx}`
          );
          _.set(copiedRow, ["columns", colIdx, "questions", questionIdx, "removable"], true);
          _.set(
            copiedRow,
            ["columns", colIdx, "questions", questionIdx, "title"],
            `${_.get(copiedRow, ["columns", colIdx, "questions", questionIdx, "title"], "")}`
          );
          if (_.has(_.get(copiedRow, ["columns", colIdx, "questions", questionIdx]), "options")) {
            _.forEach(
              _.get(copiedRow, ["columns", colIdx, "questions", questionIdx, "options"], []),
              (_opt, optIdx) => {
                _.set(
                  copiedRow,
                  ["columns", colIdx, "questions", questionIdx, "options", optIdx, "id"],
                  `${getUniqueID()}_${rowIdx}_${colIdx}_${questionIdx}_${optIdx}`
                );
              }
            );
          }
        });
      });
      return update(state, {
        formJSON: {
          schema: {
            sections: {
              [sectionIndex]: {
                rows: {
                  $splice: [[rowIdx + 1, 0, copiedRow]]
                }
              }
            }
          }
        },
        updatedSections: {
          $set: updatedSections
        }
      });
    case actions.DELETE_ROW_FROM_SECTION:
      // payload is new section
      sectionIndex = state.formJSON.schema.sections.findIndex((sec) => sec.id === payload.sectionId);
      const rowIndex = state.formJSON.schema.sections[sectionIndex].rows.findIndex((row) => row === payload.row);
      updatedSections = [...state.updatedSections];
      if (!state.updatedSections.includes(payload.sectionId)) {
        updatedSections.push(state.activeSectionId);
      }
      return update(state, {
        formJSON: {
          schema: {
            sections: {
              [sectionIndex]: {
                rows: {
                  $splice: [[rowIndex, 1]]
                }
              }
            }
          }
        },
        updatedSections: {
          $set: updatedSections
        }
      });
    case "SELECT_ACTIVE_SECTION":
      if (state.activeSectionId === payload) {
        return state;
      }
      return {
        ...state,
        activeSectionId: payload // payload is session id
      };
    case "UPDATE_ACTIVE_SECTION":
      sectionIndex = newState.formJSON.schema.sections.findIndex((sec) => sec.id === payload.id);
      if (payload.id && !newState.updatedSections.includes(payload.id)) {
        newState.updatedSections.push(payload.id);
      }
      return _.set(newState, ["formJSON", "schema", "sections", sectionIndex], payload);
    case "UPDATE_ACTIVE_SECTION_HEADER":
      sectionIndex = newState.formJSON.schema.sections.findIndex((sec) => sec.id === state.activeSectionId);
      if (!newState.updatedSections.includes(state.activeSectionId)) {
        newState.updatedSections.push(state.activeSectionId);
      }
      return _.set(newState, ["formJSON", "schema", "sections", sectionIndex, "title"], payload);
    case "SAVE_ACTIVE_SECTION":
      const saveIndex = _.findIndex(newState.formJSON.schema.sections, { id: payload.id });
      if (!newState.updatedSections.includes(state.activeSectionId)) {
        newState.updatedSections.push(state.activeSectionId);
      }
      return _.set(newState, ["formJSON", "schema", "sections", saveIndex], payload);
    case "DELETE_QUESTION_FROM_SECTION":
      sectionIndex = state.formJSON.schema.sections.findIndex((sec) => sec.id === payload.sectionId);
      sections = {
        [sectionIndex]: {
          rows: {}
        }
      };
      const fieldIndex = state.formJSON.schema.sections[sectionIndex].rows[
        payload.rowIndex
      ].columns[0].questions.findIndex((q) => q.id === payload.question.id);
      if (
        state.formJSON.schema.sections[sectionIndex].rows[payload.rowIndex].columns.length === 1 &&
        state.formJSON.schema.sections[sectionIndex].rows[payload.rowIndex].columns[0].questions.length === 1
      ) {
        if (state.formJSON.schema.sections[sectionIndex].rows.length === 1) {
          // retain row if the section has only 1 rows left
          sections[sectionIndex].rows = {
            [payload.rowIndex]: {
              columns: {
                [payload.columnIndex]: {
                  questions: {
                    $splice: [[fieldIndex, 1]]
                  }
                }
              }
            }
          };
        } else {
          // remove row as row has one column and one question
          sections[sectionIndex].rows.$splice = [[payload.rowIndex, 1]];
        }
      } else {
        sections[sectionIndex].rows = {
          [payload.rowIndex]: {
            columns: {
              [payload.columnIndex]: {
                questions: {
                  $splice: [[fieldIndex, 1]]
                }
              }
            }
          }
        };
      }
      updatedSections = [...state.updatedSections];
      if (!state.updatedSections.includes(payload.sectionId)) {
        updatedSections.push(state.activeSectionId);
      }
      return update(state, {
        formJSON: {
          schema: {
            sections: sections
          }
        },
        updatedSections: {
          $set: updatedSections
        }
      });
    /* ****************************
     *** UPDATE QUESTION        ***
     ******************************/
    case actions.TOGGLE_QUESTION_IS_REQUIRED:
      const required = forceUpdateJSON(state.activeQuestion);
      _.set(required, ["activeQuestionJSON", "required"], payload.required);
      return {
        ...state,
        activeQuestion: required
      };
    case actions.TOGGLE_QUESTION_TO_CONTACT_RECORD:
      const toContactRecord = forceUpdateJSON(state.activeQuestion);
      _.set(toContactRecord, ["activeQuestionJSON", "toContactRecord"], payload.toContactRecord);
      return {
        ...state,
        activeQuestion: toContactRecord
      };
    case actions.TOGGLE_QUESTION_COST_STRUCTURE:
      const structure = forceUpdateJSON(state.activeQuestion);
      if (payload.add) {
        _.set(
          structure,
          ["activeQuestionJSON", "costStructure"],
          _.union(_.get(structure, ["activeQuestionJSON", "costStructure"], ["cost"]), [payload.option])
        );
      } else {
        _.set(
          structure,
          ["activeQuestionJSON", "costStructure"],
          _.pull(_.get(structure, ["activeQuestionJSON", "costStructure"]), payload.option)
        );
      }

      const hasCost = _.get(structure, ["activeQuestionJSON", "costStructure"], []).length > 0 ? true : false;
      if (!hasCost) {
        _.unset(structure, ["activeQuestionJSON", "affectCost"]);
        _.unset(structure, ["activeQuestionJSON", "costRange"]);
        _.unset(structure, ["activeQuestionJSON", "priceAdjust"]);
        _.unset(structure, ["activeQuestionJSON", "questionAdjust"]);
      }

      if (!payload.isNumber) {
        for (var op = 0; op < payload.size; op++) {
          let setArrayCost = ["activeQuestionJSON", "options", `${op}`, payload.option];
          hasCost ? _.set(structure, setArrayCost, payload.cost) : _.unset(structure, setArrayCost);
        }
      } else {
        let setArrayCost = ["activeQuestionJSON", payload.option];
        hasCost ? _.set(structure, setArrayCost, payload.cost) : _.unset(structure, setArrayCost);
      }
      _.set(structure, ["activeQuestionJSON", "dollarValue"], hasCost);

      return {
        ...state,
        activeQuestion: structure
      };
    case actions.TOGGLE_QUESTION_COST_RANGE:
      const newActiveQuestionWithCostRange = { ...forceUpdateJSON(state.activeQuestion), ...state.activeQuestion };
      if (payload.size > 0) {
        for (var option = 0; option < payload.size; option++) {
          _.forEach(payload.json[option], (op) => {
            _.set(
              newActiveQuestionWithCostRange,
              ["activeQuestionJSON", "options", `${option}`, op.structure],
              op.cost
            );
          });
          if (!payload.costRange)
            _.set(newActiveQuestionWithCostRange, ["activeQuestionJSON", "options", `${option}`, "cost_type"], "+");
        }
      } else {
        _.forEach(payload.json, (op) => {
          _.set(newActiveQuestionWithCostRange, ["activeQuestionJSON", op.structure], op.cost);
        });
      }
      _.set(newActiveQuestionWithCostRange, ["activeQuestionJSON", "costRange"], payload.costRange);
      return { ...state, activeQuestion: newActiveQuestionWithCostRange };
    case actions.TOGGLE_QUESTION_COST_AFFECT:
      const updateActiveQuestion = { ...forceUpdateJSON(state.activeQuestion), ...state.activeQuestion };
      let setArrayAffect = ["activeQuestionJSON"].concat([payload.key]);
      _.set(updateActiveQuestion, setArrayAffect, payload.value);
      if (payload.set) {
        _.set(updateActiveQuestion, ["activeQuestionJSON"].concat([payload.field]), payload.setValue);
        _.set(updateActiveQuestion, ["activeQuestionJSON", "costRange"], false);
        _.set(updateActiveQuestion, ["activeQuestionJSON", "costStructure"], ["cost"]);
        if (_.has(_.get(updateActiveQuestion, ["activeQuestionJSON"]), "options")) {
          _.forEach(_.get(updateActiveQuestion, ["activeQuestionJSON", "options"], []), (opt, idx) => {
            if (_.isObject(opt.cost)) {
              _.set(
                updateActiveQuestion,
                ["activeQuestionJSON", "options", idx, "cost"],
                _.get(opt, ["cost", "expected"])
              );
            }
          });
        } else {
          if (_.isObject(_.get(updateActiveQuestion, ["activeQuestionJSON", "cost"]))) {
            _.set(
              updateActiveQuestion,
              ["activeQuestionJSON", "cost"],
              _.get(updateActiveQuestion, ["activeQuestionJSON", "cost", "expected"])
            );
          }
        }
      } else {
        _.unset(updateActiveQuestion, ["activeQuestionJSON"].concat([payload.field]));
      }
      return {
        ...state,
        activeQuestion: updateActiveQuestion
      };
    case actions.TOGGLE_QUESTION_DYNAMIC_RULES:
      const activeQuestion = { ...forceUpdateJSON(state.activeQuestion), ...state.activeQuestion };
      _.set(activeQuestion, ["activeQuestionJSON"].concat(["dynamicRules", "active"]), payload.dynamicRules);

      // Add default dynamic rule group if it's empty
      const checkRules = _.get(activeQuestion, ["activeQuestionJSON", "dynamicRules", "rules"]);
      if (!checkRules) {
        let setArrayDynamicGroupRule = ["activeQuestionJSON"].concat(["dynamicRules", "rules"]);
        _.set(activeQuestion, setArrayDynamicGroupRule, [
          { ruleGroup: { rules: [{ questionId: "", operator: "", answer: "" }] } }
        ]);
      }

      return {
        ...state,
        activeQuestion: activeQuestion
      };
    case actions.TOGGLE_SECTION_DYNAMIC_RULES:
      const activeSection = forceUpdateJSON(state.activeSection);
      _.set(activeSection, ["activeSectionJSON"].concat(["dynamicRules", "active"]), payload.dynamicRules);

      // Add default dynamic rule group if it's empty
      const checkSectionRules = _.get(activeSection, ["activeSectionJSON", "dynamicRules", "rules"]);
      if (!checkSectionRules) {
        let setArrayDynamicGroupRule = ["activeSectionJSON"].concat(["dynamicRules", "rules"]);
        _.set(activeSection, setArrayDynamicGroupRule, [
          { ruleGroup: { rules: [{ questionId: "", operator: "", answer: "" }] } }
        ]);
      }

      return {
        ...state,
        activeSection: activeSection
      };
    case actions.TOGGLE_SECTION_ADD_DYNAMIC_RULE:
      const activeSectionRule = forceUpdateJSON(state.activeSection);
      _.update(
        activeSectionRule,
        ["activeSectionJSON", "dynamicRules", "rules", payload.index, "ruleGroup", "rules"],
        (rule) => _.concat(rule, [payload.rule])
      );
      _.update(
        activeSectionRule,
        ["activeSectionJSON", "dynamicRules", "rules", payload.index, "ruleGroup", "condition"],
        () =>
          _.get(
            activeSectionRule,
            ["activeSectionJSON", "dynamicRules", "rules", payload.index, "ruleGroup", "condition"],
            "OR"
          )
      );
      return {
        ...state,
        activeSection: activeSectionRule
      };
    case actions.DELETE_DYNAMIC_RULE_FROM_SECTION:
      const dynamicRuleSection = forceUpdateJSON(state.activeSection);
      let currentRuleSection = _.get(dynamicRuleSection, [
        "activeSectionJSON",
        "dynamicRules",
        "rules",
        payload.idxGroup,
        "ruleGroup",
        "rules"
      ]);
      currentRuleSection.splice(payload.idxRule, 1);
      _.set(
        dynamicRuleSection,
        ["activeSectionJSON", "dynamicRules", "rules", payload.idxGroup, "ruleGroup", "rules"],
        currentRuleSection
      );
      if (currentRuleSection.length === 1)
        _.unset(dynamicRuleSection, [
          "activeSectionJSON",
          "dynamicRules",
          "rules",
          payload.idxGroup,
          "ruleGroup",
          "condition"
        ]);
      return { ...state, activeSection: dynamicRuleSection };
    case actions.DELETE_DYNAMIC_GROUP_RULE_FROM_SECTION:
      const dynamicRuleGroupSection = forceUpdateJSON(state.activeSection);
      let currentRuleGroupSection = _.get(dynamicRuleGroupSection, ["activeSectionJSON", "dynamicRules", "rules"], []);
      currentRuleGroupSection.splice(payload.index, 1);
      _.set(dynamicRuleGroupSection, ["activeSectionJSON", "dynamicRules", "rules"], currentRuleGroupSection);
      return { ...state, activeSection: dynamicRuleGroupSection };
    case actions.TOGGLE_SECTION_ADD_DYNAMIC_GROUP_RULE:
      const activeSectionGroup = forceUpdateJSON(state.activeSection);
      const rulesSection = _.get(activeSectionGroup, ["activeSectionJSON", "dynamicRules", "rules"]);
      if (rulesSection) {
        _.update(activeSectionGroup, ["activeSectionJSON", "dynamicRules"], (dynamicRule) => ({
          ...dynamicRule,
          condition: dynamicRule.condition || "AND"
        }));
        _.update(activeSectionGroup, ["activeSectionJSON", "dynamicRules", "rules"], (rule) =>
          _.concat(rule, [{ ruleGroup: { rules: payload.ruleGroup } }])
        );
      } else {
        let setArrayDynamicGroupRuleSection = ["activeSectionJSON"].concat(["dynamicRules", "rules"]);
        _.set(activeSectionGroup, setArrayDynamicGroupRuleSection, [{ ruleGroup: { rules: payload.ruleGroup } }]);
      }
      return {
        ...state,
        activeSection: activeSectionGroup
      };
    case actions.TOGGLE_QUESTION_ADD_DYNAMIC_GROUP_RULE:
      const activeQuestionGroup = forceUpdateJSON(state.activeQuestion);
      const rules = _.get(activeQuestionGroup, ["activeQuestionJSON", "dynamicRules", "rules"]);
      if (rules) {
        _.update(activeQuestionGroup, ["activeQuestionJSON", "dynamicRules"], (dynamicRule) => ({
          ...dynamicRule,
          condition: dynamicRule.condition || "AND"
        }));
        _.update(activeQuestionGroup, ["activeQuestionJSON", "dynamicRules", "rules"], (rule) =>
          _.concat(rule, [{ ruleGroup: { rules: payload.ruleGroup } }])
        );
      } else {
        let setArrayDynamicGroupRule = ["activeQuestionJSON"].concat(["dynamicRules", "rules"]);
        _.set(activeQuestionGroup, setArrayDynamicGroupRule, [{ ruleGroup: { rules: payload.ruleGroup } }]);
      }
      return {
        ...state,
        activeQuestion: activeQuestionGroup
      };
    case actions.TOGGLE_QUESTION_ADD_DYNAMIC_RULE:
      const activeQuestionRule = forceUpdateJSON(state.activeQuestion);
      _.update(
        activeQuestionRule,
        ["activeQuestionJSON", "dynamicRules", "rules", payload.index, "ruleGroup", "rules"],
        (rule) => _.concat(rule, [payload.rule])
      );
      _.update(
        activeQuestionRule,
        ["activeQuestionJSON", "dynamicRules", "rules", payload.index, "ruleGroup", "condition"],
        () =>
          _.get(
            activeQuestionRule,
            ["activeQuestionJSON", "dynamicRules", "rules", payload.index, "ruleGroup", "condition"],
            "OR"
          )
      );

      return {
        ...state,
        activeQuestion: activeQuestionRule
      };
    case actions.UPDATE_QUESTION_PRICE_ADJUSTED:
      const updAdjustedQuestion = forceUpdateJSON(state.activeQuestion);
      _.set(updAdjustedQuestion, ["activeQuestionJSON"].concat([payload.key]), payload.value);
      _.unset(updAdjustedQuestion, ["activeQuestionJSON"].concat([payload.unset]));
      return {
        ...state,
        activeQuestion: updAdjustedQuestion
      };
    case actions.ADD_NEW_QUESTION:
      sectionIndex = state.formJSON.schema.sections.findIndex((sec) => sec.id === payload.sectionId);
      updatedSections = (state.updatedSections || []).filter((sec) => sec !== payload.sectionId);
      updatedSections.push(payload.sectionId);
      // Check if dropped row has one column, in that case we should create a new row instead.
      if (
        sectionIndex !== -1 &&
        state.formJSON.schema.sections[sectionIndex].rows[payload.rowIndex] &&
        state.formJSON.schema.sections[sectionIndex].rows[payload.rowIndex].format.length === 1 &&
        state.formJSON.schema.sections[sectionIndex].rows[payload.rowIndex].columns[0].questions.length !== 0
      ) {
        return update(state, {
          formJSON: {
            schema: {
              sections: {
                [sectionIndex]: {
                  rows: {
                    $splice: [
                      [
                        payload.rowIndex + payload.index,
                        0,
                        {
                          format: [12],
                          columns: [
                            {
                              questions: [payload.question]
                            }
                          ]
                        }
                      ]
                    ]
                  }
                }
              }
            }
          },
          updatedSections: {
            $set: updatedSections
          }
        });
      }
      return update(state, {
        formJSON: {
          schema: {
            sections: {
              [sectionIndex]: {
                rows: {
                  [payload.rowIndex]: {
                    columns: {
                      [payload.columnIndex]: {
                        questions: {
                          $splice: [[payload.index, 0, payload.question]]
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        updatedSections: {
          $set: updatedSections
        }
      });
    case actions.MOVE_QUESTION:
      sectionIndex = state.formJSON.schema.sections.findIndex((sec) => sec.id === payload.from.sectionId);
      itemIndex = state.formJSON.schema.sections[sectionIndex].rows[payload.from.rowIndex].columns[
        payload.from.columnIndex
      ].questions.findIndex((q) => q.id === payload.from.fieldId);
      const field =
        state.formJSON.schema.sections[sectionIndex].rows[payload.from.rowIndex].columns[payload.from.columnIndex]
          .questions[itemIndex];
      newSectionIndex = state.formJSON.schema.sections.findIndex((sec) => sec.id === payload.to.sectionId);
      sections = {
        [sectionIndex]: {
          rows: {
            [payload.from.rowIndex]: {
              columns: {
                [payload.from.columnIndex]: {
                  questions: { $splice: [[itemIndex, 1]] }
                }
              }
            }
          }
        }
      };
      if (
        sectionIndex === newSectionIndex &&
        payload.from.rowIndex === payload.to.rowIndex &&
        payload.from.columnIndex === payload.to.columnIndex
      ) {
        sections[sectionIndex].rows[payload.to.rowIndex].columns[payload.to.columnIndex].questions.$splice.push([
          payload.to.index,
          0,
          field
        ]);
      } else {
        _.setWith(
          sections,
          `[${newSectionIndex}].rows[${payload.to.rowIndex}].columns[${payload.to.columnIndex}].questions`,
          {
            $splice: [[payload.to.index, 0, field]]
          },
          Object
        );
      }
      updatedSections = (state.updatedSections || []).filter(
        (sec) => sec !== payload.to.sectionId || sec !== payload.from.sectionId
      );
      updatedSections.push(payload.from.sectionId);
      if (payload.to.sectionId !== payload.from.sectionId) {
        updatedSections.push(payload.to.sectionId);
      }

      const updatedMovedRow = update(state, {
        formJSON: {
          schema: {
            sections: sections
          }
        },
        updatedSections: {
          $set: updatedSections
        }
      });

      // Remove the row If is empty
      if (
        updatedMovedRow.formJSON.schema.sections[sectionIndex].rows[payload.from.rowIndex].columns[
          payload.from.columnIndex
        ].questions.length === 0
      ) {
        return update(updatedMovedRow, {
          formJSON: {
            schema: {
              sections: {
                [sectionIndex]: {
                  rows: {
                    $splice: [[payload.from.rowIndex, 1]]
                  }
                }
              }
            }
          }
        });
      }

      return updatedMovedRow;
    case actions.MOVE_ROW:
      sectionIndex = state.formJSON.schema.sections.findIndex((sec) => sec.id === payload.from.sectionId);
      itemIndex = payload.from.rowId;
      row = state.formJSON.schema.sections[sectionIndex].rows[itemIndex];
      newSectionIndex = state.formJSON.schema.sections.findIndex((sec) => sec.id === payload.to.sectionId);
      sections = {
        [sectionIndex]: { rows: { $splice: [[itemIndex, 1]] } }
      };
      if (sectionIndex === newSectionIndex) {
        sections[sectionIndex].rows.$splice.push([payload.to.index, 0, row]);
      } else {
        sections[newSectionIndex] = { rows: { $splice: [[payload.to.index, 0, row]] } };
      }
      updatedSections = (state.updatedSections || []).filter(
        (sec) => sec !== payload.to.sectionId || sec !== payload.from.sectionId
      );
      updatedSections.push(payload.from.sectionId, payload.to.sectionId);
      return update(state, {
        formJSON: {
          schema: {
            sections: sections
          }
        },
        updatedSections: {
          $set: updatedSections
        }
      });
    case actions.MOVE_SECTION:
      sectionIndex = state.formJSON.schema.sections.findIndex((sec) => sec.id === payload.sectionId);
      const section = state.formJSON.schema.sections[sectionIndex];
      updatedSections = (state.updatedSections || []).filter((sec) => sec !== payload.sectionId);
      updatedSections.push(payload.sectionId);
      return update(state, {
        formJSON: {
          schema: {
            sections: {
              $splice: [
                [sectionIndex, 1],
                [payload.to, 0, section]
              ]
            }
          }
        },
        updatedSections: {
          $set: updatedSections
        }
      });
    case actions.UPDATE_ROW_FORMAT:
      sectionIndex = state.formJSON.schema.sections.findIndex((sec) => sec.id === payload.sectionId);
      itemIndex = payload.rowIndex;
      row = state.formJSON.schema.sections[sectionIndex].rows[itemIndex];
      const newColumns = payload.format.map((f, index) => {
        if (row.columns[index]) {
          return row.columns[index];
        } else {
          return {
            questions: []
          };
        }
      });
      if (row.columns.length > payload.format.length) {
        newColumns[payload.format.length - 1] = {
          questions: [
            ...newColumns[payload.format.length - 1].questions,
            ...row.columns.reduce((questions, col, index) => {
              if (index > payload.format.length - 1) {
                return [...questions, ...col.questions];
              }
              return questions;
            }, [])
          ]
        };
      }
      updatedSections = (state.updatedSections || []).filter(
        (sec) => sec !== payload.sectionId || sec !== payload.sectionId
      );
      updatedSections.push(payload.sectionId);
      return update(state, {
        formJSON: {
          schema: {
            sections: {
              [sectionIndex]: {
                rows: {
                  $splice: [
                    [
                      itemIndex,
                      1,
                      {
                        ...row,
                        format: payload.format,
                        columns: newColumns
                      }
                    ]
                  ]
                }
              }
            }
          }
        },
        updatedSections: {
          $set: updatedSections
        }
      });
    case actions.EDIT_QUESTION:
      return {
        ...state,
        activeQuestion: {
          activeQuestionJSON: { ...forceUpdateJSON(payload.question), ...payload.question },
          sectionId: payload.sectionId,
          rowIndex: payload.rowIndex,
          columnIndex: payload.columnIndex,
          activeTab: "1"
        },
        activeEditOption: {
          type: "editQuestion",
          activeQuestionJSON: forceUpdateJSON(payload)
        }
      };
    case actions.COPY_QUESTION:
      const copiedQuestion = forceUpdateJSON(payload.question);
      const newId = getUniqueID();
      _.set(copiedQuestion, ["id"], newId);
      _.set(copiedQuestion, ["removable"], true);
      _.set(copiedQuestion, ["title"], `${copiedQuestion.title}`);
      _.unset(copiedQuestion, "dynamicRules");

      if (_.has(copiedQuestion, "options")) {
        _.forEach(_.get(copiedQuestion, "options", []), (_opt, optIdx) => {
          _.set(copiedQuestion, ["options", optIdx, "id"], `${newId}_${optIdx}`);
        });
      }

      sectionIndex = newState.formJSON.schema.sections.findIndex((sec) => sec.id === payload.sectionId);
      const path = [
        "formJSON",
        "schema",
        "sections",
        sectionIndex,
        "rows",
        payload.rowIndex,
        "columns",
        payload.columnIndex,
        "questions"
      ];

      const questions = _.get(newState, path, []);
      questions.push(copiedQuestion);

      return _.set(newState, path, questions);
    case actions.EDIT_SECTION:
      return {
        ...state,
        activeSection: {
          activeSectionJSON: forceUpdateJSON(payload.section),
          sectionId: payload.sectionId
        },
        activeEditOption: {
          type: "editSection",
          activeSectionJSON: forceUpdateJSON(payload)
        }
      };
    case actions.EDIT_SUCCESS_SECTION:
      return {
        ...state,
        activeQuestion: {
          activeQuestionJSON: forceUpdateJSON(state.formJSON.schema.success || {})
        },
        activeEditOption: {
          type: "editSuccessSection"
        }
      };
    case actions.ADD_SUCCESS_SECTION:
      const successSection = forceUpdateJSON(state.activeQuestion.activeQuestionJSON.section);
      const existingDynamicRules = _.get(successSection, ["dynamicRules", "ruleGroups"], []);
      const successDynamicRules = _.concat(existingDynamicRules, [{ rules: payload }]);
      _.set(successSection, ["dynamicRules"], { ruleGroups: successDynamicRules });
      return {
        ...state,
        activeQuestion: {
          ...state.activeQuestion,
          activeQuestionJSON: {
            ...state.activeQuestion.activeQuestionJSON,
            section: successSection
          }
        },
        activeEditOption: {
          type: "editSuccessSection"
        }
      };
    case actions.REMOVE_RULE_SUCCESS_SECTION:
      const rulesSuccessSection = forceUpdateJSON(
        state.activeQuestion.activeQuestionJSON.section.dynamicRules.ruleGroups
      );
      rulesSuccessSection.splice(payload, 1);
      return {
        ...state,
        activeQuestion: {
          ...state.activeQuestion,
          activeQuestionJSON: {
            ...state.activeQuestion.activeQuestionJSON,
            section: {
              ...state.activeQuestion.activeQuestionJSON.section,
              dynamicRules: { ruleGroups: rulesSuccessSection }
            }
          }
        },
        activeEditOption: {
          type: "editSuccessSection"
        }
      };
    case actions.ACTIVATE_DYNAMIC_SUCCESS_SECTION:
      const activeQuestionUpd = forceUpdateJSON(state.activeQuestion.activeQuestionJSON.section);
      _.set(activeQuestionUpd, payload.key, payload.value);
      if (!activeQuestionUpd.hasOwnProperty("dynamicRules")) {
        _.set(activeQuestionUpd, ["dynamicRules", "ruleGroups", "0", "rules"], payload.section);
      }
      return {
        ...state,
        activeQuestion: {
          ...state.activeQuestion,
          activeQuestionJSON: {
            ...state.activeQuestion.activeQuestionJSON,
            section: activeQuestionUpd
          }
        },
        activeEditOption: {
          type: "editSuccessSection"
        }
      };
    case "SELECT_ACTIVE_EDIT_OPTION":
      return {
        ...state,
        activeEditOption: {
          ...payload
        }
      };
    case "CLOSE_ACTIVE_QUESTION":
      return {
        ...state,
        activeQuestion: {
          activeQuestionJSON: {},
          activeTab: "1"
        },
        activeEditOption: {
          type: null
        }
      };
    case actions.CLOSE_ACTIVE_SECTION:
      return {
        ...state,
        activeSection: {
          activeSectionJSON: {}
        },
        activeEditOption: {
          type: null
        }
      };
    case "CHANGE_EDIT_QUESTION_TAB":
      return {
        ...state,
        activeQuestion: {
          ...state.activeQuestion,
          activeTab: payload
        }
      };
    case actions.UPDATE_QUESTION_BEING_EDITED:
      let hasOptionImages = _.get(state.activeQuestion, ["activeQuestionJSON", "hasOptionImages"], false);
      let updActiveQuestion = forceUpdateJSON(state.activeQuestion);
      if (hasOptionImages) {
        updActiveQuestion = {
          ...updActiveQuestion,
          ...state.activeQuestion
        };
      }
      let setArrayOption = ["activeQuestionJSON"].concat(payload.path).concat([payload.key]);

      _.set(updActiveQuestion, setArrayOption, payload.value);
      if ([IS_EMPTY, NOT_EMPTY].includes(payload.value)) {
        setArrayOption = ["activeQuestionJSON"].concat(payload.path).concat("answer");
        _.set(updActiveQuestion, setArrayOption, "");
      }

      return {
        ...state,
        activeQuestion: updActiveQuestion
      };
    case actions.UPDATE_SECTION_BEING_EDITED:
      const updActiveSection = forceUpdateJSON(state.activeSection);
      let setArrayOptionSection = ["activeSectionJSON"].concat(payload.path).concat([payload.key]);
      _.set(updActiveSection, setArrayOptionSection, payload.value);
      if ([IS_EMPTY, NOT_EMPTY].includes(payload.value)) {
        setArrayOptionSection = ["activeSectionJSON"].concat(payload.path).concat("answer");
        _.set(updActiveSection, setArrayOptionSection, "");
      }
      return {
        ...state,
        activeSection: updActiveSection
      };
    case actions.SUBMIT_SECTION_BEING_EDITED:
      sectionIndex = newState.formJSON.schema.sections.findIndex((sec) => sec.id === state.activeSection.sectionId);
      const newSectionValue = _.get(state, ["activeSection", "activeSectionJSON", "dynamicRules"], {});
      const sectionPath = ["formJSON", "schema", "sections", sectionIndex];
      if (!newState.updatedSections.includes(state.activeSection.sectionId)) {
        newState.updatedSections.push(state.activeSection.sectionId);
      }
      newState.activeSection = {
        activeSectionJSON: {}
      };
      newState.activeEditOption = {
        type: null
      };
      return _.set(newState, [...sectionPath, "dynamicRules"], newSectionValue);
    case actions.SUBMIT_QUESTION_BEING_EDITED:
      sectionIndex = newState.formJSON.schema.sections.findIndex((sec) => sec.id === state.activeQuestion.sectionId);
      let newValue = _.get(state, ["activeQuestion", "activeQuestionJSON"], {});
      const questionPath = [
        "formJSON",
        "schema",
        "sections",
        sectionIndex,
        "rows",
        state.activeQuestion.rowIndex,
        "columns",
        state.activeQuestion.columnIndex,
        "questions"
      ];
      const sectionQuestions = _.get(newState, questionPath, []);
      const questionIndex = _.findIndex(sectionQuestions, { id: state.activeQuestion.activeQuestionJSON.id });
      if (!newState.updatedSections.includes(state.activeQuestion.sectionId)) {
        newState.updatedSections.push(state.activeQuestion.sectionId);
      }
      newState.activeQuestion = {
        activeQuestionJSON: {},
        activeTab: "1"
      };
      newState.activeEditOption = {
        type: null
      };
      // Set Empty Cost value to 0
      if(newValue.type === "checkbox" || newValue.type === "radio") {
        const getOptionCosts = _.get(newValue, "options", []);
        for (let i = 0; i < getOptionCosts.length; i++) {
          if (getOptionCosts[i]?.cost === '') {
            getOptionCosts[i].cost = 0;
          }
        }
        newValue = { ...newValue, options : getOptionCosts }
      }
      return _.set(newState, [...questionPath, questionIndex], newValue);
    case actions.SUBMIT_SUCCESS_PAGE_EDITED:
      updatedSections = (state.updatedSections || []).filter((sec) => sec !== "successPage");
      updatedSections.push("successPage");
      return update(state, {
        updatedSections: {
          $set: updatedSections
        },
        activeQuestion: {
          $set: {
            activeQuestionJSON: {},
            activeTab: "1"
          }
        },
        activeEditOption: {
          $set: {
            type: null
          }
        },
        formJSON: {
          schema: {
            success: {
              $set: _.get(state, ["activeQuestion", "activeQuestionJSON"], {})
            }
          }
        }
      });
    case actions.ADD_OPTION_FROM_QUESTION:
      let forceUpdActiveQuestion = forceUpdateJSON(state.activeQuestion);
      const hasImages = _.get(state.activeQuestion, ["activeQuestionJSON", "hasOptionImages"], false);
      if (hasImages) {
        forceUpdActiveQuestion = {
          ...forceUpdActiveQuestion,
          ...state.activeQuestion
        };
      }
      const active = _.get(forceUpdActiveQuestion, ["activeQuestionJSON"], []);
      const next = active.type === "select" ? active.options.length : active.options.length + 1;
      _.set(
        forceUpdActiveQuestion,
        ["activeQuestionJSON", "options"],
        active.options.concat({
          id: `${getUniqueID()}_${next}`,
          icon: "",
          name: `Option ${next}`,
          cost: 0,
          cost_type: "+",
          daily: 0,
          daily_type: "+",
          monthly: 0,
          monthly_type: "+",
          yearly: 0,
          yearly_type: "+",
          size: "px"
        })
      );

      return {
        ...state,
        activeQuestion: forceUpdActiveQuestion
      };
    case actions.MOVE_OPTION_FROM_QUESTION:
      let options = _.get(state, ["activeQuestion", "activeQuestionJSON", "options"], []);
      let itemIndexOption = _.findIndex(options, { id: payload.item.name });
      let item = _.find(options, (o) => o.id === payload.item.name);
      const newOptions = update(options, {
        $splice: [
          [itemIndexOption, 1],
          [payload.index, 0, item]
        ]
      });
      return forceUpdateJSON(_.set(state, ["activeQuestion", "activeQuestionJSON", "options"], newOptions));
    case actions.DELETE_OPTION_FROM_QUESTION:
      let currentOptions = _.get(state, ["activeQuestion", "activeQuestionJSON", "options"], []);
      currentOptions.splice(payload, 1);
      return forceUpdateJSON(_.set(state, ["activeQuestion", "activeQuestionJSON", "options"], currentOptions));
    case actions.DELETE_DYNAMIC_GROUP_RULE_FROM_QUESTION:
      const dynamicRuleGroups = forceUpdateJSON(state.activeQuestion);
      let currentRuleGroups = _.get(dynamicRuleGroups, ["activeQuestionJSON", "dynamicRules", "rules"], []);
      currentRuleGroups.splice(payload.index, 1);
      _.set(dynamicRuleGroups, ["activeQuestionJSON", "dynamicRules", "rules"], currentRuleGroups);
      return { ...state, activeQuestion: dynamicRuleGroups };
    case actions.DELETE_DYNAMIC_RULE_FROM_QUESTION:
      const dynamicRules = forceUpdateJSON(state.activeQuestion);
      let currentRule = _.get(dynamicRules, [
        "activeQuestionJSON",
        "dynamicRules",
        "rules",
        payload.idxGroup,
        "ruleGroup",
        "rules"
      ]);
      currentRule.splice(payload.idxRule, 1);
      _.set(
        dynamicRules,
        ["activeQuestionJSON", "dynamicRules", "rules", payload.idxGroup, "ruleGroup", "rules"],
        currentRule
      );
      if (currentRule.length === 1)
        _.unset(dynamicRules, [
          "activeQuestionJSON",
          "dynamicRules",
          "rules",
          payload.idxGroup,
          "ruleGroup",
          "condition"
        ]);
      return { ...state, activeQuestion: dynamicRules };
    case actions.ACTIVATE_REDIRECT:
      const actQuestion = forceUpdateJSON(_.get(state, ["activeQuestion", "activeQuestionJSON", "section"]));
      _.set(actQuestion, [payload.key], payload.value);
      return {
        ...state,
        activeQuestion: {
          ...state.activeQuestion,
          activeQuestionJSON: {
            ...state.activeQuestion.activeQuestionJSON,
            section: actQuestion
          }
        },
        activeEditOption: {
          type: "editSuccessSection"
        }
      };
    default:
      return state;
  }
}
