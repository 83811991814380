import * as React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }
  componentDidCatch(error, info) {
    // log the error to the server
    const { onError } = this.props;
    if (typeof onError === "function") {
      try {
        /* istanbul ignore next: Ignoring ternary; can’t reproduce missing info in test environment. */
        onError.call(this, error, info ? info.componentStack : "");
      } catch (ignoredError) {
        // do nothing
      }
    }
  }

  tryAgain() {
    this.setState({ error: null });
  }

  render() {
    let message = "";
    const { error } = this.state;
    if (error) {
      message = error.message ? error.message : error.toString();
    }
    return error ? (
      <div>
        <Modal isOpen={!!error} onExit={() => this.setState({ error: null })} onClosed={() => this.tryAgain()}>
          <ModalHeader>Fatal error</ModalHeader>
          <ModalBody>
            <div>{message}</div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.tryAgain()}>Try Again</Button>
          </ModalFooter>
        </Modal>
      </div>
    ) : (
      this.props.children
    );
  }
}

export const withErrorBoundary = (Component, onError) => {
  const Wrapped = (props) => (
    <ErrorBoundary onError={onError}>
      <Component {...props} />
    </ErrorBoundary>
  );
  // Format for display in DevTools
  const name = Component.displayName || Component.name;
  Wrapped.displayName = name ? `WithErrorBoundary(${name})` : "WithErrorBoundary";

  return Wrapped;
};
