import React, { useEffect, useRef, useState } from "react";
import NotificationAlert from "components/NotificationAlert";
import { usePermissionsWithRole } from "hooks/usePermissionsWithRole";

import { get, toLower } from "lodash";

// reactstrap components
import {
  Alert,
  Button,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import {
  FORM_NAME,
  AWS_S3_ASSETS_BUCKET,
  FEATURE_POPUP_CODE,
  FEATURE_WHITE_LABEL,
  WHITE_LABELS
} from "utils/constants";
import { useCompanySubscription } from "hooks/useCompanySubscription";
import { getLimitSubscriptionPerFeatureType, stringToBoolean } from "utils/helperFunctions";
import ManagementModal from "components/UserManagement/ManagementModal";
import CompanySubscription from "components/Subscription/CompanySubscription";
import { useSelector } from "react-redux";
import LoaderSpinner from "components/Spinner/LoaderSpinner";
import { Link } from "react-router-dom";

const notificationOptions = {
  place: "bc",
  message: (
    <div>
      Success <b>Copy</b>
    </div>
  ),
  type: "info",
  autoDismiss: 7
};

const getPopupCode = (
  formId,
  workspaceId,
  guid,
  userId,
  actionButtonSchema,
  publishUrl,
  buttonType
) => {
  return {
    button:`<button class="sinapi--modal-open-${formId} sinapi--modal-btn-${buttonType} sinapi--modal-btn-${formId}" data-id="${formId}" data-guid="${guid}">${get(actionButtonSchema, "caption", "Get Instant Quote")}</button>`,
    script:`<!-- **** START: Sinapi Code  ****  -->   
    <!-- **** START: Sinapi Modal  ****  -->
      <div id="sinapi--modal-${formId}" class="sinapi--modal" data-id="${formId}" data-guid="${guid}">
        <div class="sinapi--modal-content">
          <div id="sinapi--modal-close-${formId}" class="sinapi--close"><span id="sinapi--modal-close-btn-${formId}" class="sinapi--close-btn"></span></div>
          <div class="sinapi--modal-body">
            <iframe id="sinapi--modal-body-iframe-${formId}" class="sinapi--modal-body-iframe" src="" data-url="${publishUrl}/public/render?id=${formId}&wid=${workspaceId}" width="100%" height="100%" style="border:none"></iframe>
          </div>
        </div>
      </div>
    <!-- **** END: Sinapi Modal  ****  -->
    <!-- **** START: Sinapi Scripts  ****  -->
      <link rel="stylesheet" href="${AWS_S3_ASSETS_BUCKET}/${userId}/render/${formId}/style.css">
      <script type="text/javascript" src="${AWS_S3_ASSETS_BUCKET}/${userId}/render/${formId}/script.js"></script>
    <!-- **** END: Sinapi Scripts  ****  -->
  <!-- **** END: Sinapi Code  ****  --> 
  `
  };
}

// ***** Code/Link element component ****** //
const CodeLinkElement = ({
  formId,
  buttonTo,
  handleCopy,
  workspaceId,
  guid,
  userId,
  actionButtonSchema,
  onEditStyles,
  onClose,
  onActiveTab,
  modalHeight,
  setModalHeight,
  publishUrl,
  floatingButtonSchema
}) => {
  switch (buttonTo) {
    case "popup":
      const getPopupCodeStaticOutput = getPopupCode(formId, workspaceId, guid, userId, actionButtonSchema, publishUrl, 'static');
      return (
        <FormGroup key="popup-element">
          <Row>
            <Col lg="11">
              <h3 className="mt-4">Step 1: Add the poppup code to you website</h3>
              <p className="mt-0">
                <strong>By inserting this code right before the closing &lt;/body&gt; tag</strong>, it will make your {" "}
                {toLower(FORM_NAME)} active and ready to go!
              </p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="11">
              <Input
                readOnly
                id="input-textarea-script-static"
                key="input-textarea-script-static"
                type="textarea"
                rows={5}
                defaultValue={getPopupCodeStaticOutput.script}
                onClick={() => handleCopy("input-textarea-script-static")}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="11">
              <h3 className="mt-4">Step 2: Add a button to open your{" "}{toLower(FORM_NAME)}</h3>
              <p className="mt-0">
                <strong>Place the following code on your website wherever you want the button to appear.</strong> When clicked, it will display your{" "}
                {toLower(FORM_NAME)} in a popup.
              </p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="11">
              <Input
                readOnly
                id="input-textarea-button-static"
                key="input-textarea-button-static"
                type="textarea"
                rows={4}
                defaultValue={getPopupCodeStaticOutput.button}
                onClick={() => handleCopy("input-textarea-button-static")}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="11">
              <p className="mt-0">
                Another option is you can add this poppup functionality to any existing button on your website by simply adding the following "class" to that button. 
              </p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="11">
              <Input
                readOnly
                id="input-textarea-class-static"
                key="input-textarea-class-static"
                type="textarea"
                rows={1}
                defaultValue={`sinapi--modal-open-${formId}`}
                onClick={() => handleCopy("input-textarea-class-static")}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="11">
              <h3 className="mt-4">
                Here is an example of how your button will appear:
                {onEditStyles && 
                  <Button
                    id="editStyles"
                    color="link"
                    onClick={() => {
                      onClose();
                      onEditStyles();
                      onActiveTab();
                    }}
                  >
                    <i className="fas fa-paint-brush fa-lg ml--2" />
                  </Button>
                }
              </h3>
              {onEditStyles && <UncontrolledTooltip target="editStyles">Edit Styles</UncontrolledTooltip>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="6">
              <Button
                id={`sinapi--modal-${formId}-btn`}
                onClick={() => {
                  const sinapiModal = document.getElementById(`sinapi--modal-${formId}`);
                  const sinapiModalIframe = document.getElementById(`sinapi--modal-body-iframe-${formId}`);
                  sinapiModalIframe.src = sinapiModalIframe.dataset.url;

                  sinapiModal.style.display = "block";
                  document.body.classList.add("sinapi--body-modal-open");
                                           
                  document.getElementById(`sinapi--modal-close-btn-${formId}`).onclick = function () {
                    sinapiModal.style.display = "none";
                    document.body.classList.remove("sinapi--body-modal-open");
                  };

                  window.onclick = function (event) {
                    if (event.target === sinapiModal) {
                      sinapiModal.style.display = "none";
                      document.body.classList.remove("sinapi--body-modal-open");
                    }
                  };
                }}
                style={actionButtonSchema}
              >
                {get(actionButtonSchema, "caption", "Get Instant Quote")}
              </Button>
            </Col>
          </Row>
        </FormGroup>
      );
    case "float":
      const getPopupCodeFloatOutput = getPopupCode(formId, workspaceId, guid, userId, floatingButtonSchema, publishUrl, 'float');
      return (
        <FormGroup key="float-element">
          <Row>
            <Col lg="11">
              <h3 className="mt-4">Step 1: Add the poppup code to you website</h3>
              <p className="mt-0">
                <strong>By inserting this code right before the closing &lt;/body&gt; tag</strong>, it will make your {" "}
                {toLower(FORM_NAME)} active and ready to go!
              </p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="11">
              <Input
                readOnly
                id="input-textarea-script-float"
                key="input-textarea-script-float"
                type="textarea"
                rows={5}
                defaultValue={getPopupCodeFloatOutput.script}
                onClick={() => handleCopy("input-textarea-script-float")}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="11">
              <h3 className="mt-4">Step 2: Add a button to open your{" "}{toLower(FORM_NAME)}</h3>
              <p className="mt-0">
                <strong>Place the following code on your website wherever you want the button to appear.</strong> When clicked, it will display your{" "}
                {toLower(FORM_NAME)} in a popup.
              </p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="11">
              <Input
                readOnly
                id="input-textarea-button-float"
                key="input-textarea-button-float"
                type="textarea"
                rows={4}
                defaultValue={getPopupCodeFloatOutput.button}
                onClick={() => handleCopy("input-textarea-button-float")}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="11">
              <p className="mt-0">
                Another option is you can add this poppup functionality to any existing button on your website by simply adding the following "class" to that button. 
              </p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="11">
              <Input
                readOnly
                id="input-textarea-class-float"
                key="input-textarea-class-float"
                type="textarea"
                rows={1}
                defaultValue={`sinapi--modal-open-${formId}`}
                onClick={() => handleCopy("input-textarea-class-float")}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="11">
              <h3 className="mt-4">
                Here is an example of how your button will appear:
                {onEditStyles && 
                  <Button
                    id="editStyles"
                    color="link"
                    onClick={() => {
                      onClose();
                      onEditStyles();
                      onActiveTab();
                    }}
                  >
                    <i className="fas fa-paint-brush fa-lg ml--2" />
                  </Button>
                }
              </h3>
              {onEditStyles && <UncontrolledTooltip target="editStyles">Edit Styles</UncontrolledTooltip>}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="6">
              <Button
                id={`sinapi--modal-${guid}-btn`}
                onClick={() => {

                  const sinapiModal = document.getElementById(`sinapi--modal-${formId}`);
                  const sinapiModalIframe = document.getElementById(`sinapi--modal-body-iframe-${formId}`);
                  sinapiModalIframe.src = sinapiModalIframe.dataset.url;

                  sinapiModal.style.display = "block";
                  document.body.classList.add("sinapi--body-modal-open");

                  document.getElementById(`sinapi--modal-close-btn-${formId}`).onclick = function () {
                    sinapiModal.style.display = "none";
                    document.body.classList.remove("sinapi--body-modal-open");
                  };

                  window.onclick = function (event) {
                    if (event.target === sinapiModal) {
                      sinapiModal.style.display = "none";
                      document.body.classList.remove("sinapi--body-modal-open");
                    }
                  };
                }}
                style={{...floatingButtonSchema,position:"static"}}
              >
                {get(floatingButtonSchema, "caption", "Get Instant Quote")}
              </Button>
            </Col>
          </Row>
        </FormGroup>
      );
    case "link":
      return (
        <FormGroup key="link-element">
          <Row>
            <Col lg="11">
              <h3 className="mt-4">Link directly to your {toLower(FORM_NAME)}</h3>
              <p className="mt-0">
                You can link to this page from anywhere on your site which will display your {toLower(FORM_NAME)}.
              </p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="11">
              <Input
                readOnly
                id="input-text-link"
                key="input-text-link"
                type="text"
                defaultValue={`${publishUrl}/public/render?id=${formId}&wid=${workspaceId}`}
                onClick={() => handleCopy("input-text-link")}
              />
            </Col>
          </Row>
        </FormGroup>
      );
    case "embed":
      return (
        <FormGroup key="embed-element">
          <Row>
            <Col lg="11">
              <h3 className="mt-4">Embed your {toLower(FORM_NAME)} directly on your website</h3>
              <p className="mt-0">Inserting the following code to your website will embed your {toLower(FORM_NAME)}.</p>
            </Col>
          </Row>
          <Row>
            <Col lg="11">
              <h3>
                Choose default height
                <InputGroup size="sm">
                  <Input
                    placeholder="px"
                    className="form-control-sm col-2"
                    type="number"
                    name="width"
                    value={modalHeight}
                    onChange={(e) => setModalHeight(e.target.value)}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>px</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </h3>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg="11">
              <Input
                readOnly
                id="input-text-embed"
                key="input-text-embed"
                type="text"
                value={`<iframe src="${publishUrl}/public/render?id=${formId}&wid=${workspaceId}" width="100%" height="${modalHeight}" border="0" style="border:none"></iframe>`}
                onClick={() => handleCopy("input-text-embed")}
              />
            </Col>
          </Row>
        </FormGroup>
      );
    case "code":
      return (
        <FormGroup key="code-element">
          <Row>
            <Col lg="11">
              <Alert color="danger" className="mt-4 bg-gradient-danger" style={{ padding: "1rem 1rem" }}>
                <p className="my-0 font-weight-bold">This is a highly technical option!</p>
                <p className="my-0">Only use this option if you have a developer who can implement it for you</p>
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col lg="11">
              <h3 className="mt-4">1) Add the following code to your website</h3>
              <p className="mt-0">
                Adding this code to your website will create all necessary scripts that will allow you to bind your
                custom function (as seen in step 2) to show your popup.
              </p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col lg="11">
              <Input
                readOnly
                id="input-textarea-code"
                key="input-textarea-code"
                type="textarea"
                defaultValue="<code></code>"
                onClick={() => handleCopy("input-textarea-code")}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="11">
              <h3 className="mt-4">2) Call your Custom Function</h3>
              <p className="mt-0">
                Calling the following function via JavaScript will show the popup on your website containing the{" "}
                {toLower(FORM_NAME)}.{" "}
              </p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="11">
              <Input
                readOnly
                id="input-text-code"
                key="input-text-code"
                type="text"
                defaultValue="display_sinapi_form_1234();"
                onClick={() => handleCopy("input-text-code")}
              />
            </Col>
          </Row>
        </FormGroup>
      );
    default:
      return <></>;
  }
};

// ***** Code/Link container component ****** //
const PublishCodeContainer = ({
  formId,
  buttonTo,
  setButtonTo,
  workspaceId,
  company,
  guid,
  userId,
  actionButtonSchema,
  onEditStyles,
  onClose,
  onActiveTab,
  floatingButtonSchema
}) => {
  const notificationAlert = useRef(null);
  const [showModal, setShowModal] = useState();
  const { currentUser, currentWorkspace } = useSelector((state) => state.user);
  const userRole = currentWorkspace ? currentWorkspace.userRoleId || currentUser.roleId : null;
  const [modalHeight, setModalHeight] = useState("700");
  const isSuperAdmin = usePermissionsWithRole("superAdmin");

  const handleCopy = (elementId) => {
    var copyText = document.getElementById(elementId);
    copyText.select();
    document.execCommand("copy");
    notificationAlert.current.notificationAlert(notificationOptions);
  };

  const companySubscription = useCompanySubscription(company.id, company.planId);
  const hasPopupCode = stringToBoolean(getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_POPUP_CODE));
  const hasWhiteLabel = stringToBoolean(getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_WHITE_LABEL));
  const publishUrl = WHITE_LABELS(hasWhiteLabel, company).sinapiURL;
  // const hasDeveloperCode = stringToBoolean(getLimitSubscriptionPerFeatureType(companySubscription, FEATURE_DEVELOPER_CODE));
  useEffect(() => {
    const link = `<link rel="stylesheet" href="${AWS_S3_ASSETS_BUCKET}/${userId}/render/${formId}/style.css">`;
    const code = `<div id="sinapi--modal-${formId}" class="sinapi--modal">
      <div class="sinapi--modal-content">
        <div id="sinapi--modal-close-${formId}" class="sinapi--close"><span id="sinapi--modal-close-btn-${formId}" class="sinapi--close-btn"></span></div>
        <div class="sinapi--modal-body">
          <iframe id="sinapi--modal-body-iframe-${formId}" class="sinapi--modal-body-iframe" src="" data-url="${
      WHITE_LABELS(hasWhiteLabel, company).sinapiURL
    }/public/render?id=${formId}&wid=${workspaceId}" width="100%" height="100%" style="border:none"></iframe>
        </div>
      </div>
    </div>`;

    window.document.body.insertAdjacentHTML("afterend", `<div>${link}${code}</div>`);
  }, [guid, userId, formId, workspaceId, company, hasWhiteLabel]);

  return (
    <>
      {companySubscription.loading ? (
        <LoaderSpinner />
      ) : (
        <>
          <NotificationAlert ref={notificationAlert} className="col-8" />
          <Form>
            <div className="px-lg-1">
              <Row className="text-center">
                <Col lg="4">
                  <Button
                    color={`${buttonTo === "popup" ? "info" : "link"}`}
                    className={`${buttonTo === "popup" ? "" : "text-dark"}`}
                    onClick={() =>
                      hasPopupCode
                        ? setButtonTo("popup")
                        : setShowModal({
                            type: "planSelectionCard"
                          })
                    }
                  >
                    <i className="far fa-hand-pointer fa-2x mr-1" style={{verticalAlign:"middle",paddingRight:3}} />
                    Standard Button
                  </Button>
                </Col>
                <Col lg="4">
                  <Button
                    color={`${buttonTo === "float" ? "info" : "link"}`}
                    className={`${buttonTo === "float" ? "" : "text-dark"}`}
                    onClick={() =>
                      hasPopupCode
                        ? setButtonTo("float")
                        : setShowModal({
                            type: "planSelectionCard"
                          })
                    }
                  >
                    <i className="fas fa-bullseye fa-2x mr-1" style={{verticalAlign:"middle",paddingRight:3}} />
                    Floating Button
                  </Button>
                </Col>
                <Col lg="4">
                  <Button
                    color={`${buttonTo === "embed" ? "info" : "link"}`}
                    className={`${buttonTo === "embed" ? "" : "text-dark"}`}
                    onClick={() => setButtonTo("embed")}
                  >
                    <i className="fas fa-code fa-2x mr-1" style={{verticalAlign:"middle",paddingRight:3}} />
                    Embed On Website
                  </Button>
                </Col>
                {/* <Col lg="3">
                  <Button
                    color={`${buttonTo === "link" ? "info" : "link"}`}
                    className={`${buttonTo === "link" ? "" : "text-dark"}`}
                    onClick={() => setButtonTo("link")}
                  >
                    <i className="far fa-edit fa-2x " />
                    Link To Page
                  </Button>
                </Col> */}
                {/* <Col lg="4">
              <Button
                color={`${buttonTo === "code" ? "info" : "link"}`}
                className={`${buttonTo === "code" ? "" : "text-dark"}`}
                onClick={() => {
                  hasDeveloperCode
                    ? setButtonTo("code")
                    : setShowModal({
                        type: "planSelectionCard"
                      });
                }}
              >
                <i className="fas fa-code fa-2x mr-2" />
                Get Code
              </Button>
            </Col> */}
              </Row>
              <div className="alert alert-secondary border" style={{marginTop:25}}>
                <CodeLinkElement
                  formId={formId}
                  buttonTo={buttonTo}
                  handleCopy={handleCopy}
                  workspaceId={workspaceId}
                  guid={guid}
                  userId={userId}
                  actionButtonSchema={actionButtonSchema}
                  onEditStyles={onEditStyles}
                  onClose={onClose}
                  onActiveTab={onActiveTab}
                  modalHeight={modalHeight}
                  setModalHeight={setModalHeight}
                  publishUrl={publishUrl}
                  floatingButtonSchema={floatingButtonSchema}
                />
              </div>
              <FormGroup key="link-element" style={{marginTop:"20px"}}>
                <Row>
                  <Col lg="11">
                    <h3 className="mt-4">Public {toLower(FORM_NAME)} link</h3>
                    <p className="mt-0">
                      You can link to this page from anywhere on your site which will display your {toLower(FORM_NAME)}.
                    </p>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg="11">
                    <Input
                      readOnly
                      id="input-text-link"
                      key="input-text-link"
                      type="text"
                      defaultValue={`${publishUrl}/public/render?id=${formId}&wid=${workspaceId}`}
                      onClick={() => handleCopy("input-text-link")}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </div>
          </Form>
        </>
      )}
      {showModal && showModal.type === "planSelectionCard" ? (
        <ManagementModal
          onClose={() => setShowModal(null)}
          header={<div>GET CODE</div>}
          buttons={[{ label: "Close", onClick: () => setShowModal(null) }]}
        >
          {!["consultant"].includes(userRole) ? (
            <>
              <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
                <h2 className="font-weight-400 text-justify">
                  Please{" "}
                  <Link className="font-weight-bold btn btn-primary" to="/admin/upgrade">
                    Upgrade your account
                  </Link>
                  {` to get your developer code. This feature is not allowed by your current plan.`}
                </h2>
              </CardHeader>
              <CompanySubscription
                companyData={company}
                plans={companySubscription.plans}
                price={companySubscription.compPrice}
                showDetails={false}
              />
            </>
          ) : (
            <CardHeader className="bg-transparent pl-0 pt-0 pr-0 border-0">
              <h2 className="font-weight-400 text-justify">
                {`Your plan does not support this feature. If you would like to upgrade to get the most out of Sinapi, contact your account manager for assistance.`}
              </h2>
            </CardHeader>
          )}
        </ManagementModal>
      ) : null}
    </>
  );
};

export default PublishCodeContainer;
