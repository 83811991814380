import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import "./FormTitleHeader.scss";

export function FormTitleHeader({ title, onChange }) {
  const [editTitle, setEditTitle] = useState(false);
  const [titleValue, setTitleValue] = useState(title);

  useEffect(() => {
    setTitleValue(title);
  }, [title]);

  if (editTitle) {
    return (
      <div className="form-title-header">
        <Input className="title-edit-input" value={titleValue} onChange={(e) => setTitleValue(e.target.value)} />
        <button
          className="title-edit-button mt-1 close"
          onClick={(e) => {
            e.preventDefault();
            onChange(titleValue);
            setEditTitle(false);
          }}
        >
          <span aria-hidden="true" className="fas fa-check" />
        </button>
        <button
          className="title-edit-button mt-1 close"
          onClick={(e) => {
            e.preventDefault();
            setTitleValue(title);
            setEditTitle(false);
          }}
        >
          <span aria-hidden="true" className="fas fa-times" />
        </button>
      </div>
    );
  }
  return (
    <div className="form-title-header">
      <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">{titleValue}</h6>
      <button
        className="title-edit-button mt-1 close"
        onClick={(e) => {
          e.preventDefault();
          setEditTitle(true);
        }}
      >
        <span aria-hidden="true" className="fas fa-edit" />
      </button>
    </div>
  );
}
