import React, { useEffect, useMemo, useState } from "react";

import { forEach, get, includes, map } from "lodash";

import { FormContext } from "./FormContext";

export const FormProvider = ({ schema, children, setAllFields, inputFontsize }) => {
  const [formFields, setFormFields] = useState();
  const [formFieldsWithCost, setFormFieldsWithCost] = useState();

  const formRows = useMemo(() => map(get(schema, ["sections"]), (section) => section.rows), [schema]);

  const formColumns = useMemo(() => map(formRows, (questionRow) => map(questionRow, (row) => row.columns)), [formRows]);

  useEffect(() => {
    setFormFields([]);
    forEach(formColumns, (questionColumn, sectionIdx) =>
      forEach(questionColumn, (column) =>
        forEach(column, (formQuestions) =>
          formQuestions.questions.length > 0
            ? forEach(formQuestions.questions, (question) =>
                !includes([], question.type)
                  ? setFormFields((prevState) => [
                      ...prevState,
                      {
                        id: question.id,
                        title: question.title,
                        type: question.type,
                        required: question?.required,
                        dollarValue: question?.dollarValue,
                        dynamicRules: question?.dynamicRules,
                        section: get(schema, ["sections", sectionIdx, "id"]),
                        options: question?.options,
                        hasOptionImages: includes(["radio", "checkbox"], question.type) ? get(question, "hasOptionImages", false) : false
                      }
                    ])
                  : []
              )
            : []
        )
      )
    );
  }, [formColumns]);

  useEffect(() => {
    if (setAllFields) setAllFields(formFields);
    setFormFieldsWithCost([]);
    forEach(formFields, (field) =>
      includes(["number", "select", "radio", "checkbox"], field.type)
        ? setFormFieldsWithCost((prevState) => [
            ...prevState,
            {
              id: field.id,
              title: field.title,
              type: field.type,
              dollarValue: field.dollarValue,
              section: field.section,
            }
          ])
        : []
    );
  }, [formFields, setAllFields]);

  return (
    <FormContext.Provider
      value={{
        allRows: formRows,
        allFields: formFields,
        allColumns: formColumns,
        allFieldsWithCost: formFieldsWithCost,
        inputFontsize: inputFontsize
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
