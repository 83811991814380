import React from "react";
import Loader from "react-loader-spinner";

import "./LoaderSpinner.scss";

const LoaderSpinner = () => {
  return (
    <div id="overlay">
      <div id="spinner">
        <Loader type="Rings" color="#fff" />
      </div>
    </div>
  );
};

export default LoaderSpinner;
