import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Breadcrumb, BreadcrumbItem, Button, Container, Row, Col, Input, InputGroup } from "reactstrap";
import { Link } from "react-router-dom";

function renderBreadCrumbs(breadCrumbs, breadCrumbParams) {
  breadCrumbParams = breadCrumbParams || {};
  return breadCrumbs.map((crumb) => {
    let path = crumb.path === "/" ? crumb.layout : crumb.layout + crumb.path;
    let name = crumb.name;
    Object.keys(breadCrumbParams).forEach((key) => {
      path = path.replace(":" + key, breadCrumbParams[key]);
      name = name.replace(":" + key, breadCrumbParams[key]);
    });
    return (
      <BreadcrumbItem key={path}>
        <Link to={path}>{name}</Link>
      </BreadcrumbItem>
    );
  });
}

const TimelineHeader = ({
  name,
  button,
  parentName,
  breadCrumbs,
  breadCrumbParams,
  hideBreadcrumbs,
  showSearchBox = false,
  onChangeSearch = null
}) => {
  return (
    <>
      <div className="header header-dark bg-gradient-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h1 className="fullcalendar-title text-white d-inline-block mb-0">{name}</h1>{" "}
                {hideBreadcrumbs ? null : (
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-lg-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <Link to="/admin/dashboard">
                        <i className="fas fa-home" />
                      </Link>
                    </BreadcrumbItem>
                    {breadCrumbs ? renderBreadCrumbs(breadCrumbs, breadCrumbParams) : null}
                    {parentName ? (
                      <BreadcrumbItem>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          {parentName}
                        </a>
                      </BreadcrumbItem>
                    ) : null}
                    <BreadcrumbItem aria-current="page" className="active">
                      {name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                )}
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                {button ? (
                  <Button color={button?.color ?? "neutral"} size="sm" onClick={button.action}>
                    {button.label}
                  </Button>
                ) : null}
                {showSearchBox && typeof onChangeSearch === "function" ? (
                  <InputGroup>
                    <Input
                      name="name"
                      className="form-control"
                      placeholder="Search Company Name"
                      onChange={onChangeSearch}
                    />
                  </InputGroup>
                ) : null}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  showFilter: PropTypes.bool,
  showNew: PropTypes.bool,
  breadCrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  ),
  hideBreadcrumbs: PropTypes.bool,
  breadCrumbParams: PropTypes.object
};

TimelineHeader.defaultProps = {
  hideBreadcrumbs: true
};

export default TimelineHeader;
