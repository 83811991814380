import React, { useState } from "react";
import { FieldLabel } from "./FieldLabel";
import { optionWithIcon } from "./fieldUtils";
import { getUniqueID, defaultOnChange } from "./HelperFunctions";
import defaultImage from "../../../assets/img/default-image.png";
import { get } from "lodash";
import classNames from "classnames";
import { AWS_S3_ASSETS_BUCKET } from "utils/constants";

export const getImageURL = (optionImage) => {
  const imagePath = get(optionImage, ["imagePath"], false);
  if (imagePath) {
    const src =
      typeof imagePath === "string"
        ? imagePath !== ""
          ? `${AWS_S3_ASSETS_BUCKET}/${imagePath}`
          : defaultImage
        : URL.createObjectURL(imagePath.file);
    return src;
  }
  return defaultImage;
};

const RenderOption = ({ option, questionID, value, onChange, hoveredId, setHoveredId }) => {
  const { optionImage, name, id } = option;
  const activeSize = get(option, ["optionImage", "activeSize"], "width");
  const width = optionImage?.width || "100";
  const height = optionImage?.height || "100";
  const size = optionImage?.size || "%";
  const imgSrc = get(optionImage, ["imagePath"], false) ? getImageURL(optionImage) : defaultImage;
  const optionWidth = optionImage?.optionWidth || 0;
  const optionWidthUnit = optionImage?.optionWidthUnit || 'px';
  
  return !option.hasIcon ? (
    <div
      key={id}
      className={classNames("image-option", "custom-control", {
        "image-option-selected": id === value || hoveredId === id,
        "image-option-checked": id === value
      })}
      onMouseEnter={() => setHoveredId(id)}
      onMouseLeave={() => setHoveredId(null)}
    >
      <input
        id={id}
        name={questionID}
        value={id}
        type="radio"
        onChange={(e) => {
          onChange(e);
          setHoveredId(null);
        }}
        checked={id === value}
      />
      <label
        htmlFor={id}
        className={classNames("image-option-label", {
          "image-option-label-selected": id === value || hoveredId === id
        })}
        style={{
          width: optionWidth === 0 ? "100px" : `${optionWidth}${optionWidthUnit}`,
        }}
      >
        <div className="image-wrapper">
          <img
            src={imgSrc}
            alt=""
            style={{
              width: activeSize === "height" ? "auto" : `${width + size}`,
              height: activeSize === "width" ? "auto" : `${height + size}`,
              alignSelf: "center"
            }}
          />
        </div>
        <span className="mt-1 mx-auto text-center">{name}</span>
      </label>
    </div>
  ) : (
    <div
      key={id}
      className={classNames("image-option", "custom-control", {
        "image-option-selected": id === value || hoveredId === id,
        "image-option-checked": id === value
      })}
      onMouseEnter={() => setHoveredId(id)}
      onMouseLeave={() => setHoveredId(null)}
    >
      <input
        id={id}
        name={questionID}
        value={id}
        type="radio"
        onChange={(e) => {
          onChange(e);
          setHoveredId(null);
        }}
        checked={id === value}
      />
      <label
        htmlFor={id}
        className={classNames("image-option-label", {
          "image-option-label-selected": id === value || hoveredId === id
        })}
        style={{
          width: optionWidth === 0 ? "" : `${optionWidth}${optionWidthUnit}`,
        }}
      >
        <div className="image-wrapper">
          <i
            className={option.iconClass || "fas fa-smile-wink"}
            style={{
              fontSize: Number(option.iconSize) || 60,
              color: option.iconColor || "rgba(51, 51, 51, 1)",
              alignSelf: "center"
            }}
          ></i>
        </div>
        <span className="mt-1 mx-auto text-center">{name}</span>
      </label>
    </div>
  );
};

const RadioButtonField = (props) => {
  const { options, id, value, hasOptionImages, floatClass } = props.question;
  const { onChange = defaultOnChange } = props;
  const [hoveredId, setHoveredId] = useState(null);
  const questionID = id;

  return (
    <>
      <FieldLabel question={props.question} />
      <div className={classNames("custom-radio-list", floatClass)} style={{ display: "flex", flexWrap: "wrap" }}>
        {options.map((option, i) => {
          const { name, id } = option;
          return !hasOptionImages ? (
            <div key={id} className="custom-control custom-radio mb-3 mr-4">
              <input
                className="custom-control-input"
                id={id}
                name={questionID}
                value={id}
                type="radio"
                onChange={onChange}
                checked={id === value}
              />
              <label className="custom-control-label custom-option-label" htmlFor={id}>
                {name && name}
              </label>
            </div>
          ) : (
            <RenderOption
              option={option}
              questionID={questionID}
              value={value}
              onChange={onChange}
              key={id}
              setHoveredId={setHoveredId}
              hoveredId={hoveredId}
            />
          );
        })}
      </div>
    </>
  );
};

const PreviewRadioButtonField = () => optionWithIcon("far fa-dot-circle", "Single choice");

const getInitialData = () => ({
  id: getUniqueID(),
  title: "Radio Buttons",
  description: "<p>Please Select An Option</p>",
  required: false,
  type: "radio",
  style: "inline",
  dollarValue: true,
  affectCost: "fixed-cost",
  costStructure: ["cost"],
  hasOptionImages: false,
  options: [
    {
      id: `${getUniqueID()}_1`,
      name: "Option 1",
      icon: "",
      cost: 0,
      cost_type: "+",
      daily: 0,
      daily_type: "+",
      monthly: 0,
      monthly_type: "+",
      yearly: 0,
      yearly_type: "+",
      // size: "px"
    },
    {
      id: `${getUniqueID()}_2`,
      name: "Option 2",
      icon: "",
      cost: 0,
      cost_type: "+",
      daily: 0,
      daily_type: "+",
      monthly: 0,
      monthly_type: "+",
      yearly: 0,
      yearly_type: "+",
      // size: "px"
    },
    {
      id: `${getUniqueID()}_3`,
      name: "Option 3",
      icon: "",
      cost: 0,
      cost_type: "+",
      daily: 0,
      daily_type: "+",
      monthly: 0,
      monthly_type: "+",
      yearly: 0,
      yearly_type: "+",
      // size: "px"
    }
  ]
});

const fieldConfig = {
  key: "radio",
  component: RadioButtonField,
  getInitialData: getInitialData,
  previewComponent: PreviewRadioButtonField,
  getEditOptions: () => {
    return;
  }
};

export default fieldConfig;
